import React from 'react';
import { mapOptionsToInputOption } from '@fabrictech/tapestry/components/InputOption';
import { LOGROCKET_HIDE_CLASS_NAME } from '@fabrictech/tapestry/constants/logrocket';

const FabricGroupItemField = ({ isSecure, render, options }) => {
  // any input type can be a fabric group item, therefore, we need to perform
  // our option mapping here as well
  const optionElements = mapOptionsToInputOption(options);

  return (
    <div className={isSecure ? LOGROCKET_HIDE_CLASS_NAME : undefined}>
      {render({
        children: optionElements,

        // Some of our legacy Inputs require option JSON, so we still pass it
        // TODO: remove when those have been refactored
        options,
      })}
    </div>
  );
};

export default FabricGroupItemField;
