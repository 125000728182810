import React from 'react';
import { css } from 'glamor';

import { Align, IconType, input } from '@fabrictech/design-tokens';

import pickGlobalProps from '../../utils/pickGlobalProps';

import InputWrapper from '../InputWrapper';
import {
  getNumberInputStyles,
  getMultilineStyles,
  getIconClickStyles,
} from './getStyles';

const numberInputClassName = css(getNumberInputStyles());
const multilineClassName = css(getMultilineStyles());
const iconClickClassName = css(getIconClickStyles());

const randomTokenForAutoComplete = 'b279134b-3909-4894-8674-9e88cfc7e18b';

// This is a hack to counteract aggressive autocomplete functionality in Chrome
// when using react controlled components. Unfortunately, this behavior can
// still be observed in safari, and edge completely ignores the `autocomplete`
// attribute and will always prompt for autocomplete. We should definitely
// revisit this if the autocomplete behavior in our forms is a detriment to the
// user experience. I spent a long time trying to get the functionality working
// across browsers and forms, but it remains inconsistent, browsers have their
// own heuristics for determining which fields to fill.
const allowAutoCompleteOnlyOnEmpty = (
  value?: string | number | string[],
  defaultValue?: string
) => (value ? randomTokenForAutoComplete : defaultValue || '');

interface HTMLInputProps extends React.HTMLProps<HTMLTextAreaElement> {
  /** Allows for multi-line text input */
  multiline: true;
}

interface HTMLTextAreaProps extends React.HTMLProps<HTMLInputElement> {
  /** Allows for multi-line text input */
  multiline?: false;
}

type HTMLProps = HTMLInputProps | HTMLTextAreaProps;

export type InputTextProps = HTMLProps & {
  /** Specifies a label below the InputText */
  helperText?: string;
  /** Determines alignment of the placeholder and value */
  textAlign?: Align;
  /** Function to trigger when icon is clicked */
  onIconClick?: (event: React.SyntheticEvent) => void;
  /** Class to mark as containing sensitive information.
   *
   * TODO: Currently not being used. Let's hook this up to `isSecure` prop in `FormField`  */
  secureClassName?: string;
  /** Specifies the icon */
  icon?: IconType;
  /** Indicates whether the InputText's styles should reflect the validity of the input */
  validation?: boolean;
  /** A short label that is placed inside the field after the value */
  suffix?: string;
  /** HTML input type to use */
  type?: 'text' | 'password' | 'number' | 'tel' | 'email';
  /** Hints at the type of data that might be entered by the user while editing the element or its contents */
  inputMode?: HTMLInputProps['inputMode'];
  /** Value for the input */
  value?: string | number | string[];
  /** Width of the input */
  width?: string | number;
  /** For testing use */
  inputDataTestName?: string;
  /** Simulates focus state */
  'data-simulate-focus'?: boolean;
  /** Simulates hover state */
  'data-simulate-hover'?: boolean;
};

const defaultProps = {
  type: 'text',
  value: '',
  width: input.layout.width,
};
/** Text fields are used to gather specific data from the user. Text fields typically reside in forms but can appear in other places, like dialog */
export const InputText = (props: InputTextProps) => {
  const invalid = props.validation === false;
  const autoComplete = allowAutoCompleteOnlyOnEmpty(
    props.value,
    props.autoComplete
  );
  return (
    <InputWrapper
      className={props.className}
      helperText={props.helperText}
      invalid={invalid}
      disabled={props.disabled}
      readOnly={props.readOnly}
      width={props.width}
      textAlign={props.textAlign}
      icon={props.icon}
      secureClassName={props.secureClassName}
      suffix={props.suffix}
      hasValue={!!props.value}
      placeholder={props.placeholder}
      label={props.label}
      iconProps={{
        onClick: props.onIconClick,
        ...(props.onIconClick && { className: iconClickClassName }),
      }}
      {...pickGlobalProps(props)}
    >
      {props.multiline === true ? (
        <textarea
          rows={props.rows || 3}
          // @ts-ignore - className expects a type of `string`, but we want a style object to maintain precedence order when combining with other classes in InputWrapper
          className={multilineClassName}
          data-simulate-hover={props['data-simulate-hover']}
          data-simulate-focus={props['data-simulate-focus']}
          disabled={props.disabled}
          value={props.value}
          id={props.id}
          onBlur={props.onBlur}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onKeyPress={props.onKeyPress}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
          inputMode={props.inputMode}
        />
      ) : (
        <input
          type={props.type}
          inputMode={props.inputMode}
          // @ts-ignore - className expects a type of `string`, but we want a style object to maintain precedence order when combining with other classes in InputWrapper
          className={props.type === 'number' ? numberInputClassName : undefined}
          autoComplete={autoComplete}
          data-simulate-hover={props['data-simulate-hover']}
          data-simulate-focus={props['data-simulate-focus']}
          disabled={props.disabled}
          value={props.value}
          id={props.id}
          data-test={props.inputDataTestName}
          onBlur={props.onBlur}
          onChange={props.onChange}
          onFocus={props.onFocus}
          onKeyPress={props.onKeyPress}
          placeholder={props.placeholder}
          readOnly={props.readOnly}
        />
      )}
    </InputWrapper>
  );
};

InputText.defaultProps = defaultProps;

export default InputText;
