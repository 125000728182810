import createGetStyles from '../../createGetStyles';

// Unfortunately, this is a required hack.
// Basically, the `getStyles` created for `<InputToggle>`'s child elements
// (derived from passed-in `<InputOption>`'s) hardcodes a padding for the label
// and margins for the options themselves that makes the row of `[10, 15, 20,
// 25, 30]` year term lengths too wide to fit in the 320 px mobile Widget
// Container. The negative margins remove some of the padding on the buttons,
// making the row thin enough to fit.
// TODO: See if this is still necessary for the internal 385px treatment
// @see https://fabrictech.atlassian.net/browse/GRW-3876
const narrowButtonInteriorStyles = {
  mobileBreakpoint: {
    marginLeft: -1,
    marginRight: -1,
  },
};

const styles = {
  narrowButtonInteriorStyles,
};

export default createGetStyles(styles);
