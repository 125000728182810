import React from 'react';
import _ from 'lodash';
import { css } from 'glamor';

import { input } from '@fabrictech/design-tokens';
import { Merge } from '@fabrictech/definitely-fabric';

import pickGlobalProps from '../../utils/pickGlobalProps';
import InputWrapper from '../InputWrapper';
import getStyles from './getStyles';

export type InputDropdownProps = Merge<
  React.HTMLProps<HTMLSelectElement>,
  {
    /** Children */
    children: React.ReactElement[];
    /** The string that will be rendered before text input has been entered */
    placeholder?: string;
    /** Displays a label that floats above the selected value */
    label?: string;
    /** Displays text below the input */
    helperText?: string;
    /** Indicates whether the input's styles should reflect the validity of the input */
    validation?: boolean;
    /** For testing use */
    inputDataTestName?: string;
    /** Disables the component */
    disabled?: boolean;
    /** The selected value */
    value?: string | number;
    /** Width of the component */
    width?: string | number;
    /** onChange handler */
    onChange?: (value: string | number) => void;
    /** Simulates active state */
    'data-simulate-active'?: boolean;
    /** Simulates focus state */
    'data-simulate-focus'?: boolean;
    /** Simulates hover state */
    'data-simulate-hover'?: boolean;
  }
>;

export const InputDropdown = ({
  placeholder = 'Select an option',
  label,
  children,
  width = input.layout.width,
  disabled = false,
  onChange,
  className,
  id,
  helperText,
  value = '',
  inputDataTestName,
  'data-simulate-active': dataSimulateActive,
  'data-simulate-hover': dataSimulateHover,
  'data-simulate-focus': dataSimulateFocus,
  validation,
  ...rest
}: InputDropdownProps) => {
  const invalid = validation === false;

  const hasValue = !(_.isUndefined(value) || value === '');
  const hasLabel = !!label;

  const selectClassName = css(getStyles({ hasValue, hasLabel, disabled }));

  return (
    <InputWrapper
      className={className}
      helperText={helperText}
      invalid={invalid}
      disabled={disabled}
      width={width}
      label={label}
      hasValue={!!value}
      icon="arrowDown"
      {...pickGlobalProps(rest)}
    >
      <select
        id={id}
        // @ts-ignore - className expects a type of `string`, but we want a style object to maintain precedence order when combining with other classes in InputWrapper
        className={selectClassName}
        disabled={disabled}
        onChange={onChange ? e => onChange(e.target.value) : undefined}
        value={value}
        data-simulate-active={dataSimulateActive}
        data-simulate-hover={dataSimulateHover}
        data-simulate-focus={dataSimulateFocus}
        data-test={inputDataTestName}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {React.Children.map(children, (option, idx) => (
          <option key={idx} {...option.props}>
            {option.props.children}
          </option>
        ))}
      </select>
    </InputWrapper>
  );
};

export default InputDropdown;
