import { border, color, spacer } from '@fabrictech/design-tokens';

import { standardShadow } from '../../sharedStyles/vars/boxShadow';

const { borderWidth, borderRadius, borderStyle } = border;

const getStyles = ({
  hasShadow,
  sideBorders,
  borderWidth: borderWidthProp = borderWidth,
}: {
  /** Adds side borders */
  sideBorders?: boolean;
  /** Adds shadow */
  hasShadow?: boolean;
  /** Controls width of border */
  borderWidth?: number;
}) => ({
  borderRadius,
  boxShadow: hasShadow ? standardShadow : 'none',
  minHeight: spacer(7),
  textAlign: 'center',
  borderColor: color.border.base,
  borderStyle,
  borderWidth: borderWidthProp,
  ...(sideBorders ? {} : { borderLeft: 0, borderRight: 0 }),
});

export default getStyles;
