const getStyles = () => ({
  '::-webkit-file-upload-button': {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
    padding: 0,
    margin: 0,
    border: 0,
  },
});

export default getStyles;
