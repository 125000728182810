import { ValueOf } from '../type-utils';

export * from './applicationRequirements';
export * from './termInsurance';
export * from './date';
export * from './fabrictechTable';
export * from './ineligibilityTypes';
export * from './insurancePricing';
export * from './quoteInteractionNames';
export * from './agency';

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/services/underwriting/lib/products/index.ts
 *
 * Fabric's insurance products. Allows us to exhaustively check an application's
 * product types. If you add a new product with MGA=Fabric, please add to
 * `FabricMgaInsuranceProduct` and `FabricMgaInsuranceProductEnum`
 *
 * Note that this is a subset of `Product`. If you add something here, you
 * should add it there as well.
 *
 * They are separate because you can not simply extend/spread enums in TS.
 */
export enum InsuranceProduct {
  instant = 'instant',
  premium = 'premium',
  premiumAu = 'premium-au',
  premiumPt = 'premium-pt',
}

export const fabricInsuranceProducts = {
  wslTerm: 'wsl-term',
  instant: 'instant',
  premium: 'premium',
  premiumAu: 'premium-au',
  premiumPt: 'premium-pt',
} as const;

export type FabricInsuranceProduct = ValueOf<typeof fabricInsuranceProducts>;

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/services/underwriting/lib/products/index.ts
 *
 * All of fabric's products. Provides a set of constants for referencing them.
 *
 * Note that this is a superset of `InsuranceProduct`. If you add something
 * here, consider if it is a valid `InsuranceProduct` and added there as well.
 *
 * They are separate because you can not simply extend/spread enums in TS.
 */
export enum Product {
  instant = 'instant',
  premium = 'premium', // "premium classic"
  premiumAu = 'premium-au',
  premiumPt = 'premium-pt',
  wslTerm = 'wsl-term',
  will = 'will',
  vault = 'vault',
  finances = 'finances',
}

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/src/lib/agency/constants/index.ts
 */
export enum Mga {
  fabric = 'fabric',
  vantis = 'vantis',
}

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/services/account-management/lib/dynamoDbTables/acceptTerms/types.ts
 */
export enum TermsId {
  esignConsent = 'esignConsent',
  privacyPolicy = 'privacyPolicy',
  securityPolicy = 'securityPolicy',
  termsOfUse = 'termsOfUse',
  referralProgram = 'referralProgram',
}

export const isTermsId = (id: string): id is TermsId =>
  Object.values(TermsId).includes(id as TermsId);

export const titleByTermsId: Record<TermsId, string> = {
  [TermsId.esignConsent]: 'E-Sign Agreement',
  [TermsId.privacyPolicy]: 'Privacy Policy',
  [TermsId.securityPolicy]: 'Security Policy',
  [TermsId.termsOfUse]: 'Terms of Use',
  [TermsId.referralProgram]: 'Referral Program',
};

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/services/account-management/lib/dynamoDbTables/authorizations/types.ts
 */

export const agreementIds = {
  hipaaAuthorization: 'hipaaAuthorization',
} as const;

export type AgreementId = ValueOf<typeof agreementIds>;

// These agreements are required for Fabric to be able to gather third-party data
export const requiredAgreementIds = {
  [agreementIds.hipaaAuthorization]: agreementIds.hipaaAuthorization,
} as const;

export type RequiredAgreementId = ValueOf<typeof requiredAgreementIds>;

export const isAgreementId = (id: string): id is AgreementId =>
  Object.values(agreementIds).includes(id as AgreementId);

export const titleByAgreementId: Record<AgreementId, string> = {
  hipaaAuthorization: 'HIPAA Authorization',
};

export const shortNameByAgreementId: Record<AgreementId, string> = {
  hipaaAuthorization: 'HIPAA Authorization',
};

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/services/quilt-utils/lib/quilt/constants.ts
 */
export enum QuiltRelease {
  '1.0.0' = '1.0.0',
  '1.0.1' = '1.0.1',
  '1.0.2' = '1.0.2',
  '1.1.0' = '1.1.0',
  '1.2.0' = '1.2.0',
  '1.3.0' = '1.3.0',
  '1.4.0' = '1.4.0',
  '1.6.0' = '1.6.0',
  '1.6.1' = '1.6.1',
  '1.6.2' = '1.6.2',
}

export type UuidV4 = string;
export type Ulid = string;

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/0cbd15341acf62c6dd0456a0e01234b8ded2de9b/node/services/data/lib/dynamoDbTables/fabrictech/policy/policy.ts#L94
 */

export const fabricPolicyStatuses = {
  cancel: 'cancel',
  claim: 'claim',
  claimPending: 'claimPending',
  claimPaid: 'claimPaid',
  claimComplete: 'claimComplete',
  claimDenied: 'claimDenied',
  claimResisted: 'claimResisted',
  claimPartiallyPaid: 'claimPartiallyPaid',
  expiry: 'expiry',
  inForce: 'inForce',
  grace: 'grace',
  lapse: 'lapse',
  notTaken: 'notTaken',
} as const;

export type FabricPolicyStatus = ValueOf<typeof fabricPolicyStatuses>;

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/0cbd15341acf62c6dd0456a0e01234b8ded2de9b/node/services/reports/lib/wsl-extracts/types.ts#L110
 */
export type PlanCode = '10FT1' | '15FT1' | '20FT1' | '25FT1' | '30FT1';

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/0cbd15341acf62c6dd0456a0e01234b8ded2de9b/node/services/insurance-pricing/lib/rate/premium/types.ts#L6
 */
export interface ModalPremiums {
  monthly: number;
  quarterly: number;
  semiAnnual: number;
  annual: number;
}

export type PremiumModality = keyof ModalPremiums;
