import { Size } from '../sizes';

export const breakpoints: { [key in Size]: number } = {
  xs: 0,
  sm: 544,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
};
