import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { input } from '@fabrictech/design-tokens';

import pickGlobalProps from '../../utils/pickGlobalProps';

import Box from '../Box';
import Selection from './components/Selection';
import SelectionWithIcon from './components/SelectionWithIcon';

/**
 * Component that enables for selection of options.
 *
 * Don't use it directly! Use `InputRadioText` for single selection and `InputCheckbox` for multiple selections
 * */
class InputSelection extends React.Component {
  name = _.uniqueId('name');

  render() {
    const {
      children,
      value,
      onChange,
      onBlur,
      onFocus,
      id,
      normalizer,
      selectionComponent,
      disabled,
      borderless,
      type,
      width,
      name,
      // BoxProps
      className,
      block,
      flexDirection,
      ...rest
    } = this.props;
    const childrenArr = React.Children.toArray(children);
    const Component =
      selectionComponent ||
      (_.every(childrenArr, 'props.icon') // If a selection component is defined, use it!
        ? SelectionWithIcon // Else, if every child has an icon, use this one,
        : Selection); // or use this component
    return (
      <Box
        id={id}
        className={className}
        block={block}
        flexDirection={flexDirection}
        width={width}
        marginLeft="auto"
        marginRight="auto"
        marginBottom={input.layout.marginBottom}
        {...pickGlobalProps(rest)}
      >
        {childrenArr.map((child, index) => {
          const selectionProps = {
            type,
            key: index,
            checked:
              !_.isNil(value) &&
              value.toString() === child.props.value.toString(),
            onChange: _.isFunction(onChange)
              ? e => onChange(e.target.value)
              : null,
            onBlur: _.isFunction(onBlur) ? e => onBlur(e.target.value) : null,
            onFocus: _.isFunction(onFocus)
              ? e => onFocus(e.target.value)
              : null,
            first: index === 0,
            last: index === childrenArr.length - 1,
            borderless,
            name: name || this.name,
            width,
            ...child.props,
            disabled: disabled || child.props.disabled,
          };
          return (
            <Component {...selectionProps}>{child.props.children}</Component>
          );
        })}
      </Box>
    );
  }
}

InputSelection.propTypes = {
  /** Children */
  children: PropTypes.any.isRequired,
  /** Value */
  value: PropTypes.string,
  /** onChange handler */
  onChange: PropTypes.func,
  /** onBlur handler */
  onBlur: PropTypes.func,
  /** onFocus handler */
  onFocus: PropTypes.func,
  /** Unique id  */
  id: PropTypes.string,
  /** Unique name for grouping  */
  name: PropTypes.string,
  /** Component to use for selection */
  selectionComponent: PropTypes.any,
  /** Indicates if InputCheckbox is in a disabled state */
  disabled: PropTypes.bool,
  /** Determines if InputCheckbox should be borderless */
  borderless: PropTypes.bool,
  /** HTML input type */
  type: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  /** Width */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InputSelection.defaultProps = {
  block: true,
};

export default InputSelection;
