import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import getBaseStyles from './getStyles';

import createGetStylesWithGlobalProps from '../../utils/createGetStylesWithGlobalProps';

const getStyles = createGetStylesWithGlobalProps(getBaseStyles);

/** Displays document image and contains signatures via `DocumentSignature` */
export class DocumentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: 1,
    };
  }

  render() {
    const {
      children,
      src,
      height,
      width,
      scale,
      index,
      handleAddScrollTarget,
      secureClassName,
      ...rest
    } = this.props;

    const classNames = `${css(getStyles(rest))} ${secureClassName}`;
    return (
      <div className={classNames}>
        <img onLoad={this.props.handleImageLoad} src={src} />
        {React.Children.map(children, (child, childIndex) =>
          React.isValidElement
            ? React.cloneElement(child, {
                docHeight: height,
                docWidth: width,
                docScale: scale,
                docIndex: index,
                sigIndex: childIndex,
                handleAddScrollTarget:
                  handleAddScrollTarget &&
                  handleAddScrollTarget(index, childIndex),
              })
            : child
        )}
      </div>
    );
  }
}

DocumentPage.propTypes = {
  /** The src of the image */
  src: PropTypes.string,
  /** The width of the element for positioning signatures  */
  width: PropTypes.string,
  /** The height of the element for positioning sigatures */
  height: PropTypes.string,
  /** Image load event handler */
  handleImageLoad: PropTypes.func,
  /** Class to mark the DocumentPage as containing sensitive information */
  secureClassName: PropTypes.string,
};

export default DocumentPage;
