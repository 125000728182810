import { Diff } from '@fabrictech/definitely-fabric';
import { breakpoints, Size } from '@fabrictech/design-tokens';

import {
  queryGte,
  queryLt,
  queryBetween,
} from '../../utils/css/queryBreakpoint';

export type QueryType = 'gte' | 'lt' | 'btwn';
export type PartialQuerySize = Diff<Size, 'xs'>;

export type GteQueries = { [key in PartialQuerySize]: string };

export const gte: GteQueries = {
  sm: queryGte(breakpoints.sm),
  md: queryGte(breakpoints.md),
  lg: queryGte(breakpoints.lg),
  xl: queryGte(breakpoints.xl),
  xxl: queryGte(breakpoints.xxl),
};

export type LtQueries = { [key in PartialQuerySize]: string };

export const lt: LtQueries = {
  sm: queryLt(breakpoints.sm),
  md: queryLt(breakpoints.md),
  lg: queryLt(breakpoints.lg),
  xl: queryLt(breakpoints.xl),
  xxl: queryLt(breakpoints.xxl),
};

export type BtwnQueries = {
  [x in Diff<Size, 'xs' | 'xxl'>]:
    | { [key in Diff<Size, 'xs' | 'sm'>]: string }
    | { [key in Diff<Size, 'xs' | 'sm' | 'md'>]: string }
    | { [key in Diff<Size, 'xs' | 'sm' | 'md' | 'lg'>]: string }
    | { [key in Diff<Size, 'xs' | 'sm' | 'md' | 'lg' | 'xl'>]: string };
};

export const btwn: BtwnQueries = {
  sm: {
    md: queryBetween(breakpoints.sm, breakpoints.md),
    lg: queryBetween(breakpoints.sm, breakpoints.lg),
    xl: queryBetween(breakpoints.sm, breakpoints.xl),
    xxl: queryBetween(breakpoints.sm, breakpoints.xxl),
  },
  md: {
    lg: queryBetween(breakpoints.md, breakpoints.lg),
    xl: queryBetween(breakpoints.md, breakpoints.xl),
    xxl: queryBetween(breakpoints.md, breakpoints.xxl),
  },
  lg: {
    xl: queryBetween(breakpoints.lg, breakpoints.xl),
    xxl: queryBetween(breakpoints.lg, breakpoints.xxl),
  },
  xl: {
    xxl: queryBetween(breakpoints.xl, breakpoints.xxl),
  },
};

export const highDpi: string =
  '@media all and (-webkit-min-device-pixel-ratio : 1.5), all and (-o-min-device-pixel-ratio: 3/2), all and (min--moz-device-pixel-ratio: 1.5), all and (min-device-pixel-ratio: 1.5)';

export const onSmallPhone = queryLt(360); // Anything smaller than a Galaxy S9, such as an iPhone 5

const mediaQueries: {
  gte: GteQueries;
  lt: LtQueries;
  btwn: BtwnQueries;
} = {
  gte,
  lt,
  btwn,
};

export default mediaQueries;
