import React from 'react';
import { css } from 'glamor';

import { spacer, color, border } from '@fabrictech/design-tokens';
import { calc, padding } from '../../utils/css/values';
import mediaQueries from '../../sharedStyles/vars/mediaQueries';
import { queryLt } from '../../utils/css/queryBreakpoint';

import { GlobalProps } from '../../types';

import Text from '../Text';
import Box from '../Box';
import Icon from '../Icon';
import Button, { ButtonProps } from '../Button/Button';

export type ExternalAdCardProps = GlobalProps & {
  /** Header text and image url */
  header: {
    text: string;
    image?: string;
  };
  /** Button props */
  buttonProps: ButtonProps;
  /** Optional custom html to be rendered after the header */
  customHtml?: string;
  /** Optional boolean to determine whether featured tab should be rendered */
  featured?: boolean;
};

const featuredCornerTabWrapperClassName = css({
  position: 'absolute',
  top: 0,
  left: 0,
});
const featuredCornerTabContainerClassName = css({
  position: 'relative',
});
const cornerTabSideLength = spacer(7);
/**
 * This styles an isosceles right triangle (top left corner)
 */
const featuredCornerTabTriangleClassName = css({
  borderTop: `${cornerTabSideLength}px solid ${color.palette.purple}`,
  borderRight: `${cornerTabSideLength}px solid transparent`,
});
const cornerTabIconTopLeftMargin = 5;
/**
 * This styles the featured icon, a `star` icon at the time of writing, to be
 * positioned within a top left corner triangle.
 */
const featuredCornerTabIconClassName = css({
  position: 'absolute',
  top: -(cornerTabSideLength - cornerTabIconTopLeftMargin),
  left: cornerTabIconTopLeftMargin,
  display: 'block',
});
const FeaturedTab = () => (
  <Box className={featuredCornerTabWrapperClassName}>
    <Box className={featuredCornerTabContainerClassName}>
      <Box className={featuredCornerTabTriangleClassName}>
        <span className={featuredCornerTabIconClassName.toString()}>
          <Icon type="star" size="sm" color={color.background.primary} />
        </span>
      </Box>
    </Box>
  </Box>
);

const mobileGutterWidth = spacer(5);
const adCardBoxClassName = css({
  // Magic numbers here come from the design spec.
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  display: 'block',
  // Accounting for the 320 phone size so that a full card is shown
  [queryLt(320)]: { width: calc(`100vw - ${mobileGutterWidth * 2}px`) },
});
const ExternalAdCard = ({
  header,
  buttonProps,
  customHtml,
  featured = false,
  ...rest
}: ExternalAdCardProps) => (
  <Box
    flexDirection="column"
    padding={padding(spacer(5), spacer(4))}
    align="center"
    width={spacer(37)}
    marginBottom={0}
    backgroundColor={color.background.primary}
    className={adCardBoxClassName}
    {...rest}
  >
    {/** Optional featured tab */}
    {featured ? <FeaturedTab /> : null}

    {/** Optional header image */}
    {header.image ? (
      <Box
        block
        backgroundColor={color.background.primary}
        align="center"
        padding={spacer(2)}
        maxWidth={spacer(22)}
        marginBottom={0}
        marginLeft="auto"
        marginRight="auto"
        className={css({ ...border, borderColor: color.palette.silver })}
      >
        <img
          src={header.image}
          className={css({ width: '100%', height: 'auto' }).toString()}
        />
      </Box>
    ) : null}

    {/** Header */}
    <Box
      minHeight={spacer(18)}
      verticalAlign="center"
      marginBottom={0}
      padding={padding(spacer(3), 0)}
    >
      <Text
        header
        textAlign="center"
        rank={4}
        margin="auto"
        responsiveProps={{
          [mediaQueries.gte.sm]: {
            rank: 3,
          },
        }}
      >
        {header.text}
      </Text>
    </Box>

    {/** Optional custom html to be rendered after the header */}
    {customHtml ? (
      <div dangerouslySetInnerHTML={{ __html: customHtml }} />
    ) : null}

    {/**
     * Button
     * TODO: GRW-3485 Update button hover style to match design
     */}
    <Button
      {...{
        width: '100%',
        hasShadow: false,
        marginBottom: 0,
        color: color.base.success,
        borderless: true,
        ...buttonProps,
      }}
    />
  </Box>
);

export default ExternalAdCard;
