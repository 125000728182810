import React from 'react';
import { css, Rule } from 'glamor';
import ReactInlineSvg from 'react-inlinesvg';

import {
  sizes,
  color as colorTokens,
  spacer,
  icons,
  IconType,
  Size,
} from '@fabrictech/design-tokens';

import Box from '../Box';

import { GlobalProps } from '../../types';

import { getBadgeStyles, getSvgStyles } from './getStyles';

export type IconProps = GlobalProps & {
  /** The color of the icon */
  color?: string;
  /** The size of the icon */
  size?: Size;
  /** Displays the icon within a badge */
  badge?: boolean;
  /** The type of the icon TODO: Props type and svgDataUrl should be mutually exclusive. */
  type?: IconType;
  /** Custom icon svg */
  svgDataUrl?: string;
  /** Optional className */
  className?: Rule | 'string';
};

// Applying extra space around the icon in a badge
const badgePadding = spacer(2);

/** Used to display icons  */
export const Icon = ({
  type,
  size = 'md',
  color = colorTokens.base.accent,
  badge = false,
  className,
  svgDataUrl,
  ...rest
}: IconProps) => {
  const sideLength = sizes.icon[size];
  const badgeClassName = css(getBadgeStyles());
  const svgClassname = css(
    getSvgStyles({ sideLength, badge, color })
  ).toString();

  // TODO: Again, these props should be mutually exclusive. For now,
  // we force the type on the declaration so we can move on. Yuck.
  const svgSrc = (type ? icons[type] : svgDataUrl) as string;

  return (
    <Box
      block
      {...(badge
        ? {
            className: badgeClassName,
            background: color,
            padding: badgePadding,
            // 1 spacing unit on each side to account for extra padding
            width: sideLength + badgePadding * 2,
            height: sideLength + badgePadding * 2,
          }
        : {})}
      {...rest}
    >
      <ReactInlineSvg
        src={svgSrc}
        wrapper={React.createFactory('div')}
        className={svgClassname}
      />
    </Box>
  );
};

export default Icon;
