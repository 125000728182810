/* eslint-disable id-length */

export const fromPx = value => Number(String(value).replace(/px$/, ''));
export const toPx = value => (value > 0 || value < 0 ? `${value}px` : value);

const operate = fn => (first, ...rest) =>
  toPx(
    rest.reduce(
      (accumulator, value) => fn(accumulator, fromPx(value)),
      fromPx(first)
    )
  );

export const plus = operate((a, b) => a + b);
export const minus = operate((a, b) => a - b);
export const times = operate((a, b) => a * b);
export const divide = operate((a, b) => a / b);

const compare = fn => (a, b) => fn(fromPx(a), fromPx(b));

export const gt = compare((a, b) => a > b);
export const gte = compare((a, b) => a >= b);
export const lt = compare((a, b) => a < b);
export const lte = compare((a, b) => a <= b);
