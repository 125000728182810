import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import _ from 'lodash';
import SmoothCollapse from 'react-smooth-collapse';

import { spacer } from '@fabrictech/design-tokens';

import InputTextAuto from '../InputTextAuto';
import Button from '../Button';

import getBaseStyles from './getStyles';
import createGetStylesWithGlobalProps from '../../utils/createGetStylesWithGlobalProps';
import Text from '../Text';

const getStyles = createGetStylesWithGlobalProps(getBaseStyles);

/** Wraps `InputTextAuto` to provide for selecting multiple items */
class InputTag extends React.Component {
  constructor(props) {
    super(props);

    this.addValue = this.addValue.bind(this);
    this.deleteValue = this.deleteValue.bind(this);
  }

  addValue(valueToAdd) {
    const { onChange, onTextChange, value, valueKey } = this.props;
    const newValue = new Set(value.map(v => (valueKey ? v[valueKey] : v)));
    newValue.add(valueToAdd);
    onTextChange && onTextChange('');
    onChange && onChange(Array.from(newValue));
  }

  deleteValue(valueToDelete) {
    const { onChange, value, valueKey } = this.props;
    const newValue = new Set(value.map(v => (valueKey ? v[valueKey] : v)));
    newValue.delete(valueToDelete);
    onChange && onChange(Array.from(newValue));
  }

  render() {
    const {
      onChange, // pulling this out so it doesn't get applied in `rest`.
      options,
      limit,
      valueKey,
      labelKey,
      value,
      textValue,
      onTextChange,
      marginBottom,
      ...rest
    } = this.props;
    const className = css(getStyles(this.props));
    return (
      <div className={className} marginBottom={marginBottom}>
        <InputTextAuto
          disabled={value.length >= limit}
          value={textValue}
          valueKey={valueKey}
          labelKey={labelKey}
          options={options}
          onChange={onTextChange}
          onSelect={this.addValue}
          marginBottom={0}
          {...rest}
        />
        <SmoothCollapse expanded={!_.isEmpty(value)}>
          <ul>
            {value.map(v => {
              const keyedValue = valueKey ? v[valueKey] : v;
              const keyedLabel = labelKey ? v[labelKey] : v;
              return (
                <li key={keyedValue}>
                  <Button
                    block
                    rank="secondary"
                    icon="x"
                    size="xs"
                    marginRight={spacer(1)}
                    marginBottom={0}
                    onClick={() => this.deleteValue(keyedValue)}
                    hasShadow={false}
                  />
                  <Text rank={2} marginBottom={0}>
                    <strong>{keyedLabel}</strong>
                  </Text>
                </li>
              );
            })}
          </ul>
        </SmoothCollapse>
      </div>
    );
  }
}

InputTag.propTypes = {
  /** A predetermined list of suggested values for the input. */
  options: PropTypes.array,
  /** An optional maximum number of selections allowed. */
  limit: PropTypes.number,
  /** If `items` contains an Object, this key is where to retrieve the "value" from. */
  valueKey: PropTypes.string,
  /** If `items` contains an Object, this key is where to retrieve the "label" from. */
  labelKey: PropTypes.string,
  /** The current array value that the input is displaying. */
  value: PropTypes.array,
  /** The current text value of the text input. Not representative of any selected tags. */
  textValue: PropTypes.string,
  /** A handler that gets triggered everytime the input’s text value changes. */
  onTextChange: PropTypes.func,
  /** A handler that gets triggered everytime a tag is selected. */
  onChange: PropTypes.func,
};

export default InputTag;
