import ImmutablePureComponent from 'react-immutable-pure-component';
import _ from 'lodash';

const isNotEqual = (...args) => !_.isEqual(...args);

class ExclusiveOptionQuestionAdapter extends ImmutablePureComponent {
  getValue() {
    // TODO: InputValueAdapter allows Immutable data structures to leak into
    // into the components it wraps. This normalizes those values.
    return this.props.value.toJS ? this.props.value.toJS() : this.props.value;
  }

  getExclusiveOption() {
    const constraints = this.props.constraints.toJS
      ? this.props.constraints.toJS()
      : this.props.constraints;

    return constraints.exclusiveOption;
  }

  getShouldProcessOptions() {
    return this.getExclusiveOption() && !_.isEmpty(this.getValue());
  }

  getIsExclusiveOptionSelected() {
    return this.props.value.includes(this.getExclusiveOption());
  }

  transformOptions(options) {
    const check = this.getIsExclusiveOptionSelected() ? isNotEqual : _.isEqual;
    return options.reduce(
      (acc, option) =>
        acc.concat(
          check(option.id, this.getExclusiveOption())
            ? { ...option, disabled: true }
            : option
        ),
      []
    );
  }

  render() {
    const { render } = this.props;
    const options = this.getShouldProcessOptions()
      ? this.transformOptions(this.props.options)
      : this.props.options;
    return render({ ...this.props, options });
  }
}

export default ExclusiveOptionQuestionAdapter;
