export const globalProps = {
  marginTop: 'marginTop',
  marginRight: 'marginRight',
  marginBottom: 'marginBottom',
  marginLeft: 'marginLeft',
  margin: 'margin',
  paddingTop: 'paddingTop',
  paddingRight: 'paddingRight',
  paddingBottom: 'paddingBottom',
  paddingLeft: 'paddingLeft',
  padding: 'padding',
  hidden: 'hidden',
};

export const responsiveProps = 'responsiveProps';
