import { border, RebrandColorPalette } from '@fabrictech/design-tokens';

import { important, margin } from '../../utils/css/values';
import { hover, descendant, active, focus } from '../../utils/css/selectors';

const { borderWidth, borderStyle } = border;

const getHoverStyles = ({
  isPrimary,
  textClassName,
  color,
  hasTextBorder,
}: {
  isPrimary: boolean;
  textClassName: string;
  color: string;
  hasTextBorder: boolean;
}) => {
  /*
   * Because of the way this component has evolved over time, allowing
   * the user to override the color is no longer an easy change. In
   * several places below we check to see if the color is the default
   * and modify accordingly, which is obviously not straightforward.
   *
   * TODO: Rebuild the way in which styles are being passed to this
   * component
   * @see https://fabrictech.atlassian.net/browse/GRW-2380
   */
  const hoverColor =
    color === RebrandColorPalette.midnight
      ? RebrandColorPalette.purple
      : RebrandColorPalette.white;

  return {
    borderColor: isPrimary ? hoverColor : 'transparent',
    backgroundColor: isPrimary ? hoverColor : 'transparent',
    [descendant(`.${textClassName}`)]: {
      ...(isPrimary
        ? {
            color:
              color === RebrandColorPalette.midnight
                ? RebrandColorPalette.white
                : RebrandColorPalette.midnight,
          }
        : {
            color:
              color === RebrandColorPalette.midnight
                ? RebrandColorPalette.purpleMid
                : color,
            borderBottomStyle: hasTextBorder ? borderStyle : 'none',
            borderBottomWidth: hasTextBorder ? borderWidth : '0',
            borderBottomColor:
              color === RebrandColorPalette.midnight
                ? RebrandColorPalette.purpleMid
                : color,
          }),
    },
    '& svg path': {
      fill:
        color === RebrandColorPalette.midnight
          ? RebrandColorPalette.white
          : RebrandColorPalette.midnight,
    },
  };
};

const getStyles = ({
  block,
  disabled,
  isPrimary,
  color,
  backgroundColor,
  borderless = false,
  hasTextBorder = true,
  textClassName,
  width = 'auto',
}: {
  block: boolean;
  disabled: boolean;
  isPrimary: boolean;
  color: string;
  backgroundColor: string;
  hasTextBorder: boolean;
  borderless: boolean;
  textClassName: string;
  width: string | number;
}) => {
  const hoverStyles = getHoverStyles({
    isPrimary,
    textClassName,
    color,
    hasTextBorder,
  });
  return {
    backgroundColor,
    margin: margin(0, 'auto', 0, 'auto'),
    ...(isPrimary
      ? {
          borderColor: borderless ? 'transparent' : color,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 24,
          paddingRight: 24,
        }
      : {
          borderColor: 'transparent',
          padding: 0,
          [descendant(`.${textClassName}`)]: {
            borderBottomStyle: hasTextBorder ? borderStyle : 'none',
            borderBottomWidth: hasTextBorder ? borderWidth : '0',
            borderBottomColor:
              color === RebrandColorPalette.midnight
                ? RebrandColorPalette.purple
                : color,
          },
        }),
    height: 48,
    textDecoration: important('none'),
    display: block ? 'block' : 'inline-block',
    cursor: disabled ? 'auto' : 'pointer',
    width,
    minHeight: 50,
    borderWidth,
    borderStyle,
    [hover]: disabled ? undefined : hoverStyles,
    [focus]: disabled ? undefined : hoverStyles,
    [active]: disabled ? undefined : hoverStyles,
    ...(disabled ? { opacity: 0.5 } : {}),
  };
};

export const getTextStyles = ({
  foregroundColor,
  color,
}: {
  foregroundColor: string;
  color: string;
}) => ({
  fontFeatureSettings: '"lnum" 1',
  fontVariantNumeric: 'lining-nums',
  fontSize: '13px',
  lineHeight: '19px',
  fontFamily: 'Inter',
  fontWeight: 'bold',
  color: color === RebrandColorPalette.midnight ? foregroundColor : color,
  textAlign: 'center',
  textTransform: 'uppercase',
  letterSpacing: 1.5,
});

export default getStyles;
