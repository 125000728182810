import { color, spacer, border, input } from '@fabrictech/design-tokens';
import {
  firstChild,
  focus,
  hover,
  lastChild,
  and,
} from '../../utils/css/selectors';
import { standardShadow } from '../../sharedStyles/vars/boxShadow';
import { inputTextStyles, inputPadding } from '../../sharedStyles/vars/input';

import { aligns } from '../../constants/layout';

const { borderRadius } = border;

export const getMenuStyles = ({ align, width }) => ({
  background: color.background.primary,
  ...border,
  borderColor: color.border.base,
  boxShadow: standardShadow,
  borderRadius,
  marginTop: spacer(1),
  position: 'absolute',
  ...(align && align === aligns.left ? { left: 0 } : { right: 0 }),
  minWidth: spacer(30),
  width: width || 'auto',
  ':empty': {
    opacity: 0,
  },
});

const focusStyles = {
  color: color.font.inverse.primary,
  background: color.base.accent,
  outline: '1px solid transparent',
};

export const getItemStyles = ({ hasFocus }) => ({
  ...inputTextStyles,
  minHeight: input.layout.height,
  color: color.font.primary,
  cursor: 'pointer',
  ...inputPadding,
  ...(hasFocus ? focusStyles : {}),
  [and(focus, hover)]: focusStyles,
  [firstChild]: {
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
  },
  [lastChild]: {
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
  },
});
