import buildCampaign from './buildCampaign';
import buildSearch from './utils/buildSearch';
import buildPage from './buildPage';
import buildScreen from './buildScreen';
import buildTimezoneOffset from './buildTimezoneOffset';

const getClientContextData = pkg => {
  const sentAt = new Date().toISOString();
  const appName = pkg.name;
  const appVersion = pkg.version;
  const search = buildSearch();
  const campaign = buildCampaign(search);
  const campaignName = campaign.name;
  const campaignSource = campaign.source;
  const campaignMedium = campaign.medium;
  const campaignTerm = campaign.term;
  const campaignContent = campaign.content;
  const page = buildPage();
  const pagePath = page.path;
  const pageReferrer = page.referrer;
  const pageSearch = page.search;
  const pageTitle = page.title;
  const pageUrl = page.url;
  const screen = buildScreen();
  const screenWidth = screen.width;
  const screenHeight = screen.height;
  const screenDensity = screen.density;
  const timezoneOffset = buildTimezoneOffset();

  return {
    sentAt,
    appName,
    appVersion,
    campaignName,
    campaignSource,
    campaignMedium,
    campaignTerm,
    campaignContent,
    pagePath,
    pageReferrer,
    pageSearch,
    pageTitle,
    pageUrl,
    screenWidth,
    screenHeight,
    screenDensity,
    timezoneOffset,
  };
};

export const buildClientContext = (state = {}, pkg) => {
  const leadId = state.getIn && state.getIn(['lead', 'data', 'ids', 'leadId']);
  const identityId =
    state.getIn && state.getIn(['auth', 'credentials', 'identityId']);
  const clientData = getClientContextData(pkg);

  return {
    leadId,
    identityId,
    ...clientData,
  };
};

export const buildSwatchClientContext = (leadId, pkg) => {
  const clientData = getClientContextData(pkg);
  return {
    ...(leadId ? { leadId } : {}),
    ...clientData,
  };
};
