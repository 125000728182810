import React from 'react';

import InputSelection from '../InputSelection/InputSelection';

/**
 * Checkbox fields can stand alone or be stacked together. Multiple can be selected at the same time and you can uncheck your selections.
 * */
const InputCheckbox = props => <InputSelection {...props} type="checkbox" />;

export default InputCheckbox;
