import React from 'react';
import InputGroup from '@fabrictech/tapestry/components/InputGroup';

/**
 * fabricGroups are special, they manage state for their child questions, rather
 * than just rendering them.
 *
 * This component wraps the generic `InputGroup`, passing off some of the props
 * passsed from its adapter down to its `children`. The `children` are Question
 * components, passed in by `FabricGroupField`. This allows this field to manage
 * the validation/state of those child fields.
 */
const FabricGroupInput = ({
  // QuestionnaireInputGroupField
  colSpans,
  responsiveProps,
  id,
  children, // array of Question components

  // FormField
  marginBottom,

  // FabricQuestionGroupAdapter
  helperText,
  onReportError,
  onBlur,
  onFocus,
  suppressErrorMessages,
  validation,
}) => (
  <InputGroup
    colSpans={colSpans}
    responsiveProps={responsiveProps}
    marginBottom={marginBottom}
    helperText={helperText}
    validation={validation}
    id={id}
  >
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        onReportError,
        onBlur,
        onFocus,
        suppressErrorMessages,
      })
    )}
  </InputGroup>
);

export default FabricGroupInput;
