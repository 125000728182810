/**
 * **LINEN DUPLICATION WARNING:** Keep in sync. 🤢
 *   node/src/create-analytics-event/propertySchemasByType.ts
 */
export const quoteInteractionNames = {
  amendmentChangedCoverage: 'amendment_changed_coverage',
  getStartedChangedCoverage: 'get_started_changed_coverage',
  quoteChangedCoverage: 'quote_changed_coverage',
  quoteChangedTermLength: 'quote_changed_term_length',
  quoteGoBack: 'quote_go_back',
  quoteOverMillion: 'quote_over_million',
  quoteReceived: 'quote_received',
  quoteRequested: 'quote_requested',
};
