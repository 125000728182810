import React from 'react';
import { css } from 'glamor';
import Slider from 'rc-slider';

import { input } from '@fabrictech/design-tokens';

import { GlobalProps } from '../../types';
import pickGlobalProps from '../../utils/pickGlobalProps';
import Box from '../Box';

import getStyles from './getStyles';

export type InputSliderProps = GlobalProps & {
  /** The minimum value of the slider */
  min?: number;
  /** The maximum value of the slider */
  max?: number;
  /** Current value of the slider */
  value: number;
  /** Value to be added or subtracted on each step the slider makes */
  step?: number;
  /** If true, handles can't be moved. */
  disabled?: boolean;
  /** onChange will be triggered while the value of Slider changing */
  onChange: (value: number) => void;
  /** onAfterChange will be triggered after an interaction with the slider.
   *  Triggers on mouseUp and on the click after the last interaction.
   */
  onAfterChange: () => void;
  /** Width  */
  width?: number | string;
};

/**
 * Used to select a value in a set range.
 * As a slider’s value changes, the portion of track between the minimum value and the thumb fills with color.
 * */
export const InputSlider = ({
  min = 0,
  max = 100,
  value,
  step = 1,
  disabled = false,
  onChange,
  onAfterChange,
  width = 300,
  ...rest
}: InputSliderProps) => {
  const globalProps = pickGlobalProps(rest);
  const className = css(getStyles({ disabled }));
  return (
    <Box
      block
      width={width}
      marginLeft="auto"
      marginRight="auto"
      marginBottom={input.layout.marginBottom}
      {...globalProps}
    >
      <Slider
        className={className.toString()}
        min={min}
        max={max}
        value={value}
        step={step}
        disabled={disabled}
        onChange={onChange}
        onAfterChange={onAfterChange}
        vertical={false}
      />
    </Box>
  );
};

export default InputSlider;
