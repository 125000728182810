/**
 * This matches the type used within TS's definitions for helpers like `ReturnType<>`.
 *
 * The ts provided `Function` type is not as generic as one might think. For instance,
 * it throws when passed to `ReturnType<>`.
 *
 * Note that this should only be used with an `extends`, usually for a generic,
 * it _should not_ be used as a backdoor `any` type to get around ESLint.
 *
 * Ex.
 * `const func = <SomeFn extends AnyFunction>(fn: SomeFn): ReturnType<SomeFn> => fn();`
 */
export type AnyFunction = (...args: any[]) => any; // eslint-disable-line @typescript-eslint/no-explicit-any

/**
 * A type that matches any object.
 * @see https://github.com/microsoft/TypeScript/wiki/Breaking-Changes#-k-string-unknown--is-no-longer-a-wildcard-assignment-target
 *
 * Note that this should only be used with an `extends`, usually for a generic,
 * it _should not_ be used as a backdoor `any` type to get around ESLint.
 *
 * Ex.
 * `type ThingDoer<T extends AnyObject, U extends AnyObject> = (foo: T) => U
 */
export type AnyObject = { [key: string]: any };

/**
 * Like Typescript's standard `Omit<T, K>`, but properly distributes the `Omit` over a union type.
 *
 * Ex.
 * ```
 *   import { StandardTextFieldProps, FilledTextFieldProps, OutlinedTextFieldProps } from '@mui/material';
 *
 *   type TextFieldProps = StandardTextFieldProps | FilledTextFieldProps | OutlinedTextFieldProps;
 *   type Foo = Omit<TextFieldProps, 'select'>;
 * ```
 * This doesn't match `TextFieldProps` since the `variant` prop is not properly picked.
 * ```
 *   type Bar = DistributiveOmit<TextFieldProps, 'select'>;
 * ```
 * This properly creates:
 * ```
 *   type Bar =
 *     | Omit<StandardTextFieldProps, 'select'>
 *     | Omit<FilledTextFieldProps, 'select'>
 *     | Omit<OutlinedTextFieldProps, 'select'>;
 * ```
 */
export type DistributiveOmit<T, K extends PropertyKey> = T extends unknown
  ? Omit<T, K>
  : never;

/**
 * Opposite of `DistributiveOmit`. `K extends keyof T` since `Pick`'s `K` must be `keyof T`.
 */
export type DistributivePick<T, K extends keyof T> = T extends unknown
  ? Pick<T, K>
  : never;

/**
 * Prefers the types of T2's attributes to T1's where there is overlap, unlike
 * A & B, where the types of overlapping attributes are intersected.
 *
 * Conceptually similar to the results of `Object.assign`, but note that the actual
 * type result of `Object.assign(A, B)` is, confusingly, A & B.
 *
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#intersection-types
 *
 * Ex.
 * type A = { a: number, b: number };
 * type B = { b: string, c: string };
 *
 * const unioned: A & B = { a: 1, b: 2, c: 'c' }; // ERROR!
 * // === { a: number, b: number & string, c: number } (b is number & string, never)
 *
 * const merged: Merge<A, B> = { a: 1, b: 'b', c: 'c' };
 * // === { a: number, b: string, c: number } (b is just B.b, string)
 */
export type Merge<T1, T2> = Pick<T1, Exclude<keyof T1, keyof T2>> & T2;

/**
 * Like partial, which makes all attributes on T optional, but allows you to make
 * only a subset of keys optional.
 *
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#mapped-types
 *
 * Ex.
 * type A = { a: number, b: number, c: number };
 *
 * const partial: Partial<A> = { b: 1 };
 * // === { a?: number, b?: number, c?: number };
 *
 * const partialSubset: PartialSubset<A, 'b' | 'c'> = { b: 1 }; // ERROR! 'a' is required
 * // === { a: number, b?: number, c?: number }; (a is still required)
 */
export type PartialSubset<T extends AnyObject, O extends keyof T> = Merge<
  T,
  { [P in O]?: T[P] }
>;

/**
 * The opposite of PartialSubset, it makes the specified keys Required.
 *
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#mapped-types
 *
 * Ex.
 * type A = { a?: number, b?: number, c?: number };
 * const required: Required<A> = { a: 1, b: 1, c: 1};
 * // === { a: number, b: number, c: number };
 *
 * const requiredSubset: RequiredSubset<A, 'b' | 'c'> = { a: 1, b: 1, c: 1 }; // OK: `a` is optional
 * const requiredSubset2: RequiredSubset<A, 'b' | 'c'> = { b: 1, c: 1 }; // OK: `a` is optional
 * const requiredSubset3: RequiredSubset<A, 'b' | 'c'> = { c: 1 }; // ERROR: `b` is required
 * // === { a?: number, b: number, c: number }; (a is still optional, the rest have become required)
 */
export type RequiredSubset<T extends AnyObject, O extends keyof T> = Merge<
  T,
  { [P in O]-?: T[P] }
>;

/**
 * All keys become optional, but at least one must be present.
 */
export type AtLeastOneRequired<T extends AnyObject> = {
  [K in keyof T]: RequiredSubset<Partial<T>, K>;
}[keyof T];

/**
 * Unpacks a type of Promise<T> to access T.
 *
 * Useful in cases where the resolved value of an async function isn't explicitly typed on its own.
 * Ex.
 *
 * // some async funtion that returns a type we do not have explicitly definied elsewhere.
 * const getItems = async () => [{value: 1}];
 *
 * // some function to be called after `getItems` has resolved.
 * // Here since `getItems` returns a promise, `ReturnType` is not sufficent for accessing the resolved type.
 * const processItems = (items: ReturnType<typeof getItems>) => {
 *  const itemValues = items.map(item => item.value); // error, Property 'map' does not exist on type 'Promise<{ value: number; }[]>'. Did you forget to use 'await'?
 *  ...
 * };
 *
 * // UnpackPromise gets the internal type successfully.
 * const processItems = (items: UnpackPromise<ReturnType<typeof getItems>>) => {
 *  const itemValues = items.map(item => item.value); // no error, value is correctly inferred to be of type number.
 * ...
 * };
 *
 */
export type UnpackPromise<T> = T extends Promise<infer U> ? U : T;

/** Unpacks a type of T[] to access T */
export type UnpackArray<T> = T extends (infer U)[] ? U : T;

/**
 * Remove types from T that are assignable to U
 *
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#example-1
 */
export type Diff<T, U> = T extends U ? never : T;

/**
 * Remove types from T that are not assignable to U
 *
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#example-1
 */
export type Filter<T, U> = T extends U ? T : never;

/**
 * Return values of an object type as a union
 *
 * Useful in cases when we want to have a value of an object as a valid type.
 * Ex.
 * ```
 * type Fruit = {
 *   redApple: 'red-apple',
 *   banana: 'banana',
 *   greenApple: 'green-apple',
 * }
 * type FruitType = ValueOf<Fruit>;
 * Lunchbag.fruit: FruitType = 'green-apple'
 * ```
 */
export type ValueOf<T> = T[keyof T];

/** Boolean record with all false values except for specified keys */
type AllFalseExcept<KeyUnion extends string, TrueKey extends KeyUnion> = {
  [K in KeyUnion]: K extends TrueKey ? true : false;
};

/** Boolean record with exactly one true value */
export type ExactlyOneTrue<KeyUnion extends string> = {
  [K in KeyUnion]: AllFalseExcept<KeyUnion, K>;
}[KeyUnion];

/** Boolean record with at most one true value */
export type AtMostOneTrue<KeyUnion extends string> =
  | ExactlyOneTrue<KeyUnion>
  | { [K in KeyUnion]: false };

/** Convert a union type into an intersection type
 *
 * ex: `A | B` => `A & B`
 *
 * @see https://stackoverflow.com/a/50375286
 */
export type UnionToIntersection<T> = (T extends any
  ? (k: T) => void
  : never) extends (k: infer I) => void
  ? I
  : never;

/** Union of the keys of the constituent parts of a union type
 *
 * ex: `A | B` => `keyof A | keyof B`
 *
 * Note that `keyof (A | B) === (keyof A) & (keyof B)`
 */
export type UnionOfKeysOf<T> = keyof UnionToIntersection<T>;

/**
 * Union of function types for up to 4 function overloads.
 *
 * Ex.
 *
 * ```
 * function times2(a: string): string;
 * function times2(a: number): number;
 * function times2(a: any): any {
 *   return a + a;
 * }
 *
 * // ((a: string) => string) | ((a: number) => number)
 * type TimesTwo = FunctionOverloadUnion<typeof times2>:
 * ```
 *
 */
export type FunctionOverloadUnion<F extends AnyFunction> = F extends {
  (...args: infer A1): infer R1;
  (...args: infer A2): infer R2;
  (...args: infer A3): infer R3;
  (...args: infer A4): infer R4;
}
  ?
      | ((...args: A1) => R1)
      | ((...args: A2) => R2)
      | ((...args: A3) => R3)
      | ((...args: A4) => R4)
  : F extends {
      (...args: infer A1): infer R1;
      (...args: infer A2): infer R2;
      (...args: infer A3): infer R3;
    }
  ? ((...args: A1) => R1) | ((...args: A2) => R2) | ((...args: A3) => R3)
  : F extends {
      (...args: infer A1): infer R1;
      (...args: infer A2): infer R2;
    }
  ? ((...args: A1) => R1) | ((...args: A2) => R2)
  : F extends {
      (...args: infer A1): infer R1;
    }
  ? (...args: A1) => R1
  : never;

/**
 * Select the appropriate function overload type by the parameter type
 *
 * Ex.
 *
 * ```
 * function times2(a: string): string;
 * function times2(a: number): number;
 * function times2(a: any): any {
 *   return a + a;
 * }
 *
 * // ((a: string) => string)
 * type StringTimesTwo = SelectOverloadFromParams<typeof times2, [string]>:
 * ```
 */
export type SelectOverloadFromParams<
  Func extends AnyFunction,
  Params extends any[]
> = Filter<FunctionOverloadUnion<Func>, (...args: Params) => any>;

/**
 * Helper to make it easier to write "unit tests" for a given type.
 *
 * @see https://2ality.com/2019/07/testing-static-types.html
 */
export type AssertEqual<T, Expected> = T extends Expected
  ? (Expected extends T ? true : never)
  : never;

/**
 * Useful to enforce exhaustiveness.
 * @see https://basarat.gitbooks.io/typescript/docs/types/never.html
 */
export class UnreachableCaseError extends Error {
  constructor(val: never, label: string = 'Unreachable case') {
    super(`${label}: ${val}`);
  }
}

export type OptionalPick<T, K extends PropertyKey> = Pick<
  T,
  Extract<keyof T, K>
>;
