import { spacer, icons, sizes, color } from '@fabrictech/design-tokens';

import tags from '../../../../constants/tags';
import createBackgroundSvgString from '../../../../utils/css/createBackgroundSvgString';
import { url } from '../../../../utils/css/values';
import { toPx } from '../../../../utils/css/values/px';
import {
  checked,
  hover,
  focus,
  generalSibling,
  descendant,
  and,
  after,
} from '../../../../utils/css/selectors';

const getStyles = ({ icon, disabled }) => ({
  marginBottom: spacer(1),
  display: 'flex',
  [descendant(tags.input)]: {
    borderRadius: 0,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    border: 'none',
    backgroundColor: 'transparent',
    backgroundSize: sizes.icon.md,
    backgroundPosition: `${toPx(spacer(2))} center`,
    backgroundRepeat: 'no-repeat',
    backgroundImage: url(
      createBackgroundSvgString({
        svg: icons[icon],
        fill: color.input.base.accent,
      })
    ),
    [generalSibling(tags.label)]: {
      borderBottomColor: color.input.base.border,
      paddingTop: spacer(2),
      paddingBottom: spacer(2),
      paddingLeft: spacer(8),
      opacity: disabled ? 0.3 : undefined,
    },
    [checked]: {
      backgroundColor: 'transparent',
      backgroundImage: url(
        createBackgroundSvgString({
          svg: icons.checkmark,
          fill: color.input.base.accent,
        })
      ),
      backgroundSize: sizes.icon.md,
      // Adds a spot of background color to the checked icon
      [after]: {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: spacer(1.5),
        zIndex: -1,
        transform: `translateY(${sizes.icon.lg * -0.5}px)`,
        height: sizes.icon.lg,
        width: sizes.icon.lg,
        borderRadius: 1000,
        backgroundColor: color.input.active.accent,
      },
      [and(focus, hover)]: {
        backgroundColor: 'transparent',
        [generalSibling(tags.label)]: { backgroundColor: 'transparent' },
      },
    },
  },
});

export default getStyles;
