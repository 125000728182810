import _ from 'lodash';

import { toPx } from '../px';

const isDefined = val => !_.isUndefined(val);

const validMeasureWhiteList = ['0', 'auto'];

const measureRegEx = /(px|em|rem|vw|vh)$/;
const getIsMeasure = value =>
  _.isNumber(value) ||
  validMeasureWhiteList.indexOf(value) > -1 ||
  (_.isString(value) && value.match(measureRegEx));

const validateIsMeasure = value => {
  if (!getIsMeasure(value)) {
    throw Error(`Invalid measure - "${value}"`);
  }
};
const transformClockwiseBoxMeasures = (...tokens) => {
  tokens.forEach(validateIsMeasure);
  const [top, right, bottom, left] = tokens;
  const result = [toPx(top)];

  if (isDefined(right)) {
    if (
      top !== right ||
      (bottom && top !== bottom) ||
      (isDefined(left) && right !== left)
    ) {
      result.push(toPx(right));
    }
    if (isDefined(bottom)) {
      if (top !== bottom || (isDefined(left) && right !== left)) {
        result.push(toPx(bottom));
      }
      if (isDefined(left) && right !== left) {
        result.push(toPx(left));
      }
    }
  }
  return result.join(' ');
};

export default transformClockwiseBoxMeasures;
