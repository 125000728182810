import React from 'react';

import { css } from 'glamor';

import { inputToggleFixedWidth } from './constants';

import pickGlobalProps from '../../utils/pickGlobalProps/pickGlobalProps';

import InputWrapper from '../InputWrapper';
import InputSelection from '../InputSelection';
import Selection from '../InputSelection/components/Selection';
import getStyles from './getStyles';

const InputToggleSelection = props => (
  <Selection {...props} className={css(getStyles(props))} type="radio" />
);

/**
 * `InputToggle` allows for choosing between two exclusive options with short text.
 *
 * For more options or longer text, use `InputDropdown` or `InputRadioText`.
 * */
export const InputToggle = ({ children, helperText, id, width, ...rest }) => {
  width = width || children.length * inputToggleFixedWidth;
  return (
    <InputWrapper borderless width={width} {...pickGlobalProps(rest)}>
      <InputSelection
        id={id}
        block={false}
        flexDirection="row"
        type="radio"
        width={width}
        selectionComponent={InputToggleSelection}
        children={children}
        {...rest}
      />
    </InputWrapper>
  );
};
export default InputToggle;
