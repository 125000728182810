import { css } from 'glamor';

const opacityLow = 0.4;
const opacityHigh = 0.6;

const pulse = css.keyframes({
  '0%': { opacity: opacityLow },
  '30%': { opacity: opacityHigh },
  '70%': { opacity: opacityHigh },
  '100%': { opacity: opacityLow },
});

const delay = '0.45s';
const length = '1.5s';

const getStyles = ({ isLoading, shouldPulse }) => ({
  pointerEvents: isLoading ? 'none' : 'auto',
  transition: `opacity ${delay}`,
  opacity: isLoading ? opacityLow : 1,
  animation:
    isLoading && shouldPulse
      ? `${pulse} ${length} ${delay} linear infinite forwards`
      : 'none',
});

export default getStyles;
