import { color, typography } from '@fabrictech/design-tokens';
import {
  getStyleObjectFromFontStyle,
  mergeResponsiveStyles,
} from '@fabrictech/tapestry';
import createGetStyles from '../createGetStyles';

const baseContainerStyles = {
  background: color.background.inverse.primary,
  borderRadius: 3,
  height: 14,
  width: 24,
};
// these attributes ensure the `<AdIndicator />` floats at the top of its
// relatively-positioned container element and is centered horizontally.
const containerCenteringStyles = {
  position: 'absolute',
  top: 9,
  left: '50%',
  marginRight: -baseContainerStyles.width,
  mobileBreakpoint: {
    top: 6,
  },
};
// these attributes ensure the content in the container is
// vertically/horizontally centered
const contentCenteringStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
};
const containerStyles = mergeResponsiveStyles(
  baseContainerStyles,
  containerCenteringStyles,
  contentCenteringStyles
);

const contentFont = {
  ...typography.paragraph[1],
  fontSize: {
    desktop: 9.5,
    mobile: 9.5,
  },
  lineHeight: {
    desktop: 20,
    mobile: 20,
  },
  color: color.font.inverse.primary,
  letterSpacing: 0,
};

const contentFontStyles = getStyleObjectFromFontStyle(
  contentFont,
  'mobileBreakpoint'
);
const contentStyles = mergeResponsiveStyles(contentFontStyles, {
  textAlign: 'center',
  margin: 0,
});

const styles = {
  containerStyles,
  contentStyles,
};

export default createGetStyles(styles);
