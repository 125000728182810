import { spacer } from '@fabrictech/design-tokens';
import {
  getStyleObjectFromFontStyle,
  mediaQueries,
  mergeResponsiveStyles,
  mergeTextStyles,
} from '@fabrictech/tapestry';
import {
  marketingHeader,
  marketingParagraph,
} from '@fabrictech/tapestry/components/MarketingText/getStyles';
import createGetStyles from '../createGetStyles';

const mobileBreakpoint = 'mobileBreakpoint';

// WidgetInstructions styles
const titleFontStyles = mergeTextStyles(marketingHeader[2], marketingHeader[3]);

const titleMarginMobileStyles = {
  marginTop: spacer(1.5),
  marginBottom: spacer(1.25),
};

const titleMarginStyles = {
  marginTop: spacer(3.75),
  marginBottom: spacer(1.75),
  mobileBreakpoint: titleMarginMobileStyles,
  [mediaQueries.lt.sm]: titleMarginMobileStyles,
};

const internalTitleFontStyles = {
  ...marketingHeader[4],
  fontSize: {
    ...marketingHeader[4].fontSize,
    mobile: marketingHeader[4].fontSize.desktop,
  },
  lineHeight: {
    ...marketingHeader[4].lineHeight,
    mobile: marketingHeader[4].lineHeight.desktop,
  },
};

const internalTitleFont = getStyleObjectFromFontStyle(
  internalTitleFontStyles,
  mobileBreakpoint
);

const internalTitleFontSm = getStyleObjectFromFontStyle(
  internalTitleFontStyles,
  mediaQueries.lt.sm
);

// This is a hack for 320px. TODO: Remove once using 385 treatment
// @see https://fabrictech.atlassian.net/browse/GRW-3876
const internalTitle320MarginStyles = mergeResponsiveStyles(titleMarginStyles, {
  marginLeft: spacer(-1),
  marginRight: spacer(-1),
  mobileBreakpoint: {
    marginBottom: spacer(4),
  },
  [mediaQueries.lt.sm]: {
    marginBottom: spacer(4),
  },
});

const internalTitleStyles = mergeResponsiveStyles(
  internalTitleFont,
  internalTitleFontSm,
  internalTitle320MarginStyles
  // Add this back when removing internalTitle320MarginStyles
  // TODO: @see https://fabrictech.atlassian.net/browse/GRW-3876
  // titleMarginStyles
);

const captionFontStyles = mergeTextStyles(
  marketingParagraph[2],
  marketingParagraph[3]
);

const captionMarginStyles = {
  marginBottom: spacer(1.25),
};

const captionMobileMarginStyles = {
  marginBottom: spacer(2.25),
};

const titleFont = getStyleObjectFromFontStyle(
  titleFontStyles,
  mobileBreakpoint
);
const titleFontSm = getStyleObjectFromFontStyle(
  titleFontStyles,
  mediaQueries.lt.sm
);

const captionFont = getStyleObjectFromFontStyle(
  captionFontStyles,
  mobileBreakpoint
);

const textContainerStyles = {
  flexDirection: 'column',
  marginRight: spacer(2.5),
  mobileBreakpoint: {
    marginRight: 0,
  },
};

const titleStyles = mergeResponsiveStyles(
  titleFont,
  titleFontSm,
  titleMarginStyles
);

const captionStyles = mergeResponsiveStyles(captionFont, captionMarginStyles, {
  mobileBreakpoint: captionMobileMarginStyles,
  [mediaQueries.lt.sm]: captionMobileMarginStyles,
});

const styles = {
  internalTitleStyles,
  textContainerStyles,
  titleStyles,
  captionStyles,
};

export default createGetStyles(styles);
