import React from 'react';
import { css } from 'glamor';
import ReactInlineSvg from 'react-inlinesvg';

import { QuestionStatus } from '@fabrictech/questionnaire/utils/constants';

import Box from '../../../Box';

import {
  getWrapperStyles,
  getFlagStyles,
  getFlagSpinnerStyles,
  getSvgStyles,
} from './getStyles';

import flagSvg from './flag.svg';

const wrapperClassName = css(getWrapperStyles()).toString();

const formFieldSpinnerClassName = css(getFlagSpinnerStyles()).toString();

const FormFieldSpinner = () => (
  <div className={formFieldSpinnerClassName} data-test="FormFieldSpinner">
    <div />
    <div />
    <div />
  </div>
);

const FormFieldFlag = ({ status }: { status: QuestionStatus }) => {
  const svgClassName = css(getSvgStyles({ status })).toString();
  return status ? (
    <div className={wrapperClassName} title={status}>
      <Box className={css(getFlagStyles({ status }))}>
        <ReactInlineSvg
          src={flagSvg}
          wrapper={React.createFactory('div')}
          className={svgClassName}
        />
        {status === 'working' ? <FormFieldSpinner /> : null}
      </Box>
    </div>
  ) : null;
};

export default FormFieldFlag;
