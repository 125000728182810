import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const wwwApi = createApi({
  reducerPath: 'wwwApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: headers => {
      if (Config.apiKey) {
        headers.set('X-Api-Key', Config.apiKey);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    getQuotePremiumAu: builder.query({
      query: lead => ({
        method: 'GET',
        url: 'quote/premium-au',
        params: lead,
      }),
    }),
    createQuotePremiumAu: builder.mutation({
      query: (data: {
        leadId: string;
        termLength: number;
        coverageAmount: number;
        premium: number;
        ratingClass: string;
        recordId: string;
      }) => ({
        method: 'POST',
        url: 'quote/premium-au',
        body: data,
      }),
    }),
    createAnalyticsEvent: builder.mutation({
      query: event => ({
        method: 'POST',
        url: 'analytics',
        body: {
          type: 'quoteInteraction',
          ...event,
        },
      }),
    }),
  }),
});

export const {
  useLazyGetQuotePremiumAuQuery,
  useGetQuotePremiumAuQuery,
  useCreateQuotePremiumAuMutation,
  useCreateAnalyticsEventMutation,
} = wwwApi;
