import React from 'react';

import remark from 'remark';
import remarkSubSuper from 'remark-sub-super';
import remarkAbbr from 'remark-abbr';
import remark2rehype from 'remark-rehype';
import rehype2react from 'rehype-react';

import Box, { BoxProps } from '../../../Box';
import { TextProps } from '../../../Text/Text';
import { TagNameToComponentMap } from '../../types';

/**
 * To avoid a circualr dependency between `TextMarkdown` and `Tooltip`, this component is structured a bit different than others.
 * - `TextMarkdownBase` - this is the base component that can be extended to add functionality for rendering markdown
 * - `TextMarkdown` - this is the default component used to render markdown. In most cases, it should be used, and it is set as the default export.
 */

export type TextMarkdownBaseProps = BoxProps & {
  /** Children */
  children?: React.ReactNode;
  /** Props applied to all the components of the specified type */
  childProps?: {
    Text?: Partial<TextProps>;
  };
  getComponents: (textProps?: Partial<TextProps>) => TagNameToComponentMap;
};

const TextMarkdownBase = ({
  children,
  childProps,
  getComponents,
  ...restProps
}: TextMarkdownBaseProps) => {
  const textProps = childProps && childProps.Text;

  // Parses markdown
  const processor = remark()
    // Adds markdown support for superscript/subscript
    // `^foo^` -> `<sup>foo</sup>`
    .use(remarkSubSuper)
    // Adds markdown support for abbreviations (which we use as glossary tooltips)
    // `*[foo}: bar` -> `<Tooltip content="bar"><abbr>foo</abbr></Tooltip>`
    .use(remarkAbbr)
    // Converts markdown to HTML
    .use(remark2rehype)
    // Converts HTML to React
    .use(rehype2react, {
      createElement: React.createElement,
      Fragment: React.Fragment,
      components: getComponents(textProps),
    });

  return (
    <Box block {...restProps}>
      {processor.processSync(children).contents}
    </Box>
  );
};

export default TextMarkdownBase;
