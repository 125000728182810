import {
  border,
  color as colorTokens,
  spacer,
} from '@fabrictech/design-tokens';

import { important } from '../../utils/css/values';
import { times } from '../../utils/css/values/px';

import { sortDirections } from './constants';
import { softShadow } from '../../sharedStyles/vars/boxShadow';
import getTextStyles from '../Text/getStyles';

const { borderWidth } = border;

const {
  fontFeatureSettings,
  fontVariantNumeric,
  fontSize,
  lineHeight,
  fontFamily,
  fontWeight,
  color,
} = getTextStyles({ rank: 3 });

const textStyles = {
  fontFeatureSettings,
  fontVariantNumeric,
  fontSize,
  lineHeight,
  fontFamily,
  fontWeight,
  margin: 0,
  textAlign: 'left',
  color,
};

export const getTableStyles = () => ({
  border: 0,
});

export const getTHeadCellStyles = () => ({
  padding: spacer(2),
  borderColor: colorTokens.border.base,
  outline: '1px solid transparent',
  textAlign: 'left',
});

export const getTHeadStyles = () => ({
  ...textStyles,
  backgroundColor: colorTokens.background.secondary,
  color: colorTokens.font.primary,
  borderBottom: `${borderWidth}px solid ${colorTokens.border.base}`,
});

export const getTrStyles = () => ({
  // Need transparent borders on the top and sides so that adding
  // hover state does not displace layout.
  border: '1px solid transparent',
  borderBottom: `1px solid ${colorTokens.border.base}`,
});

export const getTrLastChildStyles = () => ({
  // Explicitly override react-table last-child default
  borderBottom: '1px solid transparent',
});

export const getTrHoverStyles = () => ({
  cursor: 'pointer',
  border: `1px solid ${colorTokens.border.action}`,
});

export const getTrLastChildHoverStyles = () => ({
  // Again, explicitly override react-table last-child default
  borderBottomColor: colorTokens.border.action,
});

export const getTHeadShadowStyles = () => ({
  boxShadow: softShadow,
});

const sortedHighlightThickness = spacer(0.5); // 4px

/**
 * Returns styles for th depending on the direction in which it's sorted.
 *
 * @param {enum} sortDirection The direction of the sorting
 * @returns {Object}
 */
export const getSortedThStyles = sortDirection => {
  const offsetY = times(
    sortedHighlightThickness,
    sortDirection === sortDirections.asc ? 1 : -1
  );
  return {
    color: colorTokens.font.primary,
    // Ripped directly from the react-table css.
    boxShadow: important(`inset 0 ${offsetY} 0 0 ${colorTokens.border.active}`),
  };
};

export const getTBodyCellStyles = () => ({
  ...textStyles,
  padding: spacer(2),
  borderLeftColor: 'transparent',
  borderRightColor: 'transparent',
});
