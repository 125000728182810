import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import getStyles from './getStyles';
import { toPx } from '../../utils/css/values/px';

/** Displays signatures within `DocumentPage` */
class DocumentSignature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setLineHeight = this.setLineHeight.bind(this);
    this.setRefParent = this.setRefParent.bind(this);
  }

  setRefParent(node) {
    this.setState({ refParent: node });
    this.props.handleAddScrollTarget(node);
  }

  setLineHeight() {
    if (this.state.refParent) {
      const { height } = this.state.refParent.getBoundingClientRect();
      this.setState({ lineHeight: toPx(height) });
    }
  }

  componentDidMount() {
    /*
     * Wrap in set timeout to defer until render is complete
     */
    setTimeout(this.setLineHeight);
    window.addEventListener('resize', this.setLineHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setLineHeight);
  }

  shouldComponentUpdate(nextProps) {
    /*
     * Slight optimization - the signature never actually cares whether or not
     * it's visible and shouldn't re-render if its visibility changes.
     */
    return nextProps.visible === this.props.visible;
  }

  render() {
    const { children, onClick, ...rest } = this.props;
    const { lineHeight } = this.state;
    return (
      <button
        ref={this.setRefParent}
        onClick={onClick}
        className={css(getStyles({ ...rest, lineHeight }))}
      >
        {children ? <span>{children}</span> : null}
      </button>
    );
  }
}

DocumentSignature.propTypes = {
  /** Height of the signature in pixels */
  height: PropTypes.string,
  /** Width of the signature in pixels */
  width: PropTypes.string,
  /** Scale of the parent document */
  docScale: PropTypes.number,
  /** Height of the parent document in pixels */
  docHeight: PropTypes.string,
  /** Width of the parent document in pixels */
  docWidth: PropTypes.string,
  /** x position of the signature in pixels */
  x: PropTypes.string,
  /** y position of the signature in pixels */
  y: PropTypes.string,
};

DocumentSignature.defaultProps = {
  docScale: 1,
  docHeight: '100px',
  docWidth: '100px',
  x: '0px',
  y: '0px',
};

export default DocumentSignature;
