import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import _ from 'lodash';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import {
  getTHeadStyles,
  getSortedThStyles,
  getTHeadCellStyles,
  getTHeadShadowStyles,
  getTBodyCellStyles,
  getTableStyles,
  getTrStyles,
  getTrLastChildStyles,
  getTrHoverStyles,
  getTrLastChildHoverStyles,
} from './getStyles';
import { sortDirections } from './constants';

const themeName = _.uniqueId('fabric-');
const rtRootClassName = `.ReactTable.${themeName}`;

/**
 * Helper that prefixes a list of classes with our theming namespace
 *
 * @param {Array} classNameSuffixes
 */
const getRtClassName = (...classNameSuffixes) =>
  [...classNameSuffixes]
    .map(classNameSuffix => [rtRootClassName, classNameSuffix].join(' '))
    .join(', ');
const rowClickableClassName = 'fabric-row-clickable';

/**
 * Injects themed-CSS overrides for ReactTable via glamor.
 */
const injectThemedCss = () => {
  // Root
  css.global(rtRootClassName, getTableStyles());

  // Header
  css.global(getRtClassName('.rt-thead'), getTHeadStyles());
  css.global(getRtClassName('.rt-thead.-header'), getTHeadShadowStyles());
  css.global(
    getRtClassName('.rt-thead .rt-th', '.rt-thead .rt-td'),
    getTHeadCellStyles()
  );

  // Sort directions
  css.global(
    getRtClassName('.rt-thead .rt-th.-sort-asc', '.rt-thead .rt-td.-sort-asc'),
    getSortedThStyles(sortDirections.asc)
  );
  css.global(
    getRtClassName(
      '.rt-thead .rt-th.-sort-desc',
      '.rt-thead .rt-td.-sort-desc'
    ),
    getSortedThStyles(sortDirections.desc)
  );

  // Body
  css.global(getRtClassName('.rt-tbody .rt-td'), getTBodyCellStyles());

  // Row
  css.global(getRtClassName('.rt-tbody .rt-tr-group'), getTrStyles());
  // We inject this way because `css.global` does not support nested selector
  // such as &:last-child or &:hover
  css.global(
    getRtClassName('.rt-tbody .rt-tr-group:last-child'),
    getTrLastChildStyles()
  );
  // Row hover
  css.global(
    `${rtRootClassName}.${rowClickableClassName} .rt-tbody .rt-tr-group:hover`,
    getTrHoverStyles()
  );
  css.global(
    `${rtRootClassName}.${rowClickableClassName} .rt-tbody .rt-tr-group:last-child:hover`,
    getTrLastChildHoverStyles()
  );
};

/**
 * The `DataTable` component is used to present tabular data. It uses a `ReactTable` instance under the hood.
 */
export class DataTable extends React.Component {
  constructor(props) {
    super(props);
    injectThemedCss();
  }

  render() {
    /*
     * Defensively define ReactTable props explicitly. The vast majority of these
     * are expected to go unused. However in the interest of flexibility all
     * are available for now.
     *
     * For the most part, these should go untouched unless we were to switch our
     * underlying table-library.
     */
    const {
      rowClickable,

      // ---- React-Table props

      // General
      data,
      columns,
      resolveData,
      loading,
      showPagination,
      showPaginationTop,
      showPaginationBottom,
      showPageSizeOptions,
      pageSizeOptions,
      defaultPageSize,
      minRows,
      showPageJump,
      collapseOnSortingChange,
      collapseOnPageChange,
      collapseOnDataChange,
      freezeWhenExpanded,
      sortable,
      multiSort,
      resizable,
      filterable,
      defaultSortDesc,
      defaultSorted,
      defaultFiltered,
      defaultResized,
      defaultExpanded,
      defaultFilterMethod,
      defaultSortMethod,
      PadRowComponent,

      // Controlled State Overrides (see Fully Controlled Component section)
      page,
      pageSize,
      sorted,
      filtered,
      resized,
      expanded,
      manual,

      // Controlled State Callbacks
      onPageChange,
      onPageSizeChange,
      onSortedChange,
      onFilteredChange,
      onResizedChange,
      onExpandedChange,

      // Pivoting
      pivotBy,

      // Key Constants
      pivotValKey,
      pivotIDKey,
      subRowsKey,
      aggregatedKey,
      nestingLevelKey,
      originalKey,
      indexKey,
      groupedByPivotKey,

      // Server-side callbacks
      onFetchData,

      // Classes
      style,

      // Component decorators
      getProps,
      getTableProps,
      getTheadGroupProps,
      getTheadGroupTrProps,
      getTheadGroupThProps,
      getTheadProps,
      getTheadTrProps,
      getTheadThProps,
      getTheadFilterProps,
      getTheadFilterTrProps,
      getTheadFilterThProps,
      getTbodyProps,
      getTrGroupProps,
      getTrProps,
      getThProps,
      getTdProps,
      getTfootProps,
      getTfootTrProps,
      getTfootThProps,
      getPaginationProps,
      getLoadingProps,
      getNoDataProps,
      getResizerProps,

      // Global Column Defaults
      column,

      // Global Expander Column Defaults
      // To override only some values, import { ReactTableDefaults } from 'react-table'
      // and construct your overrides (e.g. {...ReactTableDefaults.expanderDefaults, sortable: true})
      expanderDefaults,

      // Global Pivot Column Defaults
      pivotDefaults,

      // Text
      previousText,
      nextText,
      loadingText,
      noDataText,
      pageText,
      ofText,
      rowsText,
    } = this.props;
    return (
      <ReactTable
        data={data}
        columns={columns}
        className={_.filter([
          themeName,
          rowClickable && rowClickableClassName,
        ]).join(' ')}
        {...{
          data,
          columns,
          resolveData,
          loading,
          showPagination,
          showPaginationTop,
          showPaginationBottom,
          showPageSizeOptions,
          pageSizeOptions,
          defaultPageSize,
          minRows,
          showPageJump,
          collapseOnSortingChange,
          collapseOnPageChange,
          collapseOnDataChange,
          freezeWhenExpanded,
          sortable,
          multiSort,
          resizable,
          filterable,
          defaultSortDesc,
          defaultSorted,
          defaultFiltered,
          defaultResized,
          defaultExpanded,
          defaultFilterMethod,
          defaultSortMethod,
          PadRowComponent,
          page,
          pageSize,
          sorted,
          filtered,
          resized,
          expanded,
          manual,
          onPageChange,
          onPageSizeChange,
          onSortedChange,
          onFilteredChange,
          onResizedChange,
          onExpandedChange,
          pivotBy,
          pivotValKey,
          pivotIDKey,
          subRowsKey,
          aggregatedKey,
          nestingLevelKey,
          originalKey,
          indexKey,
          groupedByPivotKey,
          onFetchData,
          style,
          getProps,
          getTableProps,
          getTheadGroupProps,
          getTheadGroupTrProps,
          getTheadGroupThProps,
          getTheadProps,
          getTheadTrProps,
          getTheadThProps,
          getTheadFilterProps,
          getTheadFilterTrProps,
          getTheadFilterThProps,
          getTbodyProps,
          getTrGroupProps,
          getTrProps,
          getThProps,
          getTdProps,
          getTfootProps,
          getTfootTrProps,
          getTfootThProps,
          getPaginationProps,
          getLoadingProps,
          getNoDataProps,
          getResizerProps,
          column,
          expanderDefaults,
          pivotDefaults,
          previousText,
          nextText,
          loadingText,
          noDataText,
          pageText,
          ofText,
          rowsText,
        }}
      />
    );
  }
}

DataTable.propTypes = {
  /** The data to pass to the table */
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Configuration for the column headings */
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Whether or not the row should appear clickable */
  rowClickable: PropTypes.bool,
  ...ReactTable.propTypes,
};

DataTable.defaultProps = {
  minRows: 0,
  showPagination: false,
};

export default DataTable;
