import React from 'react';
import { Box, MarketingText } from '@fabrictech/tapestry';
import { internalTitleText, titleText, captionText } from './content';
import getStyles from './getStyles';
import FabricLogo from '../FabricLogo';
import useResponsiveContext from '../useResponsiveContext';

const WidgetInstructions = () => {
  const {
    isInternalWidget,
    styles: {
      textContainerClassname,
      internalTitleClassname,
      titleClassname,
      captionClassname,
    },
  } = useResponsiveContext(getStyles);
  return (
    <Box className={textContainerClassname}>
      {isInternalWidget ? (
        <MarketingText header rank={4} className={internalTitleClassname}>
          {internalTitleText}
        </MarketingText>
      ) : (
        <>
          <FabricLogo />
          <MarketingText header rank={2} className={titleClassname}>
            {titleText}
          </MarketingText>
          <MarketingText rank={2} className={captionClassname}>
            {captionText}
          </MarketingText>
        </>
      )}
    </Box>
  );
};

export default WidgetInstructions;
