import React from 'react';
import { css, Rule } from 'glamor';

import { TextRank, Align } from '@fabrictech/design-tokens';

import Text, { TextProps } from '../Text/Text';
import getBaseStyles from './getStyles';
import createGetStylesWithGlobalProps from '../../utils/createGetStylesWithGlobalProps';
import { GlobalProps } from '../../types';

const getStyles = createGetStylesWithGlobalProps(getBaseStyles);

export type MarketingTextProps = GlobalProps &
  TextProps & {
    /** Children */
    children?: React.ReactNode;
    /** Assign tag-name */
    as?: React.ReactType;
    /** Class name to extend functionality */
    className?: Rule;
    /** Whether to display as header */
    header?: boolean;
    /** Rank determines the font size, line height, and font weight */
    rank?: TextRank | 5 | 6;
    /** Aligns the text horizontally */
    textAlign?: Align;
    /** Controls the maximum width */
    maxWidth?: string | number;
    /** Assigns text color */
    color?: string;
    /** Provide a unique ID for this element */
    id?: string;
  };

export const MarketingText = ({
  as,
  children,
  className,
  header,
  textAlign,
  maxWidth,
  color,
  rank = 1,
  id,
  ...rest
}: MarketingTextProps) => {
  const style = getStyles({
    header,
    rank,
    textAlign,
    maxWidth,
    color,
    ...rest,
  });

  return (
    <Text
      as={as}
      header={header}
      rank={rank}
      className={css(style, className)}
      id={id}
    >
      {children}
    </Text>
  );
};

export default MarketingText;
