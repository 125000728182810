import { createContext } from 'react';

export type ResponsiveContextDefinition = {
  isInternalWidget: boolean;
  isLockedToMobileWidth: boolean;
  mobileBreakpoint: number;
};
export const defaultContext = {
  isInternalWidget: false,
  isLockedToMobileWidth: false,
  mobileBreakpoint: 620,
};

// mobile breakpoint examples:
// '@media screen and (max-width: 768px)'
// '@media screen and (min-width: 768px)'
const ResponsiveContext = createContext<ResponsiveContextDefinition>(
  defaultContext
);

export default ResponsiveContext;
