import React from 'react';

/**
 * This component has no design spec.
 * It was created to provide a consistent API across all `Input` components without implying confusing html semantics for any of them.
 * It serves solely as a vehicle for props.
 * */
// eslint-disable-next-line no-unused-vars
const InputOption = ({ children, ...rest }) => {
  // eslint-disable-next-line no-console
  console.warn(
    "The jsx of `InputOption` shouldn't be rendered. `InputOption` should only be used as a vehicle for props of `Input`-type components. See the README of `InputOption` for examples."
  );
  return <div>{children}</div>;
};

export default InputOption;
