export const aligns = {
  left: 'left',
  center: 'center',
  right: 'right',
};

export const verticalAligns = {
  top: 'top',
  center: 'center',
  bottom: 'bottom',
};

export const flexAligns = {
  ...aligns,
  ...verticalAligns,
  stretch: 'stretch',
};

export const flexDirections = {
  row: 'row',
  column: 'column',
};
