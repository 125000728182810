import {
  FlexAlign,
  JustifyContent,
  AlignItems,
  Width,
  Height,
  FlexDirection,
} from './types';

const justifyContentByAlign: { [key in FlexAlign]?: JustifyContent } = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  stretch: 'space-between',
};

const justifyContentByAlignReversed: { [key in FlexAlign]?: JustifyContent } = {
  right: 'flex-start',
  center: 'center',
  left: 'flex-end',
  stretch: 'space-between',
};

const alignItemsByAlign: { [key in FlexAlign]?: AlignItems } = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
  stretch: 'stretch',
};

const alignItemsByVerticalAlign: { [key in FlexAlign]?: AlignItems } = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
  stretch: 'stretch',
};

const justifyContentByVerticalAlign: { [key in FlexAlign]?: JustifyContent } = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
  stretch: 'space-between',
};

const justifyContentByVerticalAlignReversed: {
  [key in FlexAlign]?: JustifyContent;
} = {
  bottom: 'flex-start',
  center: 'center',
  top: 'flex-end',
  stretch: 'space-between',
};

export const getBaseStyles = ({
  backgroundColor,
  width,
  maxWidth,
  height,
  minHeight,
  block,
  flexDirection,
  align,
  verticalAlign,
}: {
  backgroundColor?: string;
  width: Width;
  maxWidth: Width;
  height: Height;
  minHeight: Height;
  block: boolean;
  flexDirection: FlexDirection;
  align?: FlexAlign;
  verticalAlign?: FlexAlign;
}) => ({
  position: 'relative',
  background: backgroundColor,
  zIndex: 0,
  width,
  maxWidth,
  height,
  minHeight,
  ...(block
    ? { display: 'block' }
    : {
        display: 'flex',
        flexDirection,
        ...(flexDirection === 'row'
          ? {
              justifyContent: align && justifyContentByAlign[align],
              alignItems:
                verticalAlign && alignItemsByVerticalAlign[verticalAlign],
            }
          : {}),
        ...(flexDirection === 'row-reverse'
          ? {
              justifyContent: align && justifyContentByAlignReversed[align],
              alignItems:
                verticalAlign && alignItemsByVerticalAlign[verticalAlign],
            }
          : {}),
        ...(flexDirection === 'column'
          ? {
              justifyContent:
                verticalAlign && justifyContentByVerticalAlign[verticalAlign],
              alignItems: align && alignItemsByAlign[align],
            }
          : {}),
        ...(flexDirection === 'column-reverse'
          ? {
              justifyContent:
                verticalAlign &&
                justifyContentByVerticalAlignReversed[verticalAlign],
              alignItems: align && alignItemsByAlign[align],
            }
          : {}),
      }),
});

export const getBackgroundStyles = () => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: -1,
  overflow: 'hidden',
});
