import React from 'react';
import { css } from 'glamor';
import Selection from '../Selection';
import getStyles from './getStyles';

const SelectionWithIcon = props => (
  <Selection className={css(getStyles(props))} {...props} />
);

export default SelectionWithIcon;
