/**
 * Exporting twice for backwards compatibility.
 */

enum RecordType {
  PAGE_VIEW = 'pageView',
  SECURE_PAGE_VIEW = 'securePageView',
  SURVEY_RESPONSE = 'surveyResponse',
  APPLICATION_INTERACTION = 'applicationInteraction',
  QUOTE_INTERACTION = 'quoteInteraction',
  STRIPE_PAYMENT_METHOD_INTERACTION = 'stripePaymentMethodInteraction',
  INTERCOM_SUPPORT_INTERACTION = 'intercomSupportInteraction',
  SCHEDULE_4_REAL_INTERACTION = 'schedule4RealInteraction',
  BLOG_INTERACTION = 'blogInteraction',
}

export default RecordType;
