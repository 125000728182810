import _ from 'lodash';

import { color, spacer } from '@fabrictech/design-tokens';

import { before } from '../../utils/css/selectors';
import { softShadow } from '../../sharedStyles/vars/boxShadow';
import * as mediaQueries from '../../sharedStyles/vars/mediaQueries';

export const navHeaderHeightMobile = 68;
export const navHeaderHeightDesktop = 84;

export const navHeaderHeightStyles = {
  height: navHeaderHeightMobile,
  [mediaQueries.gte.md]: {
    height: navHeaderHeightDesktop,
  },
};

const getStyles = ({
  alignWordmarkOnMobile = 'center',
  showDropdownMenu = false,
}: {
  alignWordmarkOnMobile?: 'left' | 'center';
  showDropdownMenu?: boolean;
}) =>
  _.merge({}, navHeaderHeightStyles, {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',

    /**
     * These styles position the wordmark to the left side of the header on smaller screen sizes
     * and sets the gutterWidth to equal that of the burger menu.
     */
    justifyContent: alignWordmarkOnMobile === 'left' ? 'flex-start' : 'center',
    paddingLeft: alignWordmarkOnMobile === 'left' ? spacer(2.5) : 0,

    /**
     * The wordmark padding for xl screens
     */
    [mediaQueries.gte.xl]: {
      paddingLeft: showDropdownMenu ? spacer(5) : 0,
    },

    /*
     * Rather than transitioning between a background-color of white
     * and transparent, we absolutely position a pseudo-element and
     * transition its opacity to trigger hardware acceleraation.
     *
     * "...the most performant way to animate is with CSS keyframe
     * animations or transitions on transform and opacity only."
     * @see https://www.viget.com/articles/animation-performance-101-browser-under-the-hood/
     */
    [before]: {
      content: ' ',
      display: 'block',

      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',

      // So that the wordmark will appear on top.
      zIndex: -1,

      backgroundColor: color.background.primary,
      boxShadow: softShadow,
    },
  });

export const getButtonRegionStyles = () => ({
  // Position all the way on the right side.
  position: 'absolute',
  top: 0,
  [mediaQueries.gte.xl]: {
    right: spacer(5),
  },
  right: spacer(2.5),
  // Stretch the region to full-height and vertically-align
  // contents to the center.
  display: 'flex',
  alignItems: 'center',

  // Merging styles so as to not clobber configurations
  // at various breakpoints.
  ..._.merge({}, navHeaderHeightStyles, {}),
});

export const getNavWordmarkMarginRight = ({
  showDropdownMenu = false,
}: {
  showDropdownMenu?: boolean;
}) => ({
  [mediaQueries.gte.xl]: {
    marginRight: showDropdownMenu ? spacer(0.5) : 0,
  },
  // more space needed when 5 links are on nav, ~1200px screen width
  '@media (min-width: 1400px)': {
    marginRight: showDropdownMenu ? spacer(2) : 0,
  },
});

export default getStyles;
