import * as optionsById from './optionsById';
import * as allQuestionsById from './questionsById';

const { entryQuestionIds, ...questionsById } = allQuestionsById;

const questionnaireDefinition = {
  entryQuestionIds,
  questionsById,
  optionsById,
};

export default questionnaireDefinition;
