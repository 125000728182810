export const prefix = '@@redux-api';

const actionTypes = {
  REQUEST: `${prefix}/REQUEST`,
  SUCCESS: `${prefix}/SUCCESS`,
  FAILURE: `${prefix}/FAILURE`,
  DESTROY: `${prefix}/DESTROY`,
};

export default actionTypes;
