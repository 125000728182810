/* Default Coverage Amount for Coverage Slider on Estimate Form is $250,000 */
export const defaultCoverageAmount = 2.5 * 10e4;

export const defaultExternalLandingPath = '/term-life-insurance/account';

export const chromeGrey = '#EFEFEF';
export const formWidthDesktop = 292;
export const formWidthMobile = 280;

export const defaultMediumBreakpoint = 768;

export const instapage = 'instapage';
export const webflow = 'webflow';
