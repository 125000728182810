import { css } from 'glamor';
import { border, spacer } from '@fabrictech/design-tokens';
import { toPx } from '../../utils/css/values/px';
import { after } from '../../utils/css/selectors';

const { borderRadius } = border;

// This is a workaround for this issue - https://github.com/threepointone/glamor/issues/332
const cssShim: any = css; // eslint-disable-line @typescript-eslint/no-explicit-any

const animation = cssShim.keyframes({
  '0%': { width: spacer(6), left: '0' },
  '50%': {
    width: spacer(12),
  },
  '100%': { width: spacer(6), left: `calc(100% - ${toPx(spacer(6))})` },
});

const getStyles = ({
  animationDuration,
  color,
}: {
  animationDuration: number;
  color: string;
}) => ({
  [after]: {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    backgroundColor: color,
    borderRadius,
    animation: `${animation} ${animationDuration}ms infinite alternate ease-in-out`,
  },
});

export default getStyles;
