import { ValueOf } from '@fabrictech/definitely-fabric';

export const questionStatuses = {
  required: 'required',
  optional: 'optional',
  working: 'working',
  invalid: 'invalid',
  valid: 'valid',
} as const;

export type QuestionStatus = ValueOf<typeof questionStatuses>;

// TODO: This may make more sense as an enum in ./types
export const inputTypes = {
  assessmentFactorGroup: 'assessment-factor-group',
  assessmentFactorSearch: 'assessment-factor-search',
  date: 'date',
  fabricGroup: 'fabricGroup',
  fabricGroupItem: 'fabricGroupItem',
  fabricStruct: 'fabricStruct',
  group: 'group',
  section: 'section',
  label: 'label',
  multiSelection: 'multiselection',
  singleSelection: 'singleselection',
  text: 'text',
  password: 'password',
  number: 'number',
  streetAddress: 'streetAddress',
} as const;

// Enum values for the groupType field. This is a fabric construct.
export const fabricGroupTypes = {
  legalName: 'legalName',
  firstLastName: 'firstLastName',
  height: 'height',
  homeAddress: 'homeAddress',
  driversLicenseDetails: 'driversLicenseDetails',
  phoneNumbers: 'phoneNumbers',
} as const;

export type FabricGroupType = ValueOf<typeof fabricGroupTypes>;

/**
 * The value of a questionnaire question's `type` field.
 *
 * This is defined with a valueof, as it is the most succint way to keep it and
 * `inputTypes` in sync without losing things like autocomplete on `inputTypes`.
 */
export type InputType = ValueOf<typeof inputTypes>;

export const answerStatuses = {
  invalid: 'invalid',
  valid: 'valid',
  missing: 'missing',
  absent: 'absent',
} as const;

export const questionStatusForAnswerStatus = {
  [answerStatuses.missing]: questionStatuses.required,
  [answerStatuses.absent]: questionStatuses.optional,
  [answerStatuses.invalid]: questionStatuses.invalid,
  [answerStatuses.valid]: questionStatuses.valid,
} as const;

export type AnswerState = keyof typeof answerStatuses;
