import React from 'react';
import { css } from 'glamor';

import { color } from '@fabrictech/design-tokens';
import Icon from '../../../Icon';

import { getButtonStyles, burgerWidth } from './getStyles';

const buttonClassName = css(getButtonStyles()).toString();

const burgerSvgPathWidth = 18;
const burgerHorizontalOffset = burgerWidth - burgerSvgPathWidth;

export type NavBurgerProps = {
  onClick: () => void;
  color?: string;
};

/**
 * A menu button meant to render inside the Header.
 */
const NavBurger = ({
  onClick,
  color: fillColor = color.border.active,
}: NavBurgerProps) => (
  <button onClick={onClick} className={buttonClassName}>
    <Icon
      type="burger"
      size="sm"
      // The icon itself is smaller than the viewbox and thus the thing
      // appears not to be centered. We apply a horizontal margin to
      // eliminate the off-centeredness.
      margin={`0 ${burgerHorizontalOffset}px 0 0`}
      color={fillColor}
    />
  </button>
);

export default NavBurger;
