import { sizes, LogoSize } from '@fabrictech/design-tokens';

const getStyles = ({
  color,
  width,
  height,
  size,
}: {
  color?: string;
  width?: string | number;
  height?: string | number;
  size?: LogoSize;
}) => ({
  '& svg': {
    width: width || (size ? sizes.logo[size].width : undefined),
    height: height || (size ? sizes.logo[size].height : undefined),

    // Enforce display to eliminate extra space at the bottom of the
    // svg for it to sit comfortably on the text baseline, which
    // we don't need.
    // @see https://stackoverflow.com/a/24626986
    display: 'block',

    '& path': { fill: color },
  },
});

export default getStyles;
