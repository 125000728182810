import { spacer } from '@fabrictech/design-tokens';

import { divide } from '../../utils/css/values/px';

const width = spacer(20);
const height = spacer(11);

export const getParentStyles = ({ isFullPage }: { isFullPage: boolean }) => ({
  width,
  height,
  zIndex: 2,
  ...(isFullPage
    ? {
        position: 'fixed',
        top: `calc(50% - ${divide(height, 2)})`,
        left: `calc(50% - ${divide(width, 2)})`,
      }
    : {}),
});
