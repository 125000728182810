import React from 'react';

import useResponsiveContext from '../useResponsiveContext';
import getStyles from './getStyles';

const AdIndicator = () => {
  const {
    styles: { containerClassname, contentClassname },
  } = useResponsiveContext(getStyles);
  return (
    <div className={containerClassname.toString()}>
      <p className={contentClassname.toString()}>Ad</p>
    </div>
  );
};

export default AdIndicator;
