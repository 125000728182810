import { spacer, sizes } from '@fabrictech/design-tokens';

import { after, descendant } from '../../utils/css/selectors';
import baseLoadingStyles from '../../sharedStyles/vars/loading';
import tags from '../../constants/tags';

const sideLength = sizes.icon.sm;

const loadingStyles = {
  position: 'relative',
  [after]: {
    ...baseLoadingStyles,
    content: '""',
    position: 'absolute',
    right: spacer(2),
    top: '50%',
    marginTop: sideLength * -0.5,
  },
};

export const getParentStyles = ({ loading }) => ({
  ...(loading ? loadingStyles : {}),
  zIndex: 100,
});

export const getInputStyles = () => ({
  margin: 0,
  [descendant(tags.input)]: {
    paddingRight: spacer(6),
  },
});
