/**
 * A map that holds a set of Key Value pairs
 */
const rootCounter = {};

/**
 * If the key doesn't exist, creates one with the given value and sets the value to 0
 * Increments the given key and returns the count
 * Assumes the value can be incremented with '++' prefix operator
 */
export const createIncrement = counter => key => {
  if (!counter[key]) {
    counter[key] = 0;
  }
  counter[key] += 1;
  return counter[key];
};

/**
 * Reads the value of the given key
 * Returns the count listed at that key in counter
 */
export const createRead = counter => key => counter[key];

export default {
  read: createRead(rootCounter),
  increment: createIncrement(rootCounter),
};
