import { margin } from '../../../../utils/css/values';
import { nthChild } from '../../../../utils/css/selectors';
import { divide } from '../../../../utils/css/values/px';

const getStyles = ({
  index,
  columns,
  colSpans,
  gutterWidth,
}: {
  index: number;
  columns: number;
  colSpans: number[];
  gutterWidth: number | string;
}) => ({
  flex: '1 1 auto',
  margin: margin(0, divide(gutterWidth, 2)),
  [nthChild(index + 1)]: {
    width: colSpans
      ? `calc(${(colSpans[index] / columns) * 100}% - ${gutterWidth})`
      : 'auto',
  },
});

export default getStyles;
