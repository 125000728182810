/**
 * The BannerMetadata type captures:
 * * position - The one-based index of the banner's position on the page
 * * totalBanners - The total number of banners on the page
 *
 * Currently, there are only ever two CTA banners on the blog post page, but if
 * we add more in the future, a designation like "top"/"bottom" wouldn't work.
 * By including position and totalBanners, we can assign a more useful metric
 * to the positioning of the banner on the page, regardless of how many banners
 * we have.
 */
export type BannerMetadata = {
  position: number;
  totalBanners: number;
};
/**
 * The `bannerMetadata` property is only relevant to the view metric for CTA
 * banners.
 * The `adMetadata` property is relevant to Insurance Lead Sales Banners which
 * replace CTA banners if the user's IP indicates they are in a state that we
 * do not sell insurance in.
 */
export type BlogInteractionProperties = {
  name: string;
  category: string;
  bannerMetadata?: BannerMetadata;
  adMetadata?: {
    carrier: string;
    clickUrl?: string;
    adId: number;
    displayUrl: string;
  };
};

const buildBlogInteractionProperties = ({
  name,
  category,
  bannerMetadata,
  adMetadata,
}: BlogInteractionProperties) => ({
  bannerMetadata,
  name,
  category,
  adMetadata,
});

export default buildBlogInteractionProperties;
