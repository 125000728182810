import React from 'react';
import { css } from 'glamor';

import {
  color as colorTokens,
  spacer,
  IconType,
} from '@fabrictech/design-tokens';

import { padding } from '../../utils/css/values';
import * as mediaQueries from '../../sharedStyles/vars/mediaQueries';
import TextMarkdown from '../TextMarkdown';
import Icon from '../Icon';
import Box from '../Box';

type NavBannerProps = {
  /** Provides an icon to pass to Icon. The icon won't appear on mobile (below `lg` breakpoint) */
  icon: IconType;
  /** Main message to alert the user to. Markdown supported. */
  content: string;
  /** Background color */
  backgroundColor?: string;
  /** Color of Icon and text */
  color?: string;
};

/** Configurable component for grabbing the user's atttention  */
const NavBanner = ({
  icon,
  content,
  backgroundColor = colorTokens.background.inverse.secondary,
  color = colorTokens.font.inverse.primary,
}: NavBannerProps) => (
  <Box
    backgroundColor={backgroundColor}
    align="center"
    verticalAlign="center"
    padding={padding(spacer(2), spacer(8))}
    className={css({ zIndex: 1 })}
  >
    <Icon
      responsiveProps={{ [mediaQueries.lt.lg]: { hidden: true } }}
      marginRight={spacer(2)}
      color={color}
      type={icon}
      size="md"
    />
    <TextMarkdown
      childProps={{
        Text: {
          color,
          rank: 2,
          marginBottom: 0,
        },
      }}
    >
      {content}
    </TextMarkdown>
  </Box>
);

export default NavBanner;
