import React from 'react';

import FormField from '@fabrictech/tapestry/components/FormField';

/**
 * HOC, allows us to customize the input group's layout
 */
const createFabricGroupField = ({ colSpans, responsiveProps } = {}) => {
  /**
   * This Field is a bit more complex than others. As a Field, its children
   * are the child Questions of the Fields parent Question. Since we want
   * the Questions Input to manage those Questions, we take the Field's
   * `children` and pass them to the Input via `render`.
   *
   * The Input is responsible for rendering those `children`, and overriding
   * their props to take over their validation, and other behavior.
   */
  const InputGroupField = ({ children, id, render, ...rest }) => (
    <FormField
      {...rest}
      render={fieldProps => {
        return render({
          ...fieldProps,
          colSpans,
          responsiveProps,
          id,
          children,
        });
      }}
    />
  );

  return InputGroupField;
};

export default createFabricGroupField;
