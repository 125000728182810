import { color as colorTokens } from '@fabrictech/design-tokens';

export const getSvgStyles = ({
  sideLength,
  badge,
  color,
}: {
  sideLength: number;
  badge: boolean;
  color: string;
}) => ({
  width: sideLength,
  height: sideLength,
  '& svg': {
    display: 'block',
    width: sideLength,
    height: sideLength,
    '& path': {
      fill: badge ? colorTokens.font.inverse.primary : color,
    },
  },
});

export const getBadgeStyles = () => ({
  borderRadius: 1000,
  overflow: 'hidden',
});
