import React from 'react';

import { spacer } from '@fabrictech/design-tokens';

import pickGlobalProps from '../../utils/pickGlobalProps';
import { margin } from '../../utils/css/values';
import Box from '../Box';

type ContAccordionProps = {
  /** Children */
  children: React.ReactElement[];
  /** Index of the item to be expanded by default. Default behavior is for all items to be collapsed. */
  activeIndex?: number;
  /** Width */
  width: string | number;
};

const mapChildren = ({
  children,
  activeIndex,
  width,
  activateIndex,
}: ContAccordionProps & { activateIndex: (activeIndex?: number) => void }) =>
  React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      key: index,
      expanded: activeIndex === index,
      activate: activateIndex.bind(null, index),
      first: index === 0,
      last: index === React.Children.count(children) - 1,
      width,
    })
  );

/** An aggregate of related information that can show 0-1 section at a time. Collapses down to the title for each section */
export class ContAccordion extends React.Component<ContAccordionProps> {
  static defaultProps = {
    activeIndex: undefined,
    width: '100%',
  };

  state = {
    activeIndex: this.props.activeIndex,
  };

  activateIndex = (activeIndex?: number) => {
    if (activeIndex === this.state.activeIndex) {
      activeIndex = undefined;
    }
    this.setState({ activeIndex });
  };

  render() {
    const globalProps = pickGlobalProps(this.props);
    const { width, children } = this.props;
    const { activeIndex } = this.state;
    return (
      <Box
        block
        width={width}
        margin={margin(0, 0, spacer(3), 0)}
        {...globalProps}
      >
        {mapChildren({
          children,
          activeIndex,
          width,
          activateIndex: this.activateIndex,
        })}
      </Box>
    );
  }
}

export default ContAccordion;
