import _ from 'lodash';
import { OptionSet } from '@fabrictech/questionnaire/utils/types';
import {
  transformEnumeratedOptions,
  createOptionsFromArray,
} from '@fabrictech/questionnaire/utils/options';

import addressRegionValues from '@fabrictech/tapestry/utils/optionsGenerators/inputValues/addressRegion';
import healthValues from '@fabrictech/tapestry/utils/optionsGenerators/inputValues/health';

const quoteFormMinAge = 17;
const quoteFormMaxAge = 100;
const quoteFormMaxAgeExclusive = quoteFormMaxAge + 1;

const ageValues = _.range(quoteFormMinAge, quoteFormMaxAgeExclusive).map(
  ageNum => String(ageNum)
);

const genderOptionValues = {
  M: 'Male',
  F: 'Female',
} as const;

const tobaccoValues = {
  true: 'I use tobacco',
  false: "I don't use tobacco",
} as const;

export const age: OptionSet = {
  id: 'age',
  elements: createOptionsFromArray(ageValues),
};

export const gender: OptionSet = {
  id: 'gender',
  elements: Object.entries(genderOptionValues).map(([id, label]) => ({
    id,
    label,
  })),
};

export const state: OptionSet = {
  id: 'state',
  elements: transformEnumeratedOptions(addressRegionValues),
};

export const health: OptionSet = {
  id: 'health',
  elements: transformEnumeratedOptions(healthValues),
};

export const tobacco: OptionSet = {
  id: 'tobacco',
  elements: Object.entries(tobaccoValues).map(([id, label]) => ({
    id,
    label,
  })),
};
