// TODO make configurable

export const apiRequest = ['request'];
export const apiSuccess = ['success'];
export const apiFailure = ['failure'];
export const apiCounter = ['counter'];

export const root = 'api';
export const getRoot = key => [root, key];
export const getKeyPath = type => key => getRoot(key).concat(type);
export const getRequest = getKeyPath(apiRequest);
export const getSuccess = getKeyPath(apiSuccess);
export const getFailure = getKeyPath(apiFailure);
export const getCounter = getKeyPath(apiCounter);
