import React from 'react';
import { css } from 'glamor';

import { Size, color as colorTokens } from '@fabrictech/design-tokens';

import Box from '../../Box';
import Icon from '../../Icon';

// This is a workaround for this issue - https://github.com/threepointone/glamor/issues/332
const cssShim: any = css; // eslint-disable-line @typescript-eslint/no-explicit-any

const duration = 3000;

const animationIconA = cssShim.keyframes({
  '0%': { opacity: 0 },
  '50%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const animationIconB = cssShim.keyframes({
  '0%': { opacity: 1, transform: 'rotate(0deg)' },
  '50%': { opacity: 1, transform: 'rotate(180deg)' },
  '100%': { opacity: 0, transform: 'rotate(180deg)' },
});

const HourglassSpinner = ({
  size = 'sm',
  color = colorTokens.base.accent,
}: {
  size?: Size;
  color?: string;
}) => (
  <Box
    flexDirection="column"
    align="center"
    verticalAlign="center"
    dataTestName="HourglassSpinner"
  >
    {/* Icon A */}
    <Box
      align="center"
      verticalAlign="center"
      className={{
        animation: `${animationIconA} ${duration}ms infinite`,
      }}
    >
      <Icon size={size} type="hourglass" color={color} />
    </Box>
    {/* Icon B */}
    <Box
      align="center"
      verticalAlign="center"
      className={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        animation: `${animationIconB} ${duration}ms infinite`,
      }}
    >
      <Icon size={size} type="hourglass" color={color} />
    </Box>
  </Box>
);

export default HourglassSpinner;
