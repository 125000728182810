const buildApplicationInteractionProperties = ({
  name,
  product,
  section,
  question,
  adMetadata,
  metadata,
}) => ({
  name,
  product,
  ...(section ? { section } : {}),
  ...(question ? { question } : {}),
  ...(adMetadata ? { adMetadata } : {}),
  ...(metadata ? { metadata } : {}),
});

export default buildApplicationInteractionProperties;
