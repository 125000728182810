import _ from 'lodash';
import {
  globalProps,
  responsiveProps,
} from '../createGlobalPropsFilter/constants';
import createGlobalPropsFilter from '../createGlobalPropsFilter';

const pickGlobalProps = props =>
  createGlobalPropsFilter({
    props,
    propKeys: _.keys({ ...globalProps, responsiveProps }),
    filterFn: _.pick,
  });

export default pickGlobalProps;
