import React from 'react';
import { css, Rule } from 'glamor';

import { TextRank, Align } from '@fabrictech/design-tokens';

import createGetStylesWithGlobalProps from '../../utils/createGetStylesWithGlobalProps';
import { GlobalProps } from '../../types';
import tags from '../../constants/tags';

import getBaseStyles from './getStyles';

const getStyles = createGetStylesWithGlobalProps(getBaseStyles);

const getRootComponent = ({
  header,
  rank,
}: {
  header?: boolean;
  rank: number;
}): React.ReactType => {
  if (header) {
    switch (rank) {
      case 1:
        return tags.h1;
      case 2:
        return tags.h2;
      case 3:
        return tags.h3;
      case 4:
        return tags.h4;
      case 5:
        return tags.h5;
      case 6:
        return tags.h6;
      default:
        console.error(`Invalid rank ${rank} passed to <Text>.`);
        return tags.h1;
    }
  }
  return tags.p;
};

export type TextProps = GlobalProps & {
  /** Children */
  children?: React.ReactNode;
  /** Assign tag-name */
  as?: React.ReactType;
  /** Class name to extend functionality */
  className?: Rule;
  /** Whether to display as header */
  header?: boolean;
  /** Rank determines the font size, line height, and font weight */
  rank?: TextRank | 5 | 6;
  /** Aligns the text horizontally */
  textAlign?: Align;
  /** Controls the maximum width */
  maxWidth?: string | number;
  /** Assigns text color */
  color?: string;
  /** Provide a unique ID for this element */
  id?: string;
};

export const Text = ({
  children,
  className,
  as: Component,
  header,
  rank = 1,
  textAlign = 'left',
  id,
  ...rest
}: TextProps) => {
  Component = Component || getRootComponent({ header, rank });
  const textRank = rank > 5 ? 5 : rank;
  return (
    <Component
      className={css(
        getStyles({ header, rank: textRank, textAlign, ...rest }),
        className
      )}
      id={id}
    >
      {children}
    </Component>
  );
};

export default Text;
