import React from 'react';

import { css } from 'glamor';
import _ from 'lodash';

import { spacer, input } from '@fabrictech/design-tokens';

import InputGroupItem from './components/InputGroupItem';

import Box from '../Box';
import InputWrapper from '../InputWrapper';

import { divide, times, toPx } from '../../utils/css/values/px';
import pickGlobalProps from '../../utils/pickGlobalProps';
import { ResponsiveProps } from '../../types';

type InputGroupProps = {
  children: React.ReactElement[];
  width?: string | number;
  id?: string;
  /** Number of columns */
  columns?: number;
  /* Can be modified using responsive props. */
  colSpans: number[];
  /** Specifies a label below the InputGroup */
  helperText?: string;
  /** Indicates whether the InputGroup's styles should reflect the validity of the input */
  validation?: boolean;
  /** Responsive prop configuration */
  responsiveProps?: ResponsiveProps;
};

/** Wraps Inputs to group and control layout */
const InputGroup = ({
  children,
  width = 'auto',
  helperText,
  id,
  validation,
  columns = 12,
  ...rest
}: InputGroupProps) => {
  const isInvalid = !(_.isUndefined(validation) || validation);
  const gutterWidth = toPx(spacer(2));
  return (
    <InputWrapper
      helperText={helperText}
      invalid={isInvalid}
      width={width}
      borderless={true}
      {...pickGlobalProps(rest)}
    >
      <>
        <Box
          id={id}
          align="center"
          marginLeft={divide(gutterWidth, -2)}
          marginRight={divide(gutterWidth, -2)}
          marginBottom={times(input.layout.marginBottom, -1)}
          className={css({ flexWrap: 'wrap' })}
        >
          {React.Children.map(children, (child, index) => {
            const itemProps = {
              index,
              gutterWidth,
              columns,
              ...rest,
            };
            return (
              child && <InputGroupItem {...itemProps}>{child}</InputGroupItem>
            );
          })}
        </Box>
      </>
    </InputWrapper>
  );
};

export default InputGroup;
