import ImmutablePureComponent from 'react-immutable-pure-component';

class AddressQuestionAdapter extends ImmutablePureComponent {
  state = {
    initialValue: this.props.initialValue,
    updatedTextValue: false,
  };

  handleSelect = async value => {
    const {
      onTextChange,
      options,
      groupUpdateMap,
      valueKey,
      id: questionId,
      onChange,
    } = this.props;

    const textValKey = groupUpdateMap[questionId];
    const valObj = options.find(entry => entry[valueKey] === value);
    if (valObj) {
      /**
       * Here we update sibling data by:
       * 1. Creating an array of question updates based off of the 'groupUpdateMap'
       * which maps a question id to a value key in the selected address object.
       * 2. 'onUpdateData' then updates the answers in redux via 'questionnaireUpsertAnswers'
       * causing a change to the cooresponding questions 'initialValue' props.
       * 3. Because these fields are not in a focused state, this change triggers
       * an update to the value stored in local state on each of the questions
       * via 'componentDidUpdate' in 'EventQuestionAdapter'
       */
      const update = Object.entries(this.props.groupUpdateMap).map(
        ([id, valKey]) => ({
          id,
          value: this.props.parser(valObj[valKey]),
          error: !this.props.validation,
        })
      );
      this.props.onUpdateData(update);
      onTextChange && onTextChange(valObj[textValKey]);
      onChange && (await onChange(valObj[textValKey])); // needed for error handling
      document.getElementById(questionId).blur();
    }
  };

  getValue = () =>
    this.props.initialValue &&
    !this.state.updatedTextValue &&
    !this.props.textValue
      ? this.props.initialValue
      : this.props.textValue;

  handleChange = val => {
    const { onTextChange, onChange } = this.props;
    onTextChange && onTextChange(val);
    onChange && onChange(val); // needed for error handling
    this.setState({ updatedTextValue: true });
  };

  handleOnBlur = () => {
    const { onTextChange, textValue, onBlur, onChange } = this.props;
    if (textValue) {
      this.props.onUpdateData({
        id: this.props.id,
        value: this.props.parser(textValue),
        error: !this.props.validation,
      });
      onTextChange && onTextChange(textValue);
    }
    onChange && onChange(textValue); // needed for error handling
    onBlur && onBlur(); // sets focus: false, touched: true
  };

  handleFocus = () => {
    const { onFocus, textValue, onChange } = this.props;
    onChange && onChange(textValue); // needed for error handling
    onFocus && onFocus();
  };

  render() {
    const { render, ...rest } = this.props;

    const inputProps = {
      ...rest,
      value: this.getValue(),
      onChange: this.handleChange,
      onFocus: this.handleFocus,
      onSelect: this.handleSelect,
      onBlur: this.handleOnBlur,
      selectOnBlur: false,
      showNoMatchOption: false,
    };

    return render(inputProps);
  }
}

AddressQuestionAdapter.defaultProps = {
  valueKey: 'id',
  labelKey: 'id',
};

export default AddressQuestionAdapter;
