/**
 * pads the end of a list of items with a filler item as necessary,
 * so the list will fill a grid evenly.
 */
export const padItemsToFillGridEvenly = <ListItem extends object>({
  items,
  fillerItem,
  minimumGridSize,
  columns,
}: {
  items: Array<ListItem>;
  fillerItem: ListItem;
  minimumGridSize: number;
  columns: number;
}) => {
  let padding = 0;

  if (items.length < minimumGridSize) {
    padding = minimumGridSize - items.length;
  } else {
    padding =
      items.length % columns === 0 ? 0 : columns - (items.length % columns);
  }
  const fillerItems: Array<ListItem> =
    padding > 0 ? new Array(padding).fill(fillerItem) : [];
  return [...items, ...fillerItems];
};
