import * as stateKeyPaths from './keyPath';

import * as propNames from './propNames';

export { callApiAndIncrement } from './actions';

export {
  createMapStateToReduxApiPropsImmutable,
  createMapDispatchToPropsImmutable,
  // not sure this is necessary, but it's being used in web-consumer-insights/src/analytics/connector
  // seems like the connector could use the reduxApi connector instead maybe?
  createExecute,
  default as connector,
} from './connector';

export {
  default as createFilterReduxApiProps,
  createFilterReduxApiPropsImmutable,
} from './createFilterReduxApiProps';

export {
  getApiFailureTypeImmutable,
  getIsApiFailureTypeImmutable,
  createGetIsApiFailureTypeImmutable,
} from './getApiFailureType';

export { stateKeyPaths, propNames };
export { default as actionTypes } from './actionTypes';
export { default as reducerJs } from './reducerJs';
export { default as reducerImmutable } from './reducerImmutable';
export { default, reduxApiImmutable } from './reduxApi';
export { default as requestDataMiddleware } from './requestDataMiddleware';
export { methods } from './constants';
export { default as withQueryParams } from './withQueryParams';
export { default as useApiLegacy } from './hooks/useApiLegacy';
export { default as initUseApi, ApiState } from './hooks/useApi';
export * from './types';
export * from './hooks/types';
