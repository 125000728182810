import RecordType from './types';
import { buildSurveyResponseRecord } from './surveyResponse';
import { buildQuoteInteractionRecord } from './quoteInteraction';
import { buildApplicationInteractionRecord } from './applicationInteraction';
import { buildStripePaymentMethodInteractionRecord } from './stripePaymentMethodInteraction';
import { buildIntercomSupportInteractionRecord } from './intercomSupportInteraction';
import { buildSchedule4RealInteractionRecord } from './schedule4RealInteraction';
import { buildBlogInteractionRecord } from './blogInteraction';

const buildRecordByType = {
  [RecordType.SURVEY_RESPONSE]: buildSurveyResponseRecord,
  [RecordType.QUOTE_INTERACTION]: buildQuoteInteractionRecord,
  [RecordType.APPLICATION_INTERACTION]: buildApplicationInteractionRecord,
  [RecordType.STRIPE_PAYMENT_METHOD_INTERACTION]: buildStripePaymentMethodInteractionRecord,
  [RecordType.INTERCOM_SUPPORT_INTERACTION]: buildIntercomSupportInteractionRecord,
  [RecordType.SCHEDULE_4_REAL_INTERACTION]: buildSchedule4RealInteractionRecord,
  [RecordType.BLOG_INTERACTION]: buildBlogInteractionRecord,
};

export default buildRecordByType;
