import {
  border as borderTokens,
  color,
  spacer,
} from '@fabrictech/design-tokens';

import { border, calc, margin, padding } from '../../utils/css/values';
import * as mediaQueries from '../../sharedStyles/vars/mediaQueries';
import { navHeaderHeightMobile } from '../NavHeader/getStyles';

const { borderRadius } = borderTokens;

const height = calc('100vh - 80px');

export const getZoomableRegionStyles = () => ({
  height: '100%',
  width: '100%',
});

export const getScrollableRegionStyles = () => ({
  position: 'relative',
  borderRadius,
  border: border(color.border.base),
  backgroundColor: color.background.secondary,
  flex: '1 1 auto',
  width: '100%',
  overflow: 'scroll',
  // Remove the border on smaller devices where the esign-container
  // takes up the full screen and therefore the border is unnecessary.
  [mediaQueries.lt.sm]: { border: 0 },
});

export const getParentRegionStyles = ({
  disableFullScreenMobile,
  hasHeaderOffset,
}) => ({
  margin: margin(0, 'auto'),
  width: '100%',
  maxWidth: '600px',
  height,
  maxHeight: '600px',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  [mediaQueries.lt.sm]: {
    ...(disableFullScreenMobile
      ? {}
      : {
          // TODO: We should remove fixed-positioning entirely from the
          // mobile styles of the Esign experience as they are problematic.
          // In the meantime, we add additional spacing on top of the
          // container to account for the header.
          ...(hasHeaderOffset
            ? {
                top: navHeaderHeightMobile,
                height: calc(`100% - ${navHeaderHeightMobile}px`),
              }
            : {
                top: 0,
                height: '100%',
              }),

          position: 'fixed',
          left: 0,
          maxHeight: 'none',
          width: '100%',
          zIndex: 1000000000000,
        }),
  },
});

export const getActionButtonRegionStyles = () => ({
  padding: padding(spacer(2), 0, 0),
  [mediaQueries.lt.sm]: {
    padding: padding(spacer(3)),
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
});
