import { css } from 'glamor';
import { color, sizes } from '@fabrictech/design-tokens';
import { border } from '../../utils/css/values';

const sideLength = sizes.icon.sm;

const { setAlpha } = color.utils;

const rotate = css.keyframes({
  '0%': { transform: 'rotate3d(0, 0, 1, 0deg)' },
  '25%': { transform: 'rotate3d(0, 0, 1, 90deg)' },
  '50%': { transform: 'rotate3d(0, 0, 1, 180deg)' },
  '75%': { transform: 'rotate3d(0, 0, 1, 270deg)' },
  '100%': { transform: 'rotate3d(1, 1, 1, 360deg)' },
});

const loading = {
  animation: `${rotate} .5s linear infinite`,
  border: border({
    color: setAlpha(color.base.accent, 0.3),
    width: 2,
  }),
  borderTopColor: color.base.accent,
  borderRadius: '100%',
  display: 'block',
  height: sideLength,
  width: sideLength,
};

export default loading;
