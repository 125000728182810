import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import getStyles from './getStyles';

/** Element which pulses the opacity of children when `isLoading` is true */
const UtilLoadingMask = ({ children, isLoading, shouldPulse, ...rest }) => (
  <div className={css(getStyles({ isLoading, shouldPulse }))} {...rest}>
    {children}
  </div>
);

UtilLoadingMask.propTypes = {
  /** Whether or not it's loading */
  isLoading: PropTypes.bool,
  /** Whether or not the opacity should pulse */
  shouldPulse: PropTypes.bool,
};

UtilLoadingMask.defaultProps = {
  isLoading: false,
  shouldPulse: true,
};

export default UtilLoadingMask;
