import tinyColor from 'tinycolor2';

/**
 * Sets the alpha value of the provided color
 *
 * @param {string} color - color to apply alpha value to.
 * @param {number} alpha - the new alpha value. The accepted range is 0-1
 * @returns {string} - color with the applied alpha value.
 */
const setAlpha = (color: string, alpha: number): string =>
  tinyColor(color)
    .setAlpha(alpha)
    .toString();

/**
 * Return a boolean indicating whether the color's perceived brightness is light.
 *
 * @param {string} color
 * @returns {boolean}
 */
const isLight = (color: string): boolean => tinyColor(color).isLight();

/**
 * Return a boolean indicating whether the color's perceived brightness is dark.
 *
 * @param {string} color
 * @returns {boolean}
 */
const isDark = (color: string): boolean => tinyColor(color).isDark();

/**
 * Darken the color a given amount.
 *
 * @param {string} color - color to darken
 * @param {number} [amount=10] - amount to darken.  The accepted range is 0-100. Providing 100 will always return black.
 * @returns {string} - the darkened color
 */
const darken = (color: string, amount: number = 10): string =>
  tinyColor(color)
    .darken(amount)
    .toString();

/**
 * Lighten the color a given amount.
 *
 * @param {string} color - color to lighten
 * @param {number} [amount=10] - amount to lighten.  The accepted range is 0-100. Providing 100 will always return white.
 * @returns {string} - the lightened color
 */
const lighten = (color: string, amount: number = 10): string =>
  tinyColor(color)
    .lighten(amount)
    .toString();

const utils = {
  setAlpha,
  isLight,
  isDark,
  darken,
  lighten,
};

export default utils;
