import React from 'react';
import ImmutablePureComponent from 'react-immutable-pure-component';
import connectorDefault, { connectorImmutable } from './connector';

const compose = (...apis) => Object.assign({}, ...apis);

/**
 * The decorator that is the main API to redux-api.
 */
const createReduxApi = ({ connector, baseClass: BaseClass, displayName }) => (
  ...apis
) => WrappedComponent => {
  const composedApis = compose(...apis);

  class Result extends BaseClass {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  Result.displayName = displayName;
  return connector(composedApis)(Result);
};

const reduxApi = createReduxApi({
  connector: connectorDefault,
  baseClass: React.Component,
  displayName: 'ReduxApi',
});

export const reduxApiImmutable = createReduxApi({
  connector: connectorImmutable,
  baseClass: ImmutablePureComponent,
  displayName: 'ReduxApiImmutable',
});

export default reduxApi;
