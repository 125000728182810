import _ from 'lodash';
import querystring from 'querystring';

const appendQueryParams = (pathname, query) =>
  _.isEmpty(query)
    ? pathname
    : [pathname.replace(/\?.+$/, ''), querystring.stringify(query)].join('?');
/**
 * Helper to append querystring parameters to an endpoint string
 *
 * @param {object} params A mapping of values to append as querystring parameters.
 * @param {string} endpoint Endpoint base string
 * @returns {string} The endpoint with querystring params appended
 */
const withQueryParams = (endpoint, params = {}) =>
  appendQueryParams(endpoint, params);

export default withQueryParams;
