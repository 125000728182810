import _ from 'lodash';
import { answerStatuses } from '../constants';
import getAnswerState from './getAnswerState';

/**
 * PLEASE PLEASE PLEASE NOTE:
 * PLEASE PLEASE PLEASE NOTE:
 * PLEASE PLEASE PLEASE NOTE:
 * PLEASE PLEASE PLEASE NOTE:
 *
 * This is code that, for the time being, is literally duplicated
 * on the frontend and backend. Sigh. We need to move to a place
 * where this will no longer be the case.
 *
 * In the meantime, IF YOU MAKE A CHANGE HERE MAKE SURE THAT YOU
 * ALSO MAKE THE CORRESPONDING CHANGE (frontend or backend, etc.).
 *
 * Ugh. Yuck. Thank you for your time.
 */

const getIsCompleteAnswerState = answerState =>
  answerState === answerStatuses.valid || answerState === answerStatuses.absent;

const createGetInstanceOptionsById = optionsById => optionsId => {
  if (!optionsId) return {};

  const options = optionsById[optionsId];
  if (!options) {
    throw new Error(`no options found for id: ${optionsId}`);
  }

  return { [optionsId]: options.elements };
};

/**
 *
 * @export
 * @param {Object} questionnaireDefinition - The full form definition object.
 * @param {Object} answers - a hash of user-provided answers indexed by
 * question id
 * @returns - a function that returns a questionnaire instance given a set of answers.
 */
const createGetQuestionnaire = questionnaireDefinition => answers => {
  /**
   * Takes a hash of answers and question ids and recursively
   * generates the questionnaire for them, including a flat list of questions (a.k.a. question instances)
   * This list of questions is in the "fabric flat format" and is fed
   * into the questionnaire components on the client
   *
   * @param {string[]} questionIds - an array of question ids to analyze for
   * 'reflexivity' given the answers
   * @returns - a questionnaire object
   */

  const getInstanceOptionsById = createGetInstanceOptionsById(
    questionnaireDefinition.optionsById
  );

  const baseQuestionnaireState = {
    questionsById: {},
    optionsById: {},
    nextUnanswered: null,
    completed: true,
  };

  const getQuestionnaire = questionIds =>
    questionIds.reduce((siblingAcc, questionId) => {
      const questionDefinition =
        questionnaireDefinition.questionsById[questionId];
      const {
        questions: definitionQuestionIds,
        options: definitionOptionsId,
      } = questionDefinition;
      const question = _.cloneDeep(questionDefinition);
      const answer = answers[questionId];

      // identify any nested questions
      let instanceQuestionIds;
      if (_.isPlainObject(definitionQuestionIds)) {
        // Get the nested questions for each answer.
        // Need to flatten the array of answers, as an answer could be an array (e.g. for multi-selects).
        instanceQuestionIds = _.flatten(
          Object.values(_.pick(definitionQuestionIds, answer))
        );

        question.hasReflexive = true;
        question.reflexiveOn = _.keys(definitionQuestionIds);
      } else if (_.isArray(definitionQuestionIds)) {
        instanceQuestionIds = definitionQuestionIds;
      } else if (_.isUndefined(definitionQuestionIds)) {
        instanceQuestionIds = [];
      } else {
        throw new Error(
          `Expected questions to be an array, plain object, or undefined but got: ${typeof definitionQuestionIds}`
        );
      }

      // Recurse through question's children and retrieve their state.
      // If there are no children (instanceQuestionIds === [])
      // this just returns baseQuestionnaireState, which is a trivial questionnaire
      const childQuestionnaireState = getQuestionnaire(instanceQuestionIds);

      // determine the answerState
      const childQuestionInstances = _.pick(
        childQuestionnaireState.questionsById,
        instanceQuestionIds
      );
      const answerState = getAnswerState(
        question,
        answer,
        childQuestionInstances
      );

      // determine options required by question
      const instanceOptionsById = getInstanceOptionsById(definitionOptionsId);

      // append a few attributes to `question`
      question.answerState = answerState;
      question.questions = instanceQuestionIds;

      // return top level attributes of the Questionnaire
      return {
        questionsById: {
          ...siblingAcc.questionsById,
          [question.id]: question,
          ...childQuestionnaireState.questionsById,
        },
        optionsById: {
          ...siblingAcc.optionsById,
          ...instanceOptionsById,
          ...childQuestionnaireState.optionsById,
        },
        completed:
          siblingAcc.completed &&
          childQuestionnaireState.completed &&
          getIsCompleteAnswerState(answerState),
        nextUnanswered:
          siblingAcc.nextUnanswered ||
          childQuestionnaireState.nextUnanswered ||
          (getIsCompleteAnswerState(answerState) ? null : questionId),
      };
    }, baseQuestionnaireState);

  return {
    ...getQuestionnaire(questionnaireDefinition.entryQuestionIds),
    questions: questionnaireDefinition.entryQuestionIds,
    answers,
  };
};

export default createGetQuestionnaire;
