import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Wrapper,
  Button as MenuButton,
  Menu,
  MenuItem,
} from 'react-aria-menubutton';
import { css } from 'glamor';

import { icons } from '@fabrictech/design-tokens';

import tags from '../../constants/tags';
import { aligns } from '../../constants/layout';

import Box from '../Box';
import Button from '../Button';
import Text from '../Text';

import { getMenuStyles, getItemStyles } from './getStyles';

const menuItemClassName = css(getItemStyles({}));

/** List of items contained in a menu opened by a Button */
export class MenuDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  handleMenuToggle = ({ isOpen }) => {
    this.setState({ isOpen });
  };

  render() {
    const { align, icon, borderless, items, ...rest } = this.props;
    const { isOpen } = this.state;
    const menuClassName = css(getMenuStyles({ align }));
    return (
      <Box align={align} {...rest}>
        <Wrapper
          onSelection={this.props.onSelection}
          onMenuToggle={this.handleMenuToggle}
        >
          <Button
            as={MenuButton}
            tag={tags.button}
            icon={icon}
            rank="secondary"
            borderless={borderless}
            margin={0}
            {...(isOpen ? { 'data-simulate-active': true } : {})}
          />
          <Menu className={menuClassName}>
            {items.map(({ text, value }) => (
              <MenuItem
                key={value}
                className={menuItemClassName}
                text={text}
                value={value}
              >
                <Text
                  rank={2}
                  textAlign="left"
                  marginBottom={0}
                  color="inherit"
                >
                  {text}
                </Text>
              </MenuItem>
            ))}
          </Menu>
        </Wrapper>
      </Box>
    );
  }
}

MenuDropdown.propTypes = {
  /** Horizontal alignment */
  align: PropTypes.oneOf(_.keys(aligns)),
  /** Type of Icon to display in Button */
  icon: PropTypes.oneOf(_.keys(icons)),
  /** Determines if Button should be borderless */
  borderless: PropTypes.bool,
  /** A callback to run when the user makes a selection  */
  onSelection: PropTypes.func,
  /** Array of items */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Text for the item */
      text: PropTypes.string.isRequired,
      /** Value of the item */
      value: PropTypes.string.isRequired,
    })
  ),
};

MenuDropdown.defaultProps = {
  align: aligns.left,
  icon: 'dots',
  borderless: false,
};

export default MenuDropdown;
