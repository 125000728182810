import React from 'react';
import { css } from 'glamor';

import { color } from '@fabrictech/design-tokens';
import Box from '@fabrictech/tapestry/components/Box';

import { getParentStyles, getProgressBarStyles } from './getStyles';
import { ProgressBarProps } from './types';

const parentClassName = css(getParentStyles());

const ProgressBar = ({ progress }: ProgressBarProps) => (
  <Box
    block
    width="100%"
    background={color.background.secondary}
    className={parentClassName}
  >
    <Box
      block
      background={color.background.inverse.tertiary}
      className={css(getProgressBarStyles({ progress }))}
    />
  </Box>
);

export default ProgressBar;
