import React from 'react';
import { css } from 'glamor';

import getBaseStyles from './getStyles';
import createGetStylesWithGlobalProps from '../../../../utils/createGetStylesWithGlobalProps';

const getStyles = createGetStylesWithGlobalProps(getBaseStyles);

const InputGroupItem = ({
  children,
  marginBottom,
  ...rest
}: {
  children: React.ReactElement;
  marginBottom?: string | number;
  index: number;
  columns: number;
  colSpans: number[];
  gutterWidth: number | string;
}) => (
  <div className={css(getStyles(rest)).toString()}>
    {React.Children.map(children, child =>
      React.cloneElement(child, {
        ...child.props,
        margin: 0,
        width: '100%',
      })
    )}
  </div>
);

export default InputGroupItem;
