import { color, border, spacer, shadows } from '@fabrictech/design-tokens';
import { toPx } from '../../utils/css/values/px';
import { transformShadow } from '../../sharedStyles/vars/boxShadow';

const { borderRadius, borderStyle, borderWidth } = border;

const animationDuration = '0.2s';

const shadow = transformShadow({
  shadow: shadows.standard,
  params: { offset: { width: 4, height: 4 } },
});

export const getButtonStyles = ({ disabled }: { disabled?: boolean }) => ({
  margin: 0,
  padding: 0,
  borderWidth: 0,
  display: 'block',
  background: 'none',
  outline: '1px solid transparent',
  cursor: disabled ? 'default' : 'pointer',
  opacity: disabled ? 0.3 : 1,
});

export const getBorderStyles = () => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  borderRadius,
  borderStyle,
  borderWidth,
  borderColor: color.border.base,
  boxShadow: shadow,
});

export const getBackgroundStyles = ({ checked }: { checked: boolean }) => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  width: spacer(2) - borderWidth,
  backgroundColor: color.base.success,
  transition: `opacity ${animationDuration}`,
  opacity: checked ? 1 : 0,
});

export const getMovingBoxStyles = ({ checked }: { checked: boolean }) => ({
  width: spacer(2) + borderWidth,
  height: '100%',
  backgroundColor: color.input.active.accent,
  borderRadius,
  borderStyle,
  borderWidth,
  borderColor: color.border.base,
  transition: `transform ${animationDuration}`,
  transform: checked
    ? undefined
    : `translateX(${toPx(spacer(2) - borderWidth)})`,
});
