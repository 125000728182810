import { ValueOf } from '../type-utils';

/**
 * **LINEN DUPLICATION WARNING:** Keep in sync.
 * @see https://github.com/fabrictech/linen/blob/master/node/services/underwriting/lib/ineligibility/index.ts
 */
export const ineligibilityTypes = {
  ineligibleAge: 'ineligibleAge',
  ineligibleAddressRegion: 'ineligibleAddressRegion',
  softDecline: 'softDecline',
  decline: 'decline',
  unsupported: 'unsupported',
} as const;

export type IneligibilityType = ValueOf<typeof ineligibilityTypes>;

// This constant lives in definitely-fabric since it is used by both www and
// quote-widget.
// { stateIneligible: 'state_ineligible' }
export const otherOptionsUtmTerms = {
  softDecline: 'soft_decline',
  decline: 'decline',
  ageIneligible: 'age_ineligible',
  stateIneligible: 'state_ineligible',
  unsupported: 'unsupported',
} as const;

// This constant lives in definitely-fabric since it is used by both www and
// quote-widget.
// { ineligibleAddressRegion: 'state_ineligible' }
export const utmTermByInsuranceIneligibilityType = {
  [ineligibilityTypes.ineligibleAge]: otherOptionsUtmTerms.ageIneligible,
  [ineligibilityTypes.ineligibleAddressRegion]:
    otherOptionsUtmTerms.stateIneligible,
  [ineligibilityTypes.softDecline]: otherOptionsUtmTerms.softDecline,
  [ineligibilityTypes.decline]: otherOptionsUtmTerms.decline,
  [ineligibilityTypes.unsupported]: otherOptionsUtmTerms.unsupported,
} as const;
