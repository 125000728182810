import React from 'react';
import _ from 'lodash';

/**
 * fabricGroups are a Question type where multiple Questions (usually
 * `TextInput`s) hand off things like their validation and focus state to a
 * parent Question.
 *
 * This adapter is the top level adapter for one such Question. Its
 * corresponding Input will take its output and override the behavior of its
 * children with it.
 */
class FabricQuestionGroupAdapter extends React.Component {
  constructor(props) {
    super(props);

    this.state = { inputs: {} };

    this.handleReportError = this.handleReportError.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleFocus({ id }) {
    const update = { focus: true };
    this.updateInputState(id, update);
  }

  handleBlur({ id }) {
    const update = { focus: false };
    this.updateInputState(id, update);
  }

  handleReportError({ id, message }) {
    const update = { error: message || '' };
    this.updateInputState(id, update);
  }

  /**
   * Handles generic local-state updates of a particular input. Used
   * in event handlers to de-dupe logic.
   *
   * @param {any} id The id of the input we're updating.
   * @param {any} update The state change.
   */
  updateInputState(id, update) {
    this.setState(prevState => ({
      inputs: {
        ...prevState.inputs,
        [id]: {
          ...(prevState.inputs[id] || {}),
          ...update,
        },
      },
    }));
  }

  getShowErrorMessages() {
    // Only show the error messages if none of the contained fields
    // are in focus.
    return !_.some(this.state.inputs, ({ focus }) => focus);
  }

  getErrorMessages() {
    const { inputs } = this.state;
    return _.filter(
      _.reduce(inputs, (acc, { error } = {}) => [...acc, error], [])
    );
  }

  render() {
    const showErrorMessages = this.getShowErrorMessages();
    const errorMessages = this.getErrorMessages();
    const { render, ...restProps } = this.props;

    return render({
      ...restProps,
      onReportError: this.handleReportError,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      suppressErrorMessages: true,
      helperText: showErrorMessages && errorMessages.join(' '),
      validation: _.isEmpty(errorMessages),
    });
  }
}

export default FabricQuestionGroupAdapter;
