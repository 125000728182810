import React from 'react';
import _ from 'lodash';
import { css } from 'glamor';

import { color, spacer, Align } from '@fabrictech/design-tokens';

import Box, { BoxProps } from '../Box';
import Text, { TextProps } from '../Text';

import Tooltip from '../Tooltip';
import { sameAdjacentSibling } from '../../utils/css/selectors';

export type LabelValuePair = {
  label: string;
  value?: string | number | string[];
  href?: string;
  target?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  items?: Array<LabelValuePair | undefined | null>;
  tooltip?: string;
};

export type LabelValueListProps = BoxProps & {
  labelColumnWidth?: number | string;
  labelColumnAlign?: Align;
  items: Array<LabelValuePair | undefined | null>;
  hasRowSeparator?: boolean;
};

type RowProps = Pick<
  LabelValueListProps,
  'labelColumnAlign' | 'labelColumnWidth' | 'hasRowSeparator'
> & { item: LabelValuePair };

const textProps: Partial<TextProps> = { rank: 2, marginBottom: 0 };
const cellPadding = spacer(0.5);
const gutterWidth = spacer(0.5);

const getTableStyles = () => ({
  width: '100%',
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
});

const getRowStyles = ({
  hasRowSeparator,
}: Pick<LabelValueListProps, 'hasRowSeparator'>) => ({
  ...(hasRowSeparator
    ? { borderTop: `1px solid ${color.palette.silver}` }
    : {}),
});

const getCellStyles = () => ({
  padding: cellPadding,
  verticalAlign: 'top',
  [sameAdjacentSibling]: {
    paddingLeft: cellPadding + gutterWidth,
  },
});

const getListStyles = () => ({
  margin: 0,
  padding: 0,
  listStyleType: 'none',
});

const tableClassName = css(getTableStyles()).toString();
const cellClassName = css(getCellStyles()).toString();
const listClassName = css(getListStyles()).toString();

const ItemValue = ({
  tooltip,
  value,
  href,
  target,
  onClick,
}: Pick<
  LabelValuePair,
  'tooltip' | 'value' | 'href' | 'target' | 'onClick'
>) => {
  if (tooltip) {
    return (
      <Text {...textProps}>
        <Tooltip content={tooltip}>
          <abbr>{value}</abbr>
        </Tooltip>
      </Text>
    );
  }
  if (href) {
    return (
      <Text {...textProps}>
        <a href={href} target={target} onClick={onClick}>
          {value}
        </a>
      </Text>
    );
  }

  if (Array.isArray(value)) {
    return (
      <ul className={listClassName}>
        {value.map((arrayItem, i) => (
          <li key={i}>
            <Text {...textProps}>{arrayItem}</Text>
          </li>
        ))}
      </ul>
    );
  }

  return <Text {...textProps}>{value}</Text>;
};

const Row = ({
  item: { label, value, tooltip, href, target, onClick, items },
  labelColumnWidth,
  labelColumnAlign,
  hasRowSeparator,
}: RowProps) => {
  const rowClassName = css(getRowStyles({ hasRowSeparator })).toString();
  return (
    <>
      <tr className={rowClassName}>
        <td className={cellClassName} width={labelColumnWidth}>
          <Text {...textProps} textAlign={labelColumnAlign}>
            <strong>{label}</strong>
          </Text>
        </td>
        <td className={cellClassName}>
          <ItemValue
            tooltip={tooltip}
            value={value}
            href={href}
            target={target}
            onClick={onClick}
          />
        </td>
      </tr>
      {items
        ? _.compact(items).map((childItem, i) => (
            <Row
              key={i}
              item={childItem}
              hasRowSeparator={false}
              labelColumnAlign={labelColumnAlign}
              labelColumnWidth={labelColumnWidth}
            />
          ))
        : null}
    </>
  );
};

const LabelValueList = ({
  items,
  labelColumnWidth = '35%',
  labelColumnAlign = 'left',
  hasRowSeparator = false,
  ...rest
}: LabelValueListProps) => (
  <Box width="100%" marginBottom={spacer(3)} {...rest}>
    <table className={tableClassName}>
      <tbody>
        {_.compact(items).map((item, i) => {
          const isTopRow = i === 0;
          return (
            <Row
              key={i}
              item={item}
              hasRowSeparator={hasRowSeparator && !isTopRow}
              labelColumnAlign={labelColumnAlign}
              labelColumnWidth={labelColumnWidth}
            />
          );
        })}
      </tbody>
    </table>
  </Box>
);

export default LabelValueList;
