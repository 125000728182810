import recordTypes from '../types';
import buildRecord from '../../buildRecord';
import buildPageViewProperties from './buildPageViewProperties';

const buildPageViewRecord = buildRecord(recordTypes.PAGE_VIEW)(
  buildPageViewProperties
);

export const buildSecurePageViewRecord = buildRecord(
  recordTypes.SECURE_PAGE_VIEW
)(buildPageViewProperties);

export default buildPageViewRecord;
