import transformClockwiseBoxMeasures from './common/transformClockwiseBoxMeasures';
import { createSimpleFunction, createJoinFunction } from './common/functions';

/*
 * Properties
 */
export { default as border, defaultBorderStyle } from './border';
export const padding = transformClockwiseBoxMeasures;
export const margin = transformClockwiseBoxMeasures;

/*
 * Functions
 */
export const url = createSimpleFunction('url');
export const calc = createSimpleFunction('calc');
export const imageSet = createJoinFunction('image-set');

/*
 * Transforms
 */
export const scale = createSimpleFunction('scale');

/*
 * Misc.
 */

export const important = val => `${val} !important`;
