import React from 'react';
import { css } from 'glamor';
import _ from 'lodash';

import {
  color as colorTokens,
  IconType,
  Size,
  Shape,
  button,
  sizes,
  spacer,
  ButtonRank,
  Align,
} from '@fabrictech/design-tokens';

import tags from '../../constants/tags';

import Box from '../Box';
import Icon from '../Icon';
import HourglassSpinner from '../UtilSpinner/components/HourglassSpinner';

import createGetStylesWithGlobalProps from '../../utils/createGetStylesWithGlobalProps';
import omitGlobalProps from '../../utils/omitGlobalProps';
import { GlobalProps, AsLinkProps } from '../../types';

import getBaseStyles, {
  getTextStyles as getBaseTextStyles,
  getForegroundColor,
} from './getStyles';

const getStyles = createGetStylesWithGlobalProps(getBaseStyles);
const getTextStyles = createGetStylesWithGlobalProps(getBaseTextStyles);

export type ButtonProps = AsLinkProps &
  GlobalProps & {
    /** Children node */
    children?: React.ReactNode;
    /** The hierarchy rank of the button */
    rank?: ButtonRank;
    /** The shape of the button */
    shape?: Shape;
    /** The icon to display */
    icon?: IconType;
    /** Disables the button */
    disabled?: boolean;
    /** Sets button to use `display: 'block'` */
    block?: boolean;
    /** Removes border from the button */
    borderless?: boolean;
    /** Displays shadow for the button */
    hasShadow?: boolean;
    /** The size of the button */
    size?: Size;
    /** The width of the button. Default width is based on size. */
    width?: string | number;
    /** Color of the button */
    color?: string;
    /** Displays arrow inside the button */
    hasArrow?: boolean;
    /** Displays spinner inside button */
    isLoading?: boolean;
    /** Alignment */
    align?: Align;
    /** The default behavior of the button */
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  };

/** Buttons trigger actions and allow for navigation. */
export const Button = ({
  as: Component = 'button',
  disabled = false,
  block = false,
  rank = 'primary',
  hasShadow = true,
  size = 'md',
  width,
  shape,
  borderless = false,
  color,
  responsiveProps,
  children,
  icon,
  hasArrow = false,
  isLoading = false,
  align = 'center',
  type = 'button',
  ...rest
}: ButtonProps) => {
  const isPrimary: boolean = rank === 'primary';

  /** The default should be 'square' for buttons w/o icons, but 'round' for ones with icons */
  shape = shape || (icon ? 'round' : 'square');

  /** Default color for 'primary' button is the background, for 'secondary' button is the darkText color */
  color =
    color ||
    (isPrimary ? colorTokens.button.background : colorTokens.button.darkText);

  width = width || button[size].layout.width;
  const buttonClassName = css(
    getStyles({
      shape,
      hasIcon: !!icon,
      isPrimary,
      width,
      block,
      disabled,
      hasShadow,
      size,
      borderless,
      children,
      responsiveProps,
      color,
      ...rest,
    })
  ).toString();

  const foregroundColor = getForegroundColor({ isPrimary, color });

  // Isolate particular props that text-className relies on for
  // responsiveness.
  const textResponsiveProps = _.reduce(
    responsiveProps,
    (acc, value, key) => ({
      ...acc,
      [key]: _.pick(value, ['isPrimary', 'color', 'size']),
    }),
    {}
  );

  const textClassName = css(
    getTextStyles({
      isPrimary,
      color,
      size,
      align,
      responsiveProps: textResponsiveProps,
    })
  ).toString();

  const loadingClassName = css({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  });

  return (
    <Component
      {...(Component === tags.button
        ? // Disable button action if isLoading, but don't apply disabled styles
          { disabled: disabled || isLoading, type }
        : {})}
      className={buttonClassName}
      {...omitGlobalProps(rest)}
    >
      <Box width="100%" height="100%" align="center" verticalAlign="center">
        {isLoading ? (
          <Box
            align="center"
            verticalAlign="center"
            className={loadingClassName}
          >
            <HourglassSpinner size="sm" color={foregroundColor} />
          </Box>
        ) : null}
        {icon && !isLoading ? (
          <Icon
            type={icon}
            color={foregroundColor}
            size={sizes.getRelativeSize({ size, change: -1 })}
          />
        ) : null}
        {children && !icon ? (
          <Box
            width={align === 'center' ? 'auto' : '100%'}
            className={isLoading && css({ opacity: 0 })}
          >
            <span className={textClassName}>{children}</span>
            {/* `hasArrow` should control the display of an arrow inside the button */}
            {hasArrow ? (
              <Box height={spacer(2)} verticalAlign="center">
                <Icon
                  type="arrowRightLong"
                  color={foregroundColor}
                  size={sizes.getRelativeSize({ size, change: 1 })}
                  marginLeft={spacer(2)}
                />
              </Box>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </Component>
  );
};

export default Button;
