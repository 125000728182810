export type BorderStyle = 'solid' | 'dotted' | 'dashed';

const borderWidth = 2;
const borderStyle: BorderStyle = 'solid';
const borderRadius = 0;

export const border = {
  borderWidth,
  borderStyle,
  borderRadius,
} as const;
