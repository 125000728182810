import { toPx } from './values/px';

// TODO: Should queryLt be queryLte? It seems to be inclusive
export const queryGte = val => `@media screen and (min-width: ${toPx(val)})`;
export const queryLt = val => `@media screen and (max-width: ${toPx(val)})`;
export const queryBetween = (val1, val2) =>
  `@media screen and (min-width: ${toPx(val1)}) and (max-width: ${toPx(val2)})`;

// Manifesting for easy import/export.
const queryBreakpoint = {
  gte: queryGte,
  lt: queryLt,
  between: queryBetween,
};

export default queryBreakpoint;
