import React from 'react';
import { css } from 'glamor';

import {
  spacer,
  GlyphType,
  typography,
  color,
} from '@fabrictech/design-tokens';
import { margin, padding } from '../../utils/css/values';
import mediaQueries from '../../sharedStyles/vars/mediaQueries';
import { getStyleObjectFromFontStyle } from '../../constants/typography';

import { GlobalProps } from '../../types';

import Text from '../Text';
import Box from '../Box';
import Glyph from '../Glyph';
import Button, { ButtonProps } from '../Button/Button';
import TextMarkdown from '../TextMarkdown';
import LayoutContainer from '../LayoutContainer';
import LayoutItem from '../LayoutItem';

export type AdCardProps = GlobalProps & {
  /** Subheader text and glyph */
  subheader?: {
    text: string;
    glyph: GlyphType;
  };
  /** Header text and image url */
  header: {
    text: string;
    image?: string;
  };
  /** Body text */
  body: string;
  /** Button props */
  buttonProps: ButtonProps;
  /** Optional custom html to be rendered after the body */
  customHtml?: string;
};

/** lineHeight is updated here to better center the glyph and subheader text */
const subheaderClassName = css({
  ...getStyleObjectFromFontStyle({
    ...typography.header[5],
    lineHeight: { desktop: 14, mobile: 12 },
  }),
});

const AdCard = ({
  subheader,
  header,
  body,
  buttonProps,
  customHtml,
  ...rest
}: AdCardProps) => (
  <Box
    flexDirection="column"
    marginBottom={spacer(3)}
    backgroundColor="#EFEFEF"
    responsiveProps={{
      [mediaQueries.gte.sm]: {
        marginBottom: spacer(4),
      },
    }}
    {...rest}
  >
    <LayoutContainer
      padding={padding(spacer(3.75), spacer(2), 0)}
      marginBottom={spacer(2.5)}
      gutterWidth={spacer(2)}
      responsiveProps={{
        [mediaQueries.gte.md]: {
          padding: subheader
            ? padding(spacer(3.75), spacer(3.5), 0)
            : padding(spacer(7.75), spacer(3.5), 0),
        },
      }}
      layoutItemResponsiveProps={{
        [mediaQueries.gte.md]: {
          gutterWidth: spacer(3),
        },
      }}
    >
      {/**
       * Optional Subheader (Eyebrow)
       */}
      {subheader ? (
        <LayoutItem>
          <Box marginBottom={spacer(2.5)} verticalAlign="center">
            <Glyph type={subheader.glyph} />
            <Text
              header
              rank={5}
              margin={margin(0, 0, 0, spacer(1))}
              className={subheaderClassName}
            >
              {subheader.text}
            </Text>
          </Box>
        </LayoutItem>
      ) : null}
      {/**
       * Optional header image
       */}
      <LayoutItem
        span={3}
        marginBottom={20}
        hidden={!header.image}
        responsiveProps={{
          [mediaQueries.gte.md]: {
            span: 4,
            marginBottom: 35,
          },
        }}
      >
        <Box
          // Magic numbers here come from the design spec.
          height={56}
          backgroundColor={color.background.primary}
          align="center"
          verticalAlign="center"
          padding={3}
          responsiveProps={{
            [mediaQueries.gte.md]: {
              maxWidth: 'none',
              height: 108,
              padding: 6,
            },
          }}
        >
          {header.image ? (
            <img
              src={header.image}
              className={css({
                maxWidth: '100%',
                maxHeight: '100%',
              }).toString()}
            />
          ) : null}
        </Box>
      </LayoutItem>
      {/**
       * Header with body (body rendered here only on desktop)
       */}
      <LayoutItem
        span={header.image ? 9 : 12}
        marginBottom={20}
        responsiveProps={{
          [mediaQueries.gte.md]: {
            span: header.image ? 8 : 12,
            marginBottom: 0,
          },
        }}
      >
        <Box flexDirection="column">
          <Text
            header
            rank={4}
            marginBottom={spacer(2)}
            responsiveProps={{
              [mediaQueries.gte.sm]: {
                rank: 3,
                marginBottom: spacer(2.5),
              },
            }}
          >
            {header.text}
          </Text>
          <Box
            hidden={true}
            responsiveProps={{
              [mediaQueries.gte.md]: { hidden: false },
            }}
          >
            <TextMarkdown
              childProps={{
                Text: { rank: 2, marginBottom: 0 },
              }}
            >
              {body}
            </TextMarkdown>
            {customHtml ? (
              <div dangerouslySetInnerHTML={{ __html: customHtml }} />
            ) : null}
          </Box>
        </Box>
      </LayoutItem>
      {/**
       * Body (rendered here only on mobile)
       */}
      <LayoutItem
        responsiveProps={{
          [mediaQueries.gte.md]: { hidden: true },
        }}
      >
        <TextMarkdown childProps={{ Text: { rank: 2, marginBottom: 0 } }}>
          {body}
        </TextMarkdown>
      </LayoutItem>
      {/**
       * Button
       */}
      <LayoutItem>
        <Button hasArrow width="100%" align="left" {...buttonProps} />
      </LayoutItem>
    </LayoutContainer>
  </Box>
);

export default AdCard;
