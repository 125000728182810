import _ from 'lodash';

export const ValidationErrorType = {
  Required: 'Required',
  MinValue: 'MinValue',
  MaxValue: 'MaxValue',
  Pattern: 'Pattern',
  InversePattern: 'InversePattern',
  MinDate: 'MinDate',
  MaxDate: 'MaxDate',
  InclusiveOptions: 'InclusiveOptions',
};

const validValidationErrorTypes = _.values(ValidationErrorType);

/* ILUVU: note that we can't extend Error because
 * browser environments don't handle extending
 * built-in types well.
 * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types */
export function ValidationError(type, ...params) {
  const instance = new Error(...params);
  instance.type = type;

  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));

  if (!_.includes(validValidationErrorTypes, type)) {
    throw new TypeError(
      `Invalid ValidationErrorType: '${type}', expected one of: ${validValidationErrorTypes}`
    );
  }

  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, ValidationError);
  }
  return instance;
}

ValidationError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true,
  },
});
