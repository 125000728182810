import React from 'react';
import InputOption from '../../../components/InputOption';

const enumeratedOptionsGenerator = (placeholderText, values) => () => {
  const options = values.map(option => (
    <InputOption key={option.value} value={option.value}>
      {option.label}
    </InputOption>
  ));

  return options;
};

export default enumeratedOptionsGenerator;
