import { css } from 'glamor';

// Import CSS reset.
import 'glamor/reset';

// Border-box configuration
// https://www.paulirish.com/2012/box-sizing-border-box-ftw/
css.global('html', {
  boxSizing: 'border-box',
});
css.global('*, *:before, *:after', {
  boxSizing: 'inherit',
});
