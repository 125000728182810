import { spacer, border } from '@fabrictech/design-tokens';

import tags from '../../constants/tags';
import {
  checked,
  hover,
  focus,
  generalSibling,
  descendant,
  after,
} from '../../utils/css/selectors';
import { inputStyles } from '../../sharedStyles/vars/input';
import { inputToggleFixedWidth } from './constants';

const { borderRadius } = border;

const getStyles = ({ first, last, width, disabled }) => {
  return {
    flexGrow: width ? 1 : 0,
    flexShrink: 1,
    flexBasis: width ? 0 : inputToggleFixedWidth,
    flexWrap: 'wrap',
    cursor: 'pointer',
    marginRight: last ? 0 : spacer(1),
    [descendant(tags.input)]: {
      borderRadius,
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      border: 'none',
      backgroundColor: 'transparent',
      backgroundImage: 'none',
      [generalSibling(tags.label)]: {
        ...inputStyles.base,
        borderTopLeftRadius: first ? borderRadius : 0,
        borderTopRightRadius: last ? borderRadius : 0,
        textAlign: 'center',
        paddingLeft: spacer(2),
        opacity: disabled ? 0.5 : undefined,
        transition: 'background-color 0.15s',
        [after]: {
          content: '""',
          display: 'block',
          position: 'absolute',
          bottom: 0,
          left: '50%',
          width: 0,
          height: inputStyles.active.borderBottomWidth * 3,
          zIndex: 1,
          pointerEvents: 'none',
          transition: 'left 0.15s, width 0.15s',
        },
      },
      [checked]: {
        backgroundColor: 'transparent',
        backgroundImage: 'none',
        [hover]: { background: 'transparent' },
        [focus]: { background: 'transparent' },
        [generalSibling(tags.label)]: {
          [after]: {
            width: '100%',
            left: 0,
            background: disabled
              ? inputStyles.disabled.borderBottomColor
              : inputStyles.active.borderBottomColor,
          },
        },
      },
    },
  };
};

export default getStyles;
