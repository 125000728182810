import React from 'react';
import { css } from 'glamor';

import {
  typography,
  spacer,
  RebrandColorPalette,
} from '@fabrictech/design-tokens';

import Box, { BoxProps } from '../Box';
import Card from '../Card';
import Text from '../Text';

import { getParentStyles } from './getStyles';
import { getStyleObjectFromFontStyle } from '../../constants/typography';

import HourglassSpinner from './components/HourglassSpinner';

type UtilSpinnerProps = BoxProps & {
  /** Positions `UtilSpinner` fixed and in the center of the page. */
  isFullPage?: boolean;
  /** Text content to display */
  loadingText?: string;
};

/** Used to indicate a loading state */
const UtilSpinner = ({
  isFullPage = false,
  loadingText = 'Hang tight',
  ...rest
}: UtilSpinnerProps) => (
  <Box
    block
    {...rest}
    className={css(getParentStyles({ isFullPage }))}
    dataTestName="UtilSpinner"
  >
    <Card
      backgroundColor={RebrandColorPalette.tan}
      marginBottom={0}
      padding={spacer(2)}
    >
      <HourglassSpinner />
      <Text
        className={{
          ...getStyleObjectFromFontStyle(typography.button[2]),
          whiteSpace: 'nowrap',
        }}
        textAlign="center"
        marginBottom={0}
        paddingTop={spacer(2)}
      >
        {loadingText}
      </Text>
    </Card>
  </Box>
);

export default UtilSpinner;
