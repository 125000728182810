/* eslint-disable id-length */

import { plus, minus, abs, divide } from '../coordinates';

const getDomNodeTopLeftCoord = element => {
  const { left: x, top: y } = element.getBoundingClientRect();
  return { x, y };
};

const getDocumentTopLeftScrollCoord = () => {
  const { scrollLeft: x, scrollTop: y } = document.body;
  return { x, y };
};

export const getOffset = element =>
  plus(getDomNodeTopLeftCoord(element), getDocumentTopLeftScrollCoord());

export const getScrollOffset = ({ scrollLeft: x, scrollTop: y }) => ({ x, y });

export const getTouchesFromEvent = event => event.touches;

export const getCoordsFromTouch = ({ pageX: x, pageY: y }) => ({ x, y });

export const getCoordsOfTouchEvent = event => {
  const touches = getTouchesFromEvent(event);
  /*
   * NOTE: The TouchList is not an Array, so we convert it to one
   * so we can map, etc.
   */
  return [touches[0], touches[1]].map(getCoordsFromTouch);
};

export const getScaleFromLengths = (lengthA, lengthB) =>
  (lengthB - lengthA) / ((lengthA + lengthB) / 2);

/*
 * TODO: Profile use ** here. Cursory research indicates use of **
 * is fastest in strict mode according to tests here:
 * https://jsperf.com/math-pow-vs-exponentiation-operator
 */
export const getLength = (a, b) => {
  const { x: dx, y: dy } = abs(minus(a, b));
  return Math.sqrt(dx ** 2 + dy ** 2);
};

export const getLengthOfPinchFromEvent = event => {
  const touchPoints = getCoordsOfTouchEvent(event);
  return getLength(...touchPoints);
};

export const isPinchTouch = event => getTouchesFromEvent(event).length === 2;

export const getCenter = (a, b) => divide(plus(a, b), 2);

export const getCenterCoordOfTouchEvent = event =>
  getCenter(...getCoordsOfTouchEvent(event));
