import {
  border as borderTokens,
  color,
  spacer,
} from '@fabrictech/design-tokens';

import { border, margin, padding } from '../../utils/css/values';
import { standardShadow } from '../../sharedStyles/vars/boxShadow';
import { times, plus } from '../../utils/css/values/px';
import * as mediaQueries from '../../sharedStyles/vars/mediaQueries';

const { borderRadius, borderWidth } = borderTokens;

const subcardSpacer = spacer(3);
const getGroupDepthStyles = (groupDepth: number) => ({
  padding: padding(spacer(3), times(subcardSpacer, groupDepth), spacer(0)),
  margin: margin(0, times(subcardSpacer, -groupDepth), spacer(10)),
});

const backgroundColor = color.background.tertiary;

export const getBaseStyles = ({ isGroup }: { isGroup: boolean }) => ({
  margin: margin(0, 'auto'),
  padding: 0,
  ...(isGroup
    ? {
        position: 'relative',
        top: spacer(3),
        borderRadius,
        border: border(color.border.base),
        boxShadow: standardShadow,
        ...getGroupDepthStyles(1),
        backgroundColor,
        '& &': {
          ...getGroupDepthStyles(2),
          backgroundColor,
        },
        '& & &': {
          ...getGroupDepthStyles(3),
          backgroundColor,
        },
        '& & & &': {
          ...getGroupDepthStyles(4),
          backgroundColor,
        },
        [mediaQueries.lt.sm]: {
          '&, & &, & & &, & & & &': {
            ...getGroupDepthStyles(1),
          },
        },
      }
    : {}),
});

export const getGroupTitleStyles = () => ({
  display: 'inline-block',
  textTransform: 'uppercase',
  padding: padding(spacer(1.5), spacer(4)),
  backgroundColor,
  transform: `translateY(-${plus(spacer(6), times(borderWidth, 2))})`,
  border: border(color.border.base),
  marginBottom: spacer(-3),
  fontWeight: 'bold',
});
