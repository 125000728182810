import React from 'react';
import FormField from '@fabrictech/tapestry/components/FormField';

const QuestionnaireFabricStruct = props => (
  <div id={props.id}>
    <FormField {...props} />
  </div>
);

export default QuestionnaireFabricStruct;
