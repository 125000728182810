import { spacer } from '@fabrictech/design-tokens';
import { ProgressBarProps } from './types';
import { softShadow } from '../../../../sharedStyles/vars/boxShadow';

const progressBarHeight = spacer(1);

export const getParentStyles = () => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: `-${progressBarHeight}`,
  boxShadow: softShadow,
});

export const getProgressBarStyles = ({ progress }: ProgressBarProps) => ({
  transition: 'width 1.6s',
  width: `${progress * 100}%`,
  height: progressBarHeight,
});
