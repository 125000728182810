import React from 'react';
import { css } from 'glamor';
import _ from 'lodash';

import { breakpoints } from '@fabrictech/design-tokens';
import * as mediaQueries from '../../sharedStyles/vars/mediaQueries';

import LayoutContainer, {
  LayoutContainerProps,
} from '../LayoutContainer/LayoutContainer';

import getItemResponsiveProps, { ItemStyles } from './getStyles';

export const marketingLayoutResponsiveItemStyles: {
  [key: string]: ItemStyles;
} = {
  [mediaQueries.gte.md]: { gutterWidth: 30 },
  [mediaQueries.gte.lg]: { gutterWidth: 50 },
  [mediaQueries.gte.xl]: { gutterWidth: 80 },
};

export const marketingLayoutMaxWidth = breakpoints.xxl;

const getLayoutContainerProps = ({
  className,
  layoutItemResponsiveProps = {},
  ...rest
}: LayoutContainerProps) => ({
  className: css(
    {
      [mediaQueries.lt.lg]: {
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    className
  ),
  layoutItemResponsiveProps: getItemResponsiveProps(
    _.merge({}, marketingLayoutResponsiveItemStyles, layoutItemResponsiveProps)
  ),
  ...rest,
});

/**
 * `MarketingLayoutContainer` is responsible for vertical spacing and the gutter between `LayoutItem` children.
 */
export const MarketingLayoutContainer = ({
  children,
  ...rest
}: LayoutContainerProps) => {
  const containerProps: LayoutContainerProps = getLayoutContainerProps(rest);
  return <LayoutContainer {...containerProps}>{children}</LayoutContainer>;
};

// These default props may be causing issues with props passed to the component
MarketingLayoutContainer.defaultProps = {
  hasInteriorGutter: true,
  maxWidth: breakpoints.xxl,
  padding: 0,
  gutterWidth: 20,
  columns: 12,
  align: 'center',
  _showGrid: false,
};

export default MarketingLayoutContainer;
