import { QuestionnaireDefinition } from '@fabrictech/questionnaire/utils/types';
import { Merge } from '../type-utils';
/**
 *  **LINEN DUPLICATION WARNING*
 * @see https://github.com/fabrictech/linen/blob/master/node/src/lib/application-requirements/types.ts
 * @see https://github.com/fabrictech/linen/blob/master/node/src/lib/dynamoDb/applicationRequirementsStatus/types.ts
 */

type FollowUpRequirement = {
  type: RequirementType.followUp;
  followUpId: string;
  questionnaireDefinition: QuestionnaireDefinition;
};

type HealthExamRequirement = { type: RequirementType.labResults };

export type ApplicationRequirement =
  | FollowUpRequirement
  | HealthExamRequirement;

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/src/lib/dynamoDb/applicationRequirementsStatus/constants.ts
 *
 */

export enum UnderwritingStatus {
  waitingForReview = 'waitingForReview',
  underwritingInProgress = 'underwritingInProgress',
  complete = 'complete',
}

export enum RequirementType {
  amendment = 'amendment',
  aps = 'aps',
  driversLicense = 'driversLicense',
  eir = 'eir',
  examOnePiqture = 'examOnePiqture',
  followUp = 'followUp',
  greenCard = 'greenCard',
  incidentReport = 'incidentReport',
  // Lab results sent through normal route (via CRL's SFTP server)
  labResults = 'labResults',
  medicalReview = 'medicalReview',
  mib = 'mib',
  milliman = 'milliman',
  mvr = 'mvr',
  ncf = 'ncf',
  outboundCustomerPhysicalMail = 'outboundCustomerPhysicalMail',
  phi = 'phi',
  questionToVantis = 'questionToVantis',
  secondSign = 'secondSign',
  ssnCard = 'ssnCard',
  visa = 'visa',
  waywardEkg = 'waywardEkg',
  waywardLabResults = 'waywardLabResults',
  waywardMedicalExamPart2 = 'waywardMedicalExamPart2',
}

export enum SimpleLifeCycleStatus {
  requested = 'requested',
  received = 'received',
  reviewed = 'reviewed',
  canceled = 'canceled',
}

export enum ApsLifeCycleStatus {
  requested = 'requested',
  specialAuthorizationRequestedFromCustomer = 'specialAuthorizationRequestedFromCustomer',
  specialAuthorizationReceivedFromCustomer = 'specialAuthorizationReceivedFromCustomer',
  specialAuthorizationSentToPhysician = 'specialAuthorizationSentToPhysician',
  received = 'received',
  reviewed = 'reviewed',
  canceled = 'canceled',
}

export enum LabResultsLifeCycleStatus {
  requestedSelfSchedule = 'requestedSelfSchedule',
  requested = 'requested',
  requestedAtHomeExam = 'requestedAtHomeExam',
  orderedAtHomeExam = 'orderedAtHomeExam',
  scheduled = 'scheduled',
  completed = 'completed',
  received = 'received',
  reviewed = 'reviewed',
  problemWithResults = 'problemWithResults',
  canceled = 'canceled',
  cancellationNeeded = 'cancellationNeeded',
  cancellationPending = 'cancellationPending',
  required = 'required',
}

export enum HumanApiConnectRequestLifeCycleStatus {
  requested = 'requested',
  connected = 'connected',
  canceled = 'canceled',
}

export type AnyRequirementLifecycleStatus =
  | SimpleLifeCycleStatus
  | ApsLifeCycleStatus
  | LabResultsLifeCycleStatus
  | HumanApiConnectRequestLifeCycleStatus;

export const requirementLifeCycleStatus = {
  [RequirementType.amendment]: SimpleLifeCycleStatus,
  [RequirementType.aps]: ApsLifeCycleStatus,
  [RequirementType.driversLicense]: SimpleLifeCycleStatus,
  [RequirementType.eir]: SimpleLifeCycleStatus,
  [RequirementType.examOnePiqture]: SimpleLifeCycleStatus,
  [RequirementType.followUp]: SimpleLifeCycleStatus,
  [RequirementType.greenCard]: SimpleLifeCycleStatus,
  [RequirementType.incidentReport]: SimpleLifeCycleStatus,
  [RequirementType.labResults]: LabResultsLifeCycleStatus,
  [RequirementType.medicalReview]: SimpleLifeCycleStatus,
  [RequirementType.mib]: SimpleLifeCycleStatus,
  [RequirementType.milliman]: SimpleLifeCycleStatus,
  [RequirementType.mvr]: SimpleLifeCycleStatus,
  [RequirementType.ncf]: SimpleLifeCycleStatus,
  [RequirementType.outboundCustomerPhysicalMail]: SimpleLifeCycleStatus,
  [RequirementType.phi]: SimpleLifeCycleStatus,
  [RequirementType.questionToVantis]: SimpleLifeCycleStatus,
  [RequirementType.secondSign]: SimpleLifeCycleStatus,
  [RequirementType.ssnCard]: SimpleLifeCycleStatus,
  [RequirementType.visa]: SimpleLifeCycleStatus,
  [RequirementType.waywardEkg]: SimpleLifeCycleStatus,
  [RequirementType.waywardLabResults]: SimpleLifeCycleStatus,
  [RequirementType.waywardMedicalExamPart2]: SimpleLifeCycleStatus,
};

// END DUPLICATION

export enum DeprecatedRequirementType {
  /** @deprecated Human API integration has been removed */
  humanApi = 'humanApi',
  /** @deprecated Human API integration has been removed */
  humanApiConnectRequest = 'humanApiConnectRequest',
  /** @deprecated Human API integration has been removed */
  humanApiSource = 'humanApiSource',
}

export const requirementTypeReadable: {
  [key in RequirementType | DeprecatedRequirementType]: string;
} = {
  [RequirementType.amendment]: 'Amendment',
  [RequirementType.aps]: 'APS',
  [RequirementType.driversLicense]: `Driver's License`,
  [RequirementType.eir]: 'EIR',
  [RequirementType.examOnePiqture]: 'ExamOne LabPiQture',
  [RequirementType.followUp]: 'Follow-Up',
  [RequirementType.greenCard]: 'Green Card',
  [RequirementType.labResults]: 'Lab Results',
  [RequirementType.incidentReport]: 'Incident Report',
  [RequirementType.medicalReview]: 'Medical Review',
  [RequirementType.mib]: 'MIB',
  [RequirementType.milliman]: 'Milliman',
  [RequirementType.mvr]: 'MVR',
  [RequirementType.ncf]: 'NCF',
  [RequirementType.outboundCustomerPhysicalMail]:
    'Outbound Customer Physical Mail',
  [RequirementType.phi]: 'PHI',
  [RequirementType.questionToVantis]: 'Question to Vantis',
  [RequirementType.secondSign]: 'Second Sign',
  [RequirementType.ssnCard]: 'SSN Card',
  [RequirementType.visa]: 'Visa',
  [RequirementType.waywardEkg]: 'Wayward EKG',
  [RequirementType.waywardLabResults]: 'Wayward Lab Results',
  [RequirementType.waywardMedicalExamPart2]: 'Wayward Medical Exam Part 2',
  [DeprecatedRequirementType.humanApi]: 'Human API',
  [DeprecatedRequirementType.humanApiConnectRequest]:
    'Human API Connect Request',
  [DeprecatedRequirementType.humanApiSource]: 'Human Api Source Results',
};

export type RequirementTypeToStatus = Merge<
  { [R in RequirementType]: SimpleLifeCycleStatus },
  {
    [RequirementType.labResults]: LabResultsLifeCycleStatus;
    [RequirementType.followUp]: SimpleLifeCycleStatus;
    [RequirementType.aps]: ApsLifeCycleStatus;
  }
>;

export type ApplicationRequirementsStatusGeneric<
  T extends keyof RequirementTypeToStatus
> = {
  /** uuid primary key */
  statusId: string;
  /** Application.appId */
  appId: number;
  requirementType: T;
  /*
   * Id of the source table of the status change if one exists
   * Though our current typedef here does not reflect it, this prop should be mandatory for some requirement types.
   * For ex: followUpId, heathExamResultsId
   *
   * Ideally, our typedef would be able to make certain props required based on RequirementType T.
   * @TODO: https://fabrictech.atlassian.net/browse/DEVOPS-331
   */
  sourceId?: string;

  status: RequirementTypeToStatus[T];
  statusDetail?: string;

  createdAt: string;
  /*
   * Person or third party that triggered the change
   *
   * Can be one of the following:
   * - underwriter id/email from cognito
   * - operations id/email from cognito
   * - 'applicant'
   * - external API like APS
   */
  createdBy?: string;
};

// maps the requirementType R to the correct status type for that requirementType.
// using the infer keyword allows us to map the value of R to the specific status type matching up with R,
// so that the type of `status` is always a valid type rather than a union of multiple types.
// @see https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html
export type ApplicationRequirementsStatusItem<
  R extends keyof RequirementTypeToStatus = keyof RequirementTypeToStatus
> = R extends infer U
  ? U extends keyof RequirementTypeToStatus
    ? ApplicationRequirementsStatusGeneric<U>
    : never
  : never;
