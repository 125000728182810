/* Note: these styles are needed by `react-sticky-state`, so don't modify them. */
const reactStickyStateStyles = {
  baseStyles: {
    position: 'sticky',
    top: 0,
  },
  stateStyles: {
    marginTop: '0',
    marginBottom: '0',
    position: 'fixed',
    backfaceVisibility: 'hidden',
    '&.is-absolute': {
      position: 'absolute',
    },
  },
};

export const getBaseStyles = () => ({
  ...reactStickyStateStyles.baseStyles,
  transition: 'transform 0.25s ease-in-out',
  zIndex: 2,
});

export const getStateStyles = ({
  scrollDownClassName,
}: {
  scrollDownClassName: string;
}) => ({
  [`&.${scrollDownClassName}`]: {
    transitionDelay: '0.25s',
    transform: 'translateY(-100%)',
  },
});

export const getFixedStyles = ({
  stateClassName,
}: {
  stateClassName: string;
}) => ({
  [`&.${stateClassName}`]: reactStickyStateStyles.stateStyles,
});
