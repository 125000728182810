import { spacer, FontStyle } from '@fabrictech/design-tokens';
import {
  getStyleObjectFromFontStyle,
  mediaQueries,
  mergeResponsiveStyles,
  mergeTextStyles,
} from '@fabrictech/tapestry';
import { marketingHeader } from '@fabrictech/tapestry/components/MarketingText/getStyles';
import { fontWeights } from '@fabrictech/tapestry/constants/typography';

import createGetStyles from '../../createGetStyles';

const mobileBreakpoint = 'mobileBreakpoint';

// EstimateViewer styles
const labelFontStyles = mergeTextStyles(marketingHeader[4], marketingHeader[3]);

const labelMobileLayouts = {
  marginTop: spacer(1.5),
  marginBottom: spacer(1.25),
  textAlign: 'left',
};

const labelLayoutStyles = {
  marginTop: spacer(1.875),
  marginBottom: 0,
  textAlign: 'center',
  mobileBreakpoint: labelMobileLayouts,
  [mediaQueries.lt.sm]: labelMobileLayouts,
};

const labelFont = getStyleObjectFromFontStyle(
  labelFontStyles,
  mobileBreakpoint
);

const valueMargins = {
  marginBottom: spacer(0.5),
};

const valueLayoutStyles = {
  ...valueMargins,
  textAlign: 'center',
  ' strong': {
    fontWeight: fontWeights.semibold,
  },
  mobileBreakpoint: {
    textAlign: 'left',
  },
  [mediaQueries.lt.sm]: valueMargins,
};

const valueFontStyles: FontStyle = {
  ...marketingHeader[1],
  // typescript will complain if we use `fontWeights.light` since it is 200 and
  // is not in the `FontWeight` union type, so we must use the string literal
  // here
  fontWeight: 'light',
};

const valueFont = getStyleObjectFromFontStyle(
  valueFontStyles,
  mobileBreakpoint
);

const labelStyles = mergeResponsiveStyles(labelFont, labelLayoutStyles);

const valueStyles = mergeResponsiveStyles(valueFont, valueLayoutStyles);

const styles = {
  labelStyles,
  valueStyles,
};

export default createGetStyles(styles);
