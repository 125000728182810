import _ from 'lodash';

import { layout } from '@fabrictech/design-tokens';
import { padding } from '../../utils/css/values';
import { divide } from '../../utils/css/values/px';

export type ItemStyles = {
  gutterWidth?: string | number;
  span?: number;
  columns?: number;
  offset?: number;
  order?: number;
};

const getStyleObjFromResponsiveItemStyles = ({
  span = 12,
  offset = 0,
  columns = 12,
  gutterWidth = layout.gutterWidth,
  ...rest
}: ItemStyles) => ({
  padding: padding(0, divide(gutterWidth, 2)),
  width: `${(100 * span) / columns}%`,
  margin: offset > 0 ? `0 0 0 ${(100 * offset) / columns}%` : 0,
  ...rest,
});

const getItemResponsiveProps = (responsiveItemStyles: {
  [key: string]: ItemStyles;
}) => _.mapValues(responsiveItemStyles, getStyleObjFromResponsiveItemStyles);

export default getItemResponsiveProps;
