import React, { useEffect } from 'react';
import _ from 'lodash';
import querystring from 'querystring';
import LogRocket from 'logrocket';
import Bugsnag from '@bugsnag/js';

import { buildSwatchClientContext } from '@fabrictech/web-consumer-insights/src/analytics/buildClientContext';
import QuoteWidget, {
  CreateAnalyticsEvent,
  getIsLandingPageWidget,
} from '@fabrictech/quote-widget';

import pkg from '../package.json';
import {
  useLazyGetQuotePremiumAuQuery,
  useCreateQuotePremiumAuMutation,
  useCreateAnalyticsEventMutation,
} from './resources/wwwApi';

const apiConfigResponseAttributes = ['isLoading', 'data', 'error'];

const getSource = () => {
  const searchString = window.location.search.slice(1);
  const { source } = querystring.parse(searchString);
  if (!source) return 'unknown';
  // The parse method returns a string or an array. This ensures the type returned is a string.
  return typeof source === 'string' ? source : source[0];
};

const source = getSource();
const getUtmMedium = (sourceVal: string | undefined) =>
  getIsLandingPageWidget(sourceVal) ? 'landing_page' : 'paid';

const utmParamString = `&utm_source=${encodeURIComponent(
  source
)}&utm_campaign=premium_&utm_medium=${getUtmMedium(
  source
)}&utm_content=quotewidget`;

const App = () => {
  const [getQuote, getQuoteResponse] = useLazyGetQuotePremiumAuQuery();
  const [createQuote, createQuoteResponse] = useCreateQuotePremiumAuMutation();
  const [
    createAnalyticsEvent,
    createAnalyticsEventResponse,
  ] = useCreateAnalyticsEventMutation();

  const leadId = getQuoteResponse.isSuccess
    ? getQuoteResponse.data.leadId
    : undefined;
  const createQuoteInteractionAnalyticsEvent = (
    properties: CreateAnalyticsEvent.Request
  ) => {
    createAnalyticsEvent({
      clientContext: buildSwatchClientContext(leadId, pkg),
      type: 'quoteInteraction',
      properties,
    });
  };

  // This triggers on initial render and on updates
  useEffect(() => {
    if (leadId) {
      LogRocket.identify(leadId);
      Bugsnag.setUser(leadId);
    }
  }, [leadId]);

  return (
    <div className="App">
      <QuoteWidget
        getQuotePremiumAu={{
          execute: getQuote,
          ..._.pick(getQuoteResponse, apiConfigResponseAttributes),
        }}
        createQuotePremiumAu={{
          execute: createQuote,
          ..._.pick(createQuoteResponse, apiConfigResponseAttributes),
        }}
        createAnalyticsEvent={{
          execute: createQuoteInteractionAnalyticsEvent,
          ..._.pick(createAnalyticsEventResponse, apiConfigResponseAttributes),
        }}
        landingBaseUrl={Config.wwwBaseUrl}
        source={source}
        gtmId={Config.googleTagManagerQuoteWidget}
        utmParamString={utmParamString}
      />
    </div>
  );
};

export default App;
