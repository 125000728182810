import React from 'react';
import { css } from 'glamor';

import { spacer, sizes } from '@fabrictech/design-tokens';

import Box from '../../../Box';
import Icon from '../../../Icon';
import InputOption from '../../../InputOption';
import InputRadioText from '../../../InputRadioText';
import Tooltip from '../../../Tooltip';
import mediaQueries from '../../../../sharedStyles/vars/mediaQueries';

import { InputYesNoValue } from '../../types';
import { getLabelStyles, getParentStyles } from './getStyles';

const yesValue = 'Yes';
const noValue = 'No';
const yesNoOptions = [
  { id: yesValue, label: yesValue },
  { id: noValue, label: noValue },
];

const labelClassName = css(getLabelStyles()).toString();

type YesNoSelectionProps = {
  id: string;
  tooltip?: string;
  children: React.ReactNode;
  onChange: (value: InputYesNoValue) => void;
  last?: boolean;
};

export const YesNoSelection = ({
  id,
  tooltip,
  children,
  onChange,
  last,
}: YesNoSelectionProps) => {
  const parentClassName = css(getParentStyles({ last }));

  const [value, setValue] = React.useState<string>();

  const handleChange = (update: string) => {
    setValue(update);
    onChange({ [id]: update === yesValue });
  };

  return (
    <Box
      className={parentClassName}
      verticalAlign="center"
      responsiveProps={{
        [mediaQueries.lt.md]: { flexDirection: 'column' },
      }}
    >
      <label className={labelClassName} htmlFor={id}>
        {tooltip ? (
          <Box
            align="stretch"
            responsiveProps={{ [mediaQueries.gte.sm]: { align: 'left' } }}
          >
            {children}
            <Box
              marginLeft={spacer(1)}
              verticalAlign="top"
              responsiveProps={{
                [mediaQueries.gte.sm]: { verticalAlign: 'center' },
              }}
            >
              <Box
                height={sizes.icon.xs}
                width={sizes.icon.xs}
                align="center"
                verticalAlign="center"
              >
                {/** Setting padding to be larger than icon size to provide a larger touch target size for tooltip */}
                <Tooltip content={tooltip}>
                  <Box padding={spacer(1.5)}>
                    <Icon type="info" size="xs" />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        ) : (
          children
        )}
      </label>

      <InputRadioText
        id={id}
        value={value}
        onChange={handleChange}
        marginBottom={0}
        borderless
        block={false}
        flexDirection="row"
        type="radio"
      >
        {yesNoOptions.map(option => (
          <InputOption key={option.id} value={option.id}>
            {option.label}
          </InputOption>
        ))}
      </InputRadioText>
    </Box>
  );
};

export default YesNoSelection;
