import { inputTypes } from '@fabrictech/questionnaire/utils/constants';
import { QuestionDefinition } from '@fabrictech/questionnaire/utils/types';

import * as optionsById from './optionsById';

export const age: QuestionDefinition = {
  id: 'age',
  options: optionsById.age.id,
  inputProps: {
    placeholder: 'Age',
  },
  type: inputTypes.singleSelection,
  constraints: { required: true },
};

export const gender: QuestionDefinition = {
  id: 'gender',
  type: inputTypes.singleSelection,
  options: optionsById.gender.id,
  constraints: { required: true },
  inputProps: {
    type: 'radio',
  },
};

export const state: QuestionDefinition = {
  id: 'state',
  type: inputTypes.singleSelection,
  options: optionsById.state.id,
  inputProps: {
    placeholder: 'State',
  },
  constraints: { required: true },
};

export const tobacco: QuestionDefinition = {
  id: 'tobacco',
  type: inputTypes.singleSelection,
  options: optionsById.tobacco.id,
  inputProps: {
    placeholder: 'Do you use tobacco?',
  },
  constraints: { required: true },
};

export const health: QuestionDefinition = {
  id: 'health',
  type: inputTypes.singleSelection,
  options: optionsById.health.id,
  inputProps: {
    placeholder: 'How is your health?',
  },
  constraints: { required: true },
};

export const entryQuestionIds = [
  age.id,
  gender.id,
  state.id,
  tobacco.id,
  health.id,
];
