import { useContext, useMemo } from 'react';
import { StyleAttribute, CSSProperties } from 'glamor';
import ResponsiveContext from '../ResponsiveContext';

type GetStylesCallback = (
  isLockedToMobileWidth: boolean,
  mobileBreakpoint: number
) => Record<string, StyleAttribute | CSSProperties>;
const useResponsiveContext = (getStyles: GetStylesCallback) => {
  const responsiveContext = useContext(ResponsiveContext);
  const { isInternalWidget } = responsiveContext;
  const styles = useMemo(
    () =>
      getStyles(
        responsiveContext.isLockedToMobileWidth,
        responsiveContext.mobileBreakpoint
      ),
    [
      getStyles,
      responsiveContext.isLockedToMobileWidth,
      responsiveContext.mobileBreakpoint,
    ]
  );
  return {
    styles,
    isInternalWidget,
  };
};

export default useResponsiveContext;
