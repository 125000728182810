import { border as borderTokens } from '../border';
import { color } from '../color';
import { spacer } from '../layout';
import { typography } from '../typography';

const getVerticalPadding = ({
  height,
  lineHeight,
  borderWidth,
}: {
  height: number;
  lineHeight: number;
  borderWidth: number;
}): number => (height - lineHeight - borderWidth * 2) / 2;

const { borderRadius, borderStyle, borderWidth } = borderTokens;

/** Text */
const text = {
  base: typography.input[1],
  placeholder: typography.input[2],
  label: typography.input[3],
};

/**  Layout */
const marginBottom = spacer(3);
const height = spacer(7);
const width = spacer(30);
const paddingHorizontal = spacer(2);
const paddingVertical = getVerticalPadding({
  height,
  lineHeight: text.base.lineHeight.desktop,
  borderWidth,
});

const layout = {
  width,
  height,
  paddingTop: paddingVertical,
  paddingRight: paddingHorizontal,
  paddingBottom: paddingVertical,
  paddingLeft: paddingHorizontal,
  marginBottom,
};

const withLabelHeight = spacer(7);
const withLabelVerticalPadding = getVerticalPadding({
  height,
  lineHeight: text.base.lineHeight.desktop,
  borderWidth,
});
const layoutWithLabel = {
  width,
  height: withLabelHeight,
  paddingTop: withLabelVerticalPadding + spacer(0.5),
  paddingRight: paddingHorizontal,
  paddingBottom: withLabelVerticalPadding - spacer(0.5),
  paddingLeft: paddingHorizontal,
  marginBottom,
  positionTop: spacer(1),
};

/** Layout */

/** Styles  */
const styles = {
  base: {
    borderRadius,
    borderStyle,
    borderWidth,
    borderTopColor: color.input.base.border,
    borderRightColor: color.input.base.border,
    borderLeftColor: color.input.base.border,
    borderBottomColor: color.input.base.accent,
    backgroundColor: color.input.base.background,
  },
  hover: {
    borderBottomColor: color.input.base.accent,
    backgroundColor: color.input.hover.background,
  },
  active: {
    borderBottomWidth: borderWidth,
    borderBottomColor: color.input.base.accent,
    backgroundColor: color.input.active.background,
  },
  disabled: {
    borderBottomWidth: borderWidth,
    borderBottomColor: color.border.base,
    color: color.input.disabled.text,
  },
  error: {
    borderBottomColor: color.input.error.border,
  },
};

export const input = {
  styles,
  layout,
  layoutWithLabel,
  text,
} as const;
