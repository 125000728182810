import _ from 'lodash';

import * as cssValues from '../css/values';

const splitShorthand = val => {
  switch (typeof val) {
    case 'string':
      return val.split(' ');
    case 'number':
      return [val];
    default:
      return [];
  }
};

const overrideSpacing = (sizeProp, styles, overrides) => {
  const sizes = {};
  const shortHandIndexes = {
    Top: [0],
    Right: [1, 0],
    Bottom: [2, 0],
    Left: [3, 1, 0],
  };

  const splitInitialShorthand = splitShorthand(styles[sizeProp]);
  const splitOverrideShorthand = splitShorthand(overrides[sizeProp]);

  const hasSplitOverrideShorthand = !_.isEmpty(splitOverrideShorthand);
  const hasSplitInitialShorthand = !_.isEmpty(splitInitialShorthand);

  Object.keys(shortHandIndexes).forEach(direction => {
    const key = [sizeProp, direction].join('');
    // Check more specific props before checking shorthand
    let value = _.isUndefined(overrides[key]) ? styles[key] : overrides[key];

    if (
      _.isUndefined(value) &&
      (hasSplitOverrideShorthand || hasSplitInitialShorthand)
    ) {
      const indexesToCheck = shortHandIndexes[direction];
      for (let i = 0; i < indexesToCheck.length; i += 1) {
        const index = indexesToCheck[i];
        value = hasSplitOverrideShorthand
          ? splitOverrideShorthand[index]
          : splitInitialShorthand[index];
        if (!_.isUndefined(value)) {
          break;
        }
      }
    }

    sizes[key] = value || 0;
  });

  return cssValues[sizeProp](
    sizes[`${sizeProp}Top`],
    sizes[`${sizeProp}Right`],
    sizes[`${sizeProp}Bottom`],
    sizes[`${sizeProp}Left`]
  );
};

export default overrideSpacing;
