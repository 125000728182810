const buildStripePaymentMethodInteractionProperties = ({
  name,
  product,
  paymentType,
}) => ({
  name,
  product,
  paymentType,
});

export default buildStripePaymentMethodInteractionProperties;
