import React from 'react';
import { Box } from '@fabrictech/tapestry';
import LogoAlternate from '@fabrictech/tapestry/components/Logo/LogoAlternate';

const FabricLogo = () => (
  <Box>
    <LogoAlternate size="sm" />
  </Box>
);

export default FabricLogo;
