import _ from 'lodash';

type AttrOrResponsiveRule = string | number | object;
export type StyleObject = Record<string, AttrOrResponsiveRule>;

/**
 * Util for merging multiple style objects. This method allows us to easily
 * merge style objects, combining the child objects (media queries) instead of
 * overwriting them. This can also be used for merging multiple
 * `responsiveProps` type objects
 * @param {StyleObject} baseStyles
 * @param {StyleObject[]} args - This function can accept any number of style
 *   objects (2 or more)
 */
const mergeResponsiveStyles = (
  baseStyles: StyleObject,
  ...args: StyleObject[]
) =>
  _.mergeWith(
    {},
    baseStyles,
    ...args,
    (objValue: AttrOrResponsiveRule, srcValue: AttrOrResponsiveRule) => {
      if (_.isObject(objValue)) {
        return _.merge({}, objValue, srcValue);
      }
      return objValue;
    }
  );

export default mergeResponsiveStyles;
