import { times } from '../../utils/css/values/px';

const getStyles = ({
  maxWidth,
  gutterWidth,
  hasInteriorGutter,
}: {
  gutterWidth?: string | number;
  hasInteriorGutter?: boolean;
  maxWidth?: string | number;
}) => ({
  flexWrap: 'wrap',
  width: hasInteriorGutter ? '100%' : `calc(100% + ${times(gutterWidth, 2)})`,
  maxWidth,
});

export default getStyles;
