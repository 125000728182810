import { FontStyle } from '@fabrictech/design-tokens';

import { getStyleObjectFromFontStyle } from '../../constants/typography';
import { sharedSpacingStyles, baseStyles } from '../MarketingText/getStyles';

export type MarketingHeadlineRank = 1 | 2;

export const marketingHeadline: {
  [key in MarketingHeadlineRank]: FontStyle;
} = {
  1: {
    ...baseStyles,
    fontSize: { desktop: 80, mobile: 40 },
    lineHeight: { desktop: 96, mobile: 42 },
  },
  2: {
    ...baseStyles,
    fontSize: { desktop: 70, mobile: 40 },
    lineHeight: { desktop: 84, mobile: 42 },
  },
};

export const getStyles = ({
  rank,
  color,
}: {
  rank: MarketingHeadlineRank;
  color?: string;
}) => {
  const fontStyle = marketingHeadline[rank];
  const style = getStyleObjectFromFontStyle(fontStyle);

  return { ...style, ...sharedSpacingStyles, ...(color ? { color } : {}) };
};

export default getStyles;
