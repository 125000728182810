import { spacer, typography } from '@fabrictech/design-tokens';
import {
  getStyleObjectFromFontStyle,
  mediaQueries,
  mergeResponsiveStyles,
} from '@fabrictech/tapestry';
import { marketingParagraph } from '@fabrictech/tapestry/components/MarketingText/getStyles';
import { fontWeights } from '@fabrictech/tapestry/constants/typography';
import { chromeGrey } from '../constants';
import createGetStyles from '../createGetStyles';

const mobileBreakpoint = 'mobileBreakpoint';

// EstimateForm styles
const containerStyles = {
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

const wrapperStyles = {
  flexDirection: 'column',
};

const triangleContainerStyles = {
  justifyContent: 'center',
  mobileBreakpoint: {
    paddingLeft: spacer(2.5),
    justifyContent: 'flex-start',
  },
};
/**
 * Use CSS to create a triangle {@see https://css-tricks.com/snippets/css/css-triangle/}
 */
const triangleStyles = {
  background: 'transparent',
  borderTop: '0 solid transparent',
  borderLeft: '12px solid transparent',
  borderRight: '12px solid transparent',
  borderBottom: `9px solid ${chromeGrey}`,
  height: 0,
  width: 0,
};

const inputsWrapperStyles = {
  backgroundColor: chromeGrey,
  flexDirection: 'column',
  marginBottom: spacer(2.5),
  paddingTop: spacer(1),
  paddingBottom: spacer(1),
  mobileBreakpoint: {
    paddingTop: spacer(3),
    marginLeft: -12,
    marginRight: -12,
    paddingBottom: 0,
  },
};

const labelMobileMargins = {
  marginBottom: spacer(1),
};

// This style object is derived by taking the FontStyle object for
// `<MarketingText rank={2} />` and modifying its "mobile size" breakpoint to
// use the `mobileBreakpoint` key, which will be replaced with the
// `ResponsiveContext.mobileBreakpoint` value.
const marketingParagraph2Font = getStyleObjectFromFontStyle(
  marketingParagraph[2],
  mobileBreakpoint
);

const labelStyles = mergeResponsiveStyles(
  {
    marginBottom: 0,
    mobileBreakpoint: labelMobileMargins,
    [mediaQueries.lt.sm]: labelMobileMargins,
  },
  marketingParagraph2Font
);

const extendedTermLengthMobileMargins = {
  mobileBreakpoint: {
    marginLeft: spacer(2.5),
  },
};

const extendedTermLengthLabelStyles = mergeResponsiveStyles(
  labelStyles,
  extendedTermLengthMobileMargins
);

const coverageValueWrapperStyles = {
  alignItems: 'baseline',
};

const baseCoverageItemStyles = {
  marginBottom: 0,
};

const coverageValueMobileMargin = {
  marginBottom: spacer(1.25),
};

const coverageValueFont = getStyleObjectFromFontStyle(
  marketingParagraph[1],
  mobileBreakpoint
);

const coverageValueStyles = mergeResponsiveStyles(
  {
    ...baseCoverageItemStyles,
    mobileBreakpoint: coverageValueMobileMargin,
    [mediaQueries.lt.sm]: coverageValueMobileMargin,
  },
  coverageValueFont
);

const inlineNeedMoreFont = getStyleObjectFromFontStyle(
  typography.paragraph[3],
  mobileBreakpoint
);
const inlineNeedMoreStyles = mergeResponsiveStyles(
  {
    ...baseCoverageItemStyles,
    mobileBreakpoint: {
      display: 'none',
    },
  },
  inlineNeedMoreFont
);

const needMoreFooterStyles = mergeResponsiveStyles(
  {
    display: 'none',
    textAlign: 'center',
    mobileBreakpoint: {
      display: 'block',
    },
  },
  marketingParagraph2Font,
  { fontWeight: fontWeights.normal }
);

const inputContainerStyles = {
  // TODO: potentially change for internal treatment
  // @see https://fabrictech.atlassian.net/browse/GRW-3876
  width: 320,
  mobileBreakpoint: {
    width: 270,
  },
};

const extendedTermLengthContainerStyles = mergeResponsiveStyles(
  inputContainerStyles,
  {
    mobileBreakpoint: {
      width: '100%',
    },
  }
);

const getDisclaimerContainerStyles = (isInternalWidget: boolean) => ({
  marginTop: spacer(2),
  marginBottom: 0,
  mobileBreakpoint: {
    marginTop: isInternalWidget ? spacer(3) : spacer(5.25),
    marginLeft: spacer(0.5),
    marginRight: spacer(0.5),
  },
  [mediaQueries.lt.sm]: {
    marginBottom: spacer(1.25),
  },
});

// EstimateFormSectionWrapper styles
const baseSectionStyles = {
  flexDirection: 'column',
  marginTop: spacer(1.5),
  marginLeft: spacer(3),
  marginRight: spacer(3),
  marginBottom: spacer(0.75),
  mobileBreakpoint: {
    marginTop: 0,
    marginBottom: 0,
  },
};

const coverageSectionStyles = mergeResponsiveStyles(baseSectionStyles, {
  mobileBreakpoint: {
    marginLeft: spacer(2.5),
    marginRight: spacer(2.5),
  },
});

const standardTermLengthSectionStyles = mergeResponsiveStyles(
  baseSectionStyles,
  {
    mobileBreakpoint: {
      margin: 'auto',
    },
  }
);

const extendedTermLengthSectionStyles = mergeResponsiveStyles(
  baseSectionStyles,
  {
    mobileBreakpoint: {
      marginLeft: 0,
      marginRight: 0,
    },
  }
);

const styles = {
  containerStyles,
  wrapperStyles,
  triangleContainerStyles,
  triangleStyles,
  inputsWrapperStyles,
  labelStyles,
  coverageSectionStyles,
  standardTermLengthSectionStyles,
  extendedTermLengthSectionStyles,
  extendedTermLengthLabelStyles,
  coverageValueWrapperStyles,
  coverageValueStyles,
  inlineNeedMoreStyles,
  needMoreFooterStyles,
  inputContainerStyles,
  extendedTermLengthContainerStyles,
  baseDisclaimerContainerStyles: getDisclaimerContainerStyles(false),
  internalDisclaimerContainerStyles: getDisclaimerContainerStyles(true),
};

export default createGetStyles(styles);
