import React from 'react';
import { css } from 'glamor';
import ReactInlineSvg from 'react-inlinesvg';

import {
  logos,
  LogoSize,
  RebrandColorPalette,
} from '@fabrictech/design-tokens';

import getBaseStyles from './getStyles';
import createGetStylesWithGlobalProps from '../../utils/createGetStylesWithGlobalProps';
import { Height, Width } from '../Box/types';
import { ResponsiveProps } from '../../types';

const getStyles = createGetStylesWithGlobalProps(getBaseStyles);

type LogoProps = {
  width?: Width;
  height?: Height;
  size?: LogoSize;
  inverse?: boolean;
  responsiveProps?: ResponsiveProps;
};

/** Displays the Fabric wordmark */
const Logo = ({
  size = 'md',
  inverse = false,
  width,
  height,
  ...rest
}: LogoProps) => {
  const baseColor = RebrandColorPalette.purple;
  const color = inverse ? RebrandColorPalette.white : baseColor;
  const className = css(
    getStyles({ color, width, height, ...rest })
  ).toString();

  return (
    <ReactInlineSvg
      src={logos.wordmark[inverse ? 'inverse' : 'base'][size || 'md']}
      wrapper={React.createFactory('div')}
      className={className}
    />
  );
};

export default Logo;
