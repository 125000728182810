import _ from 'lodash';

const toCoord = value => (_.isObject(value) ? value : { x: value, y: value });
const operateSpread = fn => (first, ...rest) =>
  rest.reduce(
    (accumulator, entry) =>
      _.mapValues(toCoord(entry), (value, key) => fn(accumulator[key], value)),
    toCoord(first)
  );

export const plus = operateSpread((a, b) => a + b);
export const minus = operateSpread((a, b) => a - b);
export const times = operateSpread((a, b) => a * b);
export const divide = operateSpread((a, b) => a / b);

const operateSolo = fn => entry =>
  _.mapValues(toCoord(entry), value => fn(value));

/*
 * TODO: Profile use of Math.abs. Cursory research indicates
 * use of Math.abs is recommended over alternative.
 * https://stackoverflow.com/questions/441893/which-is-faster-math-absvalue-or-value-1
 */
export const abs = operateSolo(Math.abs);
