export const getNumberInputStyles = () => {
  const numberSpinner =
    '[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button';
  return {
    [numberSpinner]: { appearance: 'none', margin: 0 },
  };
};

export const getMultilineStyles = () => ({
  resize: 'none',
});

export const getIconClickStyles = () => ({
  cursor: 'pointer',
});
