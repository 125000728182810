import createLocalForm from '@fabrictech/questionnaire/components/LocalForm';

import getFieldForInputTypeWeb from '../fields/getFieldForInputType';
import getInputForInputTypeWeb from '../inputs/getInputForInputType';

/**
 * Web specific LocalForm HOC.
 *
 * Defaults to the web-specific _Input_ and _Field_ getters.
 *
 * @param {LocalFormConfig} options An options config
 */
const createLocalFormWeb = ({
  getFieldForInputType = getFieldForInputTypeWeb,
  getInputForInputType = getInputForInputTypeWeb,
  ...restArgs
} = {}) =>
  createLocalForm({ getFieldForInputType, getInputForInputType, ...restArgs });

export default createLocalFormWeb;
