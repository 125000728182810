import React from 'react';
import { css, Rule } from 'glamor';
import SmoothCollapse from 'react-smooth-collapse';

import { spacer, input, IconType, color } from '@fabrictech/design-tokens';
import Box from '../Box';
import Icon from '../Icon';
import Text from '../Text';
import TextMarkdown from '../TextMarkdown';

import tags from '../../constants/tags';
import { padding } from '../../utils/css/values';
import { getTitleStyles, getIconStyles, getParentStyles } from './getStyles';

const titleClassName = css(getTitleStyles());

type ContAccordionItemProps = {
  /** children */
  children?: React.ReactNode;
  /** Title for the item */
  title: string;
  /** Whether or not the entry is expanded */
  expanded: boolean;
  /** Icon for the item */
  icon?: IconType;
  /** URL for anchor tag */
  href?: string;
  /** Path for Link or GatsbyLink */
  to?: string;
  /** The component should be used under the hood */
  as: React.ReactType;
  /** if the item the first */
  first?: boolean;
  /** if the item the last */
  last?: boolean;
  /** Width */
  width?: string | number;
  /* Activate function */
  activate?: () => void;
};

const ContAccordionItemIcon = ({
  expanded,
  iconClassName,
}: {
  expanded: boolean;
  iconClassName: Rule;
}) => {
  return (
    <Box verticalAlign="center" className={iconClassName}>
      <Icon
        type={expanded ? 'minus' : 'plus'}
        size="sm"
        color={color.font.primary}
      />
    </Box>
  );
};

/** Used with `ContAccordion` to display accordion items. */
export class ContAccordionItem extends React.Component<ContAccordionItemProps> {
  static defaultProps = {
    expanded: false,
    as: tags.a,
  };

  handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event && event.preventDefault();
    this.props.activate && this.props.activate();
  };

  render() {
    const {
      children,
      expanded,
      title,
      icon,
      href,
      to,
      as: ItemComponent,
      first,
      last,
      width,
    } = this.props;
    const isLink = !!(href || to);
    const iconClassName = css(getIconStyles({ expanded }));
    const parentClassName = css(getParentStyles({ first, last }));

    return (
      <Box width={width} flexDirection="column" className={parentClassName}>
        <ItemComponent
          className={titleClassName}
          onClick={isLink ? undefined : this.handleClick}
          href={href || '#'}
          to={to}
        >
          <Box verticalAlign="center">
            {icon && (
              <Icon
                type={icon}
                marginRight={input.layout.paddingRight}
                size="md"
                color={color.font.primary}
              />
            )}

            <Box width="100%">
              <Text marginBottom={0} header rank={3} paddingRight={spacer(1)}>
                {title}
              </Text>
            </Box>
            <ContAccordionItemIcon
              expanded={expanded}
              iconClassName={iconClassName}
            />
          </Box>
        </ItemComponent>
        {isLink ? (
          undefined
        ) : (
          <SmoothCollapse expanded={expanded}>
            <Box padding={padding(spacer(2), 0)} block>
              <TextMarkdown childProps={{ Text: { rank: 2 } }}>
                {`${children}`}
              </TextMarkdown>
            </Box>
          </SmoothCollapse>
        )}
      </Box>
    );
  }
}

export default ContAccordionItem;
