import { Action, Dispatch } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import { createExecute } from '@fabrictech/redux-api';

import apis, { API_POST_ANALYTICS as apiKey } from './apis';
import { buildRecordByType } from './records';

const api = apis[apiKey];

type InteractionRecordActions = {
  storePageViewRecord?: Function;
  storeSurveyResponseRecord?: Function;
  storeApplicationInteractionRecord?: Function;
  storeQuoteInteractionRecord?: Function;
  storeStripePaymentMethodInteractionRecord?: Function;
  storeIntercomSupportInteractionRecord?: Function;
  storeSchedule4RealInteractionRecord?: Function;
  storeBlogInteractionRecord?: Function;
};

const boundCreateExecute = (dispatch: Dispatch<Action<unknown>>) =>
  createExecute(dispatch, apiKey, api);

const capCamelCase = _.flow([_.camelCase, _.upperFirst]);
const getStoreRecordByTypeFnName = (
  type: keyof typeof buildRecordByType
): keyof InteractionRecordActions =>
  `store${capCamelCase(type)}Record` as keyof InteractionRecordActions;

const createMapDispatchToProps = <
  DispatchFunctions extends InteractionRecordActions
>(
  type: keyof typeof buildRecordByType
) => (dispatch: Dispatch<Action<unknown>>): DispatchFunctions => {
  const execute = boundCreateExecute(dispatch);
  return ({
    [getStoreRecordByTypeFnName(type)]: (...args: Array<unknown>) => {
      execute(buildRecordByType[type](...args));
    },
  } as unknown) as DispatchFunctions;
};

/**
 * @deprecated - Favor using the `useAnalyticsEvent` hook for new pages!
 */
const connector = <DispatchFunctions extends InteractionRecordActions>(
  type: keyof typeof buildRecordByType
) =>
  connect(
    null,
    createMapDispatchToProps<DispatchFunctions>(type)
  );
export default connector;
