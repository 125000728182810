import React from 'react';
import InputOption from './InputOption';

/**
 * Map our option props to our placeholder InputOption component, as the
 * majority of our option-accepting Inputs expect to recieve them as
 * `InputOption`s.
 */
const mapOptionsToInputOption = options => {
  if (!options) return null;

  return options.map(
    ({ id: optionId, label, icon: optionIcon, disabled, tooltip }) => (
      <InputOption
        disabled={disabled}
        value={optionId}
        key={optionId}
        icon={optionIcon} // Optional. If a prop for icon is provided from the questionnaire definition, the InputCheckBoxGroup will render its children with icons
        tooltip={tooltip}
      >
        {label}
      </InputOption>
    )
  );
};

export default mapOptionsToInputOption;
