import { color, spacer } from '@fabrictech/design-tokens';
import { after, before, and } from '../../utils/css/selectors';
import { border, margin, important } from '../../utils/css/values';
import mediaQueries from '../../sharedStyles/vars/mediaQueries';

const duration = '0.3s';

export const getBaseStyles = ({ isActive }: { isActive: boolean }) => ({
  height: spacer(7),
  transition: `background ${duration}, margin ${duration}`,
  backgroundColor: color.base.accent,
  borderRadius: important(0),
  [and(before, after)]: {
    content: '""',
    position: 'absolute',
    bottom: spacer(-1.5),
    border: border({
      color: 'transparent',
      width: 0,
    }),
    transition: `border-left-width ${duration}, border-right-width ${duration}`,
    borderTopColor: color.background.inverse.primary,
    borderTopWidth: isActive ? spacer(1.5) : 0,
  },
  [before]: {
    left: 0,
    borderLeftWidth: isActive ? spacer(3) : 0,
  },
  [after]: {
    right: 0,
    borderRightWidth: isActive ? spacer(3) : 0,
  },
  ...(isActive
    ? {
        margin: margin(0, spacer(-3), spacer(6)),
        [mediaQueries.lt.sm]: {
          margin: margin(0, 0, spacer(6)),
          [and(before, after)]: { display: 'none' },
        },
      }
    : {}),
});
