import React from 'react';
import { Rule, css } from 'glamor';

import {
  RebrandColorPalette,
  ButtonRank,
  spacer,
  sizes,
} from '@fabrictech/design-tokens';
import tags from '../../constants/tags';

import Box from '../Box';
import Icon from '../Icon';

import createGetStylesWithGlobalProps from '../../utils/createGetStylesWithGlobalProps';
import omitGlobalProps from '../../utils/omitGlobalProps';
import {
  AsLinkProps,
  OnClickProp,
  GlobalProps,
  OnMouseEnterProp,
  OnMouseLeaveProp,
} from '../../types';

import getBaseStyles, { getTextStyles } from './getStyles';

const getStyles = createGetStylesWithGlobalProps(getBaseStyles);

export type ButtonProps = AsLinkProps &
  GlobalProps & {
    /** Children node */
    children?: React.ReactNode;
    /** The hierarchy rank of the button */
    rank?: ButtonRank;
    /** Disables the button */
    disabled?: boolean;
    /** Sets button to use `display: 'block'` */
    block?: boolean;
    /** Color of the button's border and text */
    color?: string;
    /** Color of the button's background */
    backgroundColor?: string;
    /** Removes border from the button */
    borderless?: boolean;
    /** Removes border from the text */
    hasTextBorder?: boolean;
    /** onClick handler */
    onClick?: OnClickProp;
    /** onMouseEnter handler */
    onMouseEnter?: OnMouseEnterProp;
    /** onMouseLeave handler */
    onMouseLeave?: OnMouseLeaveProp;
    /** Displays arrow inside the button */
    hasArrow?: boolean;
    /** The width of the button. */
    width?: string | number;
    /** A custom className */
    className?: Rule;
    /** For use with optimonk - optimonk form can be triggered when a button that
     * has a class or id we define is clicked. In order to make sure that glamor
     * is not consuming the class name passed down and creating a new class
     * Rule, we define this additionalClassName prop.
     */
    additionalClassName?: string;
    /** Aligns the button’s children */
    align?: 'center' | 'left' | 'right';
    /** Optional key */
    key?: string;
  };

/** Buttons trigger actions and allow for navigation. */
export const MarketingButton = ({
  as: Component = 'button',
  align = 'center',
  disabled = false,
  block = false,
  rank = 'primary',
  responsiveProps,
  children,
  color = RebrandColorPalette.midnight,
  backgroundColor = 'transparent',
  hasArrow = false,
  hasTextBorder = true,
  borderless = false,
  width,
  className,
  additionalClassName,
  ...rest
}: ButtonProps) => {
  const isPrimary: boolean = rank === 'primary';
  const foregroundColor = isPrimary ? color : RebrandColorPalette.purple;

  const textClassName = css(
    getTextStyles({ color, foregroundColor })
  ).toString();

  const mergedButtonClassName = css(
    getStyles({
      isPrimary,
      block,
      disabled,
      color,
      hasTextBorder,
      backgroundColor,
      borderless,
      textClassName,
      width,
      responsiveProps,
      ...rest,
    }),
    className
  ).toString();

  const buttonClassName = additionalClassName
    ? `${mergedButtonClassName} ${additionalClassName}`
    : mergedButtonClassName;
  return (
    <Component
      {...(Component === tags.button ? { disabled, type: 'button' } : {})}
      className={buttonClassName}
      {...omitGlobalProps(rest)}
    >
      <Box width="100%" height="100%" align={align} verticalAlign="center">
        {children && (
          <>
            <span className={textClassName}>{children}</span>
            {/* `hasArrow` should control the display of an arrow inside the primary button */}
            {hasArrow && isPrimary ? (
              <Box height={spacer(2)} verticalAlign="center">
                <Icon
                  type="arrowRightLong"
                  color={foregroundColor}
                  size={sizes.getRelativeSize({ size: 'md', change: 1 })}
                  marginLeft={spacer(2)}
                />
              </Box>
            ) : null}
          </>
        )}
      </Box>
    </Component>
  );
};

export default MarketingButton;
