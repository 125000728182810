/**
 * Converts SVG string from base64 encoded to utf8 encoded so it can be used as a CSS background image.
 *
 * @param {string} svg - base64 encoded SVG string
 * @param {string=} fill - color to use as the fill
 * @return {string} SVG string that can be used as CSS background image, with color applied
 */
const createBackgroundSvgString = ({ svg, fill }) => {
  /**
   * `atob` (ASCII to binary) is a global in browsers...
   * ...but not in Node, so this is poyfill.
   */
  if (typeof atob === 'undefined') {
    global.atob = b64String =>
      Buffer.from(b64String, 'base64').toString('binary');
  }

  // Converts from base64 to XML
  const svgPrefix = /data:image\/svg\+xml;base64,/;
  const convertedString = atob(svg.replace(svgPrefix, ''));

  // Updates color of SVG fill
  const hexColorCode = /#((?:[a-f\d]{3}){1,2})/;
  const string = fill
    ? convertedString.replace(hexColorCode, fill)
    : convertedString;

  // Encodes in utf8 for use as CSS background image
  return `data:image/svg+xml;utf8,${encodeURIComponent(string)}`;
};

export default createBackgroundSvgString;
