import { mediaQueries } from '@fabrictech/tapestry';
import { fabricGroupTypes } from '@fabrictech/questionnaire/utils/constants';

import createFabricGroupField from './createFabricGroupField';

export const QuestionnaireInputGroup = createFabricGroupField();

export const QuestionnaireInputGroupLegalName = createFabricGroupField({
  responsiveProps: {
    [mediaQueries.gte.sm]: {
      colSpans: [9, 3, 12],
    },
  },
});

export const QuestionnaireInputGroupFirstLastName = createFabricGroupField({
  colSpans: [6, 6],
  responsiveProps: {
    [mediaQueries.lt.lg]: {
      colSpans: [12, 12],
    },
  },
});

export const QuestionnaireInputGroupHomeAddress = createFabricGroupField({
  colSpans: [12, 6, 6, 12],
  responsiveProps: {
    [mediaQueries.lt.sm]: {
      colSpans: [12, 12, 12, 12],
    },
  },
});

export const QuestionnaireInputGroupHeight = createFabricGroupField({
  responsiveProps: {
    [mediaQueries.gte.sm]: {
      colSpans: [6, 6],
    },
  },
});

export const QuestionnaireInputGroupDriversLicenseDetails = createFabricGroupField(
  {
    colSpans: [6, 6],
    responsiveProps: {
      [mediaQueries.lt.lg]: {
        colSpans: [12, 12],
      },
    },
  }
);

export const QuestionnaireInputGroupPhoneNumbers = createFabricGroupField({
  responsiveProps: {
    [mediaQueries.gte.sm]: {
      colSpans: [6, 6],
    },
  },
});

export const getFabricGroupFieldForGroupType = groupType => {
  switch (groupType) {
    case fabricGroupTypes.legalName:
      return QuestionnaireInputGroupLegalName;
    case fabricGroupTypes.firstLastName:
      return QuestionnaireInputGroupFirstLastName;
    case fabricGroupTypes.homeAddress:
      return QuestionnaireInputGroupHomeAddress;
    case fabricGroupTypes.height:
      return QuestionnaireInputGroupHeight;
    case fabricGroupTypes.driversLicenseDetails:
      return QuestionnaireInputGroupDriversLicenseDetails;
    case fabricGroupTypes.phoneNumbers:
      return QuestionnaireInputGroupPhoneNumbers;
    default:
      return QuestionnaireInputGroup;
  }
};
