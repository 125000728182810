import { color, border, spacer } from '@fabrictech/design-tokens';

import { padding, important } from '../../utils/css/values';

const { borderWidth, borderStyle } = border;

const getIconRotation = ({ expanded }: { expanded?: boolean }) =>
  expanded ? '180deg' : 0;

export const getIconStyles = ({ expanded }: { expanded?: boolean }) => ({
  transition: 'transform 0.2s',
  transform: `rotate(${getIconRotation({ expanded })})`,
  transformOrigin: 'center center',
});

export const getParentStyles = ({
  first,
  last,
}: {
  first?: boolean;
  last?: boolean;
}) => ({
  padding: first ? padding(0, 0, spacer(3), 0) : padding(spacer(3), 0),
  borderBottom: last
    ? 'none'
    : `${borderWidth}px ${borderStyle} ${color.input.base.border}`,
});

// Ensures that the titles are not underlined on hover
export const getTitleStyles = () => ({ textDecoration: important('none') });
