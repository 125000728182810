import _ from 'lodash';
import {
  shadows,
  color as colorTokens,
  Shadow,
} from '@fabrictech/design-tokens';
import { toPx } from '../../utils/css/values/px';

const { setAlpha } = colorTokens.utils;

const getOffset = ({
  width,
  height,
}: {
  width: number;
  height: number;
}): string => `${toPx(width)} ${toPx(height)}`;

const getSpread = (spread?: number): string =>
  spread ? `${toPx(spread)} ` : '';

const getColor = ({ color, opacity }: { color: string; opacity: number }) =>
  `${opacity && opacity !== 1 ? setAlpha(color, opacity) : color}`;

const shadowObjToBoxShadow = (shadowObj: Shadow): string => {
  const {
    color,
    offset: { width, height },
    opacity,
    blur,
    spread,
  } = shadowObj;
  return `${getOffset({ width, height })} ${toPx(blur)} ${getSpread(
    spread
  )}${getColor({ color, opacity })}`;
};

/** Transforms shadows with new provided parameters. Returns CSS box-shadow string  */
export const transformShadow = ({
  shadow,
  params,
}: {
  /** Source shadow to update */
  shadow: Shadow;
  /** Updates to apply */
  params: Partial<Shadow>;
}): string => {
  const newShadow = _.cloneDeep(shadow);
  _.merge(newShadow, params);
  return shadowObjToBoxShadow(newShadow);
};

export const standardShadow = shadowObjToBoxShadow(shadows.standard);

export const focusShadow = shadowObjToBoxShadow(shadows.focus);

export const softShadow = shadowObjToBoxShadow(shadows.soft);
