import React from 'react';

import TextMarkdownBase, {
  TextMarkdownBaseProps,
} from './components/TextMarkdownBase';
import { getTextComponents, tagNameToComponentsMapBase } from './utils';
import Tooltip from '../Tooltip';

/**
 * To avoid a circualr dependency between `TextMarkdown` and `Tooltip`, this component is structured a bit different than others.
 * - `TextMarkdownBase` - this is the base component that can be extended to add functionality for rendering markdown
 * - `TextMarkdown` - this is the default component used to render markdown. In most cases, it should be used, and it is set as the default export.
 */

export type TextMarkdownProps = Omit<TextMarkdownBaseProps, 'getComponents'>;

const getComponents: TextMarkdownBaseProps['getComponents'] = textProps => ({
  ...getTextComponents(textProps),
  ...tagNameToComponentsMapBase,
  abbr: ({ children, title }: { children: React.ReactNode; title: string }) => (
    <Tooltip content={title}>
      <abbr>{children}</abbr>
    </Tooltip>
  ),
});

const TextMarkdown = (props: TextMarkdownProps) => (
  <TextMarkdownBase getComponents={getComponents} {...props} />
);

export default TextMarkdown;
