import React from 'react';
import { css } from 'glamor';

import { RebrandColorPalette } from '@fabrictech/design-tokens';

import Box from '../Box';
import MarketingText from '../MarketingText';

const authorTitleStyles = css({ fontWeight: 'normal' });

const authorStyles = css({
  marginBottom: 6,
});

const defaultProps = {
  width: '400',
  textColor: RebrandColorPalette.purpleDark,
};

type MarketingPullQuoteProps = React.HTMLProps<HTMLDivElement> & {
  body: string;
  author?: string;
  authorTitle?: string;
  renderTopImage?: () => JSX.Element;
  renderBottomImage?: () => JSX.Element;
  width?: number;
  textColor?: string;
};

const MarketingPullQuote = ({
  body,
  author,
  authorTitle,
  renderTopImage,
  renderBottomImage,
  width,
  textColor,
}: MarketingPullQuoteProps) => {
  return (
    <Box align="center" verticalAlign="center" flexDirection="column">
      {renderTopImage && (
        <Box marginBottom={24} marginTop={12}>
          {renderTopImage()}
        </Box>
      )}
      <MarketingText
        maxWidth={width}
        header
        rank={2}
        textAlign="center"
        className={css({
          lineHeight: '36px',
          color: textColor,
        })}
      >
        {body}
      </MarketingText>
      {author && (
        <MarketingText
          header
          rank={5}
          textAlign="center"
          className={authorStyles}
        >
          {author}
        </MarketingText>
      )}
      {authorTitle && (
        <MarketingText
          rank={3}
          textAlign="center"
          className={authorTitleStyles}
        >
          {authorTitle}
        </MarketingText>
      )}
      {renderBottomImage && renderBottomImage()}
    </Box>
  );
};

MarketingPullQuote.defaultProps = defaultProps;

export default MarketingPullQuote;
