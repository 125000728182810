import { input } from '@fabrictech/design-tokens';

import { fontWeights, fontStackByFontFamily } from '../../constants/typography';
import { toPx } from '../../utils/css/values/px';

const fontStyleToTextStyle = fontStyle => {
  const {
    fontSize,
    lineHeight,
    fontFamily,
    fontWeight,
    color: fontColor,
    textTransform,
    letterSpacing,
  } = fontStyle;
  return {
    fontFeatureSettings: '"lnum" 1',
    fontVariantNumeric: 'lining-nums',
    fontSize: toPx(fontSize.desktop),
    lineHeight: toPx(lineHeight.desktop),
    fontFamily: fontStackByFontFamily[fontFamily],
    fontWeight: fontWeights[fontWeight],
    color: fontColor,
    textAlign: 'left',
    textTransform,
    marginBottom: 0,
    letterSpacing,
  };
};

export const inputTextStyles = fontStyleToTextStyle(input.text.base);
export const inputPlaceholderStyles = fontStyleToTextStyle(
  input.text.placeholder
);
export const inputLabelStyles = fontStyleToTextStyle(input.text.label);

export const inputPadding = {
  paddingTop: input.layout.paddingTop,
  paddingRight: input.layout.paddingRight,
  paddingBottom: input.layout.paddingBottom,
  paddingLeft: input.layout.paddingLeft,
};

export const inputStyles = {
  base: input.styles.base,
  hover: input.styles.hover,
  active: input.styles.active,
  disabled: input.styles.disabled,
  error: input.styles.error,
};
