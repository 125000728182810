import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';
import ReactModal from 'react-modal';

import {
  getOverlayStyles,
  getOverlayAfterOpenStyles,
  getOverlayBeforeCloseStyles,
  getModalStyles,
  getModalAfterOpenStyles,
  getModalBeforeCloseStyles,
  getCloseButtonStyles,
  getContentWrapperStyles,
} from './getStyles';
import Icon from '../Icon';

const baseOverlayClassName = css(getOverlayStyles()).toString();
const beforeCloseOverlayClassName = css(
  getOverlayBeforeCloseStyles()
).toString();
const baseModalClassName = css(getModalStyles()).toString();
const beforeCloseModalClassName = css(getModalBeforeCloseStyles()).toString();
const closeButtonClassName = css(getCloseButtonStyles());
const contentWrapperClassName = css(getContentWrapperStyles());

/** Prevents scrolling on the body when the modal is open */
const bodyOpenClassName = 'ReactModal__Body--open';
css.global(`.${bodyOpenClassName}`, { overflow: 'hidden' });

/**
 * A modal is a temporary display that lives above the page level content.
 * That content is a higher level priorty and an action needs to be taken before proceeding back to the page.
 * This can be used to help give more context and collect certain information without fully taking the user to a new page.
 * */
const OverlayModal = ({
  children,
  isOpen,
  onRequestClose,
  closeTimeoutMS = 300,
  hideCloseIcon = false,
  ...rest
}) => {
  const overlayClassName = {
    base: baseOverlayClassName,
    afterOpen: css(getOverlayAfterOpenStyles({ closeTimeoutMS })).toString(),
    beforeClose: beforeCloseOverlayClassName,
  };
  const modalClassName = {
    base: baseModalClassName,
    afterOpen: css(getModalAfterOpenStyles({ closeTimeoutMS })).toString(),
    beforeClose: beforeCloseModalClassName,
  };
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName={overlayClassName}
      className={modalClassName}
      closeTimeoutMS={closeTimeoutMS}
      ariaHideApp={false} // TODO: For proper accessibility, this should be set to true and the appElement provided to the modal
      bodyOpenClassName={bodyOpenClassName}
      {...rest}
    >
      <div className={contentWrapperClassName}>
        {onRequestClose && !hideCloseIcon ? (
          <button
            type="button"
            aria-label="Close Modal"
            className={closeButtonClassName}
            onClick={onRequestClose}
          >
            <Icon type="x" size="sm" />
          </button>
        ) : null}
        {children}
      </div>
    </ReactModal>
  );
};

OverlayModal.propTypes = {
  /** Boolean describing if the modal should be shown or not */
  isOpen: PropTypes.bool,
  /** Function triggered when the modal is requested to be closed (either by clicking on overlay or pressing ESC) */
  onRequestClose: PropTypes.func,
  /** Number indicating the milliseconds to wait before closing the modal */
  closeTimeoutMS: PropTypes.number,
  /** Boolean descibing whether the top right close icon ('X') should be shown */
  hideCloseIcon: PropTypes.bool,
};

export default OverlayModal;
