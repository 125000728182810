import { spacer } from '@fabrictech/design-tokens';
import createGetStyles from '../createGetStyles';

// FabricHeader styles
const containerStyles = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  mobileBreakpoint: {
    marginTop: spacer(0.375),
    marginLeft: spacer(0.375),
    marginRight: spacer(0.375),
  },
};

const arrowBackContainerStyles = {
  justifyContent: 'flex-end',
};

const styles = {
  containerStyles,
  arrowBackContainerStyles,
};

export default createGetStyles(styles);
