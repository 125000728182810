import { ValueOf } from '../type-utils';

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/services/insurance-pricing/lib/quote/constants.ts
 */

export const healthCategories = {
  excellent: 'excellent',
  good: 'good',
  ok: 'ok',
} as const;

export type HealthCategory = ValueOf<typeof healthCategories>;
/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/stdlib/type-utils/types.ts
 */

export enum GenderAbbreviation {
  F = 'F',
  M = 'M',
}
