import {
  border,
  color as colorTokens,
  spacer,
  button,
  sizes,
  Shape,
  Size,
  shadows,
} from '@fabrictech/design-tokens';

import { fontStackByFontFamily, fontWeights } from '../../constants/typography';
import {
  standardShadow,
  focusShadow,
  transformShadow,
} from '../../sharedStyles/vars/boxShadow';

import { padding, important, margin } from '../../utils/css/values';
import { toPx } from '../../utils/css/values/px';
import { hover, active, focus } from '../../utils/css/selectors';

const { borderWidth, borderRadius, borderStyle } = border;
const { darken, lighten, isDark } = colorTokens.utils;

const getStyles = ({
  shape,
  hasIcon,
  width,
  block,
  disabled,
  hasShadow,
  isPrimary,
  size,
  borderless,
  color,
}: {
  shape: Shape;
  hasIcon: boolean;
  width: string | number;
  block: boolean;
  disabled: boolean;
  hasShadow: boolean;
  isPrimary: boolean;
  size: Size;
  borderless: boolean;
  color: string;
}) => {
  const sideLength: number = sizes.icon[size] + spacer(1) * 2;

  /** Putting our thumb on the scale here to prefer lighter font color.
   * In the "grey" area between light and dark, we would like to prefer using a light font over a dark */
  const isDarkColor = isDark(darken(color));
  const hoverColor = isDarkColor ? lighten(color) : darken(color);
  const activeColor = isDarkColor ? lighten(color, 20) : darken(color, 20);
  const borderColor = isPrimary ? colorTokens.border.base : color;

  return {
    textDecoration: important('none'),
    margin: margin(0, 'auto', button[size].layout.marginBottom, 'auto'),
    display: block ? 'block' : 'inline-block',
    padding: hasIcon
      ? 0
      : padding(
          button[size].layout.paddingVertical,
          button[size].layout.paddingHorizontal
        ),
    ...(shape === 'square'
      ? {
          borderRadius,
          paddingLeft: button[size].layout.paddingVertical,
          paddingRight: button[size].layout.paddingVertical,
        }
      : { borderRadius: 1000 }),
    cursor: disabled ? 'auto' : 'pointer',
    width: hasIcon ? sideLength : 'auto',
    minWidth: hasIcon || width === 'auto' ? 0 : width,
    minHeight: hasIcon ? sideLength : button[size].layout.height,
    borderWidth,
    borderStyle,
    transition: 'box-shadow 0.2s, background 0.2s',
    boxShadow: hasShadow && !borderless ? standardShadow : 'none',
    backgroundColor: isPrimary ? color : 'transparent',
    borderColor,
    // Don't display a hover state if undefined
    [hover]: disabled
      ? undefined
      : {
          borderColor: isPrimary ? borderColor : hoverColor,
          backgroundColor: isPrimary ? hoverColor : 'transparent',
          // If `hasShadow`, modify the shadow to appear closer in to the button
          boxShadow: hasShadow
            ? transformShadow({
                shadow: shadows.standard,
                params: { offset: { width: 4, height: 4 } },
              })
            : undefined,
        },
    // Don't display a active state if undefined
    [active]: disabled
      ? undefined
      : {
          borderColor: isPrimary ? borderColor : activeColor,
          backgroundColor: isPrimary ? activeColor : 'transparent',
          // If `hasShadow`, modify the shadow to barely appear
          boxShadow: hasShadow
            ? transformShadow({
                shadow: shadows.standard,
                params: { offset: { width: 0, height: 0 } },
              })
            : undefined,
        },
    [focus]: {
      outline: '1px solid transparent',
      boxShadow: focusShadow,
    },
    ...(disabled ? { opacity: 0.5 } : {}),
    ...(borderless ? { borderColor: important('transparent') } : {}),
  };
};

export const getForegroundColor = ({
  isPrimary,
  color,
}: {
  isPrimary: boolean;
  color: string;
}) => {
  /** Putting our thumb on the scale here to prefer lighter font color.
   * In the "grey" area, we would like to prefer using a light font over a dark */
  const primaryColor = isDark(darken(color))
    ? colorTokens.button.lightText
    : colorTokens.button.darkText;
  return isPrimary ? primaryColor : color;
};

export const getTextStyles = ({
  isPrimary,
  color,
  size,
  align,
}: {
  isPrimary: boolean;
  color: string;
  size: Size;
  align: string;
}) => {
  const {
    fontSize,
    lineHeight,
    fontFamily,
    fontWeight,
    textTransform,
    letterSpacing = 1,
  } = button[size].text;
  return {
    fontFeatureSettings: '"lnum" 1',
    fontVariantNumeric: 'lining-nums',
    fontSize: toPx(fontSize.desktop),
    lineHeight: toPx(lineHeight.desktop),
    fontFamily: fontStackByFontFamily[fontFamily],
    fontWeight: fontWeights[fontWeight],
    color: getForegroundColor({ isPrimary, color }),
    textAlign: align,
    textTransform,
    letterSpacing,
    ...(align === 'center' ? {} : { flexGrow: 1 }),
  };
};

export default getStyles;
