import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { css } from 'glamor';

import { spacer } from '@fabrictech/design-tokens';

import tags from '../../constants/tags';

import { getTableStyles, getBlockquoteStyles } from './getStyles';
import Text, { TextProps } from '../Text';

import ListComponent, {
  ListComponentProps,
} from './components/ListComponent/ListComponent';
import { TagNameToComponentMap } from './types';

const tableClassName = css(getTableStyles());
const SimpleTable = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      as={tags.table}
      rank={2}
      padding={spacer(4)}
      marginBottom={0}
      className={tableClassName}
    >
      {children}
    </Text>
  );
};

const LinkComponent = (props: React.HTMLProps<HTMLAnchorElement>) => {
  // For internal links
  if (props.href && /^\/[^\/]/.test(props.href)) {
    return <GatsbyLink to={props.href}>{props.children}</GatsbyLink>;
  }
  // For external links (opens in new tab)
  return (
    <a {...props} target="_blank">
      {props.children}
    </a>
  );
};

const blockquoteClassName = css(getBlockquoteStyles());
const BlockquoteComponent = ({ children }: { children: React.ReactNode }) => (
  <Text as={tags.blockquote} className={blockquoteClassName} marginBottom={0}>
    {children}
  </Text>
);

export const getTextComponents = (
  textProps?: Partial<TextProps>
): TagNameToComponentMap => ({
  p: (props: Partial<TextProps>) => <Text {...props} {...textProps} />,
  h1: (props: Partial<TextProps>) => (
    <Text header {...props} {...textProps} rank={1} />
  ),
  h2: (props: Partial<TextProps>) => (
    <Text header {...props} {...textProps} rank={2} />
  ),
  h3: (props: Partial<TextProps>) => (
    <Text header {...props} {...textProps} rank={3} />
  ),
  h4: (props: Partial<TextProps>) => (
    <Text header {...props} {...textProps} rank={4} />
  ),
  h5: (props: Partial<TextProps>) => (
    <Text header {...props} {...textProps} rank={4} />
  ),
  h6: (props: Partial<TextProps>) => (
    <Text header {...props} {...textProps} rank={4} />
  ),
  ol: (props: Partial<ListComponentProps>) => (
    <ListComponent ordered {...props} {...textProps} />
  ),
  ul: (props: Partial<ListComponentProps>) => (
    <ListComponent ordered={false} {...props} {...textProps} />
  ),
});

export const tagNameToComponentsMapBase: Partial<TagNameToComponentMap> = {
  blockquote: ({ children }: { children: React.ReactNode }) => (
    <BlockquoteComponent>{children}</BlockquoteComponent>
  ),
  a: LinkComponent,
  table: ({ children }: { children: React.ReactNode }) => (
    <SimpleTable>{children}</SimpleTable>
  ),
};
