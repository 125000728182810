import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import { css } from 'glamor';

import { getParentStyles, getInputStyles } from './getStyles';
import { getMenuStyles, getItemStyles } from '../MenuDropdown/getStyles';

import InputWrapper from '../InputWrapper';

const createRenderInput = ({ inputClassName, width, invalid }) => props => (
  <InputWrapper width={width} invalid={invalid} className={inputClassName}>
    <input {...props} />
  </InputWrapper>
);

const inputClassName = css(getInputStyles());
const menuClassName = css(getMenuStyles({ align: 'left', width: '100%' }));

const renderMenu = menuItems => (
  <div className={menuClassName}>{menuItems}</div>
);

/**
 * Autocomplete / Typeahead input.
 * Wrapper around [react-autocomplete](https://github.com/reactjs/react-autocomplete).
 * */
class InputTextAuto extends React.Component {
  constructor(props) {
    super(props);
    this.getItemValue = this.getItemValue.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.getOptions = this.getOptions.bind(this);
  }

  getItemValue(item) {
    const { valueKey } = this.props;
    return valueKey ? item[valueKey] : item;
  }

  renderItem(item, isHighlighted) {
    const { labelKey } = this.props;
    return (
      <div
        data-test="AutocompleteItem"
        className={css(getItemStyles({ hasFocus: isHighlighted }))}
      >
        {labelKey ? item[labelKey] : item}
      </div>
    );
  }

  handleChange(e) {
    const { onChange } = this.props;
    onChange && onChange(e.target.value);
  }

  handleSelect(val) {
    const { onSelect } = this.props;
    onSelect && onSelect(val);
  }

  handleFocus(...params) {
    const { onFocus } = this.props;
    onFocus && onFocus(...params);
  }

  handleBlur() {
    const { onBlur } = this.props;
    onBlur && onBlur();
  }

  getOptions() {
    if (!this.props.loading && this.props.value && !this.props.options.length) {
      return this.props.showNoMatchOption
        ? [{ id: 'noMatch', label: 'No matches found. Please try again.' }]
        : [];
    }

    return this.props.options;
  }

  render() {
    const {
      width,
      value,
      disabled,
      validation,
      helperText,
      id,
      placeholder,
      label,
      loading,
      selectOnBlur,
      ...rest
    } = this.props;

    const invalid = validation === false;

    const className = css(getParentStyles({ loading }));

    return (
      <InputWrapper
        block
        helperText={helperText}
        invalid={invalid}
        width={width}
        className={className}
        borderless
        {...rest}
      >
        <Autocomplete
          getItemValue={this.getItemValue}
          isItemSelectable={item => item.id !== 'noMatch'}
          items={this.getOptions()}
          renderItem={this.renderItem}
          onChange={this.handleChange}
          onSelect={this.handleSelect}
          inputProps={{
            onFocus: this.handleFocus,
            onBlur: this.handleBlur,
            disabled,
            id,
            placeholder,
            'data-test-loading': loading,
          }}
          renderInput={createRenderInput({ inputClassName, width, invalid })}
          renderMenu={renderMenu}
          selectOnBlur={selectOnBlur}
          value={value}
          wrapperStyle={null}
        />
      </InputWrapper>
    );
  }
}

InputTextAuto.propTypes = {
  /** Width */
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** An indicator whether or not the outer context is "loading" more options. */
  loading: PropTypes.bool,
  /** If `items` contains an Object, this key is where to retrieve the "value" from. */
  valueKey: PropTypes.string,
  /** If `items` contains an Object, this key is where to retrieve the "label" from. */
  labelKey: PropTypes.string,
  /** A predetermined list of suggested values for the input. */
  options: PropTypes.array,
  /** The current value that the input is displaying. */
  value: PropTypes.string,
  /** Determines if the input is disabled or not */
  disabled: PropTypes.bool,
  /** Indicates if the value is passing or failing validation rules. */
  validation: PropTypes.bool,
  /** A label to render below the input. Usually used for error messages. */
  helperText: PropTypes.string,
  /** A handler that gets triggered everytime the input’s text value changes. */
  onChange: PropTypes.func,
  /** A handler that gets triggered everytime an option is selected from the dropdown. */
  onSelect: PropTypes.func,
  /** Specifies placeholder text */
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Determines whether a highlighted item will be selected onBlur */
  selectOnBlur: PropTypes.bool,
  /** Determines whether to show a default noMatch item if options list is empty */
  showNoMatchOption: PropTypes.bool,
};

InputTextAuto.defaultProps = {
  placeholder: 'Start typing to select...',
  validation: true,
  width: 300,
  selectOnBlur: true,
  showNoMatchOption: true,
};

export default InputTextAuto;
