import _ from 'lodash';
import { immutablePropSuffixes } from './propNames';

const createFilterReduxApiProps = apis => props =>
  _.pickBy(props, (value, key) => !apis[key]);

const immutablePropSuffixList = _.values(immutablePropSuffixes);
const immutablePropSuffixRegExp = new RegExp(
  `(${immutablePropSuffixList.join('|')})$`
);

export const createFilterReduxApiPropsImmutable = apis => props =>
  _.pickBy(
    props,
    (value, key) => !apis[key.replace(immutablePropSuffixRegExp, '')]
  );

export default createFilterReduxApiProps;
