import { parseToAbbreviatedDollarString } from './currencyUtils';

export const coverageLabelText = 'Coverage Amount:';
export const mobileNeedMoreCtaText = 'Need more?';
export const getDesktopNeedMoreCtaText = (
  maxAccelerableCoverageAmount: number
) =>
  `Need more than ${parseToAbbreviatedDollarString(
    maxAccelerableCoverageAmount
  )} in coverage?`;
// \u00a0 is the JS unicode equivalent of HTML `&nbsp;` (non-breaking space),
// which ensures no line break between words
export const termLengthLabelText = 'Policy length (in\u00a0years):';

const baseCtaText = 'Apply now';
export const defaultCtaText = baseCtaText;
export const nerdWalletCtaText = `${baseCtaText} with fabric`;
