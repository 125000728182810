import {
  border as borderTokens,
  color as colorTokens,
  spacer,
} from '@fabrictech/design-tokens';

import { softShadow } from '../../sharedStyles/vars/boxShadow';
import { border } from '../../utils/css/values';
import { plus, toPx } from '../../utils/css/values/px';

const { borderRadius } = borderTokens;
const backgroundColor = colorTokens.background.primary;
const borderColor = colorTokens.border.base;

type TooltipPosition = 'top' | 'bottom';

export const getTooltipStyles = () => ({
  backgroundColor,
  borderRadius,
  border: border(borderColor),
  boxShadow: softShadow,
});

export const getArrowStyles = ({
  position,
  color = backgroundColor,
}: {
  position: TooltipPosition;
  color?: string;
}) => {
  const arrowBorderWidth = toPx(spacer(1));
  return {
    borderTop:
      position === 'top' ? `${arrowBorderWidth} solid ${color}` : 'none',
    borderBottom:
      position === 'bottom' ? `${arrowBorderWidth} solid ${color}` : 'none',
    borderRight: `${arrowBorderWidth} solid transparent`,
    borderLeft: `${arrowBorderWidth} solid transparent`,
    bottom: `-${arrowBorderWidth}`,
    margin: `0 ${arrowBorderWidth}`,
  };
};

export const getArrowAfterStyles = ({
  position,
}: {
  position: TooltipPosition;
}) => ({
  content: '""',
  position: 'absolute',
  ...getArrowStyles({ position, color: borderColor }),
  margin: 0,
  left: spacer(-1),
  zIndex: -1,
  bottom: 'auto',
  top: position === 'top' ? plus(spacer(-1), 1) : toPx(-1),
});
