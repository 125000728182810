import { sizes, spacer } from '@fabrictech/design-tokens';
import mediaQueries from '../../../../sharedStyles/vars/mediaQueries';

export const navMobileBurgerGutter = spacer(2.5);
export const navMobileCloseGutter = spacer(1.75);
export const navTabletCloseGutter = spacer(2.5);
export const navDesktopGutter = spacer(5);

export const burgerWidth = sizes.icon.sm;
export const burgerHeight = sizes.icon.sm;

export const getButtonStyles = (): object => ({
  // Removes lingering default browser border / styles
  border: 0,
  padding: 0,
  backgroundColor: 'transparent',

  // Provide fixed dimensions to ensure that the icon
  // occupies the full dimensions of the button.
  height: burgerHeight,
  width: burgerWidth,

  // Position absolutely so we don't disrupt the centered
  // logo.
  position: 'absolute',

  // Prevents the SVG from overflowing and causing the
  // focus state to not line up with the button which
  // appears super wonky.
  overflow: 'hidden',

  // Position to right side based on the new navigation with dropdown.
  right: navMobileBurgerGutter,

  [mediaQueries.gte.xl]: {
    display: 'none',
  },
});
