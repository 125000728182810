/* eslint-disable max-len */

function formatCommaNumber(value) {
  if (!value) {
    return value;
  }

  value = String(value);
  const onlyNums = value.replace(/[^\d]/g, '');

  /*
   * http://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
   */
  return onlyNums
    .toString()
    .replace(/^0+/g, '')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export default formatCommaNumber;
