import React from 'react';
import { Box, MarketingText } from '@fabrictech/tapestry';
import { ValueOf } from '@fabrictech/definitely-fabric';
import { ineligibilityHeader, ineligibilityCaptions } from './content';
import FormButton from '../FormButton';
import LinkButton from '../LinkButton';
import getStyles from './getStyles';
import useResponsiveContext from '../useResponsiveContext';
import { GetQuotePremiumAu } from '../types';

type IneligibilityWarningProps = {
  goBack: () => void;
  quotesResponse?: GetQuotePremiumAu.Response;
  navigateToOtherOptions: () => void;
};

const isIneligibleResponse = (
  response?: GetQuotePremiumAu.Response
): response is GetQuotePremiumAu.IneligibleResponse => {
  if (response) {
    // if isEligible is true, then this object does not have an
    // `ineligibilityType`
    return !response.isEligible;
  }
  // if the response is undefined, then there can be no `ineligibilityType`
  return false;
};

const getCaption = (
  quotesResponse: GetQuotePremiumAu.IneligibleResponse
): ValueOf<typeof ineligibilityCaptions> => {
  return ineligibilityCaptions[quotesResponse.ineligibilityType];
};

const IneligibilityWarning = ({
  goBack,
  quotesResponse,
  navigateToOtherOptions,
}: IneligibilityWarningProps) => {
  const {
    styles: {
      containerClassname,
      titleClassname,
      captionClassname,
      footerWrapperClassname,
      footerTextClassname,
      footerLinkClassname,
    },
  } = useResponsiveContext(getStyles);

  return (
    <Box className={containerClassname}>
      <MarketingText header rank={2} className={titleClassname}>
        {ineligibilityHeader}
      </MarketingText>
      <MarketingText rank={2} className={captionClassname}>
        {isIneligibleResponse(quotesResponse)
          ? getCaption(quotesResponse)
          : null}
      </MarketingText>
      <FormButton onClick={navigateToOtherOptions}>See your options</FormButton>
      <Box className={footerWrapperClassname}>
        <MarketingText rank={2} className={footerTextClassname}>
          Make a mistake?&nbsp;
        </MarketingText>
        <LinkButton rank={2} className={footerLinkClassname} onClick={goBack}>
          Go back.
        </LinkButton>
      </Box>
    </Box>
  );
};

export default IneligibilityWarning;
