import React from 'react';
import { css, Rule } from 'glamor';

import { GlobalProps } from '../../types';
import { Width, Height, FlexAlign, FlexDirection } from './types';

import createGetStylesWithGlobalProps from '../../utils/createGetStylesWithGlobalProps';
import omitGlobalProps from '../../utils/omitGlobalProps';
import { getBaseStyles, getBackgroundStyles } from './getStyles';

const getStyles = createGetStylesWithGlobalProps(getBaseStyles);

const backgroundClassName = css(getBackgroundStyles()).toString();

export type BoxProps = GlobalProps & {
  /** Children node */
  children?: React.ReactNode;
  /** Class name to extend functionality */
  className?: Rule;
  /** Unique id for the HTML element */
  id?: string;
  /** dataTestName */
  dataTestName?: string;
  /** Sets component as background. To use a color, use `backgroundColor` */
  background?: string | React.ElementType;
  /** Sets background color */
  backgroundColor?: string;
  /** Width of the box */
  width?: Width;
  /** Max-width of the box */
  maxWidth?: Width;
  /** Height of the box */
  height?: Height;
  /** Min-height of the box */
  minHeight?: Height;
  /** Sets `display:'block'` */
  block?: boolean;
  /** Horizontal alignment */
  align?: FlexAlign;
  /** Vertical alignment */
  verticalAlign?: FlexAlign;
  /** Flex direction */
  flexDirection?: FlexDirection;
};

/** Box is used for layout and as a primitive for building UI. */
export const Box = ({
  children,
  className,
  id,
  dataTestName,
  background,
  backgroundColor = 'none',
  width = 'auto',
  maxWidth,
  height = 'auto',
  minHeight,
  block = false,
  flexDirection = 'row',
  align,
  verticalAlign,
  responsiveProps,
  ...rest
}: BoxProps) => {
  const Background = typeof background === 'string' ? null : background;
  const boxClassName = css(
    getStyles({
      backgroundColor:
        (typeof background === 'string' && background) || backgroundColor,
      width,
      maxWidth,
      height,
      minHeight,
      block,
      flexDirection,
      align,
      verticalAlign,
      responsiveProps,
      ...rest,
    }),
    className
  ).toString();
  return (
    <div
      className={boxClassName}
      id={id}
      data-test={dataTestName}
      {...omitGlobalProps(rest)}
    >
      {children}
      {Background ? (
        <div className={backgroundClassName}>
          <Background />
        </div>
      ) : null}
    </div>
  );
};

export default Box;
