import { spacer } from '@fabrictech/design-tokens';

import * as mediaQueries from '../../../../sharedStyles/vars/mediaQueries';
import { padding } from '../../../../utils/css/values';
import { times, plus } from '../../../../utils/css/values/px';

const verticalPaddingMobile = spacer(3);
/*
 * TODO:
 * This should be elsewhere
 */
const buttonHeight = '48px';

const getStyles = () => ({
  padding: spacer(5),
  [mediaQueries.lt.sm]: {
    padding: padding(
      verticalPaddingMobile,
      0,
      plus(times(verticalPaddingMobile, 2), buttonHeight)
    ),
  },
});

export default getStyles;
