import React from 'react';
import { css } from 'glamor';

import { color, spacer } from '@fabrictech/design-tokens';

import Box, { BoxProps } from '../Box';
import getBaseStyles from './getStyles';
import { createGetStylesResponsive } from '../../utils/createGetStylesWithGlobalProps';

type CardProps = BoxProps & {
  /** Adds side borders */
  sideBorders?: boolean;
  /** Adds shadow */
  hasShadow?: boolean;
  /** Controls width of border */
  borderWidth?: number;
};

/** Card is a styled `Box` used to display content */
export const Card = ({
  className,
  sideBorders = true,
  hasShadow = true,
  borderWidth,
  responsiveProps,
  ...rest
}: CardProps) => {
  const getStyles = createGetStylesResponsive()(getBaseStyles);
  const cardClassName = css(
    getStyles({
      hasShadow,
      sideBorders,
      responsiveProps,
      borderWidth,
    })
  );
  const boxClassName = css(cardClassName, className);
  return (
    <Box
      block
      backgroundColor={color.background.primary}
      padding={spacer(4)}
      marginBottom={spacer(5)}
      className={boxClassName}
      responsiveProps={responsiveProps}
      {...rest}
    />
  );
};

export default Card;
