import LogRocket from 'logrocket';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { getBuildInfo } from '@fabrictech/web-consumer-insights';

/**
 * Configures our app monitoring tools; Currently we use bugsnag and LogRocket
 */
export const configureMonitoringTools = () => {
  Bugsnag.start({
    apiKey: Config.bugsnagApiKey,
    plugins: [new BugsnagPluginReact()],
  });

  const buildInfo = getBuildInfo();
  const release = buildInfo && buildInfo.TRAVIS_BUILD_NUMBER;
  if (buildInfo) Bugsnag.addMetadata('buildInfo', buildInfo);

  LogRocket.init(Config.logRocketAppId, { release });
  LogRocket.log('FABRIC_BUILD_INFO', buildInfo);

  /*
   * Fetch session URL and pass along with bugsnag report metadata
   */
  LogRocket.getSessionURL(logRocketSessionUrl => {
    Bugsnag.addMetadata('user', { logRocketSessionUrl });
  });
};
