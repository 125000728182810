import baseWordmark from './svg/Fabric_Primary_Purple_RGB.svg';
import baseXsWordmark from './svg/Fabric_xs_Purple_RGB.svg';
import baseSmWordmark from './svg/Fabric_sm_Purple_RGB.svg';

import inverseWordmark from './svg/Fabric_Primary_White_RGB.svg';
import inverseSmWordmark from './svg/Fabric_sm_White_RGB.svg';
import inverseXsWordmark from './svg/Fabric_xs_White_RGB.svg';

import baseWordmarkNew from './svg/Fabric_By_GL_Color.svg';
import baseWordmarkNewInverse from './svg/Fabric_By_GL_White.svg';

export const logos = {
  wordmark: {
    base: {
      xs: baseXsWordmark,
      sm: baseSmWordmark,
      md: baseWordmark,
    },
    inverse: {
      xs: inverseXsWordmark,
      sm: inverseSmWordmark,
      md: inverseWordmark,
    },
  },
};

export const logosAlt = {
  wordmark: {
    base: {
      md: baseWordmarkNew,
      sm: baseWordmarkNew,
      xs: baseWordmarkNew,
    },
    inverse: {
      md: baseWordmarkNewInverse,
      sm: baseWordmarkNewInverse,
      xs: baseWordmarkNewInverse,
    },
  },
};
