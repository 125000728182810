import _ from 'lodash';

const indent = str => `\t${str}`;

const bugsnagMetadataContent = [
  'if (window.Bugsnag) {',
  indent('window.Bugsnag.metaData = window.Bugsnag.metaData || {};'),
  indent('window.Bugsnag.metaData.buildInfo = window.fabric.env;'),
  '}',
].join('\n');

const fabricEnvScriptContent = _.filter([
  '"use strict";',
  'window.fabric = window.fabric || {};',
  `window.fabric.env = ${JSON.stringify(fabricEnv)};`,
  Config.logFabricEnvOnLoad && 'console.log(window.fabric.env);',
  Config.bugsnagApiKey && bugsnagMetadataContent,
]).join('\n');

export default fabricEnvScriptContent;
