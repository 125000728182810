import { color, spacer, border } from '@fabrictech/design-tokens';

import { after } from '../../utils/css/selectors';

const { borderStyle, borderWidth } = border;

export const getBlockquoteStyles = () => ({
  position: 'relative',
  marginLeft: spacer(1),
  paddingLeft: spacer(2),
  [after]: {
    content: "''",
    position: 'absolute',
    top: spacer(0.5),
    bottom: spacer(0.5),
    left: 0,
    borderLeftStyle: borderStyle,
    borderLeftWidth: borderWidth,
    borderLeftColor: color.font.primary,
  },
});

export const getTableStyles = () => ({
  borderCollapse: 'collapse',
  margin: '0 auto',
  '& td, & th': {
    borderBottomStyle: borderStyle,
    borderBottomWidth: borderWidth,
    borderBottomColor: color.font.primary,
  },
  '& thead': {
    fontWeight: 'bold',
    '& th': { padding: spacer(1) },
  },
  '& tbody tr': {
    '& td': { padding: spacer(1) },
  },
});
