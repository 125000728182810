import { spacer } from '@fabrictech/design-tokens';
import { formWidthDesktop, formWidthMobile } from '../constants';
import createGetStyles from '../createGetStyles';

const containerStyles = {
  marginBottom: 0,
  width: formWidthDesktop,
  mobileBreakpoint: {
    margin: 'auto',
    marginTop: 0,
    marginBottom: 0,
    width: formWidthMobile,
  },
};
const buttonWrapperStyles = {
  marginTop: spacer(3.25),
  marginBottom: spacer(1.5),
  mobileBreakpoint: { marginTop: 0 },
};

const styles = {
  containerStyles,
  buttonWrapperStyles,
};

export default createGetStyles(styles);
