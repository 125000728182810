import _ from 'lodash';
import { css, StyleAttribute, CSSProperties } from 'glamor';
import { StyleObject } from '@fabrictech/tapestry';
import { queryLt } from '@fabrictech/tapestry/utils/css/queryBreakpoint';
import { defaultMediumBreakpoint } from '../constants';

// the base `queryLt` actually returns a `queryLte`, so this subtracts 1 to ensure
// correct breakpoint. Temporary until I switch to GTE I guess
export const getRealQueryLt = (val: number) => queryLt(val - 1);
type StyleObjectWithOptionalMobileBreakpoint = StyleObject & {
  mobileBreakpoint?: StyleObject;
};

const isStyleObject = (
  mobileStyleObj: StyleObject | undefined
): mobileStyleObj is StyleObject => _.isObject(mobileStyleObj);

const getStyleObjects = (styleObj: StyleObjectWithOptionalMobileBreakpoint) => {
  if (isStyleObject(styleObj.mobileBreakpoint)) {
    const { mobileBreakpoint: mobileStyleObj, ...baseStyleObj } = styleObj;
    return {
      mobileStyleObj,
      baseStyleObj,
    };
  }
  return {
    baseStyleObj: styleObj,
  };
};

// This helper checks for if the mobileBreakpoint variable is less than or
// greater than the default medium breakpoint. Some of the classes from Tapestry
// modify style attributes at the "medium" breakpoint. Because of this, we need
// to override those style attributes based on the mobile or desktop stylings
// (depending on if the mobileBreakpoint is less than or greater than the medium
// breakpoint). It orders the breakpoints in descending order to ensure
// that the smaller sizes' styles override the larger sizes' styles
const getMobileStyles = (
  {
    mobileStyleObj,
    baseStyleObj,
  }: {
    mobileStyleObj?: StyleObject;
    baseStyleObj: StyleObject;
  },
  mobileBreakpoint: number
) => {
  if (mobileStyleObj) {
    if (mobileBreakpoint < defaultMediumBreakpoint) {
      const mobileBreakpointStyles = { ...mobileStyleObj };
      const mediumBreakpointStyles = _.mapValues(
        mobileStyleObj,
        (attrVal, attrKey) => baseStyleObj[attrKey]
      );
      return {
        [queryLt(defaultMediumBreakpoint)]: mediumBreakpointStyles,
        [getRealQueryLt(mobileBreakpoint)]: mobileBreakpointStyles,
      };
    } else if (mobileBreakpoint > defaultMediumBreakpoint) {
      const mobileBreakpointStyles = { ...mobileStyleObj };
      const mediumBreakpointStyles = _.mapValues(
        baseStyleObj,
        (attrVal, attrKey) => mobileStyleObj[attrKey]
      );
      return {
        [getRealQueryLt(mobileBreakpoint)]: mobileBreakpointStyles,
        [queryLt(defaultMediumBreakpoint)]: mediumBreakpointStyles,
      };
    }
    return {
      [queryLt(defaultMediumBreakpoint)]: { ...baseStyleObj },
      [getRealQueryLt(mobileBreakpoint)]: { ...mobileStyleObj },
    };
  }
  return {};
};

type ClassnameToCssRuleEntry = [string, StyleAttribute | CSSProperties];
const baseClassnameToCssRuleEntries: ClassnameToCssRuleEntry[] = [];

// Accepts StyleObject, but with "mobileBreakpoint" as the key for the mobile
// sizes. In our case, this replaces lt.md. Sometimes we need to use lt.sm for
// overriding tapestry styles. Those styles can remain as is.
// It changes the keyname from "somethingStyles" to "somethingClassname"
// It returns glamor objects instead of style objects to simplify the memoization.
const createGetStyles = (map: Record<string, StyleObject>) => (
  isLockedToMobileWidth: boolean,
  mobileBreakpoint: number
) => {
  return _.fromPairs(
    Object.entries(map).reduce((acc, [key, styleObj]) => {
      const { mobileStyleObj, baseStyleObj } = getStyleObjects(styleObj);
      let joinObj: StyleObject | {} = {};
      if (isLockedToMobileWidth) {
        joinObj = {
          ...baseStyleObj,
          ...(mobileStyleObj || {}),
          ...(mobileStyleObj
            ? {
                [queryLt(defaultMediumBreakpoint)]: mobileStyleObj,
              }
            : {}),
        };
      } else {
        joinObj = {
          ...baseStyleObj,
          ...getMobileStyles(
            {
              baseStyleObj,
              mobileStyleObj,
            },
            mobileBreakpoint
          ),
        };
      }
      const newObj: ClassnameToCssRuleEntry = [
        key.replace('Styles', 'Classname'),
        css(joinObj),
      ];
      return [...acc, newObj];
    }, baseClassnameToCssRuleEntries)
  );
};

export default createGetStyles;
