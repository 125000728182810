import React from 'react';
import { css } from 'glamor';
import ReactInlineSvg from 'react-inlinesvg';

import {
  sizes,
  color as colorTokens,
  glyphs,
  GlyphType,
} from '@fabrictech/design-tokens';

import { GlobalProps } from '../../types';
import Box from '../Box';

import getStyles from './getStyles';

type GlyphProps = GlobalProps & {
  /** The color of the glyph */
  color?: string;
  /** The type of the glyph TODO: Props type and svgDataUrl should be mutually exclusive. */
  type?: GlyphType;
  /** Custom glyph svg */
  svgDataUrl?: string;
};

const Glyph = ({
  color = colorTokens.base.accent,
  type,
  svgDataUrl,
  ...rest
}: GlyphProps) => {
  const sideLength = sizes.glyph;

  // TODO: Again, these props should be mutually exclusive. For now,
  // we force the type on the declaration so we can move on. Yuck.
  const svgSrc = (type ? glyphs[type] : svgDataUrl) as string;

  const svgClassname = css(getStyles({ sideLength, color })).toString();

  return (
    <Box block {...rest}>
      <ReactInlineSvg
        src={svgSrc}
        wrapper={React.createFactory('div')}
        className={svgClassname}
      />
    </Box>
  );
};

export default Glyph;
