import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Bugsnag from '@bugsnag/js';

/** Imports a CSS Reset and Tapestry's font faces */
import '@fabrictech/tapestry/init';
import { fabricEnvScriptContent } from '@fabrictech/web-consumer-insights';

import { configureMonitoringTools } from './utils/configureMonitoringTools';
import App from './App';
import { store } from './store';

// add fabricEnv to window via script
const script = document.createElement('script');
script.innerHTML = fabricEnvScriptContent;
document.body.appendChild(script);

// configure Bugsnag and LogRocket
configureMonitoringTools();

const BugsnagErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(
  React
);

ReactDOM.render(
  <Provider store={store}>
    <BugsnagErrorBoundary>
      <App />
    </BugsnagErrorBoundary>
  </Provider>,
  document.getElementById('app')
);
