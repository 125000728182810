import isImmutable from 'is-immutable';
import _ from 'lodash';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { inputTypes } from '../../../utils/constants';
import { ValidationErrorType } from '../../../utils/validationError';
import { eventHandlingBehaviors } from '../inputUtils';

/**
 * Consumes an error type and an input's type, determines whether or not
 * it should be suppressed, and either suppresses it or returns the error.
 *
 * @param {Object}
 *  @property {Error} rawError The error to inspect
 *  @property {string} inputType The type of the input
 * @returns {Error|null}
 */
const getMaybeSuppressedError = ({ rawError, inputType }) => {
  // suppress the error when the error type is `ValidationErrorType.Required` and the input
  // type is `'multiselection'` (this means the user has deselected all options, and the
  // answer is 'missing' but not 'invalid')
  const isRequired =
    rawError && rawError.type && rawError.type === ValidationErrorType.Required;
  const isMultiselection = inputType === inputTypes.multiSelection;
  return isRequired && isMultiselection ? null : rawError;
};

/**
 * We want a long enough delay to prevent unncessary updates from being fired, but
 * a short enough delay that any side-effects of an update (like a submit button
 * being activated/disactived) feel responsive.
 *
 * For some discussion in this space,
 * @see https://stackoverflow.com/a/44755058
 */
const updateDebounceDuration = 225; // ms

/**
 * Component used to manage input events (change, blur, focus) in Questionnaire
 * input components. Injects state via a render prop.
 */
class EventQuestionAdapter extends ImmutablePureComponent {
  constructor(props) {
    super(props);
    this.createEventHandlers();

    const value = _.isNil(props.initialValue) ? '' : props.initialValue;

    this.state = {
      previousValue: props.normalizer(value),
      value: props.normalizer(value),
      error: null, // will be string of error
      touched: false,
      focus: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.validator !== prevProps.validator &&
      prevState.touched &&
      this.state.value
    ) {
      /**
       * Questions that contain dynamic constraints will receive updated
       * validators when their constraints change. In the event that a
       * validator is updated, the field was previously touched, and
       * currently has a value, we want to validate with the new validator
       */
      this.validateValue(this.state.value);
    }

    /**
     * For questions that have a change to their initialValue prop
     * without the field in a focused state, we want to update the value
     * on state to be the new initialValue. This is not a usual case.
     * It currently only applies to address autocomplete.
     */
    if (
      !this.state.focus &&
      !_.isEqual(prevProps.initialValue, this.props.initialValue)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: this.props.normalizer(this.props.initialValue) });
    }
  }

  /**
   * Creates a callback meant to handle a form-input event (i.e.
   * change, blur, focus).
   *
   * @param {Object}
   *  @property {Function} createUpdate Creates a local state update
   *  @property {Function} getValue Accesses the value of the input
   *  @property {String} type The type of change
   *  @property {String} eventPropFn The name of the prop this change should invoke (i.e. onChange)
   * @returns {Function}
   */
  createEventHandler({
    createUpdate,
    getValue = () => this.state.value,
    type,
    eventPropFn,
  }) {
    return eventArg => {
      // Call onChange / onFocus / onBlur, etc.
      if (eventPropFn && this.props[eventPropFn]) {
        this.props[eventPropFn](eventArg || { id: this.props.id });
      }

      const value = getValue(eventArg);

      // Handles validation and updates local state or communicates
      // error state back to a parent component that will consume it.
      this.handlePossibleValidation(type, value);

      // Update local state and handoff to helper used to synchronize
      // with server.
      const update = createUpdate(value);

      this.setState(update, () => {
        this.handlePossibleUpdate(type);
      });
    };
  }

  /**
   * Instantiates the event handlers using the createEventHandler
   * generic helper.
   */
  createEventHandlers() {
    this.handleChange = this.createEventHandler({
      type: 'change',
      eventPropFn: 'onChange',
      createUpdate: value => ({ value, touched: true }),
      getValue: this.getValueFromChange.bind(this),
    }).bind(this);

    this.handleFocus = this.createEventHandler({
      type: 'focus',
      eventPropFn: 'onFocus',
      createUpdate: () => ({ focus: true }),
    }).bind(this);

    this.handleBlur = this.createEventHandler({
      type: 'blur',
      eventPropFn: 'onBlur',
      createUpdate: () => ({ focus: false, touched: true }),
    }).bind(this);
  }

  /**
   * Call the update data hook provided by the parent after updating some
   * internal state.
   */
  executeUpdate = () => {
    this.setState(
      prevState => ({ previousValue: prevState.value }),
      () => {
        this.props.onUpdateData({
          id: this.props.id,
          value: this.props.parser(this.state.value),
          error: this.state.error,
        });
      }
    );
  };

  /** Wrap the above with a debounce, useful for some types of inputs. */
  executeUpdateDebounced = _.debounce(
    this.executeUpdate,
    updateDebounceDuration
  );

  /**
   * Validates value if validator is provided and updates validation
   * state as configured.
   *
   * @param {any} value
   */
  validateValue(value) {
    const { inputType, validator } = this.props;
    if (validator) {
      const rawError = validator(value.toJS ? value.toJS() : value);
      const error = getMaybeSuppressedError({ rawError, inputType });

      this.setState(
        {
          error,
        },
        () => {
          this.reportPossibleError();
        }
      );
    }
  }

  /** Wrap the above with a debounce, useful for some types of inputs. */
  validateValueDebounced = _.debounce(
    this.validateValue,
    updateDebounceDuration
  );

  /**
   * Hands off to `onReportError` as necessary. Used to communicate
   * errors back up to parent-adapters for components that don't
   * manage their own errors.
   */
  reportPossibleError() {
    const { onReportError, id } = this.props;

    if (onReportError) {
      const { error: message } = this.state;
      onReportError({ id, message });
    }
  }

  /**
   * The changeArg will either be a primitive or an object, so here
   * we coalesce to a primitive as necessary.
   *
   * @param {any} changeArg
   * @returns {string|number|boolean}
   */
  getValueFromChange(changeArg) {
    // TODO: This is a QuestionnaireAdapter, and therefore, shouldn't have
    // platform specific knowledge as it does here.
    // @see https://fabrictech.atlassian.net/browse/QLT-101
    const changeValue =
      _.isObject(changeArg) && changeArg.target
        ? changeArg.target.value
        : changeArg;

    return this.props.normalizer
      ? this.props.normalizer(changeValue, this.state.value)
      : changeValue;
  }

  /**
   * Hands off to validation-helper as necessary depending on the
   * type of the event.
   *
   * @param {string} eventType
   * @param {string|number|boolean} value
   */
  handlePossibleValidation(eventType, value) {
    const eventHandling = this.props.validateOn[eventType];
    if (!eventHandling) return;

    const parsedValue = this.props.parser(value);
    if (eventHandling === eventHandlingBehaviors.immediate) {
      this.validateValue(parsedValue);
    } else if (eventHandling === eventHandlingBehaviors.debounced) {
      this.validateValueDebounced(parsedValue);
    }
  }

  /**
   * Hands off to update helper as necessary depending on whether
   * or not the value has actually changed and the type of the event.
   *
   * @param {any} eventType
   */
  handlePossibleUpdate(eventType) {
    const eventHandling = this.props.updateOn[eventType];
    if (!this.getHasValueChanged() || !eventHandling) return;

    if (eventHandling === eventHandlingBehaviors.immediate) {
      this.executeUpdate();
    } else if (eventHandling === eventHandlingBehaviors.debounced) {
      this.executeUpdateDebounced();
    }
  }

  /**
   * Inspects state and determines if the value of the input
   * has changed.
   *
   * @returns {boolean}
   */
  getHasValueChanged() {
    const { value, previousValue } = this.state;

    // TODO: migrate this to Immutable.Iterable.isIterable, if feasible
    return isImmutable(value)
      ? !value.equals(previousValue)
      : value !== previousValue;
  }

  render() {
    const { render, suppressErrorMessages, ...rest } = this.props;

    const error = this.state.touched && !this.state.focus && this.state.error;
    const errorMessage = _.get(error, 'message', null);

    return render({
      ...rest,
      onChange: this.handleChange,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      value: this.state.value,
      validation: Boolean(!error),
      helperText: suppressErrorMessages
        ? null
        : errorMessage || rest.helperText,
    });
  }
}

EventQuestionAdapter.defaultProps = {
  parser: _.identity,
  normalizer: _.identity,
};

export default EventQuestionAdapter;
