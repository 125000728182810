import { border, color, spacer } from '@fabrictech/design-tokens';

import { standardShadow } from '../../sharedStyles/vars/boxShadow';
import * as mediaQueries from '../../sharedStyles/vars/mediaQueries';
import { important, margin, padding } from '../../utils/css/values';
import { times, toPx } from '../../utils/css/values/px';

const { borderRadius, borderWidth, borderStyle } = border;
const { setAlpha } = color.utils;

export const getOverlayStyles = () => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1003,
  backgroundColor: setAlpha(color.background.overlay, 0.6),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: spacer(6),
  opacity: 0,
  [mediaQueries.lt.md]: {
    padding: spacer(2),
    alignItems: 'stretch',
  },
});

export const getOverlayAfterOpenStyles = ({ closeTimeoutMS }) => ({
  opacity: 1,
  transition: `opacity ${closeTimeoutMS}ms`,
});

export const getOverlayBeforeCloseStyles = () => ({
  // Using !important to override the cascade
  opacity: important(0),
});

export const getModalStyles = () => ({
  position: 'relative',
  display: 'flex',
  borderRadius,
  borderColor: color.border.base,
  borderWidth,
  borderStyle,
  background: color.background.primary,
  overflow: 'hidden',
  outline: 'none',
  boxShadow: standardShadow,
  minWidth: '50%',
  maxWidth: spacer(100),
  maxHeight: '100%',
  [mediaQueries.lt.md]: {
    width: '100%',
    top: 0,
  },
  transform: `translateY(${toPx(spacer(3))})`,
});

export const getModalAfterOpenStyles = ({ closeTimeoutMS }) => ({
  transform: 'translateY(0)',
  transition: `transform ${closeTimeoutMS}ms ease-in-out`,
});

export const getModalBeforeCloseStyles = () => ({
  // Using !important to override the cascade
  transform: important(`translateY(${toPx(spacer(3))})`),
});

export const getCloseButtonStyles = () => ({
  display: 'block',
  margin: margin(0, times(spacer(6), -1), 0, 'auto'),
  border: 0,
  padding: spacer(2),
  background: 'none',
  cursor: 'pointer',
  [mediaQueries.lt.md]: {
    margin: margin(0, times(spacer(2), -1), 0, 'auto'),
  },
});

export const getContentWrapperStyles = () => ({
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  padding: padding(0, spacer(6), spacer(6), spacer(6)),
  paddingTop: 0,
  width: '100%',
  [mediaQueries.lt.md]: {
    padding: padding(0, spacer(2), spacer(2), spacer(2)),
    paddingTop: 0,
  },
});
