import { color } from '../color';
import colorUtils from '../color/utils';

const { setAlpha } = colorUtils;

export type TextRank = 1 | 2 | 3 | 4;

/** Helper type to pick a subset of TextRank */
export type PartialTextRank<P extends TextRank> = {
  [key in TextRank]: key extends P ? key : never;
}[P];

export type FontWeight = 'light' | 'normal' | 'semibold' | 'bold' | 'black';

export type TextTransform = 'none' | 'capitalize' | 'uppercase' | 'lowercase';

export type FontFamily = 'Calluna' | 'Calluna Sans' | 'Inter';

export type FontStyle = {
  fontFamily: FontFamily;
  fontWeight: FontWeight;
  fontSize: { desktop: number; mobile: number };
  lineHeight: { desktop: number; mobile: number };
  color: string;
  textTransform: TextTransform;
  letterSpacing?: number;
};

export const baseFontSize = 16;

/** Header */

const headerBaseFontStyle: FontStyle = {
  fontFamily: 'Inter',
  fontWeight: 'bold',
  fontSize: {
    desktop: 18,
    mobile: 18,
  },
  lineHeight: {
    desktop: 24,
    mobile: 24,
  },
  color: color.font.primary,
  textTransform: 'none',
  letterSpacing: 0.5,
};

const header: { [key in TextRank | 5]: FontStyle } = {
  1: {
    ...headerBaseFontStyle,
    fontSize: {
      desktop: 42,
      mobile: 32,
    },
    lineHeight: {
      desktop: 48,
      mobile: 36,
    },
  },
  2: {
    ...headerBaseFontStyle,
    fontSize: {
      desktop: 24,
      mobile: 24,
    },
    lineHeight: {
      desktop: 30,
      mobile: 30,
    },
  },
  3: headerBaseFontStyle,
  4: {
    ...headerBaseFontStyle,
    fontSize: {
      desktop: 15,
      mobile: 15,
    },
    lineHeight: {
      desktop: 21,
      mobile: 21,
    },
  },
  5: {
    ...headerBaseFontStyle,
    fontSize: {
      desktop: 13,
      mobile: 11,
    },
    lineHeight: {
      desktop: 19,
      mobile: 17,
    },
    textTransform: 'uppercase',
    letterSpacing: 1.5,
  },
};

/** Paragraph */
const paragraphBaseFontStyle: FontStyle = {
  fontFamily: 'Inter',
  fontWeight: 'normal',
  fontSize: {
    desktop: 16,
    mobile: 14,
  },
  lineHeight: {
    desktop: 22,
    mobile: 20,
  },
  color: color.font.primary,
  textTransform: 'none',
};

const paragraph: { [key in TextRank]: FontStyle } = {
  1: {
    fontFamily: 'Inter',
    fontWeight: 'bold',
    fontSize: {
      desktop: 15,
      mobile: 15,
    },
    lineHeight: {
      desktop: 21,
      mobile: 21,
    },
    color: color.font.primary,
    textTransform: 'none',
    letterSpacing: 0.25,
  },
  2: paragraphBaseFontStyle,
  3: {
    ...paragraphBaseFontStyle,
    fontSize: {
      desktop: 12,
      mobile: 10,
    },
    lineHeight: {
      desktop: 16,
      mobile: 16,
    },
  },
  4: {
    ...paragraphBaseFontStyle,
    fontSize: {
      desktop: 12,
      mobile: 10,
    },
    lineHeight: {
      desktop: 16,
      mobile: 16,
    },
  },
};

/** Input */

const inputFontStyle: FontStyle = {
  fontFamily: 'Inter',
  fontWeight: 'bold',
  fontSize: {
    desktop: 16,
    mobile: 16,
  },
  lineHeight: {
    desktop: 21,
    mobile: 21,
  },
  color: color.font.primary,
  textTransform: 'none',
};

const input: { [key in PartialTextRank<1 | 2 | 3>]: FontStyle } = {
  1: { ...inputFontStyle, letterSpacing: 0.25 },
  2: {
    ...inputFontStyle,
    fontWeight: 'normal',
  },
  3: {
    ...inputFontStyle,
    fontWeight: 'normal',
    fontSize: {
      desktop: 10,
      mobile: 10,
    },
    lineHeight: {
      desktop: 16,
      mobile: 16,
    },
  },
};

/** Button */
const buttonBaseFontStyle: FontStyle = {
  fontFamily: 'Inter',
  fontWeight: 'bold',
  fontSize: { desktop: 11, mobile: 11 },
  lineHeight: { desktop: 17, mobile: 17 },
  color: color.font.primary,
  textTransform: 'uppercase',
  letterSpacing: 1.5,
};

const button: { [key in PartialTextRank<1 | 2 | 3>]: FontStyle } = {
  1: {
    ...buttonBaseFontStyle,
    fontSize: { desktop: 15, mobile: 15 },
    lineHeight: { desktop: 19, mobile: 19 },
  },
  2: {
    ...buttonBaseFontStyle,
    fontSize: { desktop: 13, mobile: 13 },
    lineHeight: { desktop: 19, mobile: 19 },
  },
  3: buttonBaseFontStyle,
};

const shadow = {
  header: {
    desktop: `1px 1px 1px ${setAlpha(color.font.primary, 0.25)}`,
    mobile: `2px 2px 2px ${setAlpha(color.font.primary, 0.4)}`,
  },
};

export const typography = {
  header,
  paragraph,
  input,
  shadow,
  button,
} as const;
