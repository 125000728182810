import React from 'react';
import { MarketingText } from '@fabrictech/tapestry';
import getStyles from './getStyles';
import useResponsiveContext from '../../useResponsiveContext';
import {
  defaultDisclaimerText,
  nerdWalletDisclaimerText,
  internalDisclaimerText,
} from './content';

type EstimateDisclaimerProps = {
  isNerdWalletWidget: boolean;
};

const getDisclaimerText = (
  isNerdWalletWidget: boolean,
  isInternalWidget: boolean
) => {
  if (isInternalWidget) {
    return internalDisclaimerText;
  }
  return isNerdWalletWidget ? nerdWalletDisclaimerText : defaultDisclaimerText;
};

const EstimateDisclaimer = ({
  isNerdWalletWidget,
}: EstimateDisclaimerProps) => {
  const {
    isInternalWidget,
    styles: { baseDisclaimerTextClassname, internalDisclaimerTextClassname },
  } = useResponsiveContext(getStyles);
  const disclaimerTextClassname = isInternalWidget
    ? internalDisclaimerTextClassname
    : baseDisclaimerTextClassname;

  const disclaimerText = getDisclaimerText(
    isNerdWalletWidget,
    isInternalWidget
  );
  return (
    <MarketingText rank={4} className={disclaimerTextClassname}>
      {disclaimerText}
    </MarketingText>
  );
};

export default EstimateDisclaimer;
