/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/src/get-quote-premium-au/coverageAmounts.js
 */
export const premiumCoverageAmounts = [
  100000,
  150000,
  200000,
  250000,
  300000,
  350000,
  400000,
  450000,
  500000,
  600000,
  700000,
  750000,
  800000,
  900000,
  1000000,
  1250000,
  1500000,
  1750000,
  2000000,
  2500000,
  3000000,
  3500000,
  4000000,
  4500000,
  5000000,
];

/**
 * Represents the maximum coverage amount that can be accelerated.
 */
export const maxAccelerableCoverageAmount = 1500000;

/**
 * A centrally located function to get the current max accelerable coverage amount.
 * @returns {Number} The maximum accelerable coverage amount
 */
export const getMaxAccelerableCoverageAmount = () =>
  maxAccelerableCoverageAmount;
/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/src/lib/quote/constants.ts#L21
 */
export enum VantisRatingClass {
  standard = 'Standard', // 0
  ultraSelect = 'Ultra Select', // 1
  selectPlus = 'SelectPlus', // 2
  standardTobacco = 'Standard Tobacco', // 3
  select = 'Select', // 4
  /*
   * Fabric MGA acceleratable products do not have rating classes below.
   * Unsure about Vantis MGA acceleratable products and Premium Classic
   */
  substandard = 'Substandard', // 5?
  substandardTobacco = 'Substandard Tobacco', // 6?
  // this rating class is functionally the same as `Standard`
  standardNonTobacco = 'Standard Non-Tobacco', // 0
}

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/src/lib/ras/index.ts#L63
 */

/**
 * Ras's Segments for PremiumPt (Velocity 2.0) applications
 *
 * @see https://jira.hannover-re.com/confluence/display/REVALI/V2+-+Third+party+data+rules
 */
export enum PremiumPtSegment {
  segment1 = 'Segment_1',
  segment2 = 'Segment_2',
  segment3 = 'Segment_3',
  segment4 = 'Segment_4',
}

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/src/lib/kyc/types.ts#L18
 */
export enum KycStatus {
  passed = 'Passed',
  failed = 'Failed',
}

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/src/lib/products/index.ts#L161
 */
export enum TermLength {
  ten = 10,
  fifteen = 15,
  twenty = 20,
  twentyFive = 25,
  thirty = 30,
}

/**
 * **LINEN DUPLICATION WARNING**
 * These types reference the objects stored in `person/${identityId}/premium-au/rate/amendment/`
 * @see https://github.com/fabrictech/linen/blob/4a69523504035719c05877512444c581e7b16bfb/node/src/lib/rate/amendment/index.ts
 */
export interface ModalPremiums {
  monthly: number;
  quarterly: number;
  semiAnnual: number;
  annual: number;
}

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/535952c47483728e9aaad741c14e7df2e37ab955/node/src/lib/rate/amendment/index.ts#L23
 */
export enum OfferType {
  offerAtTimeOfFinalDecision = 'offerAtTimeOfFinalDecision',
  lowestOffer = 'lowestOffer',
  middleOffer = 'middleOffer',
  highestOffer = 'highestOffer',
}

export const offerTypeReadable = {
  [OfferType.offerAtTimeOfFinalDecision]: 'At Decision',
  [OfferType.lowestOffer]: 'Lowest',
  [OfferType.middleOffer]: 'Highest',
  [OfferType.highestOffer]: 'Highest',
} as const;

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/535952c47483728e9aaad741c14e7df2e37ab955/node/src/lib/rate/amendment/index.ts#L30
 */
export enum OfferCardType {
  approvedOffer = 'approvedOffer',
  longerTerm = 'longerTerm',
  longestTerm = 'longestTerm',
  moreAffordable = 'moreAffordable',
  mostAffordable = 'mostAffordable',
}

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/535952c47483728e9aaad741c14e7df2e37ab955/node/src/lib/rate/amendment/index.ts#L38
 */
export interface AmendmentOffer {
  faceAmount: number;
  premium: number;
  termLength: TermLength;
  offerType?: OfferType;
  offerCardType?: OfferCardType;
}

export interface FriendlyAmendmentOffer extends AmendmentOffer {
  offerType: OfferType;
  offerCardType: OfferCardType;
}

export interface AmendmentOffersByTermLength {
  [termLength: string]: AmendmentOffer[];
}

export interface AmendmentOfferModalPremiums
  extends ModalPremiums,
    AmendmentOffer {}

export interface AmendmentOfferModalPremiumsByTermLength {
  [termLength: string]: AmendmentOfferModalPremiums[];
}

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/stdlib/fabric-aws/dynamoDb/application/constants.ts
 */

/**
 * This enum describes the possible algorithmic underwriting decisions made
 * by our system, a.k.a. the decision "at desktop" (which is the common industry
 * parlance but is a little confusing for our purposes, since the vast vast
 * majority of our users apply from a mobile device).
 *
 * While the values represented here are a subset of the RAS decision options
 * from Hannover (@see ../../ras/index), the algorithmic decision is made from
 * a combination of factors, of which the RAS decision is just one.
 *
 * At the time of writing, the only algorithmic decisions we allow are
 *   - Accept: approve the application and give an offer without manual UW
 *   - Decline: decline the application and without manual UW
 *   - ReferToUnderwriter: defer final underwriting decision to a human UW
 *
 */

export enum AlgorithmicDecision {
  // Please document changes to this enum in ./README.md
  accept = 'Accept',
  referToUnderwriter = 'ReferToUnderwriter',
  decline = 'Decline',
}

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/stdlib/fabric-aws/dynamoDb/application/constants.ts
 *
 * !!!! JAVA DUPLICATION WARNING !!!!
 *
 * This enum duplicates the one defined in
 * linen/linen-model/src/main/java/com/fabrictech/linen/model/application/FinalDecision.java
 */
export enum FinalDecision {
  accept = 'Accept',
  decline = 'Decline',
  reject = 'Reject',
  postpone = 'Postpone',
}

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/stdlib/fabric-aws/dynamoDb/application/constants.ts
 */
export enum ApplicationCanceledType {
  expired = 'expired',
  customerRequestedWithdrawal = 'customerRequestedWithdrawal',
  inactivityWithdrawal = 'inactivityWithdrawal',
  legacyWithdrawal = 'legacyWithdrawal',
}

export const applicationCanceledTypesReadable = {
  [ApplicationCanceledType.expired]: 'Expired',
  [ApplicationCanceledType.customerRequestedWithdrawal]:
    'Customer requested withdrawal',
  [ApplicationCanceledType.inactivityWithdrawal]: 'Inactivity withdrawal',
  [ApplicationCanceledType.legacyWithdrawal]: 'Legacy withdrawal',
} as const;

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/stdlib/fabric-aws/dynamoDb/application/constants.ts
 */
// NOTE: This does not include "legacy" withdrawals because it is intended for
// recording new withdrawals only.
export const applicationWithdrawnTypes = {
  customerRequested: ApplicationCanceledType.customerRequestedWithdrawal,
  inactivity: ApplicationCanceledType.inactivityWithdrawal,
} as const;

export const applicationWithdrawnTypesReadable = {
  [applicationWithdrawnTypes.customerRequested]: 'Customer requested',
  [applicationWithdrawnTypes.inactivity]: 'Inactivity',
} as const;

/**
 * **LINEN DUPLICATION WARNING**
 * @see https://github.com/fabrictech/linen/blob/master/node/stdlib/fabric-aws/dynamoDb/application/constants.ts
 */
export enum ApplicationCanceledReason {
  cannotAffordOrTooExpensive = 'cannotAffordOrTooExpensive',
  choseAnotherCarrier = 'choseAnotherCarrier',
  didNotIntendToApply = 'didNotIntendToApply',
  outstandingCustomerDocument = 'outstandingCustomerDocument',
  outstandingFollowUp = 'outstandingFollowUp',
  refusedHealthExam = 'refusedHealthExam',
  refusedToShareMedicalRecords = 'refusedToShareMedicalRecords',
  underwritingTooSlow = 'underwritingTooSlow',
}

export const DID_NOT_PROVIDE_REASON = 'didNotProvideReason';

export const applicationCanceledReasonsReadable = {
  /*
   * This is added here so that Customer Success can explicitly select "Did not provide reason"
   * This value is not written to the database, so it is not included in `ApplicationCanceledReason`ss
   */
  [DID_NOT_PROVIDE_REASON]: 'Did not provide reason',
  [ApplicationCanceledReason.cannotAffordOrTooExpensive]:
    'Cannot afford / too expensive',
  [ApplicationCanceledReason.choseAnotherCarrier]: 'Chose another carrier',
  [ApplicationCanceledReason.didNotIntendToApply]: 'Did not intend to apply',
  [ApplicationCanceledReason.outstandingCustomerDocument]:
    'Outstanding customer document',
  [ApplicationCanceledReason.outstandingFollowUp]: 'Outstanding Follow-up',
  [ApplicationCanceledReason.refusedHealthExam]: 'Refused health exam',
  [ApplicationCanceledReason.refusedToShareMedicalRecords]:
    'Refused to share medical records',
  [ApplicationCanceledReason.underwritingTooSlow]: 'Underwriting too slow',
} as const;
