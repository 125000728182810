import _ from 'lodash';
import { GetQuotePremiumAu } from './types';

const isEmptyLead = (partialLeadData: Partial<GetQuotePremiumAu.Request>) =>
  _.isEmpty(_.omit(partialLeadData, 'leadId'));

// The leadData should only be pre-filled if the form hasn't been touched yet
export const shouldSetAnswersWithPrefilledLead = (
  leadDataState: Partial<GetQuotePremiumAu.Request>,
  prefilledLeadProp: Partial<GetQuotePremiumAu.Request>
) => isEmptyLead(leadDataState) && !isEmptyLead(prefilledLeadProp);

// The lead data we consume for the `<LeadForm />` are stored as various
// data-types such as string, boolean, number. However, the `tobacco` property
// is considered a string by the form, but a boolean by the Lead type, so we
// must cast it to a string for the `<LocalForm />` to register the field as
// filled. This function casts all but age to string.
export const transformLeadToAnswersById = (
  partialLeadData: Partial<GetQuotePremiumAu.Request>
) =>
  _.fromPairs(
    Object.entries(partialLeadData).map(([id, answer]) =>
      id === 'age' ? [id, answer] : [id, String(answer)]
    )
  );
