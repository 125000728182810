import React from 'react';
import { css } from 'glamor';
import _ from 'lodash';

import { color, spacer, layout, breakpoints } from '@fabrictech/design-tokens';

import createGetStylesWithGlobalProps from '../../utils/createGetStylesWithGlobalProps/createGetStylesWithGlobalProps';

import { margin, padding } from '../../utils/css/values/index';
import { times, divide, gt } from '../../utils/css/values/px';

import LayoutItem, { LayoutItemProps } from '../LayoutItem/LayoutItem';
import Box, { BoxProps } from '../Box';
import { ResponsiveProps } from '../../types';

import getStylesFromProps from './getStyles';

const getStyles = createGetStylesWithGlobalProps(getStylesFromProps);

export type LayoutContainerProps = BoxProps & {
  /** Number of grid columns */
  columns?: number;
  /** Width of the gutters in the grid */
  gutterWidth?: string | number;
  /** Displays an interior gutter -padding on to the left of the first column and right of the last */
  hasInteriorGutter?: boolean;
  /** Max width  */
  maxWidth?: string | number;
  /** Displays grid columns - helpful for troubleshooting layout issues. Not to be used in production */
  _showGrid?: boolean;
  /** Responsive Props to be set on LayoutItem */
  layoutItemResponsiveProps?: ResponsiveProps;
};

/** If `_showGrid`, creates a background for LayoutContainer to aid with visualizing layout */
const GridBackground = ({
  columns = 12,
  gutterWidth,
  hasInteriorGutter,
  maxWidth,
}: LayoutContainerProps) => (
  // `<GridBackground>` is used inside of `<LayoutContainer>`, so it needs to be used before it's defined.
  // This is separated out only to make it easier to read.
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  <LayoutContainer
    background={color.background.tertiary}
    columns={columns}
    gutterWidth={gutterWidth}
    marginBottom={0}
    maxWidth={maxWidth}
    hasInteriorGutter={hasInteriorGutter}
    height="100%"
    className={css({ width: '100%' })} // Override to work with !hasInteriorGutter
  >
    {_.range(columns).map(column => (
      <LayoutItem span={1} key={column}>
        <Box block background={color.background.secondary} height="100%" />
      </LayoutItem>
    ))}
  </LayoutContainer>
);

/**
 * `LayoutContainer` is responsible for vertical spacing and the gutter between `LayoutItem` children.
 */
export const LayoutContainer = ({
  children,
  className,
  columns = 12,
  gutterWidth = layout.gutterWidth,
  maxWidth = breakpoints.xl,
  align = 'center',
  hasInteriorGutter = true,
  background,
  _showGrid = false,
  layoutItemResponsiveProps = {},
  ...rest
}: LayoutContainerProps) => {
  const layoutContainerClassName = getStyles({
    maxWidth,
    gutterWidth,
    hasInteriorGutter,
    ...rest,
  });
  // If _showGrid is enabled, creates a background that displays the columns and gutters
  background = _showGrid
    ? () => (
        <GridBackground
          columns={columns}
          gutterWidth={gutterWidth}
          hasInteriorGutter={hasInteriorGutter}
          maxWidth={maxWidth}
        />
      )
    : background;
  return (
    <Box
      align={align}
      background={background}
      margin={margin(
        0,
        // If no interior gutter, uses a negative margin to offset LayoutItem padding
        hasInteriorGutter ? 0 : times(gutterWidth, -0.5),
        spacer(11)
      )}
      padding={
        hasInteriorGutter && gt(gutterWidth, 0)
          ? padding(0, divide(gutterWidth, 2))
          : 0 // If no interior gutter, remove padding
      }
      className={css(layoutContainerClassName, className)}
      {...rest}
    >
      {React.Children.map(children, child =>
        // Apply column number, gutterWidth, and itemResponsiveProps down to children
        React.isValidElement(child)
          ? React.cloneElement<LayoutItemProps>(child, {
              gutterWidth,
              columns,
              layoutItemResponsiveProps,
            })
          : child
      )}
    </Box>
  );
};

export default LayoutContainer;
