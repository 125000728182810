import React from 'react';
import { css } from 'glamor';

import {
  spacer,
  GlyphType,
  TextRank,
  typography,
} from '@fabrictech/design-tokens';
import { margin } from '../../utils/css/values';
import mediaQueries from '../../sharedStyles/vars/mediaQueries';
import { getStyleObjectFromFontStyle } from '../../constants/typography';

import { GlobalProps } from '../../types';

import Text from '../Text';
import Box from '../Box';
import Glyph from '../Glyph';

export type PageHeaderProps = GlobalProps & {
  /** Subheader text */
  subheader: string;
  /** Header text */
  header: string;
  /** Body text */
  body?: string | JSX.Element;
  /** The type of the glyph */
  glyph: GlyphType;
  /** Size of page header */
  size?: 'sm' | 'lg';
  /** Controls the maximum width */
  maxWidth?: string | number;
};

/**
 * NOTE: body is currently only used with lg size PageHeader
 * TODO: update sm size  marginBottom if needed, per design
 */
const getTextProps = (size: 'sm' | 'lg', body?: string | JSX.Element) => {
  const textRankBySize = {
    sm: { desktop: 2, mobile: 3 },
    lg: { desktop: 1, mobile: 2 },
  };
  return {
    rank: textRankBySize[size].mobile as TextRank,
    marginBottom: body ? spacer(2) : 0,
    responsiveProps: {
      [mediaQueries.gte.sm]: {
        rank: textRankBySize[size].desktop,
        marginBottom: body ? spacer(3) : 0,
      },
    },
  };
};

/** lineHeight is updated here to better center the glyph and subheader text */
const subheaderClassName = css({
  ...getStyleObjectFromFontStyle({
    ...typography.header[5],
    lineHeight: { desktop: 14, mobile: 12 },
  }),
});

const PageHeader = ({
  subheader,
  header,
  body,
  glyph,
  size = 'lg',
  ...rest
}: PageHeaderProps) => {
  const textProps = getTextProps(size, body);
  return (
    <Box
      flexDirection="column"
      marginBottom={spacer(2)}
      responsiveProps={{
        [mediaQueries.gte.sm]: {
          marginBottom: spacer(3),
        },
      }}
      {...rest}
    >
      <Box marginBottom={spacer(1)} verticalAlign="center">
        <Glyph type={glyph} />
        <Text
          header
          rank={5}
          margin={margin(0, 0, 0, spacer(1))}
          className={subheaderClassName}
        >
          {subheader}
        </Text>
      </Box>
      <Text header {...textProps}>
        {header}
      </Text>
      {body ? (
        <Text rank={2} marginBottom={0}>
          {body}
        </Text>
      ) : null}
    </Box>
  );
};

export default PageHeader;
