import { CALL_API } from 'redux-api-middleware';
import _ from 'lodash';
import querystring from 'querystring';

const appendQueryParams = (pathname, query) => {
  if (!query || _.isEmpty(query)) {
    return pathname;
  }

  return [pathname, querystring.stringify(query)].join('?');
};

const requestMethodTypes = {
  GET: 'GET',
  PUT: 'PUT',
  POST: 'POST',
  DELETE: 'DELETE',
};

const defaultBodySerializer = JSON.stringify;
const defaultDataProvider = () => ({ data: {} });

// return dataProvider and bodySerializer
const getConfiguration = requestUtil => {
  if (_.isPlainObject(requestUtil)) {
    const {
      dataProvider = defaultDataProvider,
      bodySerializer = defaultBodySerializer,
    } = requestUtil;
    return { dataProvider, bodySerializer };
  } else if (_.isFunction(requestUtil)) {
    return { dataProvider: requestUtil, bodySerializer: defaultBodySerializer };
  }
  throw Error('Invalid requestUtil type.');
};

const requestDataMiddleware = ({ getState }) => next => action => {
  if (!action[CALL_API] || !action[CALL_API].requestUtil) {
    return next(action);
  }

  const { requestUtil } = action[CALL_API];
  const { dataProvider, bodySerializer } = getConfiguration(requestUtil);
  const { data } = dataProvider(getState());

  if (data) {
    switch (action[CALL_API].method) {
      case requestMethodTypes.GET:
      case requestMethodTypes.DELETE: {
        /**
         * Append data as query params
         */
        action[CALL_API].endpoint = appendQueryParams(
          action[CALL_API].endpoint,
          data
        );
        break;
      }

      case requestMethodTypes.POST:
      case requestMethodTypes.PUT: {
        /**
         * Injects data into request body
         */
        action[CALL_API].body = bodySerializer({
          ...(action[CALL_API].body || {}),
          ...data,
        });
        break;
      }

      default:
        throw Error('Invalid request method type');
    }
  }

  delete action[CALL_API].requestUtil;
  return next(action);
};

export default requestDataMiddleware;
