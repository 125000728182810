import React from 'react';
import { css } from 'glamor';

import getStyles from './getStyles';

const DocumentViewerControlEntry = ({ children, last }) => (
  <div className={css(getStyles({ last }))}>{children}</div>
);

export default DocumentViewerControlEntry;
