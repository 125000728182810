import _ from 'lodash';
import { globalProps } from '../createGlobalPropsFilter/constants';
import createGlobalPropsFilter from '../createGlobalPropsFilter';

const omitGlobalProps = props =>
  createGlobalPropsFilter({
    props,
    propKeys: _.keys(globalProps),
    filterFn: _.omit,
  });

export default omitGlobalProps;
