import React from 'react';
import { css } from 'glamor';

import { UnreachableCaseError } from '@fabrictech/definitely-fabric';
import { spacer, color, border } from '@fabrictech/design-tokens';
import { AsLinkProps } from '../../types';
import { Box, Button, TextMarkdown, Icon, Text, Switch } from '../..';
import { SwitchProps } from '../Switch/Switch';

const { borderStyle } = border;

const minHeight = spacer(9);

type MenuItemDescriptionProps = {
  itemType: 'describe';
  description: string;
};
type MenuItemButtonProps = AsLinkProps & { itemType: 'button'; title: string };
type MenuItemSwitchProps = SwitchProps & { itemType: 'switch'; title: string };
type MenuItemProps =
  | MenuItemButtonProps
  | MenuItemSwitchProps
  | MenuItemDescriptionProps;

type MenuItemListProps = {
  menuItems: Array<MenuItemProps>;
};

/** MenuItem that is a Button, usually used to navigate to another screen */
const MenuItemButton = ({ title, itemType, ...rest }: MenuItemButtonProps) => (
  <Box
    flexDirection="column"
    width="100%"
    height={minHeight}
    verticalAlign="center"
  >
    <Button
      rank="secondary"
      borderless
      hasShadow={false}
      width="100%"
      align="left"
      marginBottom={0}
      paddingLeft={0}
      paddingRight={0}
      {...rest}
    >
      <Box flexDirection="row" align="stretch" width="100%">
        <Text marginBottom={0}>{title}</Text>
        <Icon size="sm" type="arrowRight" />
      </Box>
    </Button>
  </Box>
);

/** MenuItem that is a Switch, usually used to toggle something on/off */
const MenuItemSwitch = ({ title, itemType, ...rest }: MenuItemSwitchProps) => (
  <Box
    flexDirection="column"
    width="100%"
    height={minHeight}
    verticalAlign="center"
  >
    <Box
      flexDirection="row"
      verticalAlign="center"
      align="stretch"
      width="100%"
    >
      <Text marginBottom={0}>{title}</Text>
      <Switch {...rest} />
    </Box>
  </Box>
);

/** MenuItem that is descriptive text content */
const MenuItemDescription = ({ description }: MenuItemDescriptionProps) => {
  return (
    <Box paddingTop={spacer(5)}>
      <TextMarkdown childProps={{ Text: { rank: 3 } }}>
        {description}
      </TextMarkdown>
    </Box>
  );
};

/** A list of menu items. Can be a Button, Switch, or descriptive text */
const MenuItemList = ({ menuItems }: MenuItemListProps) => {
  const bottomBorderClassName = css({
    borderStyle,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: color.border.base,
  });

  const renderItem = (item: MenuItemProps) => {
    switch (item.itemType) {
      case 'button':
        return <MenuItemButton {...item} />;
      case 'switch':
        return <MenuItemSwitch {...item} />;
      case 'describe':
        return <MenuItemDescription {...item} />;
      default:
        throw new UnreachableCaseError(item);
    }
  };
  return (
    <Box flexDirection="column" align="stretch">
      {menuItems.map((item, index) => {
        const boxClassName =
          index < menuItems.length - 1 ? bottomBorderClassName : null;
        return (
          item && (
            <Box
              key={index}
              width="100%"
              verticalAlign="center"
              className={boxClassName}
            >
              {renderItem(item)}
            </Box>
          )
        );
      })}
    </Box>
  );
};

export default MenuItemList;
