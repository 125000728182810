import React from 'react';
import { css } from 'glamor';
import _ from 'lodash';

import { layout } from '@fabrictech/design-tokens';

import getStylesFromProps from './getStyles';
import createGetStylesWithGlobalProps from '../../utils/createGetStylesWithGlobalProps/createGetStylesWithGlobalProps';
import { GlobalProps, ResponsiveProps } from '../../types';
import { joinResponsiveProps } from '../../utils/joinResponsiveProps';

const getStyles = createGetStylesWithGlobalProps(getStylesFromProps);

export type LayoutItemProps = GlobalProps & {
  children?: React.ReactNode;
  /** Number of columns to span */
  span?: number;
  /** Number of columns to the left to offset */
  offset?: number;
  /** Order of the element */
  order?: number;
  /** Number of grid columns (inherits from LayoutContainer) */
  columns?: number;
  /** Width of the gutters in the grid (inherits from LayoutContainer) */
  gutterWidth?: string | number;
  /** Responsive Props to be set on LayoutItem (inherits from LayoutContainer) */
  layoutItemResponsiveProps?: ResponsiveProps;
};

/** `LayoutItem` is responsible for the horizontal spacing, width, and position of items */
export const LayoutItem = ({
  children,
  span = 12,
  offset = 0,
  columns = 12,
  gutterWidth = layout.gutterWidth,
  layoutItemResponsiveProps = {},
  responsiveProps = {},
  ...rest
}: LayoutItemProps) => {
  const className = css(
    getStyles({
      span,
      offset,
      columns,
      gutterWidth,
      responsiveProps: _.isEmpty(layoutItemResponsiveProps)
        ? responsiveProps
        : joinResponsiveProps({
            layoutItemResponsiveProps,
            responsiveProps,
          }),
      ...rest,
    })
  ).toString();
  return <div className={className}>{children}</div>;
};

export default LayoutItem;
