import { spacer } from '../layout';

/** We use Size to control the size of icons - this doens't map to any CSS attributes directly */
export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

/**
 * Helper type to essentially pick a subset of Size.
 * Useful for typing components that should only be created with certain Sizes,
 * but which wrap a component (for ex, Button) which can use any Size
 *
 * usage:
 *
 * type MyComponentSize = PartialSize<'sm' | 'md'>;   // resolves to "sm" | "md"
 * const validPartialSize: MyComponentSize = 'sm';    // ok
 * const invalidPartialSize: MyComponentSize = 'xxl'; // Error: Type '"xxl"' is not assignable to type '"sm" | "md"'.
 *
 * type IncorrectPartialSize = PartialSize<'o'>;      // Error: Type '"o"' does not satisfy the constraint 'Size'.
 */
export type PartialSize<P extends Size> = {
  [key in Size]: key extends P ? key : never;
}[P];

const glyph = spacer(1.5);

const icon: { [key in Size]: number } = {
  xs: spacer(2),
  sm: spacer(3),
  md: spacer(4),
  lg: spacer(5),
  xl: spacer(7),
  xxl: spacer(7),
};

const illustration: { [key in Size]: number } = {
  xs: spacer(5),
  sm: spacer(7),
  md: spacer(9),
  lg: spacer(12),
  xl: spacer(15),
  xxl: spacer(20),
};

const button: { [key in Size]: { height: number; width: number } } = {
  xs: { width: spacer(20), height: spacer(4) },
  sm: { width: spacer(20), height: spacer(4) },
  md: { width: spacer(28), height: spacer(6) },
  lg: { width: spacer(65), height: spacer(8) },
  xl: { width: spacer(75), height: spacer(8) },
  xxl: { width: spacer(75), height: spacer(8) },
};

export type LogoSize = PartialSize<'xs' | 'sm' | 'md'>;

const logo: {
  [key in LogoSize]: { width: number; height: number };
} = {
  xs: { width: 40, height: 12 },
  sm: { width: 80, height: 24 },
  md: { width: 130, height: 40 },
};

/**
 * Gets a size relative to the starting size
 *
 * @param {Size} size - starting size
 * @param {number} change - amount and direction to move
 * @return {Size} the relative size
 *
 * @example
 * // returns 'sm'
 *  getRelativeSize({size: 'md', change: -1 });
 *
 */
const getRelativeSize = ({
  size,
  change,
}: {
  size: Size;
  change: number;
}): Size => {
  const sizeArray = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as Size[];
  const index = sizeArray.indexOf(size) + change;
  if (index < 0) {
    return sizeArray[0];
  }
  if (index > sizeArray.length - 1) {
    return sizeArray[sizeArray.length - 1];
  }
  return sizeArray[index];
};

export const sizes = {
  glyph,
  icon,
  illustration,
  button,
  logo,
  getRelativeSize,
} as const;
