import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'glamor';

import { color } from '@fabrictech/design-tokens';

import getStyles from './getStyles';

import Icon from '../../../Icon';

const closeButtonClassName = css(getStyles());

const OverlayToastCloseButton = ({ closeToast }) => (
  <div onClick={closeToast} className={closeButtonClassName}>
    <Icon type="x" color={color.font.inverse.primary} size="sm" />
  </div>
);

OverlayToastCloseButton.propTypes = {
  /** Function to close the toast */
  closeToast: PropTypes.func,
};

OverlayToastCloseButton.defaultProps = {};

export default OverlayToastCloseButton;
