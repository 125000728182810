import React from 'react';

import { inputTypes } from '../../../utils/constants';
import EventQuestionAdapter from './EventQuestionAdapter';
import ValueQuestionAdapter from './ValueQuestionAdapter';
import ExclusiveOptionQuestionAdapter from './ExclusiveOptionQuestionAdapter';
import FabricQuestionGroupAdapter from './FabricGroupQuestionAdapter';
import AddressQuestionAdapter from './AddressQuestionAdapter';

/**
 * If `ValueAdapter` is wanted, returns a composite adapter container it and
 * the `EventAdapter`.
 *
 * Otherwise, just returns the `EventAdapter`.
 */
const getEventValueQuestionAdapter = adaptValuesForPremiumAu => {
  if (!adaptValuesForPremiumAu) return EventQuestionAdapter;

  const EventValueQuestionAdapter = ({ render: renderInput, ...restProps }) => (
    <EventQuestionAdapter
      {...restProps}
      render={eventHandlerProps => (
        <ValueQuestionAdapter {...eventHandlerProps} render={renderInput} />
      )}
    />
  );
  return EventValueQuestionAdapter;
};

/**
 * Creates a getter for our `QuestionAdapters`. QuestionAdapters are the layer
 * between the `questionnaire` logic in `Question` and the presentational,
 * platform specific `Field`/`Input` components. They are responsible for
 * handling things like user triggered events, modifying options, etc.
 *
 * QuestionAdapters all take a render prop named, appropriately, `render`. They
 * modify their props as necessary, and then pass a full props object to
 * `render`. This makes them easy to compose: multiple QuestionAdapters can be
 * chained together by spreading the parameter of the outer QuestionAdapter's
 * `render` function as props on the inner QuestionAdapter. Therefore, a
 * QuestionAdapter can contain 0 or more other QuestionAdapters.
 *
 * These QuestionAdapters can be different from one form instance to another.
 * Therefore, this creator takes a few parameters, and creates new components,
 * which are accessible through the getter it returns. This patterns allows us
 * to avoid recreating these components during render cycles, which would break
 * things.
 *
 * TODO: As this is all non-presentational logic, we might want to consider
 * migrating these to custom hooks. This work is currently blocked by
 * QuestionAdapters that depend on Redux, as `www` is currently stuck on a
 * version of react-redux that doesn't support `useSelector`/`useDispatch`.
 */
const createGetQuestionAdapter = ({
  adaptValuesForPremiumAu,
  RemoteOptionsQuestionAdapter,
  AddressAutocompleteQuestionAdapter,
} = {}) => {
  // conditionally composes the `EventAdapter` and `ValueAdapter`
  const EventValueQuestionAdapter = getEventValueQuestionAdapter(
    adaptValuesForPremiumAu
  );

  // multiselect must support exclusiveOption constraints
  const MultiselectQuestionAdapter = ({
    render: renderInput,
    ...restProps
  }) => (
    <EventValueQuestionAdapter
      {...restProps}
      render={eventValueProps => (
        <ExclusiveOptionQuestionAdapter
          {...eventValueProps}
          render={eventValueExclusiveOptionProps =>
            renderInput(eventValueExclusiveOptionProps)
          }
        />
      )}
    />
  );

  // assessmentFactorSearch requires a special adapter to support remote options
  const AssessmentFactorSearchQuestionAdapter = ({
    render: renderInput,
    ...restProps
  }) => {
    if (!RemoteOptionsQuestionAdapter) {
      throw new Error(
        'Attempted to render assessmentFactorSearch without RemoteOptionsAdapter'
      );
    }

    return (
      <RemoteOptionsQuestionAdapter
        {...restProps}
        render={remoteOptionsProps => (
          <EventValueQuestionAdapter
            {...remoteOptionsProps}
            render={remoteOptionEventValueProps =>
              renderInput(remoteOptionEventValueProps)
            }
          />
        )}
      />
    );
  };

  const StreetAddressAutocompleteQuestionAdapter = ({
    render: renderInput,
    ...restProps
  }) => {
    if (!AddressAutocompleteQuestionAdapter) {
      throw new Error(
        'Attempted to render address without AddressAutocompleteQuestionAdapter'
      );
    }

    return (
      <AddressAutocompleteQuestionAdapter
        {...restProps}
        render={addressAutoProps => (
          <EventQuestionAdapter
            {...addressAutoProps}
            render={addressAutoEventProps => (
              <AddressQuestionAdapter
                {...addressAutoEventProps}
                render={addressAutoEventAddProps =>
                  renderInput(addressAutoEventAddProps)
                }
              />
            )}
          />
        )}
      />
    );
  };

  // Some inputs don't require any adapting. This is a render prop no-op
  const PassThroughQuestionAdapter = ({ render: renderInput, ...restProps }) =>
    renderInput(restProps);

  // getter, mapping an inputType string to an Adapter
  const getQuestionAdapter = type => {
    switch (type) {
      case inputTypes.password:
      case inputTypes.text:
      case inputTypes.date:
      case inputTypes.number:
        return EventQuestionAdapter;
      case inputTypes.singleSelection:
        return EventValueQuestionAdapter;
      case inputTypes.multiSelection:
        return MultiselectQuestionAdapter;
      case inputTypes.assessmentFactorSearch:
        return AssessmentFactorSearchQuestionAdapter;
      case inputTypes.fabricGroup:
        return FabricQuestionGroupAdapter;
      case inputTypes.section:
      case inputTypes.group:
      case inputTypes.fabricGroupItem:
      case inputTypes.fabricStruct:
      case inputTypes.label:
      case inputTypes.assessmentFactorGroup:
        return PassThroughQuestionAdapter;
      case inputTypes.streetAddress:
        return StreetAddressAutocompleteQuestionAdapter;
      default:
        console.error(
          `Could not find a QuestionAdapter component corresponding to inputType "${type}"`
        );
        return PassThroughQuestionAdapter;
    }
  };

  return getQuestionAdapter;
};

export default createGetQuestionAdapter;
