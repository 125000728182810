import { spacer, sizes } from '@fabrictech/design-tokens';

import { inputPlaceholderStyles } from '../../sharedStyles/vars/input';

const iconPadding = sizes.icon.sm + spacer(2);

const getOpacity = ({
  hasLabel,
  hasValue,
  disabled,
}: {
  hasValue: boolean;
  hasLabel: boolean;
  disabled: boolean;
}): number | undefined => {
  if (hasLabel && !hasValue) {
    // Hides placeholder when there's a label and nothing is selected
    return 0;
  }
  if (disabled) {
    // Opacity for disabled state is handled by InputWrapper
    return undefined;
  }
  return 1;
};

const getStyles = ({
  hasValue,
  hasLabel,
  disabled,
}: {
  hasValue: boolean;
  hasLabel: boolean;
  disabled: boolean;
}) => ({
  cursor: 'pointer',
  marginRight: iconPadding * -1,
  paddingRight: iconPadding + spacer(2),
  textAlignLast: 'left',
  ...(hasValue ? {} : inputPlaceholderStyles),
  opacity: getOpacity({ hasLabel, hasValue, disabled }),
  zIndex: 1,
});

export default getStyles;
