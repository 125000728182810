import React from 'react';
import { css } from 'glamor';
import Tippy from '@tippy.js/react';

import _ from 'lodash';

import { Size, spacer } from '@fabrictech/design-tokens';

import {
  getTooltipStyles,
  getArrowStyles,
  getArrowAfterStyles,
} from './getStyles';

import Box from '../Box';

import TextMarkdownBase from '../TextMarkdown/components/TextMarkdownBase';
import { TextMarkdownProps } from '../TextMarkdown';
import {
  getTextComponents,
  tagNameToComponentsMapBase,
} from '../TextMarkdown/utils';

// The default `TextMarkdown` component can create tooltips from markdown, and if imported/used here, creates a cyclical dependency.
// We create our own on top of `TextMarkdownBase` to avoid it
const TextMarkdown = (props: TextMarkdownProps) => (
  <TextMarkdownBase
    getComponents={textProps => ({
      ...getTextComponents(textProps),
      ...tagNameToComponentsMapBase,
    })}
    {...props}
  />
);

/* The Tooltip component is wrapping @tippy.js/react, which uses an existing CSS stylesheet.
 * The following code defines a custom theme using `themeName` and then individually injects
 * the CSS rules to apply the theme using the `global` method from glamor.
 */
const themeName = `fabric-${_.uniqueId('tooltipTheme')}`;
css.global(`.tippy-tooltip.${themeName}-theme`, getTooltipStyles());
css.global(
  `.tippy-popper[x-placement^='top'] .${themeName}-theme .tippy-arrow`,
  getArrowStyles({ position: 'top' })
);
css.global(
  `.tippy-popper[x-placement^='bottom'] .${themeName}-theme .tippy-arrow`,
  getArrowStyles({ position: 'bottom' })
);
css.global(
  `.tippy-popper[x-placement^='top'] .${themeName}-theme .tippy-arrow:after`,
  getArrowAfterStyles({ position: 'top' })
);
css.global(
  `.tippy-popper[x-placement^='bottom'] .${themeName}-theme .tippy-arrow:after`,
  getArrowAfterStyles({ position: 'bottom' })
);

/**
 * Tooltips appear when the user hovers over the target text or icon.
 * The tooltip houses additional text information relavent to the associated copy or element.
 * */
const Tooltip = ({
  children,
  content,
  size = 'md',
}: {
  /** Children */
  children: React.ReactElement;
  /** Body content for the tooltip */
  content: string;
  /** Size of the tooltip text and padding */
  size?: Size;
}) => {
  const textRank = size === 'sm' ? 3 : 2;
  const padding = size === 'sm' ? spacer(2) : spacer(3);
  const marginBottom = spacer(3);
  return (
    <Tippy
      theme={themeName}
      arrow={true}
      content={
        // Using negative margin to offset the marginBottom of the last Text item
        <Box padding={padding} marginBottom={-marginBottom}>
          <TextMarkdown childProps={{ Text: { rank: textRank, marginBottom } }}>
            {content}
          </TextMarkdown>
        </Box>
      }
    >
      <span>{children}</span>
    </Tippy>
  );
};
export default Tooltip;
