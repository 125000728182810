import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import memoizeById from '../../utils/memoizeById';
import createLocalFormQuestion from './createLocalFormQuestion';

/**
 *
 * @typedef {object} LocalFormConfig
 * @property {object} [questionTransformers] Maps questionIds to their transformers.
 * @property {object} [QuestionComponent] Component to render a single _Question_.
 *    Defaulted to a standard-form rendering state.
 *    Left as an option to support more complex question rendering logic.
 * @property {object} [masks] see `createLocalFormQuestion`
 * @property {Function} [transformQuestionData] see `createLocalFormQuestion`
 * @property {Function} [secureQuestions] see `createLocalFormQuestion`
 * @property {boolean} [handleTextInputChangeEvent] see `createLocalFormQuestion`
 * @property {Function} [getFieldForInputType] see `createLocalFormQuestion`
 * @property {Function} [getInputForInputType] see `createLocalFormQuestion`
 * @property {object} [customQuestionAdapters] see `createLocalFormQuestion`
 * @property {object} [localFormInputProps] see `createLocalFormQuestion`
 * @property {boolean} [adaptValuesForPremiumAu]
 */

/**
 * HOC to return the local questionnaire _Form_ component.
 *
 * @param {LocalFormConfig} options An options config
 * @return {React.ComponentType<{
 *  questionnaireInstance: object;
 *  onUpdateData: Function;
 *  sectionStates?: { [sectionId: string]: 'active' | 'inactive' };
 *  onSectionButtonClick?: (sectionId: string) => void;
 *  renderChildren?: (children: React.ReactNode) => JSX.Element;
 * }>}
 */
const createLocalForm = ({
  // Form config
  questionTransformers = {},

  // Question config
  masks,
  transformQuestionData,
  secureQuestions,
  handleTextInputChangeEvent,
  getFieldForInputType,
  getInputForInputType,
  adaptValuesForPremiumAu,
  customQuestionAdapters,
  localFormInputProps,
  QuestionComponent = createLocalFormQuestion({
    masks,
    transformQuestionData,
    secureQuestions,
    handleTextInputChangeEvent,
    getFieldForInputType,
    getInputForInputType,
    adaptValuesForPremiumAu,
    customQuestionAdapters,
    localFormInputProps,
  }),
} = {}) => {
  /**
   * Memoized function to wrap an transformer around the generic QuestionComponent
   * @param {number} id The question id
   * @return {object} The transformed component
   */
  const transformQuestionComponent = memoizeById(id => {
    const transformer = questionTransformers[id] || _.identity;
    return transformer(QuestionComponent);
  });

  /**
   * Curried function to render out a component post-transformer.
   * @param {object} props Props to pass down to the transformed QuestionComponent.
   * @param {number} id The question id
   * @return {object} The transformed component
   */
  const renderQuestionById = props => id => {
    const TransformedQuestionComponent = transformQuestionComponent(id);

    return (
      <TransformedQuestionComponent
        {...props}
        id={id}
        key={id}
        testID={`Question-${id}`}
      />
    );
  };

  /**
   * Simple top level component, iterates through its questions and renders them.
   * @param {object} props See propTypes
   * @return {object} A React Element
   */
  const LocalForm = ({
    questionnaireInstance,
    onUpdateData,
    sectionStates = {},
    onSectionButtonClick,
    renderChildren = _.identity,
  }) =>
    renderChildren(
      questionnaireInstance.questions.map(
        renderQuestionById({
          onUpdateData,
          questionnaireInstance,
          sectionStates,
          onSectionButtonClick,

          // pass the memoized render to the question component as a prop,
          // to support nested questions
          renderQuestionById,
        })
      )
    );
  LocalForm.propTypes = {
    questionnaireInstance: PropTypes.object.isRequired,
    onUpdateData: PropTypes.func.isRequired,
  };

  LocalForm.displayName = 'LocalForm';

  return LocalForm;
};

export default createLocalForm;
