import React from 'react';
import { logosAlt, LogoSize } from '@fabrictech/design-tokens';
import { mediaQueries } from '@fabrictech/tapestry';
import { css } from 'glamor';
import { Height, Width } from '../Box/types';
import Box from '../Box';

type LogoAlternateProps = {
  widthMobile?: Width;
  heightMobile?: Height;
  width?: Width;
  height?: Height;
  marginBottomMobile?: number | string;
  marginBottom?: number | string;
  size?: LogoSize;
  inverse?: boolean;
};

const LogoAlternateClassName = css({
  ' .img-responsive': {
    width: '100%',
    height: 'auto',
  },
});

/** Displays the Fabric wordmark */
const LogoAlternate = ({
  size = 'md',
  inverse = false,
  marginBottomMobile,
  marginBottom,
  widthMobile,
  heightMobile,
  width,
  height,
}: LogoAlternateProps) => {
  return (
    <Box
      width={widthMobile}
      height={heightMobile}
      marginBottom={marginBottomMobile}
      className={LogoAlternateClassName}
      responsiveProps={{
        [mediaQueries.gte.lg]: {
          width,
          height,
          marginBottom,
        },
      }}
    >
      <img
        src={logosAlt.wordmark[inverse ? 'inverse' : 'base'][size || 'md']}
        className="img-responsive"
        alt="Fabric by Gerber Life Logo"
      />
    </Box>
  );
};

export default LogoAlternate;
