import React from 'react';
import { css } from 'glamor';

import Text, { TextProps } from '../../../Text/Text';
import tags from '../../../../constants/tags';

import { getListStyles } from './getStyles';

export type ListComponentProps = TextProps & {
  ordered: boolean;
};

const ListComponent = ({
  ordered,
  className,
  ...props
}: ListComponentProps) => (
  <Text
    as={ordered ? tags.ol : tags.ul}
    className={css(className, getListStyles({ ordered }))}
    {...props}
  />
);

export default ListComponent;
