import _ from 'lodash';

const ONE_THOUSAND = 10e2;
const ONE_MILLION = 10e5;

const THOUSAND = 'K';
const MILLION = 'M';

// 250000 -> $250K
// 1000000 -> $1M
// 1500000 -> $1.5M
export const parseToAbbreviatedDollarString = (value: number) => {
  if (!Number.isFinite(value)) {
    return null;
  }
  const isMillionOrMore = value >= ONE_MILLION;
  let multiplierValue: string | undefined;
  if (isMillionOrMore) {
    const integerPart = Math.floor(value / ONE_MILLION);
    const decimalNum = _.trimEnd(String(value % ONE_MILLION), '0');
    const decimalPart = decimalNum ? `.${decimalNum}` : '';

    multiplierValue = `${integerPart}${decimalPart}`;
  } else {
    multiplierValue = String(Math.floor(value / ONE_THOUSAND));
  }
  const multiplierLetter = isMillionOrMore ? MILLION : THOUSAND;

  return `$${multiplierValue}${multiplierLetter}`;
};

// 5.99 -> $5.99
// 5.9 -> $5.90
// 5 -> $5.00
export const parseToDollarsAndCentsString = (value: number) => {
  if (!Number.isFinite(value)) {
    return null;
  }
  return `$${Number(value).toFixed(2)}`;
};
