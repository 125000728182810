import { color, spacer } from '@fabrictech/design-tokens';

import { descendant, lastChild, before } from '../../../../utils/css/selectors';
import tags from '../../../../constants/tags';
import { margin, padding } from '../../../../utils/css/values';

const counterName = 'type-sp-list-counter';

export const getListStyles = ({ ordered }: { ordered: boolean }) => ({
  margin: margin(0, 0, spacer(4), 0),
  padding: 0,
  listStyleType: 'none',
  counterReset: counterName,
  [descendant(tags.li)]: {
    counterIncrement: counterName,
    margin: margin(0, 0, spacer(1), 0),
    position: 'relative',
    padding: padding(0, 0, 0, spacer(4)),
    listStyle: 'none',
    [before]: {
      content: ordered ? `"counter(${counterName})".` : '•',
      color: color.base.accent,
      fontWeight: 'bold',
      display: 'inline-block',
      position: 'absolute',
      left: 0,
      top: 0,
    },
    [lastChild]: {
      marginBottom: 0,
    },
  },
});
