import React from 'react';
import { css, Rule } from 'glamor';

import { color, spacer, border } from '@fabrictech/design-tokens';
import { GlobalProps } from '../../types';
import mediaQueries from '../../sharedStyles/vars/mediaQueries';

import Box from '../Box';
import Text from '../Text';
import Tooltip from '../Tooltip';
import Icon from '../Icon';

export type ListItem = {
  label: string;
  value: string;
  tooltip?: string;
};

export type DescriptionListProps = GlobalProps & {
  listItems: ListItem[];
  listItemComponent?: React.FunctionComponent<{
    listItem: ListItem;
    key: number;
  }>;
  className?: Rule;
};

export type DescriptionListItemProps = GlobalProps & {
  listItem: ListItem;
  key: number;
};

const listTooltipContainerClassName = css({
  position: 'absolute',
  top: 0,
  right: 0,
});

const DescriptionListTooltip = ({ content }: { content: string }) => (
  <Box verticalAlign="center" className={listTooltipContainerClassName}>
    <Box
      height={spacer(1)}
      width={spacer(1)}
      align="center"
      verticalAlign="center"
    >
      <Tooltip content={content}>
        <Box padding={spacer(1)}>
          <Icon type="info" size="sm" />
        </Box>
      </Tooltip>
    </Box>
  </Box>
);

const listItemContainerClassName = css({
  justifyContent: 'space-between',
});
const listItemValueContainerClassName = css({
  ...border,
  borderColor: color.border.base,
});

const DescriptionListItem = ({
  listItem,
  ...rest
}: DescriptionListItemProps) => {
  const { label, value, tooltip } = listItem;
  return (
    <Box
      height={spacer(5)}
      verticalAlign="center"
      marginBottom={spacer(2)}
      className={listItemContainerClassName}
      responsiveProps={{
        [mediaQueries.gte.sm]: {
          height: spacer(7),
        },
      }}
      {...rest}
    >
      <Text
        rank={2}
        marginBottom={0}
        responsiveProps={{
          [mediaQueries.gte.sm]: {
            header: true,
            rank: 3,
          },
        }}
      >
        <strong>{label}</strong>
      </Text>

      <Box height="100%">
        <Box
          align="center"
          verticalAlign="center"
          width={136}
          height="100%"
          backgroundColor={color.background.secondary}
          className={listItemValueContainerClassName}
          responsiveProps={{
            [mediaQueries.gte.sm]: {
              width: 260,
            },
          }}
        >
          <Text
            rank={2}
            marginBottom={0}
            responsiveProps={{
              [mediaQueries.gte.sm]: {
                header: true,
                rank: 3,
              },
            }}
          >
            <strong>{value}</strong>
          </Text>
        </Box>
        {tooltip ? <DescriptionListTooltip content={tooltip} /> : null}
      </Box>
    </Box>
  );
};

const DescriptionList = ({
  listItems,
  listItemComponent,
  className,
  ...rest
}: DescriptionListProps) => (
  <Box
    flexDirection="column"
    align="stretch"
    className={css({ flex: 1 }, className)}
    width="100%"
    {...rest}
  >
    {listItems.map((listItem, key) =>
      listItemComponent ? (
        React.createElement(listItemComponent, { listItem, key })
      ) : (
        <DescriptionListItem
          {...{
            listItem,
            key,
            /** Removes bottom margin from last element */
            marginBottom: key === listItems.length - 1 ? 0 : spacer(2),
          }}
        />
      )
    )}
  </Box>
);

export default DescriptionList;
