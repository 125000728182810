import { border, color } from '@fabrictech/design-tokens';

import { standardShadow } from '../../sharedStyles/vars/boxShadow';
import * as mediaQueries from '../../sharedStyles/vars/mediaQueries';
import { important } from '../../utils/css/values';
import getTextStyles from '../Text/getStyles';

const { borderRadius } = border;

const toastTypeToBackgroundColorMap = {
  default: color.base.accent,
  info: color.base.accent,
  success: color.base.success,
  warning: color.base.accent,
  error: color.base.error,
};

export const getBackgroundStyles = type => ({
  background: important(toastTypeToBackgroundColorMap[type]),
});

export const getToastStyles = () => ({
  ...getTextStyles({ rank: 2 }),
  color: color.background.primary,
  textAlign: 'center',
  borderRadius,
  boxShadow: standardShadow,
  [mediaQueries.lt.sm]: {
    borderRadius: 0,
  },
});
