import { useContext, useMemo } from 'react';
import { ResponsiveProps } from '@fabrictech/tapestry/types';
import ResponsiveContext from '../ResponsiveContext';
import { getRealQueryLt } from '../createGetStyles';

type TapestryComponentStyleProps = {
  responsiveProps: ResponsiveProps;
};

// Note, this hook does not do special logic for text sizing responsively since
// it's only used for <FormButton>
const useResponsiveContextForResponsiveProps = <
  Props extends TapestryComponentStyleProps
>({
  responsiveProps,
  ...styleProps
}: Props): TapestryComponentStyleProps => {
  const responsiveContext = useContext(ResponsiveContext);
  const fixedStyleProps = useMemo(() => {
    const { isLockedToMobileWidth, mobileBreakpoint } = responsiveContext;
    const {
      mobileBreakpoint: mobileObj,
      ...otherBreakpoints
    } = responsiveProps;
    if (isLockedToMobileWidth) {
      return {
        ...styleProps,
        ...mobileObj,
        responsiveProps: otherBreakpoints,
      };
    } else {
      return {
        ...styleProps,
        responsiveProps: {
          [getRealQueryLt(mobileBreakpoint)]: mobileObj,
          ...otherBreakpoints,
        },
      };
    }
  }, [responsiveContext, responsiveProps, styleProps]);
  return fixedStyleProps;
};

export default useResponsiveContextForResponsiveProps;
