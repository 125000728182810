import React from 'react';
import { css } from 'glamor';
import _ from 'lodash';
import SmoothCollapse from 'react-smooth-collapse';

import { color, spacer, TextRank, Align } from '@fabrictech/design-tokens';
import { QuestionStatus } from '@fabrictech/questionnaire/utils/constants';

import { getBaseStyles, getGroupTitleStyles } from './getStyles';

import { LOGROCKET_HIDE_CLASS_NAME } from '../../constants/logrocket';
import Box from '../Box';
import Text from '../Text';
import TextMarkdown from '../TextMarkdown';
import Tooltip from '../Tooltip';
import Icon from '../Icon';
import FormFieldFlag from './components/FormFieldFlag';
import { mapOptionsToInputOption } from '../InputOption';

const groupTitleClassName = css(getGroupTitleStyles());

const groupTitleProps = {
  header: false,
  rank: 2 as TextRank,
  color: color.font.primary,
  className: groupTitleClassName,
};

const captionProps = {
  textAlign: 'center' as Align,
  marginBottom: 0,
  header: true,
  rank: 3 as TextRank,
  color: color.font.primary,
};

const subcaptionProps = {
  textAlign: 'center' as Align,
  rank: 2 as TextRank,
  color: color.font.primary,
};

type FormFieldProps = {
  /** Children node */
  children?: React.ReactNode;
  /** Function that takes props and returns a React Element */
  render?: (props: unknown) => React.ReactNode | null;
  /** Caption for the FormField */
  caption?: string;
  /** Sub-caption for the FormField.  */
  subcaption?: string;
  /** Allows for use of markdown in `caption` and `subcaption`.
   * See `TextMarkdown` component for more info on markdown support */
  useMarkdown?: boolean;
  /** Whether or not it is a group title */
  isGroup?: boolean;
  /** Help content to appear in a tooltip next to the caption */
  tooltip?: string;
  /** Depth of nesting */
  depth?: number;
  /** Status of the question */
  status?: QuestionStatus;
  /** Flag to mark the FormField as containing sensitive information */
  isSecure?: boolean;
  /** inputDataTestName */
  inputDataTestName?: string;
  /** Children node */
  input?: React.ReactNode;
  /** Options */
  options?: Array<unknown>;
};

/** Combination of elements that provide enough information for the user and the proper input type to collect the right feedback. */
export const FormField = ({
  render = () => null,
  input: Input,
  inputDataTestName,
  isSecure,
  caption,
  subcaption = '',
  useMarkdown = false,
  tooltip,
  children,
  isGroup = false,
  status = 'valid',
  options,
}: FormFieldProps) => {
  if (Input) {
    // TODO: This a temporary part of our refactor. Should be removed once
    // we're confident that all instances that were passing the input are now
    // passing render props
    throw new Error(
      'FormField received invalid prop: `input`. Pass `render` instead.'
    );
  }

  // map our option props to our placeholder InputOption component
  const optionElements = mapOptionsToInputOption(options);

  // call the render prop, creating our optional inputElement
  const inputElement = render({
    marginBottom: spacer(7),
    children: optionElements,

    // Some of our legacy Inputs require option JSON, so we still pass it
    // TODO: remove when those have been refactored
    options,
  });

  const className = css(getBaseStyles({ isGroup })).toString();
  return (
    <div className={className}>
      {status ? <FormFieldFlag status={status} /> : null}
      {caption ? (
        <Box
          align="center"
          verticalAlign="center"
          marginBottom={isGroup ? 0 : spacer(3)}
        >
          {useMarkdown ? (
            <TextMarkdown
              childProps={{
                Text: { ...captionProps, ...(isGroup ? groupTitleProps : {}) },
              }}
            >
              {caption}
            </TextMarkdown>
          ) : (
            <Text {...captionProps} {...(isGroup ? groupTitleProps : {})}>
              {caption}
            </Text>
          )}
          {tooltip ? (
            <Box marginLeft={spacer(1)} marginRight={spacer(-2)}>
              <Tooltip content={tooltip}>
                <Icon type="info" size="sm" />
              </Tooltip>
            </Box>
          ) : null}
        </Box>
      ) : null}
      {subcaption ? (
        <SmoothCollapse expanded={subcaption !== ''}>
          {useMarkdown ? (
            <TextMarkdown childProps={{ Text: subcaptionProps }}>
              {subcaption}
            </TextMarkdown>
          ) : (
            <Text {...subcaptionProps}>{subcaption}</Text>
          )}
        </SmoothCollapse>
      ) : null}
      {inputElement ? (
        <div
          className={isSecure ? LOGROCKET_HIDE_CLASS_NAME : undefined}
          data-test={inputDataTestName}
        >
          {inputElement}
        </div>
      ) : null}
      <SmoothCollapse
        expanded={!_.isNil(children) && status !== 'working'}
        allowOverflowWhenOpen={true}
        heightTransition=".4s ease"
      >
        {children}
      </SmoothCollapse>
    </div>
  );
};

export default FormField;
