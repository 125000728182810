import _ from 'lodash';
import React from 'react';
import { Rule } from 'glamor';
import { InputToggle, InputOption } from '@fabrictech/tapestry';
import { SwatchWidget } from '../../types';
import getStyles from './getStyles';
import useResponsiveContext from '../../useResponsiveContext';

type TermLengthToggleProps = {
  hasExtendedTermStyles: boolean;
  options: SwatchWidget.TermLength[];
  onChange: (newTermLength: SwatchWidget.TermLength) => void;
  value: SwatchWidget.TermLength;
};

type CssClassnameObject = {
  toString: () => string;
};

const isCssClassnameObject = (cssRule: Rule): cssRule is CssClassnameObject =>
  _.has(cssRule, 'toString');

const getTermLengthOptionProps = (
  hasExtendedTermStyles: boolean,
  narrowButtonInteriorClassname: Rule
) => {
  if (
    isCssClassnameObject(narrowButtonInteriorClassname) &&
    hasExtendedTermStyles
  ) {
    return {
      className: narrowButtonInteriorClassname.toString(),
    };
  }
  return {};
};

const TermLengthToggle = ({
  hasExtendedTermStyles,
  onChange,
  options,
  value: valueProp,
}: TermLengthToggleProps) => {
  const {
    styles: { narrowButtonInteriorClassname },
  } = useResponsiveContext(getStyles);
  const termLengthOptionProps = getTermLengthOptionProps(
    hasExtendedTermStyles,
    narrowButtonInteriorClassname
  );
  const value = _.isString(valueProp) ? valueProp : `${valueProp}`;
  return (
    <InputToggle
      marginBottom={0}
      width="100%"
      helperText={undefined}
      id="term-length"
      onChange={onChange}
      value={value}
    >
      {options.map(termLength => (
        <InputOption key={termLength} value={termLength}>
          <span {...termLengthOptionProps}>{termLength}</span>
        </InputOption>
      ))}
    </InputToggle>
  );
};

export default TermLengthToggle;
