import { spacer } from '@fabrictech/design-tokens';
import {
  getStyleObjectFromFontStyle,
  mediaQueries,
  mergeResponsiveStyles,
  mergeTextStyles,
} from '@fabrictech/tapestry';
import {
  marketingParagraph,
  marketingHeader,
} from '@fabrictech/tapestry/components/MarketingText/getStyles';
import createGetStyles from '../createGetStyles';

const mobileBreakpoint = 'mobileBreakpoint';

// Ineligibility Container styles
const containerStyles = {
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'flex-start',
  marginTop: spacer(3.75),
  mobileBreakpoint: {
    marginTop: spacer(1.5),
    marginLeft: spacer(0.375),
    marginRight: spacer(0.375),
  },
};

// Ineligibility Title styles
const titleMarginStyles = {
  marginBottom: spacer(2.25),
  [mediaQueries.lt.sm]: {
    marginBottom: spacer(2.25),
  },
};
const titleFontStyles = mergeTextStyles(marketingHeader[2], marketingHeader[3]);

const titleFont = getStyleObjectFromFontStyle(
  titleFontStyles,
  mobileBreakpoint
);

const titleStyles = mergeResponsiveStyles(titleFont, titleMarginStyles);

// Ineligibility Caption styles
const captionMarginStyles = {
  marginBottom: spacer(4),
  [mediaQueries.lt.sm]: {
    marginBottom: spacer(4),
  },
};

const captionFontStyles = mergeTextStyles(
  marketingParagraph[2],
  marketingParagraph[3]
);

const captionFont = getStyleObjectFromFontStyle(
  captionFontStyles,
  mobileBreakpoint
);

const captionStyles = mergeResponsiveStyles(captionFont, captionMarginStyles);

const footerWrapperStyles = {
  marginTop: spacer(5),
  mobileBreakpoint: {
    marginTop: spacer(3.75),
  },
};

const footerTextStyles = {
  ...captionStyles,
};

const footerLinkFontStyles = mergeTextStyles(
  marketingParagraph[2],
  marketingParagraph[3]
);

const footerLinkStyles = getStyleObjectFromFontStyle(
  footerLinkFontStyles,
  mobileBreakpoint
);

const styles = {
  containerStyles,
  titleStyles,
  captionStyles,
  footerWrapperStyles,
  footerTextStyles,
  footerLinkStyles,
};

export default createGetStyles(styles);
