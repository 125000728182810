import _ from 'lodash';

import { Diff } from '@fabrictech/definitely-fabric';
import { breakpoints, Size } from '@fabrictech/design-tokens';
import {
  lt,
  gte,
  QueryType,
  PartialQuerySize,
} from '@fabrictech/tapestry/sharedStyles/vars/mediaQueries';

import { ResponsiveProps } from '@fabrictech/tapestry/types';

const mediaQueries = { lt, gte };
const breakpointKeys = _.without(_.keys(breakpoints), 'xs') as Diff<
  Size,
  'xs'
>[];

type PartialQueryType = Diff<QueryType, 'btwn'>;
const iterationOrderByQueryType: {
  [key in PartialQueryType]: PartialQuerySize[];
} = {
  lt: _.reverse([...breakpointKeys]),
  gte: breakpointKeys,
};

/**
 * This helper takes `responsiveProps` and "cascades" the styles by updating
 * the appropriate max-width and min-width breakpoints with the styles.
 *
 * TODO: update to handle `btwn` queryType
 */
export const cascadeResponsiveProps = (responsiveProps: ResponsiveProps) =>
  _.reduce(
    iterationOrderByQueryType,
    (accu, iterationOrder, _queryType) => {
      /**
       * Here we iterate over breakpoints that are ordered to properly "cascade" based on `queryType`.
       * We collate props in `propsToAdd` and build `allResponsiveProps` by setting `propsToAdd`
       * at the current mediaQuery/breakpoint and allowing any declared styles in `responsiveProps`
       * at the current mediaQuery/breakpoint to take precedence/overwrite styles in `propsToAdd`.
       */
      const { allResponsiveProps } = _.reduce(
        iterationOrder,
        (acc, breakpoint) => {
          const queryType = _queryType as PartialQueryType;
          const mediaQuery = mediaQueries[queryType][breakpoint];

          const propsToAdd = {
            ...acc.propsToAdd,
            ...responsiveProps[mediaQuery],
          };

          const currentResponsiveProps = {
            ...propsToAdd,
            ...responsiveProps[mediaQuery], // overwite any propsToAdd with those that exist in responsiveProps
          };

          return {
            propsToAdd,
            allResponsiveProps: {
              ...acc.allResponsiveProps,
              ...(_.isEmpty(currentResponsiveProps) // only keep breakpoints that have props
                ? {}
                : {
                    [mediaQuery]: currentResponsiveProps,
                  }),
            },
          };
        },
        { propsToAdd: {}, allResponsiveProps: {} }
      );
      return { ...accu, ...allResponsiveProps };
    },
    {}
  );

/**
 * This function combines the `layoutItemResponsiveProps` that are inherited from LayoutContainer
 * with `responsiveProps` set on the LayoutItem directly. `responsiveProps` set on the LayoutItem
 * directly take precedence over those inherited from LayoutContainer.
 */
export const joinResponsiveProps = ({
  layoutItemResponsiveProps = {},
  responsiveProps = {},
}: {
  layoutItemResponsiveProps: ResponsiveProps;
  responsiveProps: ResponsiveProps;
}) => {
  /**
   * When combining `layoutItemResponsiveProps` with `responsiveProps`,
   * we want any `responsiveProps` set on the LayoutItem to take precedence.
   * To facilitate this, we have to make sure those props/styles "cascade"
   * to all the appropriate breakpoints so they won't be overwritten by
   * any `layoutItemResponsiveProps` set at other breakpoints.
   */
  const allResponsiveProps: ResponsiveProps = cascadeResponsiveProps(
    responsiveProps
  );

  /**
   * `allResponsiveProps` may contain media queries at additional `gte` and `lt`
   * breakpoints, but we are only concerned with media queries that have been set
   * explicitly in either `layoutItemResponsiveProps` or `responsiveProps`, so here
   * we create an array with those explicitly set media queries.
   */
  const orderedMediaQueries = [...Object.values(lt), ...Object.values(gte)];
  const existingMediaQueries = _.filter(orderedMediaQueries, mediaQuery =>
    [..._.keys(layoutItemResponsiveProps), ..._.keys(responsiveProps)].includes(
      mediaQuery
    )
  );

  /**
   * Finally, we reduce over `existingMediaQueries` and combine `layoutItemResponsiveProps`
   * with `allResponsiveProps` (derived from `responsiveProps`).
   */
  return _.reduce(
    existingMediaQueries,
    (acc, mediaQuery) => ({
      ...acc,
      [mediaQuery]: {
        ...layoutItemResponsiveProps[mediaQuery],
        ...allResponsiveProps[mediaQuery],
      },
    }),
    {}
  );
};

export default joinResponsiveProps;
