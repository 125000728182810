export enum Schedule4RealInteraction {
  clickedSchedulingCta = 'clicked_scheduling_cta',
  clickedScheduleOverPhoneCta = 'clicked_schedule_over_phone_cta',
  clickedSchedule4RealAppointment = 'clicked_schedule4real_appointment',
  clickedBookSchedule4RealAppointmentCta = 'clicked_book_schedule4real_appointment_cta',
}

export interface Schedule4RealAppointmentDateLocalTime {
  date: string;
  localTime: string;
}
