import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { callApiAndIncrement } from '@fabrictech/redux-api';

import apis, { API_POST_ANALYTICS as apiKey } from './apis';
import { buildRecordByType } from './records';

const api = apis[apiKey];

/**
 * This hook returns a function that will build an Analytics record and then
 * dispatch it to the redux store. It was created by tracing the code path in
 * the `AnalyticsConnector`. This is an intermediate step until we have the time
 * to move the Analytics API reducer slice to rtk-query
 */
const useAnalyticsEvent = (type: keyof typeof buildRecordByType) => {
  const dispatch = useDispatch();
  const dispatchAnalyticsEvent = useCallback(
    (...args: Array<unknown>) => {
      // we know it's a function because this is only the apis[api]
      const actionCreator = callApiAndIncrement(apiKey, api) as Extract<
        ReturnType<typeof callApiAndIncrement>,
        Function
      >;
      const record = buildRecordByType[type](...args);
      return dispatch(actionCreator(record));
    },
    [dispatch, type]
  );
  return dispatchAnalyticsEvent;
};

export default useAnalyticsEvent;
