import { spacer } from '@fabrictech/design-tokens';
import { margin } from '../../../../utils/css/values';

/*
 * TODO
 * This should reference icon button somewhere
 */
const iconButtonWidth = '48px';
const horizontalPadding = spacer(2);

const getStyles = () => ({
  margin: margin(spacer(2), horizontalPadding, 0, 0),
  position: 'absolute',
  top: 0,
  right: 0,
  width: iconButtonWidth,
});

export default getStyles;
