import React from 'react';
import _ from 'lodash';
import InputOption from '../../../components/InputOption';

const incrementalOptionsGenerator = ops => {
  const {
    min,
    max,
    increment = 1,
    reverse = false,
    stringBefore,
    stringAfter,
    formatter,
    range,
  } = ops;
  const options = [];
  _.forEach(range || _.range(min, max + increment, increment), value => {
    options.push(
      <InputOption key={value} value={value}>
        {stringBefore}
        {formatter ? formatter(value) : value}
        {stringAfter}
      </InputOption>
    );
  });
  return reverse ? options.reverse() : options;
};

export default incrementalOptionsGenerator;
