import { storeRecord } from './actions';

const listener = buildRecord => ({ getState, dispatch }) => location => {
  const state = getState();
  const record = buildRecord(state, location);
  const action = storeRecord(record);
  dispatch(action);
};

export default listener;
