import { spacer, input } from '@fabrictech/design-tokens';

import tags from '../../constants/tags';
import { descendant, adjacentSibling } from '../../utils/css/selectors';

const getStyles = () => {
  return {
    alignItems: 'center',
    [descendant(tags.ul)]: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 0,
      justifyContent: 'center',
      listStyle: 'none',
      paddingLeft: 0,
      marginTop: 0,
    },
    [descendant(tags.li)]: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 0,
      height: input.layout.height,
      [adjacentSibling(tags.li)]: {
        marginLeft: spacer(4),
      },
    },
  };
};

export default getStyles;
