import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import * as optionsGenerators from '../../utils/optionsGenerators';
import InputDropdown from '../InputDropdown';
import { precisions } from './constants';

import InputGroup from '../InputGroup';
import * as mediaQueries from '../../sharedStyles/vars/mediaQueries';

const parsers = {
  [precisions.day]: value => {
    const [year = '', month = '', date = ''] = value.split('-');
    return { year, month, date };
  },
  [precisions.month]: value => {
    const [year = '', month = ''] = value.split('-');
    return { year, month };
  },
  [precisions.year]: year => ({ year }),
};

const formatters = {
  [precisions.day]: ({ year, month, date }) => [year, month, date].join('-'),
  [precisions.month]: ({ year, month }) => [year, month].join('-'),
  [precisions.year]: ({ year }) => year,
};

const fieldTypes = {
  date: 'date',
  month: 'month',
  year: 'year',
};

const fieldVisibilities = {
  [precisions.day]: {
    [fieldTypes.date]: true,
    [fieldTypes.month]: true,
    [fieldTypes.year]: true,
  },
  [precisions.month]: {
    [fieldTypes.month]: true,
    [fieldTypes.year]: true,
  },
  [precisions.year]: {
    [fieldTypes.year]: true,
  },
};

const getDaysInMonth = ({ year, month }) => {
  const yearMonth = formatters[precisions.month]({
    year: year || '2000',
    month: month || '01',
  });

  return moment(yearMonth, 'YYYY-MM').daysInMonth();
};

const getDateInYearAndMonth = ({ year, month, date }) => {
  const d = parseInt(date, 10) || 0;

  if (d === 0) {
    // if d is 0, it means the above parseInt didn't work, which means there
    // was no selection and this should effectively be a noop
    return '';
  }

  // otherwise, we format the allowed date, and upate that, if the previous
  // state's date is already valid this should effectively be a noop
  return _.padStart(Math.min(d, getDaysInMonth({ year, month })), 2, '0');
};

/** A composite component made up of three dropdown, month, date, and year.
 *
 * Behind the scenes, it coalesces the internal values of the three dropdowns into a single value to the onChange event.
 * */
export class InputDateDropdown extends Component {
  constructor(props) {
    super(props);

    const { value = '' } = props;
    this.state = parsers[this.props.precision](value);

    this.handleChange = this.handleChange.bind(this);
    this.getValue = this.getValue.bind(this);
  }

  hasValue() {
    return !_.some(_.values(this.state), _.isEmpty);
  }

  getValue() {
    return formatters[this.props.precision](this.state);
  }

  handleChange(key) {
    const { cumulativeUpdate, onChange, precision } = this.props;
    const shouldUpdateDate =
      precision === precisions.day && key !== fieldTypes.date;

    return value => {
      this.setState(
        prevState => ({
          ...(shouldUpdateDate && {
            // to calculate the right date, we need to merge the new key value
            // with current state, effectively creating a kind of "next state"
            [fieldTypes.date]: getDateInYearAndMonth({
              ...prevState,
              [key]: value,
            }),
          }),
          [key]: value,
        }),
        () => {
          if (cumulativeUpdate || this.hasValue()) {
            onChange(this.getValue());
          }
        }
      );
    };
  }

  render() {
    const { startingYearsAgo, endingYearsAgo, ...rest } = this.props;
    const { month, date, year } = this.state;
    const fieldVisibility = fieldVisibilities[this.props.precision];

    return (
      <InputGroup
        responsiveProps={{
          [mediaQueries.gte.sm]: { colSpans: [4, 4, 4] },
          [mediaQueries.lt.sm]: { colSpans: [12, 12, 12] },
        }}
        {...rest}
      >
        {fieldVisibility[fieldTypes.month] && (
          <InputDropdown
            label="Month"
            value={month}
            onChange={this.handleChange(fieldTypes.month)}
            inputDataTestName="MonthDropdown"
          >
            {optionsGenerators.months()}
          </InputDropdown>
        )}
        {fieldVisibility[fieldTypes.date] && (
          <InputDropdown
            label="Date"
            value={date}
            onChange={this.handleChange(fieldTypes.date)}
            inputDataTestName="DayDropdown"
          >
            {optionsGenerators.dates(getDaysInMonth({ year, month }))()}
          </InputDropdown>
        )}
        {fieldVisibility[fieldTypes.year] && (
          <InputDropdown
            label="Year"
            value={year}
            onChange={this.handleChange(fieldTypes.year)}
            inputDataTestName="YearDropdown"
          >
            {optionsGenerators.yearsBetween(startingYearsAgo, endingYearsAgo)}
          </InputDropdown>
        )}
      </InputGroup>
    );
  }
}

InputDateDropdown.propTypes = {
  /** The current array value that the input is displaying */
  value: PropTypes.string,
  /** A handler that gets triggered when the date is changed */
  onChange: PropTypes.func,
  /** Whether or not to trigger `onChange` when any sub inputs change or to wait until all have a value */
  cumulativeUpdates: PropTypes.bool,
  /** Number of years in the past to start counting back from  */
  startingYearsAgo: PropTypes.number,
  /** Number of years in the past to stop counting back to */
  endingYearsAgo: PropTypes.number,
  /** Precision of date to day or month */
  precision: PropTypes.oneOf(Object.keys(precisions)),
  /** Bottom-margin configuration */
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

InputDateDropdown.defaultProps = {
  cumulativeUpdates: false,
  startingYearsAgo: 0,
  endingYearsAgo: 100,
  precision: precisions.day,
};

export default InputDateDropdown;
