import { RebrandColorPalette } from '@fabrictech/design-tokens';
import {
  descendant,
  hover,
  focus,
  visited,
  active,
  and,
} from '../../utils/css/selectors';
import tags from '../../constants/tags';

const getIconRotation = ({ expanded }: { expanded?: boolean }) =>
  expanded ? '180deg' : 0;

export const getIconStyles = ({ expanded }: { expanded?: boolean }) => ({
  transition: 'transform 0.2s',
  transform: `rotate(${getIconRotation({ expanded })})`,
  transformOrigin: 'center center',
});

export const getComponentStyles = ({ expanded }: { expanded: boolean }) => ({
  textDecoration: 'none',
  [descendant(tags.h3)]: {
    color: expanded ? RebrandColorPalette.purple : RebrandColorPalette.midnight,
    textDecoration: 'none',
  },
  [and(focus, hover, active)]: {
    textDecoration: 'none',
    [descendant(tags.h3)]: {
      color: RebrandColorPalette.purple,
    },
  },
  [visited]: {
    textDecoration: 'none',
    [descendant(tags.h3)]: {
      color: expanded
        ? RebrandColorPalette.purple
        : RebrandColorPalette.midnight,
    },
  },
});
