import _ from 'lodash';
import React, { useMemo, useEffect, useState } from 'react';

import {
  Product,
  ValueOf,
  quoteInteractionNames,
  getMaxAccelerableCoverageAmount,
} from '@fabrictech/definitely-fabric';
import { Box, MarketingText } from '@fabrictech/tapestry';
import EstimateViewer from './EstimateViewer';
import LinkButton from '../LinkButton';
import EstimateFormSectionWrapper from './EstimateFormSectionWrapper';
import CoverageSlider from './CoverageSlider';
import TermLengthToggle from './TermLengthToggle';
import FormButton from '../FormButton';
import EstimateDisclaimer from './EstimateDisclaimer';
import {
  SwatchWidget,
  GetQuotePremiumAu,
  CreateAnalyticsEvent,
} from '../types';
import { defaultCoverageAmount } from '../constants';
import { parseToAbbreviatedDollarString } from './currencyUtils';
import {
  coverageLabelText,
  mobileNeedMoreCtaText,
  getDesktopNeedMoreCtaText,
  termLengthLabelText,
  defaultCtaText,
  nerdWalletCtaText,
} from './content';
import getStyles from './getStyles';
import useResponsiveContext from '../useResponsiveContext';

export type EstimateFormProps = {
  isLoading?: boolean;
  quotesResponse?: GetQuotePremiumAu.Response;
  goBack: () => void;
  onAnalyticsEvent: (
    analyticsEvent: Omit<CreateAnalyticsEvent.Request, 'source'>
  ) => void;
  onSubmit: (quote: SwatchWidget.Quote) => void;
  isNerdWalletWidget: boolean;
};

// The parameters of the form's options based on the quotes response.
type EstimateFormOptions = {
  // index of the lowest coverage selectable
  minCoverageIndex: number;
  // index of the max coverage selectable
  maxCoverageIndex: number;
  // quotes that are selectable, sorted by coverage amount
  quotesByCoverage: SwatchWidget.Quote[];
  // term lengths that are selectable
  termLengths: SwatchWidget.TermLength[];
};

// The currently selected values of the form
type SelectedValues = {
  // index of the currently selected coverage amount
  coverageIndex: number;
  // currently selected term length
  termLength: SwatchWidget.TermLength;
};

// The currently selected quote, based on coverage amount and term length
type SelectedQuote = SwatchWidget.Quote;

// This typeguard ensures that the response is defined and has quotes since the
// lead is eligible.
const isEligibleResponse = (
  quotesResponse?: GetQuotePremiumAu.Response
): quotesResponse is GetQuotePremiumAu.EligibleResponse =>
  _.get(quotesResponse, 'isEligible', false);

// This value determines what the default max coverage amount is on the slider
// and when the "Need More?" CTA should be shown
const maxAccelerableCoverageAmount = getMaxAccelerableCoverageAmount();

const desktopNeedMoreCtaText = getDesktopNeedMoreCtaText(
  maxAccelerableCoverageAmount
);

const transformQuoteToAnalyticsEvent = (
  { coverageAmount, termLength, premium }: SwatchWidget.Quote,
  name: ValueOf<typeof quoteInteractionNames>
) => ({
  product: Product.premiumAu,
  name,
  quoteOutputs: {
    coverageAmount,
    termLength,
    premium,
  },
});

const transformQuoteToCoverageChangedEvent = (quote: SwatchWidget.Quote) =>
  transformQuoteToAnalyticsEvent(
    quote,
    quoteInteractionNames.quoteChangedCoverage
  );

const transformQuoteToTermLengthChangedEvent = (quote: SwatchWidget.Quote) =>
  transformQuoteToAnalyticsEvent(
    quote,
    quoteInteractionNames.quoteChangedTermLength
  );

const transformQuoteToQuoteReceivedEvent = (quote: SwatchWidget.Quote) =>
  transformQuoteToAnalyticsEvent(quote, quoteInteractionNames.quoteReceived);

const EstimateForm = ({
  isLoading,
  quotesResponse,
  goBack,
  onAnalyticsEvent: handleAnalyticsEvent,
  onSubmit: baseHandleSubmit,
  isNerdWalletWidget,
}: EstimateFormProps) => {
  const [estimateFormOptions, setEstimateFormOptions] = useState<
    EstimateFormOptions
  >(({} as unknown) as EstimateFormOptions);
  const [selectedValues, setSelectedValues] = useState<SelectedValues>(
    ({} as unknown) as SelectedValues
  );
  const [selectedQuote, setSelectedQuote] = useState<SelectedQuote>(
    ({} as unknown) as SelectedQuote
  );
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const handleSubmit = () => {
    baseHandleSubmit(selectedQuote);
  };

  // Initialize the estimate form upon receiving a new quotes response.
  useEffect(() => {
    if (!isInitialized) {
      if (isEligibleResponse(quotesResponse)) {
        const { defaultTermLength: initialTermLength } = quotesResponse;
        const quotesByCoverage =
          quotesResponse.quotesByTermLength[initialTermLength];
        const termLengths = Object.keys(quotesResponse.quotesByTermLength)
          .map(
            termLength =>
              Number.parseInt(termLength, 10) as SwatchWidget.TermLength
          )
          .sort();
        const minCoverageIndex = 0;
        const maxCoverageIndex = quotesByCoverage.findIndex(
          ({ coverageAmount }) =>
            coverageAmount === maxAccelerableCoverageAmount
        );
        const newEstimateFormOptions = {
          minCoverageIndex,
          maxCoverageIndex,
          termLengths,
          quotesByCoverage,
        };
        setEstimateFormOptions(newEstimateFormOptions);
        const quoteIndex = quotesByCoverage.findIndex(
          ({ coverageAmount }) => coverageAmount === defaultCoverageAmount
        );
        if (quoteIndex > -1) {
          setSelectedValues({
            coverageIndex: quoteIndex,
            termLength: initialTermLength,
          });
          const selected = quotesByCoverage[quoteIndex];
          setSelectedQuote(selected);
          handleAnalyticsEvent(transformQuoteToQuoteReceivedEvent(selected));
          setIsInitialized(true);
        }
      }
    }
  }, [quotesResponse, handleAnalyticsEvent, isInitialized]);

  const handleCoverageChange = (newCoverageIndex: number) => {
    setSelectedValues(prev => ({
      ...prev,
      coverageIndex: newCoverageIndex,
    }));
    const selected = estimateFormOptions.quotesByCoverage[newCoverageIndex];
    setSelectedQuote(selected);
  };

  const handleAfterCoverageChange = () => {
    handleAnalyticsEvent(transformQuoteToCoverageChangedEvent(selectedQuote));
  };

  const handleTermLengthChange = (termLength: SwatchWidget.TermLength) => {
    // this is for typescript, this condition should always be true by the time
    // this function is attached to a component
    if (isEligibleResponse(quotesResponse)) {
      const quotesByCoverage = quotesResponse.quotesByTermLength[termLength];
      if (quotesByCoverage) {
        setEstimateFormOptions(prev => ({
          ...prev,
          quotesByCoverage,
        }));
        setSelectedValues(prev => ({
          ...prev,
          termLength,
        }));
        const selected = quotesByCoverage[selectedValues.coverageIndex];
        setSelectedQuote(selected);
        handleAnalyticsEvent(transformQuoteToTermLengthChangedEvent(selected));
      }
    }
  };

  // If the currently selected coverage amount is the default maximum and this
  // option hasn't been enabled already, display a "Need more?" CTA that will
  // allow greater coverage amounts to be selectable.
  const showNeedMoreCta = useMemo(() => {
    const { quotesByCoverage, maxCoverageIndex } = estimateFormOptions;
    if (quotesByCoverage) {
      const displayMaxQuote = quotesByCoverage[maxCoverageIndex];
      const isDisplayMaxAccelerableQuote =
        displayMaxQuote.coverageAmount === maxAccelerableCoverageAmount;
      const isSelectedMaxAccelerableQuote =
        selectedQuote.coverageAmount === maxAccelerableCoverageAmount;
      return isDisplayMaxAccelerableQuote && isSelectedMaxAccelerableQuote;
    }
    return false;
  }, [estimateFormOptions, selectedQuote]);

  const handleNeedMoreCtaClick = () => {
    setEstimateFormOptions(prev => ({
      ...prev,
      maxCoverageIndex: estimateFormOptions.quotesByCoverage.length - 1,
    }));
  };
  const isFormDisabled = !selectedQuote.premium;

  const hasExtendedTermStyles = useMemo(
    () =>
      estimateFormOptions.termLengths &&
      estimateFormOptions.termLengths.length > 4,
    [estimateFormOptions.termLengths]
  );
  const {
    isInternalWidget,
    styles: {
      containerClassname,
      wrapperClassname,
      triangleContainerClassname,
      triangleClassname,
      inputsWrapperClassname,
      coverageSectionClassname,
      labelClassname,
      coverageValueWrapperClassname,
      coverageValueClassname,
      inlineNeedMoreClassname,
      needMoreFooterClassname,
      inputContainerClassname,
      extendedTermLengthSectionClassname,
      standardTermLengthSectionClassname,
      extendedTermLengthLabelClassname,
      extendedTermLengthContainerClassname,
      baseDisclaimerContainerClassname,
      internalDisclaimerContainerClassname,
    },
  } = useResponsiveContext(getStyles);
  const termLengthSectionClassname = hasExtendedTermStyles
    ? extendedTermLengthSectionClassname
    : standardTermLengthSectionClassname;
  const termLengthLabelClassname = hasExtendedTermStyles
    ? extendedTermLengthLabelClassname
    : labelClassname;
  const termLengthInputContainerClassname = hasExtendedTermStyles
    ? extendedTermLengthContainerClassname
    : inputContainerClassname;
  const disclaimerContainerClassname = isInternalWidget
    ? internalDisclaimerContainerClassname
    : baseDisclaimerContainerClassname;

  // On the NerdWallet version of the widget, we use:
  // * different CTA text
  // * different disclaimer text
  const ctaText = isNerdWalletWidget ? nerdWalletCtaText : defaultCtaText;
  // Use non-breaking space (&nbsp;) for the two labels to prevent linebreak
  // between estimate value and Need More? CTA and between "in" and "years"
  return (
    <Box className={containerClassname}>
      <Box className={wrapperClassname}>
        <EstimateViewer goBack={goBack} value={selectedQuote.premium} />
        <Box className={triangleContainerClassname}>
          <Box className={triangleClassname} />
        </Box>
        <Box className={inputsWrapperClassname}>
          <EstimateFormSectionWrapper
            className={coverageSectionClassname}
            label={
              <>
                <MarketingText rank={2} className={labelClassname}>
                  <strong>{coverageLabelText}</strong>
                </MarketingText>
                <Box className={coverageValueWrapperClassname}>
                  <MarketingText rank={1} className={coverageValueClassname}>
                    {parseToAbbreviatedDollarString(
                      selectedQuote.coverageAmount
                    )}
                    &nbsp;
                  </MarketingText>
                  {showNeedMoreCta ? (
                    <LinkButton
                      rank={3}
                      className={inlineNeedMoreClassname}
                      onClick={handleNeedMoreCtaClick}
                    >
                      {mobileNeedMoreCtaText}
                    </LinkButton>
                  ) : null}
                </Box>
              </>
            }
            footer={
              showNeedMoreCta ? (
                <LinkButton
                  className={needMoreFooterClassname}
                  rank={2}
                  onClick={handleNeedMoreCtaClick}
                >
                  {desktopNeedMoreCtaText}
                </LinkButton>
              ) : (
                undefined
              )
            }
          >
            {estimateFormOptions.quotesByCoverage ? (
              <Box className={inputContainerClassname}>
                <CoverageSlider
                  min={estimateFormOptions.minCoverageIndex}
                  max={estimateFormOptions.maxCoverageIndex}
                  onChange={handleCoverageChange}
                  onAfterChange={handleAfterCoverageChange}
                  value={selectedValues.coverageIndex}
                />
              </Box>
            ) : null}
          </EstimateFormSectionWrapper>
          <EstimateFormSectionWrapper
            className={termLengthSectionClassname}
            label={
              <MarketingText rank={2} className={termLengthLabelClassname}>
                <strong>{termLengthLabelText}</strong>
              </MarketingText>
            }
          >
            {estimateFormOptions.termLengths ? (
              <Box className={termLengthInputContainerClassname}>
                <TermLengthToggle
                  hasExtendedTermStyles={hasExtendedTermStyles}
                  onChange={handleTermLengthChange}
                  options={estimateFormOptions.termLengths}
                  value={selectedValues.termLength}
                />
              </Box>
            ) : null}
          </EstimateFormSectionWrapper>
        </Box>
        <FormButton
          disabled={isFormDisabled}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          {ctaText}
        </FormButton>
        <Box className={disclaimerContainerClassname}>
          <EstimateDisclaimer isNerdWalletWidget={isNerdWalletWidget} />
        </Box>
      </Box>
    </Box>
  );
};

export default EstimateForm;
