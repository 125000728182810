import { color, spacer } from '@fabrictech/design-tokens';
import { chromeGrey } from './constants';
import createGetStyles from './createGetStyles';

const getContainerStyles = (
  isLeadScreen: boolean,
  isInternalWidget: boolean
) => ({
  // Declaring this explicitly for use with `<AdIndicator />`, however `<Box />`
  // has position: 'relative' by default
  position: 'relative',
  background: color.background.primary,
  flexDirection: 'column',
  border: isInternalWidget ? 'none' : `10px solid ${chromeGrey}`,
  padding: spacer(3.75),
  height: isInternalWidget ? 'auto' : 530,
  width: 620,
  mobileBreakpoint: {
    ...(isInternalWidget ? {} : { borderWidth: 5 }),
    padding: isLeadScreen ? spacer(1.875) : spacer(1.5),
    height: isInternalWidget ? 'auto' : 660,
    // TODO: add logic for internal width
    // @see https://fabrictech.atlassian.net/browse/GRW-3876
    width: 320,
  },
});

const wrapperStyles = {
  margin: '0 auto',
};

const leadFormStyles = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  mobileBreakpoint: {
    flexDirection: 'column',
    alignItems: 'stretch',
  },
};

const styles = {
  baseContainerStyles: getContainerStyles(false, false),
  leadContainerStyles: getContainerStyles(true, false),
  internalBaseContainerStyles: getContainerStyles(false, true),
  internalLeadContainerStyles: getContainerStyles(true, true),
  wrapperStyles,
  leadFormStyles,
};

const getStyles = createGetStyles(styles);

export default getStyles;
