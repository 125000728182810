import { spacer } from '@fabrictech/design-tokens';

import { padding } from '../../utils/css/values';
import { sameAdjacentSibling } from '../../utils/css/selectors';
import { SectionState } from './types';

export const getStyles = () => ({
  padding: padding(0, spacer(4)),
});

export const getParentStyles = ({
  sectionState,
}: {
  sectionState: SectionState;
}) => ({
  width: '100%',
  ...(sectionState === 'inactive'
    ? {
        /** Hides the next `inactive` sections that follow another `inactive` section  */
        [sameAdjacentSibling]: {
          display: 'none',
        },
      }
    : {}),
  ...(sectionState === 'hidden' ? { display: 'none' } : {}),
});
