import { buildClientContext } from './buildClientContext';
import pkg from '../../package.json';

export const API_POST_ANALYTICS = 'apiPostAnalytics';

export const getClientContextRequestData = state => {
  const clientContext = buildClientContext(state, pkg);
  return { data: { clientContext } };
};

const apis = {
  [API_POST_ANALYTICS]: record => ({
    method: 'POST',
    endpoint: '/api/analytics',
    headers: { 'Content-Type': 'application/json' },
    body: record,
    requestUtil: getClientContextRequestData,
    credentials: 'same-origin',
  }),
};

export default apis;
