import React from 'react';

import InputText from '../InputText';
import { InputTextProps } from '../InputText/InputText';

type InputTextPasswordState = { readonly showPassword: boolean };

/** Wraps `InputText` to provide extra functionality for password fields.
 * Clicking on the icon toggles password visibility */
export class InputTextPassword extends React.PureComponent<
  InputTextProps,
  InputTextPasswordState
> {
  state = { showPassword: false };

  handlePasswordIconClick = () =>
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));

  render() {
    const { showPassword } = this.state;
    return (
      <InputText
        {...this.props}
        type={showPassword ? 'text' : 'password'}
        icon={showPassword ? 'eyeClosed' : 'eye'}
        onIconClick={this.handlePasswordIconClick}
      />
    );
  }
}

export default InputTextPassword;
