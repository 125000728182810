import incrementalOptionsGenerator from './incrementalOptionsGenerator';

const currentYear = new Date().getFullYear();

const yearsBetween = (from, to) =>
  incrementalOptionsGenerator({
    min: currentYear - to,
    max: currentYear - from,
    reverse: true,
  });

export default yearsBetween;
