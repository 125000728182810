import ImmutablePureComponent from 'react-immutable-pure-component';
import _ from 'lodash';

import { inputTypes } from '../../../utils/constants';

class ValueQuestionAdapter extends ImmutablePureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.getInputIndividualValue = this.getInputIndividualValue.bind(this);

    this.supportsMultipleAnswers = [
      inputTypes.assessmentFactorSearch,
      inputTypes.multiSelection,
    ].includes(props.inputType);

    // We only want blur and focus events on particular inputs
    this.triggerNonChangeEvents = [
      inputTypes.assessmentFactorSearch,
      inputTypes.singleSelection,
    ].indexOf(props.inputType);

    this.isAssessmentFactorSearch =
      props.inputType === inputTypes.assessmentFactorSearch;
  }

  getValue() {
    const { value } = this.props;
    if (_.isEmpty(value)) {
      return this.supportsMultipleAnswers ? [] : {};
    }
    return value;
  }

  getObjectValue(value) {
    const { options, valueKey } = this.props;
    const currentValue = this.getValue();

    return (
      (this.supportsMultipleAnswers &&
        currentValue.find(entry => entry[valueKey] === value)) ||
      options.find(entry => entry[valueKey] === value)
    );
  }

  getInputIndividualValue(v) {
    const { labelKey, valueKey } = this.props;
    return this.isAssessmentFactorSearch
      ? _.pick(v, [valueKey, labelKey])
      : v[valueKey];
  }

  getInputValue() {
    const { valueKey } = this.props;
    const value = this.getValue();
    return this.supportsMultipleAnswers
      ? value.map(this.getInputIndividualValue)
      : value[valueKey];
  }

  handleChange(value) {
    const { onChange } = this.props;

    onChange(
      this.supportsMultipleAnswers
        ? value.map(v => this.getObjectValue(v))
        : this.getObjectValue(value)
    );
  }

  render() {
    const {
      render,
      onChange, // not used, but removed from `...rest`
      onBlur,
      onFocus,
      value, // not used, but removed from `...rest`
      ...rest
    } = this.props;

    const inputProps = {
      ...rest,
      ...(this.triggerNonChangeEvents ? { onBlur, onFocus } : {}),
      onChange: this.handleChange,
      value: this.getInputValue(),
    };

    return render(inputProps);
  }
}

ValueQuestionAdapter.defaultProps = {
  valueKey: 'id',
  labelKey: 'id',
};

export default ValueQuestionAdapter;
