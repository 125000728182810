import getQueryParams from './getQueryParams';
import actionTypes from './actionTypes';
import * as apiKeyPath from './keyPath';
import apiCounter from './counter';

const { REQUEST, SUCCESS, FAILURE, DESTROY } = actionTypes;

/**
 * DUPLICATION WARNING!
 *
 * This reducer intentionally duplicates the functionality in `reducerJs`,
 * the only difference in API being that it deals with `Immutable` objects.
 *
 * It is structured much differently because the patterns we use here are
 * less ergonomic when dealing with JS objects.
 */

/*
 * If the action is the result from the most recent api request it will be applied to the state
 * Otherwise it will be discarded and the current state will be returned
 */
const buildCountGuardedReducer = (counter, keyPath) => reducer => (
  state,
  action
) => {
  const {
    meta: { count, key, reducer: clientReducer },
  } = action;
  if (count === counter.read(key)) {
    const newState = reducer(state, action).setIn(
      keyPath.getCounter(key),
      count
    );
    return clientReducer ? clientReducer(newState, action) : newState;
  }
  return state;
};

const buildReducerWithCounter = buildCountGuardedReducer(
  apiCounter,
  apiKeyPath
);

/**
 * The behaviors of the redux-api reducer. Client reducers, if specified, will be applied to the state
 * returned by the redux-api reducer.
 */
const buildRequestReducer = keyPath => (
  state,
  { meta: { key, endpoint }, payload, error }
) =>
  state
    .setIn(keyPath.getRequest(key), {
      payload,
      error,
      endpoint,
      queryParams: getQueryParams(endpoint),
    })
    // reset any previous state
    .deleteIn(keyPath.getSuccess(key))
    .deleteIn(keyPath.getFailure(key));

const buildSuccessReducer = keyPath => (state, { meta: { key }, payload }) =>
  state.setIn(keyPath.getSuccess(key), { payload });

const buildFailureReducer = keyPath => (
  state,
  { meta: { key }, payload, error }
) => state.setIn(keyPath.getFailure(key), { payload, error });

const buildDestroyReducer = keyPath => (state, { meta: { key } }) =>
  state.deleteIn(keyPath.getRoot(key));

/**
 * The reducer actions of the Redux-api reducer
 */
const requestReducer = buildRequestReducer(apiKeyPath);
const successReducer = buildSuccessReducer(apiKeyPath);
const failureReducer = buildFailureReducer(apiKeyPath);
const destroyReducer = buildDestroyReducer(apiKeyPath);

const countGuardedRequestReducer = buildReducerWithCounter(requestReducer);
const countGuardedSuccessReducer = buildReducerWithCounter(successReducer);
const countGuardedFailureReducer = buildReducerWithCounter(failureReducer);

const behaviors = {
  [REQUEST]: countGuardedRequestReducer,
  [SUCCESS]: countGuardedSuccessReducer,
  [FAILURE]: countGuardedFailureReducer,
  [DESTROY]: destroyReducer,
};

/**
 * The redux-api reducer.
 * matches the action type to the available behaviors and returns the new state reduced from the match or current state
 */
const reduxApiReducer = (state, action) => {
  const behavior = behaviors[action.type];
  return behavior ? behavior(state, action) : state;
};

export default reduxApiReducer;
