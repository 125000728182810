import React from 'react';
import { css } from 'glamor';

import { color, spacer, IconType } from '@fabrictech/design-tokens';

import Box from '../Box';
import Icon from '../Icon';
import Text from '../Text';

import { getBaseStyles } from './getStyles';

import createGetStylesWithGlobalProps from '../../utils/createGetStylesWithGlobalProps';
import pickGlobalProps from '../../utils/pickGlobalProps';

const getStyles = createGetStylesWithGlobalProps(getBaseStyles);

type CardBannerState = 'active' | 'inactive';

type CardBannerProps = {
  /** Icon for the CardBanner */
  icon?: IconType;
  /** Title for the CardBanner */
  title?: string;
  /** Status of the CardBanner */
  status?: CardBannerState;
};

/**
 * Section headers are used in the application page of the signup process.
 * These headers help tell the user what section they are in, what is to come, and what has already been completed.
 * */
const CardBanner = ({
  icon,
  title,
  status = 'active',
  ...rest
}: CardBannerProps) => {
  const isActive = status === 'active';
  const globalProps = pickGlobalProps(rest);
  const parentClassName = css(getStyles({ isActive }));
  return (
    <Box
      align="center"
      verticalAlign="center"
      className={parentClassName}
      {...globalProps}
    >
      {icon && (
        <Icon type={icon} color={color.font.inverse.primary} size="md" />
      )}
      {isActive && title && (
        <Text
          color={color.font.inverse.primary}
          marginBottom={0}
          marginLeft={spacer(2)}
        >
          {title}
        </Text>
      )}
    </Box>
  );
};

export default CardBanner;
