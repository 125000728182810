import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ImmutablePureComponent from 'react-immutable-pure-component';
import { fromJS } from 'immutable';

import createQuestionValidator from '../../utils/createQuestionValidator';
import createGetQuestionAdapter from '../shared/questionAdapters/createGetQuestionAdapter';
import { inputTypes } from '../../utils/constants';

import {
  getQuestionStatus,
  getInputUpdateEventConfig,
  getInputValidateEventConfig,
  getHtmlInputType,
  getIsGroupInputType,
} from '../shared/inputUtils';
import memoizeById from '../../utils/memoizeById';

/**
 * HOC to return the local questionnaire _Question_ component.
 *
 * @param {object} options An options config
 *  @property {object} masks Maps questionIds to their masks
 *  @property {function} transformQuestionData Helper that passes formMods to a question.
 *  @property {array<string>} secureQuestions List of questions to hide from logging.
 *  @property {boolean} handleTextInputChangeEvent Whether or not to respond to
 *    the `change` event from text like inputs.
 *  @property {function} getFieldForInputType Get the _Field_ component
 *  @property {function} getInputForInputType Get _Input_ component
 * * @property {boolean} adaptValuesForPremiumAu
 *  @property {object} localFormInputProps Props passed to all _Input_ components in a LocalForm
 * @return {object}
 */
const createLocalFormQuestion = ({
  masks = {},
  transformQuestionData = _.identity,
  secureQuestions = [],
  handleTextInputChangeEvent = true,
  getFieldForInputType,
  getInputForInputType,
  adaptValuesForPremiumAu,
  customQuestionAdapters,
  localFormInputProps = {},
}) => {
  /**
   * Different types of inputs require different state management components.
   * Build a getter to map from these input types to the appropriate state manager.
   */

  const getQuestionAdapter = createGetQuestionAdapter({
    adaptValuesForPremiumAu,
    ...customQuestionAdapters,
  });

  /**
   * Memoized helper to instantiate validators on a question level.
   * @return {[function]} Optionally returns a validator for the question instance.
   */
  const getQuestionValidator = memoizeById((id, { constraints, type }) => {
    if (constraints) {
      return createQuestionValidator({
        constraints,
        type,
      });
    }

    return null;
  });

  /**
   * Component that renders a single question.
   *
   * @class LocalFormQuestion
   * @extends {ImmutablePureComponent}
   */
  class LocalFormQuestion extends ImmutablePureComponent {
    getQuestionData = props => {
      const { id, questionnaireInstance } = props;
      const question = questionnaireInstance.questionsById[id];
      const whitelistedAttributes = _.pick(question, [
        'answerStatus',
        'caption',
        'subcaption',
        'useMarkdown',
        'title',
        'type',
        'groupType',
        'precision',
        'constraints',
        'inputProps',
        'tooltip',
        'id',
        'imageUri',
      ]);

      return {
        ...whitelistedAttributes,
        initialValue: questionnaireInstance.answers[id],
        questions: question.questions || [],
        isSecure: secureQuestions.includes(id),
        validator: getQuestionValidator(id, question),
        options: fromJS(
          questionnaireInstance.optionsById[question.options] || null
        ),
      };
    };

    render() {
      const {
        id: questionId,
        questionnaireInstance,
        onUpdateData,
        depth,
        isFabricGroupItem,
        renderQuestionById,
        children,
        width,
      } = this.props;

      // TODO: many of these attributes are theoretically coming from form mods.
      // We should try to clarify what can come from what.
      const {
        isSecure,
        answerStatus,
        caption,
        subcaption,
        useMarkdown,
        title,
        icon,
        tooltip,
        inputProps,
        questions,
        options,
        type,
        validator,
        constraints,
        precision,
        initialValue,
        groupType,
        margin,
        onReportError,
        onBlur,
        onFocus,
        suppressErrorMessages,
        imageUri,
      } = transformQuestionData({
        ...this.props,
        ...this.getQuestionData(this.props),
      });

      // Don't increase the depth if the question is the top-level of a group
      const nestedDepth = getIsGroupInputType(type) ? depth : depth + 1;
      const { normalizer, parser } = masks[questionId] || {};

      const status = getQuestionStatus({
        answerStatus,
        type,
      });

      const { sectionState, onToggleSectionButtonClick } =
        type === inputTypes.section
          ? {
              sectionState: this.props.sectionStates[questionId],
              onToggleSectionButtonClick: () =>
                this.props.onSectionButtonClick(questionId),
            }
          : { sectionState: null, onToggleSectionButtonClick: null };

      const adapterProps = {
        /**
         * By default, we turn on the (debounced) text change handling
         * because it allows us to make forms like the follow up response
         * form submittable before the user blurs the last input, and we
         * don't share DynamicForm's issues with firing network requests
         * during interaction.
         */
        updateOn: getInputUpdateEventConfig(type, {
          handleTextInputChangeEvent,
        }),
        validateOn: getInputValidateEventConfig(type, {
          handleTextInputChangeEvent,
        }),
        type: getHtmlInputType(type),
        inputType: type,
        initialValue,
        onUpdateData,
        id: questionId,
        precision,
        constraints,
        validator,
        normalizer,
        parser,
        onBlur,
        onFocus,
        onReportError,
        suppressErrorMessages,
        options: options && options.toJS(),

        // TODO: these presentational props don't really belong here
        align: 'center',
        width,
        margin,
        ...inputProps,
      };

      // These three components make up the contents of the `Question`
      const QuestionAdapter = getQuestionAdapter(type);
      const Field = getFieldForInputType(
        isFabricGroupItem ? inputTypes.fabricGroupItem : type,
        groupType
      );
      const Input = getInputForInputType({ questionId, type, options }); // null

      return (
        <QuestionAdapter
          {...adapterProps}
          render={({ options: adaptedOptions, ...adaptedInputProps }) => (
            <Field
              options={adaptedOptions}
              sectionState={sectionState}
              onToggleSectionButtonClick={onToggleSectionButtonClick}
              status={status}
              caption={caption}
              subcaption={subcaption}
              useMarkdown={useMarkdown}
              title={title}
              icon={icon}
              tooltip={tooltip}
              depth={depth}
              isSecure={isSecure}
              id={questionId}
              isGroup={type === inputTypes.assessmentFactorGroup}
              imageUri={imageUri}
              render={fieldProps => {
                // not all Questions have an Input! If we don't have an Input,
                // render null
                if (!Input) return null;

                // otherwise, render the Input component
                return (
                  <Input
                    /**
                     * The order of these Input props follows the following
                     * priority:
                     *  * adaptedInputProps - props passed to a particular
                     *    question's _Input_ component, set as inputProps in the
                     *    question definition
                     *  * localFormInputProps - props passed to all _Input_
                     *    components within a LocalForm, set as a prop when
                     *    calling createLocalForm
                     *  * fieldProps - props passed to _Input_ from a particular
                     *    _Field_ component, set when invoking the `render`
                     *    method in the _Field_ component itself
                     */
                    {...fieldProps}
                    {...localFormInputProps}
                    {...adaptedInputProps}
                  />
                );
              }}
            >
              {_.isEmpty(questions)
                ? children
                : questions.map(
                    renderQuestionById({
                      depth: nestedDepth,
                      onUpdateData: this.props.onUpdateData,
                      questionnaireInstance,
                      isFabricGroupItem: type === 'fabricGroup',
                      renderQuestionById,
                    })
                  )}
            </Field>
          )}
        />
      );
    }
  }
  LocalFormQuestion.propTypes = {
    id: PropTypes.string.isRequired,
    onUpdateData: PropTypes.func.isRequired,
    questionnaireInstance: PropTypes.object.isRequired,
    renderQuestionById: PropTypes.func.isRequired,
    depth: PropTypes.number.isRequired,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  };

  LocalFormQuestion.defaultProps = {
    depth: 0,
    width: '100%',
  };

  return LocalFormQuestion;
};

export default createLocalFormQuestion;
