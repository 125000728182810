const immutableRequestPropSuffix = 'Request';
const immutableSuccessPropSuffix = 'Success';
const immutableFailurePropSuffix = 'Failure';
const immutablePendingPropSuffix = 'Pending';
const immutableExecutePropSuffix = 'Execute';
const immutableDestroyPropSuffix = 'Destroy';
const immutableCounterPropSuffix = 'Counter';

export const immutablePropSuffixes = {
  request: immutableRequestPropSuffix,
  success: immutableSuccessPropSuffix,
  failure: immutableFailurePropSuffix,
  pending: immutablePendingPropSuffix,
  execute: immutableExecutePropSuffix,
  destroy: immutableDestroyPropSuffix,
  counter: immutableCounterPropSuffix,
};

const getImmutablePropName = (apiKey, propKey) => [apiKey, propKey].join('');
export const getImmutableRequestPropName = apiKey =>
  getImmutablePropName(apiKey, immutableRequestPropSuffix);
export const getImmutableSuccessPropName = apiKey =>
  getImmutablePropName(apiKey, immutableSuccessPropSuffix);
export const getImmutableFailurePropName = apiKey =>
  getImmutablePropName(apiKey, immutableFailurePropSuffix);
export const getImmutablePendingPropName = apiKey =>
  getImmutablePropName(apiKey, immutablePendingPropSuffix);
export const getImmutableCounterPropName = apiKey =>
  getImmutablePropName(apiKey, immutableCounterPropSuffix);
export const getImmutableExecutePropName = apiKey =>
  getImmutablePropName(apiKey, immutableExecutePropSuffix);
export const getImmutableDestroyPropName = apiKey =>
  getImmutablePropName(apiKey, immutableDestroyPropSuffix);

const getImmutablePropNameRegex = suffix => new RegExp(`^api\\w+${suffix}`);

const immutableFailurePropRegex = getImmutablePropNameRegex(
  immutableFailurePropSuffix
);
const immutableRequestPropRegex = getImmutablePropNameRegex(
  immutableRequestPropSuffix
);

export const getIsImmutableRequestProp = propName =>
  immutableRequestPropRegex.test(propName);
export const getIsImmutableFailureProp = propName =>
  immutableFailurePropRegex.test(propName);

const getImmutablePropSuffixRegex = suffix => new RegExp(`${suffix}$`);

const failureSuffixRegex = getImmutablePropSuffixRegex(
  immutableFailurePropSuffix
);
export const getApiKeyFromFailurePropName = apiFailurePropName =>
  apiFailurePropName.replace(failureSuffixRegex, '');

const requestSuffixRegex = getImmutablePropSuffixRegex(
  immutableRequestPropSuffix
);
export const getApiKeyFromRequestPropName = apiRequestPropName =>
  apiRequestPropName.replace(requestSuffixRegex, '');
