import _ from 'lodash';
import { Map } from 'immutable';

/**
 * Get a failure's type when using `reduxApiImmutable`.
 *
 * @param {Immutable.Map} failureProp The Immutable.Map passed to a component's
 *  someApiFailure prop via reduxApiImmutable upon failure.
 * @return {String} The failure's type.
 */
export const getApiFailureTypeImmutable = (failureProp = new Map()) =>
  // While failure is an Immutable.Map, its `payload` property is a plan JS
  // object, whose `response` property is an Error whose type we can check.
  _.get(failureProp.get('payload') || {}, ['response', 'type']);

/**
 * Checks if a failure is of a particular type.
 * @param {Immutable.Map} failureProp
 * @param {String} type
 * @return {Boolean}
 */
export const getIsApiFailureTypeImmutable = (failureProp, type) =>
  type === getApiFailureTypeImmutable(failureProp);

/**
 * Creates a bound failure-type check
 * @param {String} type
 */
export const createGetIsApiFailureTypeImmutable = type => failureProp =>
  getIsApiFailureTypeImmutable(failureProp, type);
