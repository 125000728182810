import _ from 'lodash';
import getQueryParams from './getQueryParams';
import apiActionTypes from './actionTypes';
import * as apiKeyPath from './keyPath';
import apiCounter from './counter';

const { REQUEST, SUCCESS, FAILURE, DESTROY } = apiActionTypes;

/**
 * DUPLICATION WARNING!
 *
 * This reducer intentionally duplicates the functionality in `reducerImmutable`,
 * the only difference in API being that it deals with plain JS objects.
 *
 * It is structured much differently because the patterns we use in `rI` are
 * less ergonomic when dealing with JS objects.
 */

/**
 * Reducer for an individual request key in the api slice, e.g. `state.api.myRequest`.
 */
const apiKeyReducer = (keyState = {}, action) => {
  const {
    meta: { key, count, endpoint },
    payload,
    error,
  } = action;

  // do nothing for actions which have been superseded by more recent requests
  if (count !== apiCounter.read(key)) return keyState;

  switch (action.type) {
    case REQUEST:
      return {
        ..._.omit(keyState, 'success', 'failure'),
        counter: count,
        request: {
          payload,
          error,
          endpoint,
          queryParams: getQueryParams(endpoint),
        },
      };
    case SUCCESS:
      return {
        ...keyState,
        counter: count,
        success: { payload },
      };
    case FAILURE:
      return {
        ...keyState,
        counter: count,
        failure: { payload, error },
      };
    default:
      // this case should be unreachable, due to the checks in reducers below
      throw new Error(
        `Received action with type ${action.type} in apiKeyReducer.`
      );
  }
};

/**
 * Reducer for the whole api state slice, `state.api`
 */
const apiReducer = (apiState = {}, action) => {
  const { key } = action.meta;

  // DESTROY operates at the api state level (and ignores the counter)
  if (action.type === DESTROY) return _.omit(apiState, key);

  // get next state
  const prevKeyState = apiState[key];
  const nextKeyState = apiKeyReducer(prevKeyState, action);

  // if keyState is unchanged, exit, don't unnecessarily change object identity
  if (prevKeyState === nextKeyState) return apiState;

  // otherwise, merge in next state
  return { ...apiState, [key]: nextKeyState };
};

// key by the actual type instead of a human readable string,
// makes it easier to check if an action is one of these types
const apiActionTypesByType = _.invert(apiActionTypes);

/**
 * Top level reducer, takes full state object.
 */
const reduxApiReducer = (globalState = {}, action) => {
  // not an api action, do nothing
  if (!apiActionTypesByType[action.type]) return globalState;

  // get next state
  const prevApiState = globalState[apiKeyPath.root];
  const nextApiState = apiReducer(prevApiState, action);

  // if apiState is unchanged, exit, don't unnecessarily change object identity
  if (prevApiState === nextApiState) return globalState;

  // otherwise, merge in next state, and apply optional action reducer as appropriate
  const nextState = { ...globalState, [apiKeyPath.root]: nextApiState };
  return action.meta.reducer
    ? action.meta.reducer(nextState, action)
    : nextState;
};

export default reduxApiReducer;
