import _ from 'lodash';

type SurveyResponseValue = string;

type SurveyResponseOtherValueContainer = {
  value: string;
  otherValue: string;
};

type SurveyResponse =
  | {
      value: string;
    }
  | {
      value: 'other';
      otherValue: string;
    };
const buildSurveyResponseProperties = (
  value: SurveyResponseValue | SurveyResponseOtherValueContainer
): SurveyResponse => {
  if (_.isString(value)) {
    return {
      value,
    };
  }
  return value;
};

export default buildSurveyResponseProperties;
