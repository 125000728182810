import { useEffect, useState } from 'react';
import { shouldSetAnswersWithPrefilledLead } from '../leadUtil';

import { GetQuotePremiumAu } from '../types';
import getQuestionnaireInstance from './questionnaire';

// TODO: Add questionnaire definition with `inputProps: { width: 320 }`
// @see https://fabrictech.atlassian.net/browse/GRW-3876
const useLeadFormAnswers = (
  leadData: Partial<GetQuotePremiumAu.Request>,
  isInternalWidget: boolean
) => {
  const [answersById, setAnswersById] = useState<
    Partial<GetQuotePremiumAu.Request>
  >(leadData);
  // On the internal widget:
  // If the user navigates directly to the QuotePage, there is a point in time
  // when the widget has been rendered, but the lead hasn't been fetched from
  // the API yet. In order to prevent some jarring change to the form, we only
  // reset the lead form with the prefilled lead data if data hasn't been entered
  // in the form yet.
  useEffect(() => {
    if (isInternalWidget) {
      if (shouldSetAnswersWithPrefilledLead(answersById, leadData)) {
        setAnswersById({
          ...answersById,
          ...leadData,
        });
      }
    }
  }, [isInternalWidget, answersById, leadData]);

  const questionnaireInstance = getQuestionnaireInstance(answersById);

  const onUpdateData = ({ id, value }: { id: string; value: string }) => {
    setAnswersById(prev => ({ ...prev, [id]: value }));
  };

  return {
    answersById,
    onUpdateData,
    questionnaireInstance,
  } as const;
};

export default useLeadFormAnswers;
