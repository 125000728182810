import React from 'react';
import { css } from 'glamor';
import TextLoop from 'react-text-loop';

import { IconType, color, spacer } from '@fabrictech/design-tokens';

import LayoutPage from '../LayoutPage';
import LayoutContainer from '../LayoutContainer';
import LayoutItem from '../LayoutItem';
import Box from '../Box';
import CardWithBadge from '../CardWithBadge';
import NavHeader, { NavHeaderProps } from '../NavHeader';
import Text from '../Text';
import LoadingIndicator from '../LoadingIndicator';
import * as mediaQueries from '../../sharedStyles/vars/mediaQueries';
import tags from '../../constants/tags';
import { ResponsiveProps } from '../../types';
import {
  getTextLoopItemStyles,
  getCardStyles,
  getLoadingStyles,
  getHeaderWrapperStyles,
} from './getStyles';

const textLoopItemClassName = css(getTextLoopItemStyles()).toString();
const headerWrapperClassName = css(getHeaderWrapperStyles()).toString();
const textLoopSpeed = 1200 * 2;

type CardPageProps = {
  children?: React.ReactNode;
  /** Animates rule on the bottom of the card when in loading state */
  isLoading?: boolean;
  /** Component to display when the page is in a loading state */
  loader?: React.FunctionComponent;
  /** Array of strings to loop while the page is in a loading state */
  textLoopStrings?: string[];
  /** `LayoutPage` - hasHeaderOffset */
  hasHeaderOffset?: boolean;
  /** `LayoutItem` - Number of columns to span */
  layoutItemSpan?: number;
  /** `LayoutItem` - Responsive prop configuration */
  layoutItemResponsiveProps?: ResponsiveProps;
  /** `Card` - props */
  cardProps?: object;
  /** `Card` - icon badge to display when in loading state */
  icon?: IconType;
  /** `NavHeader` - props */
  navHeaderProps?: NavHeaderProps;
  /** height */
  height?: string | number;
  /**
   * Set to false for situations where isLoading is true but the
   * LoadingIndicator is not needed.
   */
  showLoadingIndicator?: boolean;
  /** A heading to display */
  heading?: string;
  /** A subheading to display */
  subheading?: string;
  /** Background color for LayoutPage */
  backgroundColor?: string;
};

const loadingClassName = css(getLoadingStyles());

const getLoader = ({
  LoaderComponent,
  textLoopStrings = ['Loading...'],
}: {
  LoaderComponent?: React.FunctionComponent;
  textLoopStrings?: string[];
}) => {
  if (LoaderComponent) {
    return <LoaderComponent />;
  }
  return (
    <Text textAlign="center" rank={2} as={tags.div}>
      <TextLoop interval={textLoopSpeed}>
        {textLoopStrings.map((item: string, index: number) => (
          <div key={index} className={textLoopItemClassName}>
            {item}
          </div>
        ))}
      </TextLoop>
    </Text>
  );
};

/** A page that displays a Card inside of LayoutPage, with an optional NavHeader, and Card badge */
const CardPage = ({
  children,
  isLoading = false,
  loader: LoaderComponent,
  textLoopStrings,
  layoutItemSpan = 12,
  layoutItemResponsiveProps = {
    [mediaQueries.gte.xl]: { span: 8 },
    [mediaQueries.gte.md]: { span: 10 },
  },
  icon,
  cardProps = { marginBottom: 0 },
  navHeaderProps,
  height,
  heading,
  subheading,
  showLoadingIndicator = true,
  hasHeaderOffset,
  backgroundColor,
}: CardPageProps) => {
  const hasBadge = Boolean(icon);

  const cardClassName = css(getCardStyles({ isLoading, hasBadge }));

  return (
    <Box
      block
      width="100%"
      height={height || (isLoading && navHeaderProps) ? '100%' : 'auto'}
    >
      {navHeaderProps && (
        <header className={headerWrapperClassName}>
          <NavHeader {...navHeaderProps} />
        </header>
      )}
      <LayoutPage
        hasHeaderOffset={hasHeaderOffset}
        backgroundColor={backgroundColor}
      >
        <LayoutContainer
          marginBottom={0}
          height="100%"
          responsiveProps={{
            [mediaQueries.lt.sm]: { hasInteriorGutter: false },
          }}
        >
          {heading || subheading ? (
            <LayoutItem span={12}>
              {heading ? (
                <Text
                  header
                  rank={2}
                  textAlign="center"
                  // If there's a subheading below, we want them to be closer
                  // together than to the proceeding content.
                  marginBottom={spacer(subheading ? 2 : 4)}
                  className={css({
                    maxWidth: 640,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingRight: spacer(3),
                    paddingLeft: spacer(3),
                  })}
                >
                  {heading}
                </Text>
              ) : null}
              {subheading ? (
                <Text
                  rank={2}
                  textAlign="center"
                  marginBottom={spacer(4)}
                  className={css({
                    maxWidth: 640,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    paddingRight: spacer(3),
                    paddingLeft: spacer(3),
                  })}
                >
                  {subheading}
                </Text>
              ) : null}
            </LayoutItem>
          ) : null}
          <LayoutItem
            span={layoutItemSpan}
            responsiveProps={layoutItemResponsiveProps}
          >
            <CardWithBadge
              backgroundColor={color.background.tertiary}
              marginBottom={0}
              icon={icon}
              className={cardClassName}
              // We extend the card full-bleed at mobile, so we remove the
              // side borders as well so that you don't have a border right up
              // against the edge of the browser window which feels odd.
              responsiveProps={{ [mediaQueries.lt.sm]: { sideBorders: false } }}
              {...cardProps}
            >
              {isLoading
                ? getLoader({ LoaderComponent, textLoopStrings })
                : children}
              {isLoading && showLoadingIndicator ? (
                <LoadingIndicator className={loadingClassName} />
              ) : null}
            </CardWithBadge>
          </LayoutItem>
        </LayoutContainer>
      </LayoutPage>
    </Box>
  );
};

export default CardPage;
