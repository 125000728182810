import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SmoothCollapse from 'react-smooth-collapse';

import {
  Product,
  quoteInteractionNames,
  utmTermByInsuranceIneligibilityType,
} from '@fabrictech/definitely-fabric';
import { Box } from '@fabrictech/tapestry';

import { SwatchWidget, GetQuotePremiumAu, CreateAnalyticsEvent } from './types';
import LeadForm from './LeadForm';
import EstimateForm, { EstimateFormProps } from './EstimateForm';
import IneligibilityWarning from './IneligibilityWarning';
import WidgetInstructions from './WidgetInstructions';
import FabricHeader from './FabricHeader';
import FabricLogo from './FabricLogo';
import AdIndicator from './AdIndicator';
import getStyles from './getStyles';
import useResponsiveContext from './useResponsiveContext';
import {
  shouldSetAnswersWithPrefilledLead,
  transformLeadToAnswersById,
} from './leadUtil';
import { webflow } from './constants';

const leadScreen = 'lead' as const;
const estimateScreen = 'estimate' as const;
const ineligibleScreen = 'ineligible' as const;
type WidgetScreen =
  | typeof leadScreen
  | typeof estimateScreen
  | typeof ineligibleScreen;

const isFulfilledResponse = (
  apiData?: GetQuotePremiumAu.Response
): apiData is GetQuotePremiumAu.Response => _.isObject(apiData);

const isIneligibleResponse = (
  apiData?: GetQuotePremiumAu.Response
): apiData is GetQuotePremiumAu.IneligibleResponse =>
  !_.get(apiData, 'isEligible', true);

const QuoteWidget = ({
  createAnalyticsEvent,
  getQuotePremiumAu,
  createQuotePremiumAu,
  source,
  utmParamString,
  prefilledLead = {},
  landingBaseUrl,
  landingUrl,
  isFabricHostedWidget,
  showHeaderText = true,
}: SwatchWidget.QuoteWidgetProps) => {
  const [widgetScreen, setWidgetScreen] = useState<WidgetScreen>(leadScreen);
  const [lead, setLead] = useState<Partial<GetQuotePremiumAu.Request>>(
    transformLeadToAnswersById(prefilledLead)
  );

  const {
    isInternalWidget,
    styles: {
      leadContainerClassname,
      baseContainerClassname,
      internalLeadContainerClassname,
      internalBaseContainerClassname,
      wrapperClassname,
      leadFormClassname,
    },
  } = useResponsiveContext(getStyles);

  // On the internal widget:
  // If the user navigates directly to the QuotePage, there is a point in time
  // when the widget has been rendered, but the lead hasn't been fetched from
  // the API yet. In order to prevent some jarring change to the form, we only
  // reset the lead form with the prefilled lead data if the form hasn't been
  // touched yet.
  useEffect(() => {
    if (isInternalWidget) {
      if (shouldSetAnswersWithPrefilledLead(lead, prefilledLead)) {
        setLead(transformLeadToAnswersById(prefilledLead));
      }
    }
  }, [isInternalWidget, prefilledLead, lead]);

  const handleLeadSubmit = (leadData: GetQuotePremiumAu.Request) => {
    const nextLead = {
      ...lead,
      ...leadData,
      ...(source ? { source } : {}),
    };
    setLead(nextLead);
    getQuotePremiumAu.execute(nextLead);
    const quoteInputs = _.omit(nextLead, ['leadId', 'source']);
    createAnalyticsEvent.execute({
      product: Product.premiumAu,
      name: quoteInteractionNames.quoteRequested,
      quoteInputs,
      source,
    });
  };

  const quotesResponse = getQuotePremiumAu.data;

  // On the NerdWallet version of the widget, we indicate that this widget is an Ad
  const isNerdWalletWidget = source === 'nerdWallet';
  // On Webflow widgets, we don't send UTM params
  const isWebflowWidget = _.startsWith(source, webflow);

  const extraQueryParams =
    utmParamString && !isWebflowWidget ? utmParamString : '';

  const navigateToOtherOptions = useCallback(() => {
    if (isIneligibleResponse(quotesResponse)) {
      const pathPart = 'term-life-insurance/other-options';
      const queryPart = `lead_id=${quotesResponse.leadId}&utm_term=${
        utmTermByInsuranceIneligibilityType[quotesResponse.ineligibilityType]
      }${extraQueryParams}`;

      const destinationUrl = `${landingBaseUrl}/${pathPart}?${queryPart}`;

      window.top.location.href = destinationUrl;
    }
  }, [extraQueryParams, quotesResponse, landingBaseUrl]);

  // Set leadId on state and change to Estimate screen
  useEffect(() => {
    if (isFulfilledResponse(quotesResponse)) {
      const { leadId } = quotesResponse;
      setLead(prev => ({
        ...prev,
        leadId,
      }));
      if (isIneligibleResponse(quotesResponse)) {
        if (isFabricHostedWidget) {
          navigateToOtherOptions();
        } else {
          setWidgetScreen(ineligibleScreen);
        }
      } else {
        setWidgetScreen(estimateScreen);
      }
    }
  }, [quotesResponse, isFabricHostedWidget, navigateToOtherOptions]);

  const goBack = () => {
    setWidgetScreen(leadScreen);
    createAnalyticsEvent.execute({
      product: Product.premiumAu,
      name: quoteInteractionNames.quoteGoBack,
      source,
    });
  };

  const handleEstimateFormAnalytics = (
    analyticsEvent: Omit<CreateAnalyticsEvent.Request, 'source'>
  ) => {
    createAnalyticsEvent.execute({
      ...analyticsEvent,
      source,
    });
  };
  const handleEstimateSubmit: EstimateFormProps['onSubmit'] = quote => {
    const { bandId, roundedCoverageAmount, ...quotePart } = quote;
    // this is for typescript, this should always be true at this step
    if (lead.leadId) {
      const quotePayload = {
        ...quotePart,
        leadId: lead.leadId,
      };
      // attach quote to lead
      createQuotePremiumAu.execute(quotePayload);
    }
  };

  useEffect(() => {
    if (createQuotePremiumAu.data) {
      const destinationUrl = `${landingUrl}?lead_id=${createQuotePremiumAu.data.leadId}${extraQueryParams}`;
      window.top.location.href = destinationUrl;
    }
  }, [createQuotePremiumAu.data, landingUrl, extraQueryParams]);

  const showLeadScreen = widgetScreen === leadScreen;

  const containerClassname = useMemo(() => {
    if (showLeadScreen) {
      return isInternalWidget
        ? internalLeadContainerClassname
        : leadContainerClassname;
    }
    return isInternalWidget
      ? internalBaseContainerClassname
      : baseContainerClassname;
  }, [
    baseContainerClassname,
    leadContainerClassname,
    internalLeadContainerClassname,
    internalBaseContainerClassname,
    isInternalWidget,
    showLeadScreen,
  ]);

  // On the Internal Quote Widget:
  //   Never show the logo
  // On the External Quote Widget:
  //   On the Lead Screen, the logo is rendered via `<WidgetInstructions />`
  //   On the Estimate/Ineligible Screens, render logo in this component
  const showFabricLogo = !(isInternalWidget || showLeadScreen);
  const showEstimateScreen = widgetScreen === estimateScreen;
  const showIneligibleScreen = widgetScreen === ineligibleScreen;

  return (
    <Box className={containerClassname}>
      {isNerdWalletWidget ? <AdIndicator /> : null}
      {showFabricLogo ? (
        <FabricHeader goBack={goBack}>
          <FabricLogo />
        </FabricHeader>
      ) : null}
      <Box className={wrapperClassname}>
        <SmoothCollapse expanded={showLeadScreen} allowOverflowWhenOpen>
          {showLeadScreen ? (
            <Box className={leadFormClassname}>
              {showHeaderText ? <WidgetInstructions /> : null}
              <LeadForm
                isLoading={getQuotePremiumAu.isLoading}
                leadData={lead}
                onSubmit={handleLeadSubmit}
              />
            </Box>
          ) : null}
        </SmoothCollapse>
        <SmoothCollapse expanded={!showLeadScreen} allowOverflowWhenOpen>
          {showEstimateScreen ? (
            <EstimateForm
              isLoading={createQuotePremiumAu.isLoading}
              quotesResponse={quotesResponse}
              goBack={goBack}
              onAnalyticsEvent={handleEstimateFormAnalytics}
              onSubmit={handleEstimateSubmit}
              isNerdWalletWidget={isNerdWalletWidget}
            />
          ) : null}
          {showIneligibleScreen ? (
            <IneligibilityWarning
              goBack={goBack}
              quotesResponse={quotesResponse}
              navigateToOtherOptions={navigateToOtherOptions}
            />
          ) : null}
        </SmoothCollapse>
      </Box>
    </Box>
  );
};

export default QuoteWidget;
