import React from 'react';
import { css } from 'glamor';

import getStyles from './getStyles';

import Button from '../../../Button';
import DocumentViewerControlEntry from './components/DocumentViewerControlEntry';

const className = css(getStyles());

const buttonProps = {
  rank: 'primary',
  shape: 'square',
  margin: 0,
};

const DocumentViewerControls = ({ handleZoomIn, handleZoomOut }) => {
  return (
    <div className={className}>
      <DocumentViewerControlEntry>
        <Button {...buttonProps} icon="plus" onClick={handleZoomIn} />
      </DocumentViewerControlEntry>
      <DocumentViewerControlEntry last>
        <Button {...buttonProps} icon="minus" onClick={handleZoomOut} />
      </DocumentViewerControlEntry>
    </div>
  );
};

export default DocumentViewerControls;
