import {
  typography,
  color as colorTokens,
  spacer,
  TextRank,
  Align,
} from '@fabrictech/design-tokens';

import { hover, active, visited, descendant } from '../../utils/css/selectors';

import tags from '../../constants/tags';
import {
  fontWeights,
  getStyleObjectFromFontStyle,
} from '../../constants/typography';

import { important, margin } from '../../utils/css/values';

const getStyles = ({
  rank,
  header,
  textAlign,
  maxWidth,
  color,
}: {
  rank: TextRank;
  header: boolean;
  textAlign: Align;
  maxWidth: string | number;
  color: string;
}) => {
  const fontStyle = header
    ? typography.header[rank]
    : typography.paragraph[rank];
  return {
    fontFeatureSettings: '"lnum" 1',
    fontVariantNumeric: 'lining-nums',
    margin: margin(0, 0, spacer(3), 0),
    maxWidth,
    textAlign,
    [descendant(tags.a)]: {
      textDecoration: 'underline',
      color: important(color || colorTokens.font.link),
      cursor: 'pointer',
      [visited]: { color: important(color || colorTokens.font.link) },
      [active]: { color: important(color || colorTokens.font.link) },
      [hover]: { color: important(color || colorTokens.font.link) },
    },
    [descendant(tags.sup)]: {
      fontWeight: fontWeights.bold,
      color: important(colorTokens.font.secondary),
    },
    [descendant(tags.strong)]: {
      // Because our default font-weight is 200,
      // and the reset.css sets <strong> to use `font-weight: bolder`, which goes up to the next weight
      // we have to override the reset here to use "bold"
      fontWeight: fontWeights.bold,
    },
    [descendant(tags.abbr)]: {
      textDecoration: 'none',
      borderBottom: `2px dashed ${colorTokens.font.secondary}`,
      cursor: 'help',
    },
    ...getStyleObjectFromFontStyle(fontStyle),
    ...(color ? { color } : {}),
  };
};

export default getStyles;
