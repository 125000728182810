import React from 'react';
import { Text, TextProps } from '@fabrictech/tapestry';
import { OnClickEvent } from '@fabrictech/tapestry/types';

type LinkButtonProps = Pick<TextProps, 'children' | 'className' | 'rank'> & {
  onClick: () => void;
};

const LinkButton = ({
  children,
  className,
  onClick: baseHandleClick,
  rank,
}: LinkButtonProps) => {
  const handleClick = (event: OnClickEvent) => {
    event.preventDefault();
    baseHandleClick();
  };
  const textProps = {
    className,
    rank,
  };

  return (
    <Text {...textProps}>
      <a href="#" role="button" onClick={handleClick}>
        {children}
      </a>
    </Text>
  );
};

export default LinkButton;
