import { CALL_API } from 'redux-api-middleware';
import _ from 'lodash';
import counter from './counter';
import apiActionTypes from './actionTypes';

const { REQUEST, SUCCESS, FAILURE, DESTROY } = apiActionTypes;

/**
 * Destructure the action to access method and endpoint.
 */
const makeOverrideMeta = (key, reducer, count, meta) => (
  action,
  state,
  response
) => {
  const {
    [CALL_API]: { method, endpoint },
  } = action;
  const value = _.isFunction(meta) ? meta(action, state, response) : meta;
  return {
    ...value,
    key,
    reducer,
    method,
    endpoint,
    count,
  };
};

/**
 * Store important information about the request in the `meta` property. For example, the `reducer` property
 * will be used in the redux-api reducer.
 */
const makeOverrideTypes = (
  key,
  count,
  { reducers = {}, payloads = {}, metas = {} } = {}
) => [
  {
    type: REQUEST,
    meta: makeOverrideMeta(key, reducers.request, count, metas.request),
    ...(payloads.request && { payload: payloads.request }),
  },
  {
    type: SUCCESS,
    meta: makeOverrideMeta(key, reducers.success, count, metas.success),
    ...(payloads.success && { payload: payloads.success }),
  },
  {
    type: FAILURE,
    meta: makeOverrideMeta(key, reducers.failure, count, metas.failure),
    ...(payloads.failure && { payload: payloads.failure }),
  },
];

const mergeMeta = (clientMeta, overrideMeta) => {
  if (_.isFunction(clientMeta)) {
    return (...args) =>
      Object.assign({}, clientMeta(...args), overrideMeta(...args));
  } else if (_.isPlainObject(clientMeta)) {
    return (...args) => Object.assign({}, clientMeta, overrideMeta(...args));
  }
  return overrideMeta;
};

const mergeType = (clientType, overrideTypes) => {
  if (_.isPlainObject(clientType)) {
    const meta = mergeMeta(clientType.meta, overrideTypes.meta);
    return Object.assign({}, clientType, overrideTypes, { meta });
  }
  // We don't support dispatching custom actions yet
  return overrideTypes;
};

const mergeTypes = (clientTypes, overrideTypes) => {
  if (_.isPlainObject(clientTypes)) {
    const { request, success, failure } = clientTypes;
    return [request, success, failure].map((clientType, index) =>
      mergeType(clientType, overrideTypes[index])
    );
  }
  return overrideTypes;
};

const createTypes = (key, { types, ...options }, count) => {
  const overrideTypes = makeOverrideTypes(key, count, options);
  return mergeTypes(types, overrideTypes);
};

/**
 * Valid RSAA action creator for redux-api-middleware. `reducers` is not a valid property
 * in the redux-api-middleware RSAA. Hence, it is removed.
 */
const createCallApi = (key, count) => api => {
  const { reducers, payloads, metas, ...rsaa } = api;
  const types = createTypes(key, api, count);
  return {
    [CALL_API]: Object.assign({}, rsaa, { types }),
  };
};

/**
 * CALL_API action creator. `api` can be either a function or plain object.
 * Used by execute to start tracking api requests
 */
export const callApiAndIncrement = (key, api) => {
  const count = counter.increment(key);
  const actionCreator = createCallApi(key, count);
  if (_.isFunction(api)) {
    return (...args) => actionCreator(api(...args));
  } else if (_.isPlainObject(api)) {
    return actionCreator(api);
  }
  throw Error('Api is not a function or plain object.');
};

/**
 * Destroy action creator
 */
export const destroy = key => ({
  type: DESTROY,
  meta: { key },
});
