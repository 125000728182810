export type Align = 'left' | 'center' | 'right';

export type VerticalAlign = 'top' | 'center' | 'bottom';

export type FlexAlign = Align | VerticalAlign | 'stretch';

export type FlexDirection = 'row' | 'column';

const spacingBase = 8;

export const spacer = (rank: number): number => rank * spacingBase;

const gutterWidth = spacer(3);
const minWidth = 320;

export const layout = {
  gutterWidth,
  minWidth,
} as const;
