import React from 'react';
import { css } from 'glamor';

import { color, spacer } from '@fabrictech/design-tokens';

import Box from '../../../Box';
import Text from '../../../Text';

const FormSectionHeader = ({
  caption,
  subcaption,
}: {
  /** Caption for the section */
  caption?: string;
  /** Subcaption for the section */
  subcaption?: string;
}) => (
  <Box align="center" flexDirection="column">
    {caption && (
      <Text header textAlign="center">
        {caption}
      </Text>
    )}
    {subcaption && (
      <Text rank={2} textAlign="center">
        {subcaption}
      </Text>
    )}
    <Box align="center" width="100%">
      <Box
        block
        background={color.base.accent}
        padding={1}
        marginBottom={spacer(7)}
        className={css({ width: spacer(10) })}
      />
    </Box>
  </Box>
);

export default FormSectionHeader;
