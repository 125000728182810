import { color } from '../color';

export type Shadow = {
  color: string;
  opacity: number;
  offset: { width: number; height: number };
  blur?: number;
  spread?: number;
};

const focus: Shadow = {
  color: color.base.accent,
  opacity: 0.2,
  offset: {
    width: 0,
    height: 0,
  },
  blur: 0,
  spread: 4,
};

const standard: Shadow = {
  color: color.background.overlay,
  opacity: 0.06,
  offset: {
    width: 8,
    height: 8,
  },
  blur: 0,
};

const soft: Shadow = {
  color: color.background.overlay,
  opacity: 0.2,
  offset: {
    width: 0,
    height: 4,
  },
  blur: 8,
};

export const shadows = {
  standard,
  focus,
  soft,
} as const;
