import {
  getStyleObjectFromFontStyle,
  mediaQueries,
  mergeResponsiveStyles,
} from '@fabrictech/tapestry';
import { marketingParagraph } from '@fabrictech/tapestry/components/MarketingText/getStyles';
import createGetStyles from '../../createGetStyles';

const mobileBreakpoint = 'mobileBreakpoint';

const disclaimerTextFont = getStyleObjectFromFontStyle(
  marketingParagraph[4],
  mobileBreakpoint
);
const baseDisclaimerTextStyles = mergeResponsiveStyles(
  {
    marginBottom: 0,
    mobileBreakpoint: {
      marginBottom: 0,
    },
    [mediaQueries.lt.sm]: {
      marginBottom: 0,
    },
  },
  disclaimerTextFont
);

const internalDisclaimerFontStyles = {
  ...marketingParagraph[4],
  fontSize: {
    ...marketingParagraph[4].fontSize,
    mobile: marketingParagraph[4].fontSize.desktop,
  },
  lineHeight: {
    ...marketingParagraph[4].lineHeight,
    mobile: marketingParagraph[4].lineHeight.desktop,
  },
};

const internalDisclaimerTextFont = getStyleObjectFromFontStyle(
  internalDisclaimerFontStyles,
  mobileBreakpoint
);

const internalDisclaimerTextStyles = mergeResponsiveStyles(
  baseDisclaimerTextStyles,
  internalDisclaimerTextFont
);

const styles = {
  baseDisclaimerTextStyles,
  internalDisclaimerTextStyles,
};

export default createGetStyles(styles);
