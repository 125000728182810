import _ from 'lodash';
import { responsiveProps } from './constants';

const createGlobalPropsFilter = ({ props, propKeys, filterFn }) => {
  const filteredProps = filterFn(props, propKeys);
  return filteredProps[responsiveProps]
    ? {
        ...filteredProps,
        [responsiveProps]: _.mapValues(
          filteredProps[responsiveProps],
          respProps => filterFn(respProps, propKeys)
        ),
      }
    : filteredProps;
};

export default createGlobalPropsFilter;
