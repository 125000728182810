import _ from 'lodash';

import overrideSpacing from '../../../overrideSpacing';

const filterSizeProps = props =>
  _.pickBy(props, e => _.isNumber(e) || _.isString(e));

const directions = ['Top', 'Right', 'Bottom', 'Left'];

const createGetStylesConfigurableSpacing = sizeProp => {
  // ex: ['marginTop', 'marginRight', 'marginBottom', 'marginLeft', 'margin']
  const sizeProps = directions
    .map(direction => [sizeProp, direction].join(''))
    .concat(sizeProp);

  return getStyles => props => {
    const overrides = _.pick(props, sizeProps);
    const restProps = _.omit(props, sizeProps);

    const styles = getStyles(restProps);

    if (_.isEmpty(filterSizeProps(overrides))) {
      return styles;
    }

    const initialSizes = _.pick(styles, sizeProps);
    const restStyles = _.omit(styles, sizeProps);

    return {
      ...restStyles,
      [sizeProp]: overrideSpacing(sizeProp, initialSizes, overrides),
    };
  };
};

export default createGetStylesConfigurableSpacing;
