import React from 'react';
import PropTypes from 'prop-types';

import LogoAlternate from '@fabrictech/tapestry/components/Logo/LogoAlternate';
import tags from '../../../../constants/tags';
import { AsLinkProps, ResponsiveProps } from '../../../../types';

export type NavWordmarkProps = {
  inverse?: boolean;
  height?: number;
  width?: number;
  responsiveProps?: ResponsiveProps;
} & AsLinkProps;

// Custom dimensions for the wordmark glyph as prescribed
// by design for it to appear in the Nav.
export const navWordmarkDimensions = {
  gerberLife: {
    desktop: {
      width: 142,
      height: 65,
    },
    mobile: {
      width: 132,
      height: 61,
    },
  },
  legacy: {
    desktop: {
      width: 102,
      height: 40,
    },
    mobile: {
      width: 75,
      height: 24,
    },
  },
};

/** Displays the Fabric wordmark */
const NavWordmark = ({
  as: Component = 'span',
  inverse,
  responsiveProps,
  ...rest
}: NavWordmarkProps) => (
  // We want the root component to be able to act as a link
  // for ease of use, so we spread rest.
  <Component {...rest}>
    <LogoAlternate
      inverse={inverse}
      width={navWordmarkDimensions.gerberLife.desktop.width}
      height={navWordmarkDimensions.gerberLife.desktop.height}
      widthMobile={navWordmarkDimensions.gerberLife.mobile.width}
      heightMobile={navWordmarkDimensions.gerberLife.mobile.height}
    />
  </Component>
);

NavWordmark.propTypes = {
  /** The component should be used under the hood */
  as: PropTypes.any,
  inverse: PropTypes.bool,
};

NavWordmark.defaultProps = {
  as: tags.a,
  inverse: false,
};

export { NavWordmark };
