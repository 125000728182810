type BuildInfoWindow = typeof window &
  Partial<{
    fabric: {
      env: {
        TRAVIS_BUILD_NUMBER: string;
        TRAVIS_COMMIT: string;
      };
    };
  }>;

export const getBuildInfo = () => {
  const { fabric } = window as BuildInfoWindow;
  return fabric && fabric.env;
};
