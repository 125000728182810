/**
 * Available icons in Quilt.
 * **LINEN DUPLICATION WARNING**: Keep these in sync.
 *   /src/lib/quilt/constants
 */

import bookmark from './svg/bookmark.svg';
import checklist from './svg/checklist.svg';
import file from './svg/file.svg';
import gear from './svg/gear.svg';
import lock from './svg/lock.svg';
import person from './svg/person.svg';
import pie from './svg/pie.svg';
import star from './svg/star.svg';

export const glyphs = {
  bookmark,
  checklist,
  file,
  gear,
  lock,
  person,
  pie,
  star,
};

export type GlyphType = keyof typeof glyphs;
