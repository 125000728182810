import { input, color } from '@fabrictech/design-tokens';
import {
  inputTextStyles,
  inputStyles,
  inputPadding,
} from '../../../../sharedStyles/vars/input';

export const getLabelStyles = () => ({
  display: 'block',
  width: '100%',
  minHeight: input.layout.height,
  ...inputTextStyles,
  ...inputPadding,
});

export const getParentStyles = ({ last }: { last?: boolean }) => ({
  ...inputStyles.base,
  borderBottomColor: last ? color.input.base.border : 'transparent',
});
