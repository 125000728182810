import { FontStyle, FontFamily, FontWeight } from '@fabrictech/design-tokens';
import * as mediaQueries from '../sharedStyles/vars/mediaQueries';
import { toPx } from '../utils/css/values/px';

export const fontWeights: { [key in FontWeight]: string | number } = {
  light: 200,
  normal: 'normal',
  semibold: 600,
  bold: 'bold',
  black: 900,
};

export const fontStackByFontFamily: { [key in FontFamily]: string } = {
  Calluna: 'Calluna',
  'Calluna Sans': [
    'Calluna Sans',
    'Avenir Next',
    'Helvetica Neue',
    'Segoe UI',
    'Helvetica',
    'Arial',
    'sans-serif',
  ].join(', '),
  Inter: 'Inter',
};

export const getStyleObjectFromFontStyle = (
  fontStyle: FontStyle,
  mobileBreakpoint: string = mediaQueries.lt.md
) => {
  const {
    fontFamily,
    fontSize,
    lineHeight,
    fontWeight,
    color,
    textTransform,
    letterSpacing,
  } = fontStyle;
  return {
    fontSize: fontSize.desktop,
    lineHeight: toPx(lineHeight.desktop),
    [mobileBreakpoint]: {
      fontSize: fontSize.mobile,
      lineHeight: toPx(lineHeight.mobile),
    },
    fontFamily: fontStackByFontFamily[fontFamily],
    fontWeight: fontWeights[fontWeight],
    color,
    textTransform,
    letterSpacing,
  };
};

type FontSizeAttr = keyof Pick<FontStyle, 'fontSize' | 'lineHeight'>;
const fontSizeAttributes: FontSizeAttr[] = ['fontSize', 'lineHeight'];

// Use this util to merge two `FontStyle` objects to get a new `FontStyle`
// object with `fontSize` and `lineHeight` on mobile from the second source.
export const mergeTextStyles = (
  desktopSource: FontStyle,
  mobileSource: FontStyle
) =>
  fontSizeAttributes.reduce(
    (acc, attr) => ({
      ...acc,
      [attr]: {
        ...acc[attr],
        mobile: mobileSource[attr].mobile,
      },
    }),
    desktopSource
  );
