import { input, border, spacer, color } from '@fabrictech/design-tokens';

import { padding } from '../../utils/css/values';
import { focus, before } from '../../utils/css/selectors';
import { focusShadow } from '../../sharedStyles/vars/boxShadow';

const { borderRadius } = border;

const thumb = '& .rc-slider-handle';
const rail = '& .rc-slider-rail';
const track = '& .rc-slider-track';

const sliderStyles = {
  railHeight: spacer(1.25),
  border: `${input.styles.base.borderWidth}px ${input.styles.base.borderStyle} ${color.input.base.border}`,
  borderColor: color.input.base.border,
};

const getMirrorImageBoxShadowStyle = ({
  verticalOffset,
  backgroundColor,
}: {
  verticalOffset: string;
  backgroundColor: string;
}) => `0px ${verticalOffset} 0px 0px ${backgroundColor}`;

const getStyles = ({ disabled }: { disabled: boolean }) => ({
  // To appear disabled, we simply dim the whole thing.
  opacity: disabled ? 0.6 : 1,

  // The height for the entire slider, thumb and all.
  height: spacer(5),
  width: '100%',
  padding: padding(spacer(2), 0),
  position: 'relative',
  touchAction: 'none',

  [rail]: {
    background: color.palette.white,
    position: 'absolute',
    left: 0,
    border: sliderStyles.border,
    borderRadius,
    height: sliderStyles.railHeight,
    width: '100%',
    cursor: disabled ? 'default' : 'pointer',
  },
  [track]: {
    position: 'absolute',
    height: sliderStyles.railHeight,
    border: sliderStyles.border,
    borderColor: sliderStyles.borderColor,
    backgroundColor: disabled
      ? color.input.disabled.accent
      : color.input.active.accent,
    borderRadius,
  },
  [thumb]: {
    cursor: disabled ? 'default' : 'pointer',
    border: sliderStyles.border,
    display: 'inline-block',
    position: 'absolute',
    width: spacer(2.5),
    height: spacer(3),
    // Add the top "tick" mark inside the thumb. The additional
    // marks will be evenly-spaced mirror images of this one
    // via box-shadows below.
    [before]: {
      content: ' ',
      display: 'block',
      // Height / color are meant to reflect the rail.
      height: input.styles.base.borderWidth,
      backgroundColor: sliderStyles.borderColor,
      position: 'absolute',
      // Center the tick-mark. It'll automatically take up as much
      // space as it can within these confines.
      left: spacer(0.5),
      right: spacer(0.5),
      // Aligns the top interior border with the top-border of the
      // rail.
      top: spacer(0.75),
      // Two evenly spaced box-shadows that show below the main
      // tick-mark above to create the illusion of three discrete
      // tick-marks.
      boxShadow: [
        getMirrorImageBoxShadowStyle({
          verticalOffset: `${spacer(0.5)}px`,
          backgroundColor: sliderStyles.borderColor,
        }),
        getMirrorImageBoxShadowStyle({
          verticalOffset: `${spacer(1)}px`,
          backgroundColor: sliderStyles.borderColor,
        }),
      ].join(', '),
    },
    top: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    backgroundColor: disabled
      ? color.input.disabled.accent
      : color.input.active.accent,
    pointerEvents: disabled ? 'none' : 'auto',
    touchAction: 'pan-x',
    boxShadow: '0 0 0 transparent',
    transition: 'box-shadow 0.15s',
    [focus]: {
      outline: '1px solid transparent',
      boxShadow: focusShadow,
    },
  },
});

export default getStyles;
