import React from 'react';
import { css } from 'glamor';
import getStyles from './getStyles';

import InputWrapper from '../InputWrapper';

const inputClassName = css(getStyles()).toString();

type InputFileProps = React.HTMLProps<HTMLInputElement> & {
  /** Width */
  width?: number | string;
  /** Disabled */
  disabled?: boolean;
  /** Defines the file types the file input should accept */
  accept?: string;
  /** Lists selected file */
  files?: FileList;
  /** Allows the user to select more than one file */
  multiple?: boolean;
  /** Displays a label that floats above the selected value */
  label?: string;
};

/** Input for uploading files */
export const InputFile = ({
  disabled,
  label,
  width,
  ...rest
}: InputFileProps) => (
  <InputWrapper hasValue={true} label={label} disabled={disabled} width={width}>
    <input
      type="file"
      disabled={disabled}
      className={inputClassName}
      {...rest}
    />
  </InputWrapper>
);

export default InputFile;
