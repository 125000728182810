import { fromJS, Iterable } from 'immutable';
import { connect } from 'react-redux';
import _ from 'lodash';

import { callApiAndIncrement, destroy } from './actions';
import * as keyPath from './keyPath';

import {
  getImmutableRequestPropName,
  getImmutableSuccessPropName,
  getImmutableFailurePropName,
  getImmutablePendingPropName,
  getImmutableExecutePropName,
  getImmutableDestroyPropName,
} from './propNames';

/**
 * Gets whether or not a request is pending.
 * @param request
 * @param success
 * @param failure
 * @return {boolean}
 */
export const getPending = (request, success, failure) =>
  request && !request.error && !success && !failure;

const getRawApiKeyState = (state, key) => {
  // if state is an Immutable object, grab things using Immutable's api
  if (Iterable.isIterable(state)) {
    return {
      request: state.getIn(keyPath.getRequest(key)),
      success: state.getIn(keyPath.getSuccess(key)),
      failure: state.getIn(keyPath.getFailure(key)),
      counter: state.getIn(keyPath.getCounter(key)),
    };
  }

  // else, state is a js object, pull the values normally
  return {
    request: _.get(state, keyPath.getRequest(key)),
    success: _.get(state, keyPath.getSuccess(key)),
    failure: _.get(state, keyPath.getFailure(key)),
    counter: _.get(state, keyPath.getCounter(key)),
  };
};

/**
 * Given the redux state and a redux-api api-key, gets request,
 * success, and failure state for the corresponding API and
 * infers pending state. Consolidates logic for several
 * mapStateToProps calls.
 * @param {Immutable.Map} state The redux state
 * @param {string} key The redux-api api-key
 * @returns {object}
 */
const mapRequestState = (state, key) => {
  const { request, success, failure, counter } = getRawApiKeyState(state, key);
  const pending = getPending(request, success, failure);

  return { request, success, failure, pending, counter };
};

/**
 * Creates a mapStateToProps fn that maps each redux-api
 * key to its corresponding request-state as returned by
 * mapRequestState. To be used with react-redux connect.
 * @param {object} apis A redux-api configuration object
 * @return {function}
 */
const createMapStateToProps = apis => state =>
  Object.keys(apis).reduce(
    (accumulator, key) =>
      Object.assign(accumulator, {
        [key]: mapRequestState(state, key),
      }),
    {}
  );

/**
 * Same as above except each redux-api key here is mapped
 * to top-level keys that each represent a slice of request
 * state. Allows for optional filtering.
 * @param {object} apis A redux-api configuration object
 * @param {object} options Configuration for this function
 * @param {?object} options.propBaseNameMapping A mapping of api base names to custom strings
 * @return {function}
 */
export const createMapStateToReduxApiPropsImmutable = (
  apis,
  { propBaseNameMapping = {} } = {}
) => state =>
  _.reduce(
    apis,
    (acc, api, key) => {
      const { request, success, failure, pending } = mapRequestState(
        state,
        key
      );

      const propBaseName = propBaseNameMapping[key] || key;

      // For now, since v1 of redux-api contains plain-JS nested inside
      // immutable Maps, we have to cast that plain-JS to immutable.
      // TODO: Fix this
      acc[getImmutableRequestPropName(propBaseName)] =
        request && fromJS(request);
      acc[getImmutableSuccessPropName(propBaseName)] =
        success && fromJS(success);
      acc[getImmutableFailurePropName(propBaseName)] =
        failure && fromJS(failure);

      // Don't need to run fromJS here since these are primitives.
      acc[getImmutablePendingPropName(propBaseName)] = pending;
      return acc;
    },
    {}
  );

/**
 * Gets the action creator we wish to invoke and invokes it wrapped
 * in a redux dispatch call. Used when mapping dispatch to props.
 * @param {function} dispatch The redux-dispatch fn
 * @param {string|symbol|number} key The redux-api api-key
 * @param {object|function} api The redux-api RSAA or function that returns one
 * @return {any => any}
 */
export const createExecute = (dispatch, key, api) => (...args) => {
  const result = callApiAndIncrement(key, api);
  return dispatch(_.isFunction(result) ? result(...args) : result);
};

/**
 * Creates a mapDispatchToProps fn that maps each redux-api
 * api-key to an object nested under which are dispatchers.
 * @param {object} apis A redux-api configuration object
 * @return {function}
 */
const createMapDispatchToProps = apis => dispatch =>
  _.reduce(
    apis,
    (acc, api, key) => {
      acc[key] = {
        execute: (...args) => createExecute(dispatch, key, api)(...args),
        destroy: () => dispatch(destroy(key)),
      };
      return acc;
    },
    {}
  );

/**
 * Creates a mapDispatchToProps fn that maps each redux-api
 * api-key to a flat object with a key for each dispatcher,
 * prefixed by the api-key.
 * @param {object} apis A redux-api configuration object
 * @param {object} options Configuration for this function
 * @param {?object} options.propBaseNameMapping A mapping of api base names to custom strings
 * @return {function}
 */
export const createMapDispatchToPropsImmutable = (
  apis,
  { propBaseNameMapping = {} } = {}
) => dispatch =>
  _.reduce(
    apis,
    (acc, api, key) => {
      const propBaseName = propBaseNameMapping[key] || key;

      return Object.assign(acc, {
        [getImmutableExecutePropName(propBaseName)]: (...args) =>
          createExecute(dispatch, key, api)(...args),
        [getImmutableDestroyPropName(propBaseName)]: () =>
          dispatch(destroy(key)),
      });
    },
    {}
  );

/**
 * Creates a mergeProps function used to merge the results of
 * mapStateToProps and mapDispatchToProps - necessary because
 * their results contain the same top-level keys and thus would
 * be overwritten by the default shallow merge.
 * @param {object} apis A redux-api configuration object
 * @return {function}
 */
const createMergeProps = apis => (stateProps, dispatchProps, ownProps) => {
  const apiProps = _.reduce(
    apis,
    (acc, api, key) =>
      Object.assign(acc, {
        [key]: Object.assign({}, stateProps[key], dispatchProps[key]),
      }),
    {}
  );
  return Object.assign({}, ownProps, apiProps);
};

/**
 * The default redux-api connector.
 * @param {object} apis A redux-api configuration object
 * @return {function}
 */
const connector = apis =>
  connect(
    createMapStateToProps(apis),
    createMapDispatchToProps(apis),
    createMergeProps(apis)
  );

/**
 * A redux-api connector that provides unnested props. This eliminates
 * performance bottlenecks and allows for use of ImmutablePureComponent.
 * @param {object} apis A redux-api configuration object
 * @return {function}
 */
export const connectorImmutable = apis =>
  connect(
    createMapStateToReduxApiPropsImmutable(apis),
    createMapDispatchToPropsImmutable(apis)
  );

export default connector;
