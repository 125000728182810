import React from 'react';
import { css } from 'glamor';
import _ from 'lodash';
import { spacer, sizes } from '@fabrictech/design-tokens';

import Box from '../../../Box';
import Tooltip from '../../../Tooltip';
import Icon from '../../../Icon';

import {
  getParentStyles,
  getLabelStyles,
  getInputStyles,
  getTooltipStyles,
} from './getStyles';

class Selection extends React.Component {
  id = _.uniqueId('selection');

  render() {
    const {
      className,
      children,
      disabled,
      first,
      last,
      borderless,
      checked,
      value,
      id, // prevent override of ID
      type,
      icon,
      width,
      tooltip,
      ...rest
    } = this.props;

    const parentClassName = css(className, getParentStyles());

    const inputClassName = css(
      getInputStyles({
        borderless,
        disabled,
        isSingleSelect: type === 'radio',
      })
    );

    const labelClassName = css(
      getLabelStyles({
        borderless,
        first,
        last,
        disabled,
        tooltip,
      })
    );

    const tooltipClassName = css(getTooltipStyles({ disabled }));

    const inputProps = {
      ...rest,
      className: inputClassName,
      type,
      id: this.id,
      disabled,
      checked,
      value: value || this.id,
    };

    return (
      <div className={parentClassName}>
        <input {...inputProps} />
        <label className={labelClassName} htmlFor={this.id}>
          {children}
        </label>
        {tooltip ? (
          <Box
            className={tooltipClassName}
            height={sizes.icon.xs}
            width={sizes.icon.xs}
            align="center"
            verticalAlign="center"
          >
            {/** Setting padding to be larger than icon size to provide a larger touch target size for tooltip */}
            <Tooltip content={tooltip}>
              <Box padding={spacer(1.5)}>
                <Icon type="info" size="xs" />
              </Box>
            </Tooltip>
          </Box>
        ) : null}
      </div>
    );
  }
}

Selection.defaultProps = {
  type: 'checkbox',
};

export default Selection;
