import React from 'react';
import { Box, MarketingText } from '@fabrictech/tapestry';
import FabricHeader from '../../FabricHeader';
import getStyles from './getStyles';
import { parseToDollarsAndCentsString } from '../currencyUtils';
import useResponsiveContext from '../../useResponsiveContext';

type EstimateViewerHeaderProps = {
  goBack: () => void;
  isInternalWidget: boolean;
  children: React.ReactElement;
};

const EstimateViewerHeader = ({
  goBack,
  isInternalWidget,
  children,
}: EstimateViewerHeaderProps) => {
  if (isInternalWidget) {
    return (
      <FabricHeader goBack={goBack}>
        <Box>{children}</Box>
      </FabricHeader>
    );
  }
  return children;
};

type EstimateViewerProps = {
  goBack: () => void;
  value: number;
};

const EstimateViewer = ({ goBack, value }: EstimateViewerProps) => {
  const {
    isInternalWidget,
    styles: { labelClassname, valueClassname },
  } = useResponsiveContext(getStyles);
  return (
    <Box block>
      <EstimateViewerHeader goBack={goBack} isInternalWidget={isInternalWidget}>
        <MarketingText header rank={4} className={labelClassname}>
          Your estimate:
        </MarketingText>
      </EstimateViewerHeader>
      <MarketingText rank={1} className={valueClassname}>
        <strong>{parseToDollarsAndCentsString(value)}</strong>/mo
      </MarketingText>
    </Box>
  );
};

export default EstimateViewer;
