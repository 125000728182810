import React from 'react';
import { InputSlider, InputSliderProps } from '@fabrictech/tapestry';

const CoverageSlider = ({
  min,
  max,
  onChange,
  onAfterChange,
  value,
}: InputSliderProps) => (
  <InputSlider
    min={min}
    max={max}
    onChange={onChange}
    onAfterChange={onAfterChange}
    value={value}
    marginBottom={0}
    width="100%"
  />
);

export default CoverageSlider;
