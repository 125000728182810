import React from 'react';
import { css } from 'glamor';
import createGetStylesWithGlobalProps from '../../createGetStylesWithGlobalProps';

const createPrimitiveComponent = ({
  tagName: Component = 'div',
  getStyles: getStylesFromLocalProps,
  displayName,
  disableGlobalProps,
}) => {
  if (!displayName) {
    // eslint-disable-next-line no-console
    console.warn(
      'createPrimitiveComponent created a component without a "displayName" :('
    );
  }

  const getStyles = disableGlobalProps
    ? getStylesFromLocalProps
    : createGetStylesWithGlobalProps(getStylesFromLocalProps);

  const PrimitiveComponent = ({ className, children, ...rest }) => {
    className = className || css(getStyles(rest));
    return <Component className={className}>{children}</Component>;
  };

  PrimitiveComponent.displayName = displayName;

  return PrimitiveComponent;
};

export default createPrimitiveComponent;
