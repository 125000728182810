import React from 'react';

import { createLocalForm } from '@fabrictech/questionnaire-web';
import {
  Box,
  InputDropdown,
  InputToggle,
  mediaQueries,
} from '@fabrictech/tapestry';
import { OnClickEvent } from '@fabrictech/tapestry/types';
import { spacer } from '@fabrictech/design-tokens';

import FormButton from '../FormButton';
import { GetQuotePremiumAu } from '../types';
import useLeadFormAnswers from './useLeadFormAnswers';
import { gender } from './questionnaire/optionsById';
import getStyles from './getStyles';
import useResponsiveContext from '../useResponsiveContext';

const getInputForInputType = ({ questionId }: { questionId: string }) => {
  if (questionId === gender.id) {
    return InputToggle;
  }
  return InputDropdown;
};
const localFormInputProps = {
  marginBottom: spacer(2),
  responsiveProps: {
    [mediaQueries.lt.md]: {
      marginBottom: spacer(2),
    },
  },
};
export const LeadLocalForm = createLocalForm({
  getInputForInputType,
  // @ts-ignore - All of LocalForm hasn't been properly typed yet, and has some errors
  localFormInputProps,
});

const isFullLead = (
  leadData: Partial<GetQuotePremiumAu.Request>,
  isCompleted: boolean
): leadData is GetQuotePremiumAu.Request => isCompleted;

type LeadFormProps = {
  isLoading?: boolean;
  leadData: Partial<GetQuotePremiumAu.Request>;
  onSubmit: (leadData: GetQuotePremiumAu.Request) => void;
};

const LeadForm = ({
  isLoading,
  leadData,
  onSubmit: baseHandleSubmit,
}: LeadFormProps) => {
  const {
    isInternalWidget,
    styles: { containerClassname, buttonWrapperClassname },
  } = useResponsiveContext(getStyles);
  const {
    answersById,
    onUpdateData,
    questionnaireInstance,
  } = useLeadFormAnswers(leadData, isInternalWidget);

  const handleSubmit = (
    event: React.FormEvent<HTMLFormElement> | OnClickEvent
  ) => {
    event.preventDefault();
    if (isFullLead(answersById, questionnaireInstance.completed)) {
      baseHandleSubmit(answersById);
    }
  };
  return (
    <Box className={containerClassname}>
      <form name="lead" onSubmit={handleSubmit}>
        <LeadLocalForm
          questionnaireInstance={questionnaireInstance}
          onUpdateData={onUpdateData}
        />
        <Box className={buttonWrapperClassname}>
          <FormButton
            disabled={!questionnaireInstance.completed}
            isLoading={isLoading}
            onClick={handleSubmit}
          >
            See your estimate
          </FormButton>
        </Box>
      </form>
    </Box>
  );
};

export default LeadForm;
