import _ from 'lodash';

const createAddSortedArrayValue = baseArr => (arr = [], value) => {
  let index = _.findIndex(baseArr, entry => _.isEqual(entry, value)) - 1;
  while (index > -1 && !_.includes(arr, baseArr[index])) {
    index -= 1;
  }
  arr = arr.slice(0);
  arr.splice(Math.max(0, index), 0, value);
  return arr;
};

export default createAddSortedArrayValue;
