import _ from 'lodash';
import getMetaTagByName from '../util/getMetaTagByName';

const transformContentAttributeValueToString = obj =>
  _.reduce(
    obj,
    (acc, val, key) => {
      acc.push(`${key}=${val}`);
      return acc;
    },
    []
  ).join(', ');

const setMetaContent = (name, content) => {
  const metaTag = getMetaTagByName(name);
  const newContent = transformContentAttributeValueToString(content);
  metaTag.setAttribute('content', newContent);
};

export default setMetaContent;
