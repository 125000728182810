import * as mediaQueries from '@fabrictech/tapestry/sharedStyles/vars/mediaQueries';
import { spacer } from '@fabrictech/design-tokens';

export const getTextLoopItemStyles = () => ({
  /** Added style to fix bug in TextLoop with centered text: items need a set width */
  textAlign: 'center',
  width: spacer(60),
  [mediaQueries.lt.md]: {
    width: spacer(40),
  },
  [mediaQueries.lt.sm]: {
    width: spacer(34),
  },
});
export const getCardStyles = ({
  isLoading,
  hasBadge,
}: {
  isLoading?: boolean;
  hasBadge?: boolean;
}) => ({
  ...(isLoading && !hasBadge
    ? {
        [mediaQueries.lt.sm]: {
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      }
    : {}),
});

export const getLoadingStyles = () => ({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
});

export const getHeaderWrapperStyles = () => ({
  width: '100%',
  position: 'sticky',
  top: 0,
  left: 0,
  zIndex: 1000,
});
