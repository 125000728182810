const doUse = 'do';
const doNotUse = 'do not';

const tobaccoValues = [
  { label: doNotUse, value: false },
  { label: doUse, value: true },
];

export const tobaccoValuesYesNoMap = {
  [doUse]: 'Yes',
  [doNotUse]: 'No',
};

export default tobaccoValues;
