import { inputTypes } from '@fabrictech/questionnaire/utils/constants';

import FormSection from '@fabrictech/tapestry/components/FormSection';
import FormField from '@fabrictech/tapestry/components/FormField';

import FabricGroupItemField from './FabricGroupItemField';
import QuestionnaireFabricStruct from './QuestionnaireFabricStruct';

import { getFabricGroupFieldForGroupType } from './getFabricGroupFieldForGroupType';

/**
 * A helper that determines which component to render around
 * an input component depending on its corresponding inputType
 *
 * @param {Object} components
 * @param {Object} inputTypes
 */

const getFieldForInputType = (type, groupType) => {
  switch (type) {
    case inputTypes.section:
      return FormSection;
    case inputTypes.fabricGroup:
      return getFabricGroupFieldForGroupType(groupType);
    case inputTypes.fabricGroupItem:
      return FabricGroupItemField;
    case inputTypes.fabricStruct:
      return QuestionnaireFabricStruct;
    default:
      return FormField;
  }
};

export default getFieldForInputType;
