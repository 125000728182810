export function googleTagManagerSnippet(id) {
  /* eslint-disable quotes */
  return [
    "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});", // eslint-disable-line max-len
    "var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';",
    "j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;",
    'f.parentNode.insertBefore(j,f); })',
    `(window,document,'script','dataLayer','${id}');`,
  ].join('');
  /* eslint-enable quotes */
}

export function googleTagManagerIFrame(id) {
  /* eslint-disable quotes */
  return `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`; // eslint-disable-line max-len
  /* eslint-enable quotes */
}
