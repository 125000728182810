import { spacer } from '@fabrictech/design-tokens';
import { standardShadow } from '../../sharedStyles/vars/boxShadow';
import tags from '../../constants/tags';
import { descendant } from '../../utils/css/selectors';

const getStyles = () => ({
  boxShadow: standardShadow,
  position: 'relative',
  marginBottom: spacer(3),
  [descendant(tags.img)]: {
    userSelect: 'none',
    width: '100%',
  },
});

export default getStyles;
