import utils from './utils';

/** Rebrand */
// TODO: Rename when `ColorPalette` has been removed
export enum RebrandColorPalette {
  white = '#FFFFFF',
  slate = '#7A7A7A',
  silver = '#E5E5E5',
  purple = '#8B36DE',
  purpleMid = '#6E1FBC',
  purpleDark = '#442F6B',
  midnight = '#1E063E',
  mint = '#B9D1D5',
  mintAlternate = '#CEDFE2',
  pink = '#DBC0C0',
  sand = '#F2E9E2',
  tan = '#E2D1C1',
  tanAlternate = '#F9F6F3',
  tanLight = '#F5EEE9',
  green = '#22CE5C',
  red = '#EC5040',
}

const base = {
  accent: RebrandColorPalette.midnight,
  error: RebrandColorPalette.red,
  success: RebrandColorPalette.green,
} as const;

const palette = {
  white: RebrandColorPalette.white,
  slate: RebrandColorPalette.slate,
  silver: RebrandColorPalette.silver,
  purple: RebrandColorPalette.purple,
  purpleMid: RebrandColorPalette.purpleMid,
  purpleDark: RebrandColorPalette.purpleDark,
  midnight: RebrandColorPalette.midnight,
  mint: RebrandColorPalette.mint,
  mintAlternate: RebrandColorPalette.mintAlternate,
  pink: RebrandColorPalette.pink,
  sand: RebrandColorPalette.sand,
  tan: RebrandColorPalette.tan,
  tanAlt: RebrandColorPalette.tanAlternate,
  tanLight: RebrandColorPalette.tanLight,
  green: RebrandColorPalette.green,
  red: RebrandColorPalette.red,
} as const;

const font = {
  primary: RebrandColorPalette.midnight,
  secondary: RebrandColorPalette.purpleDark,
  link: RebrandColorPalette.purple,
  error: base.error,
  success: base.success,
  inverse: {
    primary: RebrandColorPalette.white,
  },
} as const;

const border = {
  base: RebrandColorPalette.midnight,
  active: RebrandColorPalette.purple,
} as const;

const background = {
  primary: RebrandColorPalette.white,
  secondary: RebrandColorPalette.silver,
  tertiary: RebrandColorPalette.tanLight,
  overlay: RebrandColorPalette.midnight,
  inverse: {
    primary: RebrandColorPalette.midnight,
    secondary: RebrandColorPalette.purpleMid,
    tertiary: RebrandColorPalette.purple,
    quaternary: RebrandColorPalette.mint,
  },
} as const;

const inputBg = utils.setAlpha(RebrandColorPalette.white, 0.3);

const input = {
  base: {
    background: inputBg,
    border: border.base,
    text: font.primary,
    accent: border.base,
  },
  disabled: {
    background: inputBg,
    border: border.base,
    text: font.primary,
    accent: RebrandColorPalette.silver,
  },
  hover: {
    background: utils.setAlpha(RebrandColorPalette.white, 0.5),
    border: border.base,
    text: font.primary,
    accent: border.base,
  },
  active: {
    background: RebrandColorPalette.white,
    border: border.base,
    text: font.primary,
    accent: RebrandColorPalette.tan,
  },
  error: {
    background: inputBg,
    border: base.error,
    text: font.primary,
    accent: base.error,
  },
} as const;

const button = {
  background: RebrandColorPalette.tan,
  lightText: font.inverse.primary,
  darkText: font.primary,
};

export const paymentFormColors = {
  placeholder: '#C2C2C2',
};

export const ambassadorModuleColors = {
  button: {
    background: RebrandColorPalette.white,
  },
};

export const color = {
  base,
  palette,
  font,
  border,
  background,
  input,
  button,
  utils,
};
