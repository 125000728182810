import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { css } from 'glamor';

import {
  getScrollableRegionStyles,
  getZoomableRegionStyles,
  getParentRegionStyles,
  getActionButtonRegionStyles,
} from './getStyles';

import Button from '../Button';
import DocumentViewerDocCollection from './components/DocumentViewerDocCollection';
import DocumentViewerControls from './components/DocumentViewerControls';
import pickPrivateProps from '../../utils/pickPrivateProps/pickPrivateProps';

import * as zoomable from './utils/zoomable';

import { fromPx, toPx } from '../../utils/css/values/px';
import { minus } from './utils/coordinates';
import { getMetaContent, setMetaContent } from '../../utils/meta';

const scrollableRegionClassName = css(getScrollableRegionStyles());
const zoomableRegionClassName = css(getZoomableRegionStyles());
const actionButtonRegionClassName = css(getActionButtonRegionStyles());

const createGetHasPropChanged = (nextProps, props) => name =>
  !_.isEqual(nextProps[name], props[name]);

/*
 * Desired scroll position is sum of (1) offset of page relative to
 * container and (2) offset of signature box relative to page.
 */
const getScrollTargetNodeOffset = node =>
  node.offsetParent.offsetTop + node.offsetTop;

/** `DocumentViewer` contains `DocumentPage`s and adds zoom and paging functionality */
class DocumentViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startLength: 0,
      moving: false,
      baseScale: props.minScale,
      scale: props.minScale,
      translate: { x: 0, y: 0 },
      offset: { x: 0, y: 0 },
    };

    this.imageLoadCount = 0;
    this.scrollTargets = [];
    this.handleImageLoad = this.handleImageLoad.bind(this);
    this.handleAddScrollTarget = this.handleAddScrollTarget.bind(this);
    this.handleScroll = _.throttle(this.handleScroll.bind(this), 250);
    this.handleZoomIn = this.handleZoomIn.bind(this);
    this.handleZoomOut = this.handleZoomOut.bind(this);
    this.handleZoomableTouchStart = this.handleZoomableTouchStart.bind(this);
    this.handleZoomableTouchMove = this.handleZoomableTouchMove.bind(this);
    this.handleZoomableTouchEnd = this.handleZoomableTouchEnd.bind(this);
    this.setScrollableRef = this.setScrollableRef.bind(this);
    this.setZoomableRef = this.setZoomableRef.bind(this);
  }

  handleImageLoad() {
    this.imageLoadCount += 1;
    if (this.imageLoadCount === this.props.children.length) {
      this.handleAllImagesLoaded();
    }
  }

  handleAllImagesLoaded() {
    const { width, height } = this.state.refZoomable.getBoundingClientRect();
    this.setState({
      initialWidth: width,
      initialHeight: height,
      baseWidth: width,
      baseHeight: height,
      width,
      height,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const getHasPropChanged = createGetHasPropChanged(nextProps, this.props);

    if (getHasPropChanged('scrollTargetIndex')) {
      this.handleScrollToTargetIndex(nextProps.scrollTargetIndex);
    }
  }

  handleScrollToTargetIndex = index => {
    const { node } = this.scrollTargets[index] || {};
    if (node) {
      this.state.refScrollable.scrollTop =
        getScrollTargetNodeOffset(node) - fromPx(this.props.scrollTargetOffset);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { refScrollable, moving, initialOffset, offset } = this.state;
    if (refScrollable && prevState.moving && !moving) {
      const scrollLeft = initialOffset.x - offset.y;
      const scrollTop = initialOffset.y - offset.y;
      refScrollable.scrollLeft = scrollLeft;
      refScrollable.scrollTop = scrollTop;
    }
  }

  componentDidMount() {
    // eslint-disable-next-line global-require
    const detectIt = require('detect-it').default;
    const { hasTouch } = detectIt;
    // check to confirm the viewport meta tag is there so the
    // DocumentViewer doesn't error in the case that it's not
    const metaContent = getMetaContent('viewport');
    const supportTouch = hasTouch && metaContent;
    if (supportTouch) {
      this.supportTouch = supportTouch;

      /*
       * Update viewport meta-tag to use fixed width for touch-enabled
       * devices that do not allow us to disable native zoom.
       */
      this.initialViewportMetaContent = getMetaContent('viewport');
      setMetaContent('viewport', {
        ...this.initialViewportMetaContent,
        width: document.body.getBoundingClientRect().width,
      });
    } else {
      console.warn(
        'Document Viewer may not behave correctly without viewport meta tag.'
      );
    }
  }

  componentWillUnmount() {
    if (this.supportTouch) {
      /*
       * Reset viewport meta tag
       */
      setMetaContent('viewport', { ...this.initialViewportMetaContent });
    }

    if (this.state.refScrollable) {
      this.state.refScrollable.removeEventListener('scroll', this.handleScroll);
    }
  }

  handleZoom(scale) {
    const { width, height } = this.getWidthAndHeight(scale);
    this.setState({ width, height, scale });
  }

  handleZoomIn() {
    const scale = this.state.scale + this.props.scaleFactor;
    this.handleZoom(scale);
  }

  handleZoomOut() {
    const scale = this.state.scale - this.props.scaleFactor;
    this.handleZoom(scale);
  }

  isScrollTargetVisible({ node }) {
    const scrollableRegionScrollTop = this.state.refScrollable.scrollTop;
    const {
      height: scrollableRegionHeight,
    } = this.state.refScrollable.getBoundingClientRect();
    const scrollTargetOffset = getScrollTargetNodeOffset(node);

    /*
     * TODO:
     * Currently only determines visibility vertically - we should be able to
     * replicate this check for horizontal visibility.
     */
    return (
      scrollTargetOffset >= scrollableRegionScrollTop &&
      scrollTargetOffset <= scrollableRegionScrollTop + scrollableRegionHeight
    );
  }

  handleScroll() {
    /*
     * Determine whether or not scroll targets are visible
     */
    this.scrollTargets.forEach(target => {
      target.visible = this.isScrollTargetVisible(target);
    });
    if (this.props.onScroll) {
      this.props.onScroll(this.scrollTargets);
    }
  }

  setScrollableRef(node) {
    if (!this.state.refScrollable) {
      this.setState({ refScrollable: node });
      node.addEventListener('scroll', this.handleScroll);
    }
  }

  setZoomableRef(node) {
    if (!this.state.refZoomable) {
      const initialOffset = zoomable.getOffset(node);
      this.setState({ refZoomable: node, initialOffset });
    }
  }

  getZoomableStyles() {
    const { moving, width, height, translate, scale } = this.state;
    if (moving) {
      return {
        transformOrigin: '0 0',
        transform: `matrix3d(${scale}, 0, 0, 0, 0, ${scale}, 0, 0, 0, 0, 1, 0, ${-translate.x}, ${-translate.y}, 0, 1)`,
      };
    }
    if (width && height) {
      return {
        /*
         * Note: 2d translation here will be managed by the scrollLeft
         * and scrollTop updates in componentDidUpdate.
         */
        width: toPx(width),
        height: toPx(height),
      };
    }
    return {};
  }

  getWidthAndHeight(scale) {
    const { initialWidth, initialHeight } = this.state;
    return {
      width: scale * initialWidth,
      height: scale * initialHeight,
    };
  }

  limitScale(scale) {
    const { width } = this.getWidthAndHeight(scale);
    return width >= this.props.maxWidth
      ? this.state.scale
      : Math.max(this.props.minScale, scale);
  }

  getScaleFromPinchEvent(event) {
    const { startLength, baseScale } = this.state;
    const newLength = zoomable.getLengthOfPinchFromEvent(event);
    const newScale = zoomable.getScaleFromLengths(startLength, newLength);
    return this.limitScale(baseScale + newScale);
  }

  handleZoomableTouchStart(event) {
    if (zoomable.isPinchTouch(event)) {
      // Disable native browser zoom (primarily for Mobile Safari)
      event.preventDefault();
      const startLength = zoomable.getLengthOfPinchFromEvent(event);
      this.setState({ startLength });
    }
  }

  handleZoomableTouchMove(event) {
    if (zoomable.isPinchTouch(event)) {
      const { baseWidth, baseHeight, refZoomable } = this.state;

      const center = zoomable.getCenterCoordOfTouchEvent(event);
      const offset = zoomable.getOffset(refZoomable);

      // Center of the touch event offset by zoomable
      const centerInZoomable = minus(center, offset);
      const scale = this.getScaleFromPinchEvent(event);
      const { width, height } = this.getWidthAndHeight(scale);
      const translate = {
        x: (centerInZoomable.x * (width - baseWidth)) / width,
        y: (centerInZoomable.y * (height - baseHeight)) / height,
      };

      this.setState({ offset, width, height, scale, translate, moving: true });
    }
  }

  handleZoomableTouchEnd() {
    const { scale, width, height } = this.state;
    const newState = {
      baseScale: scale,
      moving: false,
    };
    if (width) {
      newState.baseWidth = width;
    }
    if (height) {
      newState.baseHeight = height;
    }
    this.setState(newState);
  }

  appendScrollIndex({ index, node }) {
    this.scrollTargets.push({ index, node });
    this.scrollTargets = _.sortBy(
      this.scrollTargets,
      ({ index: _index }) => _index
    );
  }

  handleAddScrollTarget(docIndex, sigIndex) {
    const index = docIndex + sigIndex * 0.1;
    return node => {
      this.appendScrollIndex({ index, node });
    };
  }

  getButtonProps() {
    const { buttonProps, getButtonProps } = this.props;
    return (
      buttonProps ||
      getButtonProps({
        scrollTargets: this.scrollTargets,
        scrollToTarget: this.handleScrollToTargetIndex,
      })
    );
  }

  render() {
    const {
      children,
      disableFullScreenMobile,
      hasHeaderOffset,
      ...rest
    } = this.props;
    const privateProps = pickPrivateProps(rest);
    const parentRegionClassName = css(
      getParentRegionStyles({ disableFullScreenMobile, hasHeaderOffset })
    );
    const buttonProps = this.getButtonProps();
    return (
      <div className={parentRegionClassName}>
        <div className={scrollableRegionClassName} ref={this.setScrollableRef}>
          <div
            className={zoomableRegionClassName}
            ref={this.setZoomableRef}
            style={this.getZoomableStyles()}
            onTouchStart={this.handleZoomableTouchStart}
            onTouchMove={this.handleZoomableTouchMove}
            onTouchEnd={this.handleZoomableTouchEnd}
          >
            <DocumentViewerDocCollection {...privateProps}>
              {React.Children.map(children, (child, index) =>
                React.cloneElement(child, {
                  ...privateProps,
                  handleImageLoad: this.handleImageLoad,
                  handleAddScrollTarget: this.handleAddScrollTarget,
                  marginBottom: index === children.length - 1 ? 0 : undefined,
                  scale: this.state.scale,
                  index,
                })
              )}
            </DocumentViewerDocCollection>
          </div>
        </div>
        <DocumentViewerControls
          handleZoomIn={this.handleZoomIn}
          handleZoomOut={this.handleZoomOut}
          {...privateProps}
        />
        {buttonProps && (
          <div
            className={actionButtonRegionClassName}
            data-test="MediaViwerButtonContainer"
          >
            {React.createElement(Button, {
              width: '100%',
              margin: 0,
              hasShadow: true,
              ...buttonProps,
            })}
          </div>
        )}
      </div>
    );
  }
}

DocumentViewer.propTypes = {
  /** Children of DocumentViewer */
  children: PropTypes.node,
  /** Minimum scale */
  minScale: PropTypes.number,
  /** Maximum width */
  maxWidth: PropTypes.number,
  /** Scale of the viewer */
  scaleFactor: PropTypes.number,
  /** Offset of scroll target */
  scrollTargetOffset: PropTypes.string,
  /** Disables going full screen on mobile. Used only in styleguide to prevent page takeover */
  disableFullScreenMobile: PropTypes.bool,
  /**  Enables/disbles headerOffsets */
  hasHeaderOffset: PropTypes.bool,
};

DocumentViewer.defaultProps = {
  minScale: 1,
  maxWidth: 1275,
  scaleFactor: 0.1,
  scrollTargetOffset: '48px',
  disableFullScreenMobile: false,
  hasHeaderOffset: true,
};

export default DocumentViewer;
