import React from 'react';
import { Box, Icon } from '@fabrictech/tapestry';
import { OnClickEvent } from '@fabrictech/tapestry/types';

import getStyles from './getStyles';
import useResponsiveContext from '../useResponsiveContext';

type FabricHeaderProps = {
  goBack: () => void;
  children: React.ReactElement;
};

const FabricHeader = ({ goBack, children }: FabricHeaderProps) => {
  const handleClick = (event: OnClickEvent) => {
    event.preventDefault();
    goBack();
  };
  const {
    styles: { containerClassname, arrowBackContainerClassname },
  } = useResponsiveContext(getStyles);
  return (
    <Box className={containerClassname}>
      {children}
      <Box className={arrowBackContainerClassname}>
        <a href="#" onClick={handleClick} role="button" title="Go Back">
          <Icon type="arrowBack" size="xs" />
        </a>
      </Box>
    </Box>
  );
};

export default FabricHeader;
