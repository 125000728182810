import _ from 'lodash';
import getMetaTagByName from '../util/getMetaTagByName';

const getContentAttribute = node => node.getAttribute('content');

const transformContentAttribute = str =>
  _.reduce(
    str.split(', '),
    (acc, entry) => {
      const [key, value] = entry.split('=');
      acc[key] = value;
      return acc;
    },
    {}
  );

const getMetaContent = metaTagName => {
  const metaTagNode = getMetaTagByName(metaTagName);
  if (!metaTagNode) {
    return null;
  }

  const contentAttribute = getContentAttribute(metaTagNode);

  return transformContentAttribute(contentAttribute);
};

export default getMetaContent;
