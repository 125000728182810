import {
  Schedule4RealInteraction,
  Schedule4RealAppointmentDateLocalTime,
} from './types';

const buildSchedule4RealInteractionProperties = ({
  name,
  schedule4RealAppointment,
}: {
  name: Schedule4RealInteraction;
  schedule4RealAppointment?: Schedule4RealAppointmentDateLocalTime;
}) => ({ name, schedule4RealAppointment });

export default buildSchedule4RealInteractionProperties;
