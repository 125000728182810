import React from 'react';
import { Button, ButtonProps } from '@fabrictech/tapestry';
import { color } from '@fabrictech/design-tokens';
import { formWidthDesktop, formWidthMobile } from '../constants';
import useResponsiveContextForResponsiveProps from '../useResponsiveContextForResponsiveProps';

type FormButtonProps = Pick<
  ButtonProps,
  'children' | 'disabled' | 'isLoading' | 'onClick'
>;

const defaultFormButtonProps: Partial<ButtonProps> = {
  borderless: true,
  hasShadow: false,
  size: 'md',
};

type DefaultStyleProps = Required<
  Pick<ButtonProps, 'color' | 'marginBottom' | 'responsiveProps' | 'width'>
>;
const defaultStyleProps: DefaultStyleProps = {
  color: color.palette.purple,
  marginBottom: 0,
  responsiveProps: {
    mobileBreakpoint: {
      width: formWidthMobile,
    },
  },
  width: formWidthDesktop,
};

const FormButton = ({
  children,
  disabled,
  isLoading,
  onClick,
}: FormButtonProps) => {
  const {
    responsiveProps,
    ...buttonProps
  } = useResponsiveContextForResponsiveProps<DefaultStyleProps>(
    defaultStyleProps
  );
  return (
    <Button
      {...defaultFormButtonProps}
      {...buttonProps}
      responsiveProps={responsiveProps}
      disabled={disabled}
      isLoading={isLoading}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default FormButton;
