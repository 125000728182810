import _ from 'lodash';
import {
  questionStatuses,
  inputTypes,
  questionStatusForAnswerStatus,
} from '../../utils/constants';

export const getQuestionStatus = ({
  answerStatus,
  type,
  loading,
  isOutOfSync,
}) => {
  if (loading) {
    return questionStatuses.working;
  }

  if (isOutOfSync) {
    return questionStatuses.invalid;
  }

  // No flag on no answerStatus, no flag on assessment factor group types.
  if (
    !answerStatus ||
    [inputTypes.assessmentFactorGroup, inputTypes.group].includes(type)
  ) {
    return null;
  }

  return questionStatusForAnswerStatus[answerStatus];
};

const textLikeInputTypes = [
  inputTypes.text,
  inputTypes.password,
  inputTypes.number,
  inputTypes.streetAddress,
];

/**
 * Determines how quickly to respond to a type of event.
 * `undefined` is implicitly our `unhandled` handling value.
 */
export const eventHandlingBehaviors = {
  immediate: 'immediate',
  debounced: 'debounced',
};

export const getInputUpdateEventConfig = _.memoize(
  (type, { handleTextInputChangeEvent = false } = {}) => {
    // if we have a non-textlike input, update on every change
    if (!textLikeInputTypes.includes(type)) {
      return { change: eventHandlingBehaviors.immediate };
    }

    // If the input is text like, we don't want to fire an update with every change,
    // as that will cause input jankiness. Always fire on blur, and in some cases,
    // fire on change (with a debounce)
    let textInputHandling = { blur: eventHandlingBehaviors.immediate };

    if (handleTextInputChangeEvent) {
      textInputHandling = {
        ...textInputHandling,
        change: eventHandlingBehaviors.debounced,
      };
    }

    return textInputHandling;
  },
  (...args) => JSON.stringify([...args])
);

export const getInputValidateEventConfig = _.memoize(
  (type, { handleTextInputChangeEvent = false } = {}) => {
    // we want to validate on both change AND blur of single
    // selection inputs to account for scenarios in which the user
    // focuses but never changes.
    if (type === inputTypes.singleSelection) {
      return {
        blur: eventHandlingBehaviors.immediate,
        change: eventHandlingBehaviors.immediate,
      };
    }

    // Similar to firing updates, we want to trigger validation on a
    // non-text-like input when the user is *makes* their changes.
    if (!textLikeInputTypes.includes(type)) {
      return { change: eventHandlingBehaviors.immediate };
    }

    // If the input is text like, we don't want to fire validation with every change,
    // as that will cause input jankiness. Always fire on blur, and in some cases,
    // fire on change (with a debounce)
    let textInputHandling = { blur: eventHandlingBehaviors.immediate };

    if (handleTextInputChangeEvent) {
      textInputHandling = {
        ...textInputHandling,
        change: eventHandlingBehaviors.debounced,
      };
    }

    return textInputHandling;
  },
  (...args) => JSON.stringify([...args])
);

export const getHtmlInputType = type =>
  textLikeInputTypes.includes(type) ? type : undefined;

const groupInputTypes = [
  inputTypes.assessmentFactorGroup,
  inputTypes.section,
  inputTypes.group,
  inputTypes.fabricGroup,
  inputTypes.fabricStruct,
];
export const getIsGroupInputType = type => _.includes(groupInputTypes, type);
