import { divide } from '../../utils/css/values/px';
import { padding } from '../../utils/css/values/index';

const getStyles = ({
  gutterWidth,
  span,
  columns,
  offset,
  order,
}: {
  gutterWidth: string | number;
  span: number;
  columns: number;
  offset: number;
  order?: number;
}) => ({
  minHeight: '1px',
  padding: padding(0, divide(gutterWidth, 2)),
  display: 'block',
  flex: '0 0 auto',
  width: `${(100 * span) / columns}%`,
  margin: offset > 0 ? `0 0 0 ${(100 * offset) / columns}%` : 0,
  order,
});

export default getStyles;
