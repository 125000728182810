import _ from 'lodash';
import { answerStatuses } from '../constants';

/**
 * PLEASE PLEASE PLEASE NOTE:
 * PLEASE PLEASE PLEASE NOTE:
 * PLEASE PLEASE PLEASE NOTE:
 * PLEASE PLEASE PLEASE NOTE:
 *
 * This is code that, for the time being, is literally duplicated
 * on the frontend and backend. Sigh. We need to move to a place
 * where this will no longer be the case.
 *
 * In the meantime, IF YOU MAKE A CHANGE HERE MAKE SURE THAT YOU
 * ALSO MAKE THE CORRESPONDING CHANGE (frontend or backend, etc.).
 *
 * It's also similar to some other front-end questionnaire related code,
 * @see apps/www/src/state/resources/questionnaire/reducers/questionnaireMount/utils/transformToFlattenedQuestionnaire/transformToFlattenedQuestionnaire.js:72
 *
 * Ugh. Yuck. Thank you for your time.
 */

/**
 * Iterates over the questions in a group, and decides the answerState for the entire group.
 *
 * @param {array<object>} childQuestionInstances
 * @return {string} The aggregated answer status
 */
const getGroupAnswerState = childQuestionInstances => {
  const grouped = _.groupBy(childQuestionInstances, 'answerState');

  if (_.has(grouped, answerStatuses.invalid)) {
    return answerStatuses.invalid;
  }
  if (_.has(grouped, answerStatuses.missing)) {
    return answerStatuses.missing;
  }
  if (_.has(grouped, answerStatuses.valid)) {
    return answerStatuses.valid;
  }
  return answerStatuses.absent;
};

export default getGroupAnswerState;
