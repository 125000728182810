import React from 'react';
import { Rule } from 'glamor';
import { Box } from '@fabrictech/tapestry';
import getStyles from './getStyles';
import useResponsiveContext from '../../useResponsiveContext';

type EstimateFormSectionWrapperProps = {
  className: Rule;
  label: React.ReactElement;
  children: React.ReactElement | null;
  footer?: React.ReactElement;
};

const EstimateFormSectionWrapper = ({
  className,
  label,
  children,
  footer,
}: EstimateFormSectionWrapperProps) => {
  const {
    styles: { stackingContainerClassname, labelClassname, footerClassname },
  } = useResponsiveContext(getStyles);
  return (
    <Box className={className}>
      <Box className={stackingContainerClassname}>
        <Box className={labelClassname}>{label}</Box>
        <Box>{children}</Box>
      </Box>
      <Box className={footerClassname}>{footer}</Box>
    </Box>
  );
};

export default EstimateFormSectionWrapper;
