import { input, Align, color } from '@fabrictech/design-tokens';

import {
  inputStyles,
  inputPlaceholderStyles,
  inputTextStyles,
  inputLabelStyles,
  inputPadding,
} from '../../sharedStyles/vars/input';
import { standardShadow } from '../../sharedStyles/vars/boxShadow';
import {
  focus,
  placeholder as placeholderSelector,
} from '../../utils/css/selectors';

const disabledOpacity = 0.5;
const readOnlyOpacity = 0.3;

export const getInputBorderStyles = () => ({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: -1,
  ...inputStyles.base,
  transition: 'background 0.15s, box-shadow 0.3s',
  boxShadow: '0 0 0 transparent',
});

const inputLabelBaseStyles = {
  ...inputPlaceholderStyles,
  position: 'absolute',
  top: input.layoutWithLabel.paddingTop,
  left: input.layoutWithLabel.paddingLeft,
  transition: 'all 0.15s',
  pointerEvents: 'none',
};

const inputLabelActiveStyles = {
  top: input.layoutWithLabel.positionTop,
  ...inputLabelStyles,
};

export const getInputStyles = ({
  inputBorderClassName,
  disabled,
  readOnly,
  invalid,
  textAlign,
  borderless,
  hasSuffix,
  hasValue,
  hasPlaceholder,
  hasLabel,
}: {
  inputBorderClassName: string;
  disabled?: boolean;
  readOnly?: boolean;
  invalid?: boolean;
  textAlign: Align;
  borderless?: boolean;
  hasSuffix?: boolean;
  hasValue?: boolean;
  hasPlaceholder?: boolean;
  hasLabel?: boolean;
}) => {
  const inputBorderSelector = `& ~ .${inputBorderClassName}`;
  const suffixSelector = `& ~ p`;
  const labelSelector = `& ~ label`;
  return borderless
    ? {}
    : {
        display: 'block',
        appearance: 'none',
        background: 'none',
        border: 'none',
        minHeight: input.layout.height,
        minWidth: 0,
        width: '100%',
        ...inputTextStyles,
        ...inputPadding,
        textAlign,
        ...(hasPlaceholder
          ? { [placeholderSelector]: { ...inputPlaceholderStyles, textAlign } }
          : {}),
        ...(hasLabel
          ? {
              paddingTop: input.layoutWithLabel.paddingTop,
              paddingBottom: input.layoutWithLabel.paddingBottom,
              minHeight: input.layoutWithLabel.height,
              [labelSelector]: {
                ...inputLabelBaseStyles,
                ...(hasValue || hasPlaceholder ? inputLabelActiveStyles : {}),
                ...(invalid ? { color: color.input.error.accent } : {}),
                ...(disabled ? { opacity: disabledOpacity } : {}),
              },
            }
          : {}),
        ...(hasSuffix
          ? {
              [suffixSelector]: {
                ...inputPlaceholderStyles,
                opacity: !(hasPlaceholder || hasLabel) || hasValue ? 1 : 0,
                transition: 'opacity 0.15s',
                ...(hasLabel
                  ? {
                      paddingTop: input.layoutWithLabel.paddingTop,
                    }
                  : {}),
              },
            }
          : {}),

        [focus]: {
          outline: '1px solid transparent',
          [inputBorderSelector]: {
            ...inputStyles.active,
            boxShadow: standardShadow,
          },
          ...(hasPlaceholder ? { [placeholderSelector]: { opacity: 0 } } : {}),
          ...(hasSuffix ? { [suffixSelector]: { opacity: 1 } } : {}),
          ...(hasLabel
            ? {
                [labelSelector]: {
                  ...inputLabelActiveStyles,
                  color: invalid ? color.input.error.accent : color.font.link,
                },
              }
            : {}),
        },
        ...(disabled
          ? {
              color: inputStyles.disabled.color,
              [inputBorderSelector]: inputStyles.disabled,
              opacity: disabledOpacity,
              [inputBorderSelector]: { opacity: disabledOpacity },
            }
          : {}),
        ...(readOnly
          ? {
              [inputBorderSelector]: { opacity: readOnlyOpacity },
            }
          : {}),
      };
};
