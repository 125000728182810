import {
  ineligibilityTypes,
  IneligibilityType,
} from '@fabrictech/definitely-fabric';

export const ineligibilityHeader = 'It’s not you, it’s us.';

export const ineligibilityCaptions: Partial<
  Record<IneligibilityType, string>
> = {
  [ineligibilityTypes.ineligibleAddressRegion]:
    'While we don’t yet offer policies in your state, we have trusted partners that can help get you covered.',
  [ineligibilityTypes.ineligibleAge]:
    'While we don’t offer policies for your age group, we have trusted partners that can help get you covered.',
};
