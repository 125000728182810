import React from 'react';

import { Helmet } from 'react-helmet';
import {
  googleTagManagerIFrame,
  googleTagManagerSnippet,
} from '@fabrictech/web-consumer-insights';
import { SwatchWidget } from './types';
import ResponsiveContext, { defaultContext } from './ResponsiveContext';
import QuoteWidget from './QuoteWidget';
import { defaultExternalLandingPath } from './constants';
import getIsLandingPageWidget from './getIsLandingPageWidget';

const QuoteWidgetAdapter = ({
  createAnalyticsEvent,
  getQuotePremiumAu,
  createQuotePremiumAu,
  prefilledLead = {},
  source,
  utmParamString,
  mobileBreakpoint,
  isLockedToMobileWidth: maybeIsLockedToMobileWidth,
  internal: maybeIsInternalWidget,
  landingBaseUrl,
  landingPath = defaultExternalLandingPath,
  gtmId,
  showHeaderText = true,
}: SwatchWidget.QuoteWidgetAdapterProps) => {
  const isLockedToMobileWidth = Boolean(maybeIsLockedToMobileWidth);
  const isInternalWidget = Boolean(maybeIsInternalWidget);
  const isLandingPageWidget = getIsLandingPageWidget(source);
  const isFabricHostedWidget = isInternalWidget || isLandingPageWidget;
  return (
    <>
      {gtmId ? (
        <>
          <Helmet
            script={[
              {
                type: 'text/javascript',
                innerHTML: googleTagManagerSnippet(gtmId),
              },
            ]}
          />
          <noscript
            dangerouslySetInnerHTML={{
              __html: googleTagManagerIFrame(gtmId),
            }}
          />
        </>
      ) : null}
      <ResponsiveContext.Provider
        // TODO: Fix this
        // @see https://fabrictech.atlassian.net/browse/DEVOPS-1025
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          ...defaultContext,
          ...(mobileBreakpoint ? { mobileBreakpoint } : {}),
          ...(isInternalWidget
            ? { isInternalWidget, isLockedToMobileWidth: true }
            : {
                isLockedToMobileWidth,
              }),
        }}
      >
        <QuoteWidget
          createAnalyticsEvent={createAnalyticsEvent}
          getQuotePremiumAu={getQuotePremiumAu}
          createQuotePremiumAu={createQuotePremiumAu}
          prefilledLead={prefilledLead}
          landingBaseUrl={landingBaseUrl}
          landingUrl={`${landingBaseUrl}${landingPath}`}
          isFabricHostedWidget={isFabricHostedWidget}
          {...(source ? { source } : {})}
          {...(utmParamString ? { utmParamString } : {})}
          showHeaderText={showHeaderText}
        />
      </ResponsiveContext.Provider>
    </>
  );
};

export default QuoteWidgetAdapter;
