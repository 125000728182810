import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { css } from 'glamor';
import SmoothCollapse from 'react-smooth-collapse';
import Scrollchor from 'react-scrollchor';

import { spacer, color, IconType } from '@fabrictech/design-tokens';

import { getStyles, getParentStyles } from './getStyles';

import mediaQueries from '../../sharedStyles/vars/mediaQueries';
import CardBanner from '../CardBanner';
import LayoutContainer from '../LayoutContainer';
import LayoutItem from '../LayoutItem';
import Button from '../Button';
import TextMarkdown from '../TextMarkdown';
import Box from '../Box';

import { SectionState } from './types';

import FormSectionHeader from './components/FormSectionHeader';

const scrollToNextUnansweredCopy = `
Looks like there is an unanswered question or two.

Click below to jump right to them (look out for the colored flags).`;

type FormSectionProps = {
  /** Children node */
  children?: React.ReactNode;
  /** Icon for the CardBanner */
  icon?: IconType;
  /** Caption for the FormSection */
  caption?: string;
  /** Subcaption for the FormSection */
  subcaption?: string;
  /** The Questionnaire questionId for this section */
  id: string;
  /** Status of the FormSection */
  sectionState: SectionState;
  /** Hides the "Next" button that toggles section visibility */
  hideButton?: boolean;
  /** Whether section should scroll into view when opened */
  scrollToNextSection?: boolean;
  /** Handler to toggle next section */
  onToggleSectionButtonClick?: () => void;
};

const className = css(getStyles()).toString();

/** A section of a form that gathers information around a certain category. */
const FormSection = ({
  children,
  icon,
  caption,
  subcaption,
  id: sectionId,
  sectionState = 'active',
  onToggleSectionButtonClick,
  hideButton = false,
  scrollToNextSection = true,
}: FormSectionProps) => {
  const scrollRef = useRef<Scrollchor>(null);
  const parentClassName = css(getParentStyles({ sectionState })).toString();
  const sectionExpanded = sectionState === 'active';

  // TODO: set this in a way that doesn't make it potentially undefined, so we
  // can remove the `@ts-expect-error` below.
  // https://fabrictech.atlassian.net/browse/ACL-1484
  const [scrollId, setScrollId] = useState<string>();
  useEffect(() => {
    setScrollId(_.uniqueId('scrollId'));
  }, []);

  return (
    <div id={scrollId} className={parentClassName}>
      <SmoothCollapse expanded={!sectionExpanded} allowOverflowWhenOpen>
        <Scrollchor
          /* @ts-expect-error see scrollId TODO */
          to={scrollId}
          disableHistory={true}
          animate={{ offset: spacer(-12) }}
          ref={scrollRef}
        />
        <SmoothCollapse expanded={!scrollToNextSection}>
          <Box
            align="center"
            flexDirection="column"
            paddingLeft={spacer(2)}
            paddingRight={spacer(2)}
            marginBottom={spacer(3)}
          >
            <TextMarkdown
              childProps={{
                Text: {
                  textAlign: 'center',
                  rank: 3,
                  color: color.font.primary,
                  marginBottom: 0,
                },
              }}
            >
              {`${scrollToNextUnansweredCopy}`}
            </TextMarkdown>
          </Box>
        </SmoothCollapse>
        <Button
          block
          hasArrow={true}
          hidden={hideButton}
          onClick={() => {
            onToggleSectionButtonClick && onToggleSectionButtonClick();
            scrollToNextSection &&
              scrollRef.current &&
              scrollRef.current.simulateClick();
          }}
          // ILUVU: we need this in order for E2E tests to advance sections,
          // without it hidden buttons remain in the way of our selector
          data-test-next-button-section-id={sectionId}
          data-test-section-expanded={sectionExpanded ? 'true' : 'false'}
          // maintained for legacy purposes, prefer `data-test-section-expanded`
          data-test={sectionExpanded ? '' : 'FormSectionNextButton'}
        >
          Next
        </Button>
      </SmoothCollapse>
      {icon && sectionState !== 'hidden' ? (
        <CardBanner icon={icon} status={sectionState} />
      ) : null}
      <SmoothCollapse expanded={sectionExpanded} allowOverflowWhenOpen={true}>
        <LayoutContainer
          align="center"
          columns={8}
          gutterWidth={0}
          marginBottom={0}
        >
          <LayoutItem
            span={6}
            responsiveProps={{ [mediaQueries.lt.lg]: { span: 8 } }}
          >
            <div className={className}>
              {caption || subcaption ? (
                <FormSectionHeader caption={caption} subcaption={subcaption} />
              ) : null}
              {children}
            </div>
          </LayoutItem>
        </LayoutContainer>
      </SmoothCollapse>
    </div>
  );
};

export default FormSection;
