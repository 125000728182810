import _ from 'lodash';

/**
 * Memoization helper that can simultaneously store results for multiple invocations
 * of the same function.
 * Uses the _first_ argument of the wrapped function as key in the result store object.
 * Note that changing subsequent arguments will not change the memoized result.
 *
 * @param {function} fn Any function that should be memoized.
 * @return {function} Function of the same type as fn, but with its results memoized.
 */
const memoizeById = fn => {
  let memoizedVals = {};

  return (id, ...rest) => {
    if (_.has(memoizedVals, id)) return memoizedVals[id];
    memoizedVals = {
      ...memoizedVals,
      [id]: fn(id, ...rest),
    };

    return memoizedVals[id];
  };
};

export default memoizeById;
