import { Option } from '../types';

// Transforms the options generators from Tapestry to be used with LocalForm
// @see packages/tapestry/utils/optionsGenerators/index.js
export const transformEnumeratedOptions = (
  items: Array<{ label: string; value: string }>
): Option[] =>
  items.map(({ label, value }) => ({
    id: value,
    label,
  }));

// Creates options from an array of strings
export const createOptionsFromArray = (array: string[]): Option[] =>
  array.map(value => ({
    id: value,
    label: value,
  }));

// Creates options from an object
export const createOptionsFromObject = (
  object: Record<string, string>
): Option[] =>
  Object.keys(object).map(key => ({
    id: key,
    label: object[key],
  }));
