import _ from 'lodash';

import createGetStylesConfigurableSpacing from './utils/createGetStylesConfigurableSpacing';
import createGetStylesWithHidden from './utils/createGetStylesWithHidden';
import createGetStylesResponsive from './utils/createGetStylesResponsive';

const createGetStylesWithGlobalProps = (getStyles, ops = {}) =>
  _.flowRight([
    createGetStylesResponsive(ops),
    createGetStylesConfigurableSpacing('margin'),
    createGetStylesConfigurableSpacing('padding'),
    createGetStylesWithHidden,
  ])(getStyles);

export default createGetStylesWithGlobalProps;
