/**
 * Available icons in Quilt.
 * **LINEN DUPLICATION WARNING**: Keep these in sync.
 * @see https://github.com/fabrictech/linen/blob/master/node/services/quilt-utils/lib/quilt/constants.ts
 */

import checkmark from './svg/checkmark.svg';
import checkmarkSmall from './svg/checkmarkSmall.svg';
import circle from './svg/circle.svg';
import square from './svg/square.svg';
import dots from './svg/dots.svg';
import arrowBack from './svg/arrowBack.svg';
import arrowLeft from './svg/arrowLeft.svg';
import arrowRight from './svg/arrowRight.svg';
import arrowRightLong from './svg/arrowRightLong.svg';
import arrowDown from './svg/arrowDown.svg';
import error from './svg/error.svg';
import success from './svg/success.svg';
import eye from './svg/eye.svg';
import eyeClosed from './svg/eyeClosed.svg';
import info from './svg/info.svg';
import lock from './svg/lock.svg';
import minus from './svg/minus.svg';
import plus from './svg/plus.svg';
import x from './svg/x.svg';
import star from './svg/star.svg';
import share from './svg/share.svg';
import you from './svg/you.svg';
import beneficiary from './svg/beneficiary.svg';
import health from './svg/health.svg';
import debt from './svg/debt.svg';
import wallet from './svg/wallet.svg';
import identity from './svg/identity.svg';
import document from './svg/document.svg';
import work from './svg/work.svg';
import review from './svg/review.svg';
import tree from './svg/tree.svg';
import coins from './svg/coins.svg';
import cake from './svg/cake.svg';
import thumbsUp from './svg/thumbsUp.svg';
import thumbsDown from './svg/thumbsDown.svg';
import aPlusRated from './svg/aPlusRated.svg';
import creditCard from './svg/creditCard.svg';
import person from './svg/person.svg';
import checklist_outline from './svg/checklist_outline.svg';
import vault_outline from './svg/vault_outline.svg';
import speech_outline from './svg/speech_outline.svg';
import person_outline from './svg/person_outline.svg';
import dollar from './svg/dollar.svg';
import location from './svg/location.svg';
import phone from './svg/phone.svg';
import pen from './svg/pen.svg';
import feather from './svg/feather.svg';
import shield from './svg/shield.svg';
import school from './svg/school.svg';
import money from './svg/money.svg';
import play from './svg/play.svg';
import hourglass from './svg/hourglass.svg';
import checklist from './svg/checklist.svg';
import vault from './svg/vault.svg';
import stopwatch from './svg/stopwatch.svg';
import speech from './svg/speech.svg';
import burger from './svg/burger.svg';
import rainCloud from './svg/rainCloud.svg';
import apple from './svg/apple.svg';
import piggyBank from './svg/piggyBank.svg';
import handshake from './svg/handshake.svg';
import smartphone from './svg/smartphone.svg';
import lightbulb from './svg/lightbulb.svg';
import file from './svg/file.svg';
import umbrella from './svg/umbrella.svg';
import graduation from './svg/graduation.svg';
import coinsAlt from './svg/coins_alt.svg';
import fabric from './svg/fabric.svg';
import cancel from './svg/cancel.svg';
import handshakeAlt from './svg/handshakeAlt.svg';
import whyFabric from './svg/whyFabric.svg';
import temple from './svg/temple.svg';

export const icons = {
  checkmark,
  checkmarkSmall,
  circle,
  square,
  arrowBack,
  arrowLeft,
  arrowRight,
  arrowDown,
  arrowRightLong,
  eye,
  eyeClosed,
  error,
  success,
  info,
  dollar,
  lock,
  minus,
  plus,
  x,
  star,
  share,
  thumbsUp,
  thumbsDown,
  location,
  phone,
  pen,
  creditCard,
  feather,
  shield,
  school,
  money,
  play,
  hourglass,
  stopwatch,
  speech,
  speech_outline,
  checklist,
  checklist_outline,
  vault,
  vault_outline,
  person,
  person_outline,
  you,
  beneficiary,
  health,
  debt,
  wallet,
  identity,
  document,
  work,
  review,
  tree,
  coins,
  cake,
  aPlusRated,
  burger,
  rainCloud,
  apple,
  piggyBank,
  handshake,
  smartphone,
  lightbulb,
  file,
  dots,
  umbrella,
  graduation,
  coinsAlt,
  fabric,
  cancel,
  handshakeAlt,
  temple,
  whyFabric,
};

export type IconType = keyof typeof icons;
