import LogRocket from 'logrocket';
import { configureStore } from '@reduxjs/toolkit';

import { wwwApi } from './resources/wwwApi';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [wwwApi.reducerPath]: wwwApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      wwwApi.middleware,
      /**
       * Give LogRocket access to redux actions as well as all our network
       * requests. For some reason rtk-query requests aren't picked up by
       * LogRocket as network requests, so this is how we have access to them.
       * TODO: investigate why rtk-query requests aren't picked up by LogRocket
       */
      LogRocket.reduxMiddleware(),
    ]),
  devTools: true,
});
