import React from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import { css, Rule } from 'glamor';

import {
  spacer,
  color,
  input,
  Align,
  IconType,
} from '@fabrictech/design-tokens';

import Box from '../Box';
import Icon, { IconProps } from '../Icon';
import Text from '../Text';

import { GlobalProps } from '../../types';
import pickGlobalProps from '../../utils/pickGlobalProps';

import { getInputBorderStyles, getInputStyles } from './getStyles';
import tags from '../../constants/tags';

const inputBorderClassName = css(getInputBorderStyles()).toString();

const getClassName = ({
  inputClassName,
  className,
}: {
  inputClassName: Rule;
  className: Rule | string;
}): string => {
  return typeof className === 'string'
    ? `${css(inputClassName)}${className ? ` ${className}` : ''}`
    : css(inputClassName, className).toString();
};

type InputWrapperProps = GlobalProps & {
  /** Class name to extend functionality */
  className?: Rule;
  /** Class name to override border styles */
  inputBorderStyle?: string;
  /** Children */
  children?: React.ReactNode;
  /** Specifies a label below the child input */
  helperText?: string;
  /** Whether the field is invalid */
  invalid?: boolean;
  /** Width of the field */
  width?: string | number;
  /** Disabled */
  disabled?: boolean;
  /** ReadOnly */
  readOnly?: boolean;
  /** Determines alignment of the label */
  textAlign?: Align;
  /** Icon */
  icon?: IconType;
  /** Props for the icon */
  iconProps?: IconProps;
  /** borderless */
  borderless?: boolean;
  /** Displays as block */
  block?: boolean;
  /** Class to mark as containing sensitive information */
  secureClassName?: string;
  /** Postfix label */
  suffix?: string;
  /** Boolean indicating whether or not the input is empty */
  hasValue?: boolean;
  /** The string that will be rendered before text input has been entered */
  placeholder?: string;
  /** Displays a label that floats above the selected value */
  label?: string;
};

/** Used within Input components to control width and provide bottom label  */
const InputWrapper = ({
  children,
  className,
  width = 240,
  helperText,
  invalid,
  disabled,
  readOnly,
  textAlign = 'left',
  icon,
  iconProps,
  borderless,
  block,
  secureClassName,
  suffix,
  hasValue,
  placeholder,
  inputBorderStyle,
  label,
  ...rest
}: InputWrapperProps) => {
  const hasPlaceholder = !!placeholder;
  const hasLabel = !!label;
  const hasSuffix = !!suffix;

  const inputClassName = getInputStyles({
    inputBorderClassName,
    invalid,
    disabled,
    readOnly,
    textAlign,
    borderless,
    hasSuffix,
    hasValue,
    hasPlaceholder,
    hasLabel,
  });
  return (
    <Box
      block
      width={width}
      marginLeft="auto"
      marginRight="auto"
      marginBottom={input.layout.marginBottom}
      className={className}
      {...pickGlobalProps(rest)}
    >
      <Box verticalAlign="center" block={block || borderless}>
        {children &&
          React.Children.map(
            children as React.ReactElement[],
            child =>
              child &&
              child.props &&
              React.cloneElement(child, {
                ...child.props,
                className: `${getClassName({
                  inputClassName,
                  className: child.props.className,
                })}${secureClassName ? ` ${secureClassName}` : ''}`,
              })
          )}
        {hasLabel ? (
          <Text as={tags.label} rank={2} marginBottom={0}>
            {label}
          </Text>
        ) : null}
        {hasSuffix ? (
          <Text
            rank={2}
            marginBottom={0}
            marginRight={input.layout.paddingRight}
          >
            {suffix}
          </Text>
        ) : null}
        {borderless ? null : (
          <div
            className={`${inputBorderClassName}${
              inputBorderStyle ? ` ${inputBorderStyle}` : ''
            }`}
          />
        )}
        {icon && (
          <Icon
            type={icon}
            color={color.font.primary}
            size="sm"
            marginRight={input.layout.paddingRight}
            {...iconProps}
          />
        )}
      </Box>
      <SmoothCollapse expanded={Boolean(helperText)}>
        <Text
          rank={3}
          color={invalid ? color.font.error : color.font.primary}
          marginTop={spacer(1)}
          marginLeft={input.layout.paddingLeft}
          marginRight={input.layout.paddingRight}
          marginBottom={0}
        >
          {helperText}
        </Text>
      </SmoothCollapse>
    </Box>
  );
};

export default InputWrapper;
