import { border as borderTokens } from '../border';
import { typography, FontStyle } from '../typography';
import { spacer } from '../layout';
import { Size, sizes } from '../sizes';

const { borderWidth } = borderTokens;

const marginBottom = spacer(3);
const paddingHorizontal = spacer(3);

const buttonTypographyBySize: { [key in Size]: FontStyle } = {
  xs: typography.button[3],
  sm: typography.button[3],
  md: typography.button[2],
  lg: typography.button[1],
  xl: typography.button[1],
  xxl: typography.button[1],
};

const getVerticalPadding = (size: Size): number =>
  (sizes.button[size].height -
    buttonTypographyBySize[size].lineHeight.desktop -
    borderWidth * 2) /
  2;

export type ButtonRank = 'primary' | 'secondary';

type ButtonLayout = {
  marginBottom: number;
  paddingHorizontal: number;
  paddingVertical: number;
  height: number;
  width: number;
};

export const button: {
  [key in Size]: { text: FontStyle; layout: ButtonLayout };
} = {
  xs: {
    layout: {
      marginBottom,
      paddingHorizontal,
      paddingVertical: getVerticalPadding('xs'),
      height: sizes.button.xs.height,
      width: sizes.button.xs.width,
    },
    text: buttonTypographyBySize.xs,
  },
  sm: {
    layout: {
      marginBottom,
      paddingHorizontal,
      paddingVertical: getVerticalPadding('sm'),
      height: sizes.button.sm.height,
      width: sizes.button.sm.width,
    },
    text: buttonTypographyBySize.sm,
  },
  md: {
    layout: {
      marginBottom,
      paddingHorizontal,
      paddingVertical: getVerticalPadding('md'),
      height: sizes.button.md.height,
      width: sizes.button.md.width,
    },
    text: buttonTypographyBySize.md,
  },
  lg: {
    layout: {
      marginBottom,
      paddingHorizontal,
      paddingVertical: getVerticalPadding('lg'),
      height: sizes.button.lg.height,
      width: sizes.button.lg.width,
    },
    text: buttonTypographyBySize.lg,
  },
  xl: {
    layout: {
      marginBottom,
      paddingHorizontal,
      paddingVertical: getVerticalPadding('xl'),
      height: sizes.button.xl.height,
      width: sizes.button.xl.width,
    },
    text: buttonTypographyBySize.xl,
  },
  xxl: {
    layout: {
      marginBottom,
      paddingHorizontal,
      paddingVertical: getVerticalPadding('xxl'),
      height: sizes.button.xxl.height,
      width: sizes.button.xxl.width,
    },
    text: buttonTypographyBySize.xxl,
  },
} as const;
