import _ from 'lodash';
import moment from 'moment';

import { inputTypes } from './constants';
import { ValidationErrorType, ValidationError } from './validationError';

/**
 * PLEASE PLEASE PLEASE NOTE:
 * PLEASE PLEASE PLEASE NOTE:
 * PLEASE PLEASE PLEASE NOTE:
 * PLEASE PLEASE PLEASE NOTE:
 *
 * This is code that, for the time being, is literally duplicated
 * on the frontend and backend. Sigh. We need to move to a place
 * where this will no longer be the case.
 *
 * In the meantime, IF YOU MAKE A CHANGE HERE MAKE SURE THAT YOU
 * ALSO MAKE THE CORRESPONDING CHANGE (frontend or backend, etc.).
 *
 * Ugh. Yuck. Thank you for your time.
 */

export const constraintTypes = {
  required: 'required',
  minValue: 'minValue',
  maxValue: 'maxValue',
  pattern: 'pattern',
  inversePattern: 'inversePattern',
  inclusiveOptions: 'inclusiveOptions',
  minValueViolationMessage: 'minValueViolationMessage',
  maxValueViolationMessage: 'maxValueViolationMessage',
  patternViolationMessage: 'patternViolationMessage',
  inversePatternViolationMessage: 'inversePatternViolationMessage',
  inclusiveOptionsViolationMessage: 'inclusiveOptionsViolationMessage',
};

export const requiredViolationMessage = 'This field is required';
/**
 * Creates a validator to ensure a required value
 * is present.
 *
 * @param {*} value The value
 * @returns {Error|undefined} The validation error (if present)
 */
const validateRequired = value =>
  // Numbers are considered empty, so we explicitly remove numbers from
  // this check.
  _.isEmpty(value) && !_.isNumber(value)
    ? new ValidationError(
        ValidationErrorType.Required,
        requiredViolationMessage
      )
    : undefined;

/**
 * Creates a validator to compare a number against
 * a min value.
 *
 * @param {String} number The min value (inclusive)
 * @param {String} message The message to display
 * @returns {Function}
 */
const createValidateMinValue = (
  number,
  message = `Must be a minimum of ${number}`
) => value =>
  value >= number
    ? undefined
    : new ValidationError(ValidationErrorType.MinValue, message);

/**
 * Creates a validator to compare a number against
 * a max value.
 *
 * @param {String} number The max value (inclusive)
 * @param {String} message The message to display
 * @returns {Function}
 */
const createValidateMaxValue = (
  number,
  message = `Must be a maximum of ${number}`
) => value =>
  value <= number
    ? undefined
    : new ValidationError(
        ValidationErrorType.MaxValue,

        message
      );

/**
 * Creates a validator to check against a serialized
 * regex pattern.
 *
 * @param {String} patternToMatch Serialized regex
 * @param {String} message The message to display
 * @returns {Function}
 */
const createValidatePattern = (
  patternToMatch,
  message = 'Not properly formatted'
) => value =>
  // Ignore if value is empty since a required check
  // will handle that for us if necessary.
  _.isEmpty(value) || new RegExp(patternToMatch).test(value)
    ? undefined
    : new ValidationError(ValidationErrorType.Pattern, message);

/**
 * Creates a validator to check against a serialized
 * regex pattern and returns error for matches.
 *
 * @param {String} patternToMatch Serialized regex
 * @param {String} message The message to display
 * @returns {Function}
 */
const createValidateInversePattern = (
  patternToMatch,
  message = 'Not properly formatted'
) => value =>
  new RegExp(patternToMatch, 'i').test(value)
    ? new ValidationError(ValidationErrorType.InversePattern, message)
    : undefined;

/**
 * Creates a validator to check against a min date range.
 *
 * @param {String} minDate Formatted min-date (inclusive)
 * @param {String} message The message to display
 * @returns {Function}
 */
const createValidateMinDate = (
  minDate,
  message = `Date must be after ${minDate}`
) => value =>
  moment(value).isAfter(minDate)
    ? undefined
    : new ValidationError(ValidationErrorType.MinDate, message);

/**
 * Creates a validator to check against a max date range.
 *
 * @param {String} maxDate Formatted max-date (exclusive)
 * @param {String} message The message to display
 * @returns {Function}
 */
const createValidateMaxDate = (
  maxDate,
  message = `Date must be before ${maxDate}`
) => value =>
  moment(value).isBefore(maxDate)
    ? undefined
    : new ValidationError(ValidationErrorType.MaxDate, message);

/**
 * Creates a validator to check against an inclusive option not being the only selected value.
 *
 * @param {Array<string>} inclusiveOptions Array of values that can not be the only selected value
 * @param {String} message The message to display
 * @returns {Function}
 */
const createValidateInclusiveOptions = (
  inclusiveOptions,
  message = `This option can not be the only one selected.`
) => value =>
  value.length === 1 && inclusiveOptions.includes(value[0])
    ? new ValidationError(ValidationErrorType.InclusiveOptions, message)
    : undefined;

/**
 * Helper to get the validation creators for min/max constraints
 * depending on the question type.
 *
 * @param {String} type
 * @returns {AnyObject}
 */
const getRangeValidationFns = type =>
  type === inputTypes.date
    ? {
        createValidateMin: createValidateMinDate,
        createValidateMax: createValidateMaxDate,
      }
    : {
        createValidateMin: createValidateMinValue,
        createValidateMax: createValidateMaxValue,
      };

/**
 * Gets a set of validation functions that can be reduced
 * iteratively to get a validation state for an answer.
 *
 * @param {AnyObject} question
 *  @property {AnyObject} constraints
 *  @property {String} type The type of the question
 * @returns {Function}
 */
const getValidationFns = ({ constraints = {}, type }) =>
  _.reduce(
    constraints,
    (acc, value, constraint) => {
      if (constraint === constraintTypes.required && value) {
        acc.push(validateRequired);
      } else if (constraint === constraintTypes.minValue) {
        acc.push(
          getRangeValidationFns(type).createValidateMin(
            value,
            constraints.minValueViolationMessage
          )
        );
      } else if (constraint === constraintTypes.maxValue) {
        acc.push(
          getRangeValidationFns(type).createValidateMax(
            value,
            constraints.maxValueViolationMessage
          )
        );
      } else if (constraint === constraintTypes.pattern) {
        acc.push(
          createValidatePattern(value, constraints.patternViolationMessage)
        );
      } else if (constraint === constraintTypes.inversePattern) {
        acc.push(
          createValidateInversePattern(
            value,
            constraints.inversePatternViolationMessage
          )
        );
      } else if (constraint === constraintTypes.inclusiveOptions) {
        acc.push(
          createValidateInclusiveOptions(
            value,
            constraints.inclusiveOptionsViolationMessage
          )
        );
      }

      return acc;
    },
    []
  );

/**
 * Creates a validator for a question with the option of transforming
 * its constraints on demand (used to transform Immutable constraints
 * to plain JS since this logic is shared with the backend).
 *
 * @param {AnyObject} question
 *  @property {AnyObject} constraints
 *  @property {String} type The type of the question
 * @param {AnyObject} ops
 *  @property {Function} transformConstraints
 * @returns {Function}
 */
const createQuestionValidator = (
  { constraints, type },
  { transformConstraints = _.identity } = {}
) => {
  const validationFns = getValidationFns({
    constraints: transformConstraints(constraints),
    type,
  });

  // This reduce acts like an Array.prototype.find, except except
  // instead of returning the first validationFn whose invocation
  // is truthy, we return the return value of that invocation.
  return value =>
    validationFns.reduce(
      (acc, validator) => acc || validator(value),
      undefined
    );
};

export default createQuestionValidator;
