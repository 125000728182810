/*
 * DUPLICATION WARNING
 * DUPLICATION WARNING
 * DUPLICATION WARNING
 *
 * Please do not update this set of constants without also updating
 * the corresponding values in linen.
 * @see https://github.com/fabrictech/linen/blob/master/node/src/lib/quilt/constants.ts#L134-L140
 */

export type QuiltPlatform = 'ios' | 'android';

export const storeIdsByPlatform: {
  [key in QuiltPlatform]: string | undefined;
} = {
  ios: `1472411752`,
  android: 'com.meetfabric.fabric',
};

export const storeLinksByPlatform: {
  [key in QuiltPlatform]: string;
} = {
  ios: `https://itunes.apple.com/us/app/id${storeIdsByPlatform.ios}`,
  android: `https://play.google.com/store/apps/details?id=${storeIdsByPlatform.android}`,
};
