import _ from 'lodash';

/*
 * Selector-creators
 */

export const descendant = val => `& ${val}`;
export const directDescendant = val => `> ${val}`;
export const and = (...vals) => vals.join(', ');
export const adjacentSibling = val => `& + ${val}`;
export const sameAdjacentSibling = adjacentSibling('&');
export const generalSibling = val => `& ~ ${val}`;
export const nthChild = val => `&:nth-child(${val})`;

/*
 * Interactions
 */

const isLibrary = Config.styleguidistLibrary;
const createInteractionSelector = name =>
  and(..._.filter([`&:${name}`, isLibrary && `&[data-simulate-${name}]`]));

export const hover = createInteractionSelector('hover');
export const active = createInteractionSelector('active');
export const visited = createInteractionSelector('visited');
export const checked = createInteractionSelector('checked');
export const focus = createInteractionSelector('focus');

/*
 * Pseudo-selectors
 */
export const before = '&:before';
export const after = '&:after';
export const firstChild = '&:first-child';
export const lastChild = '&:last-child';
export const not = val => `&:not(${val})`;

/*
 * Pseudo-elements
 */
export const placeholder = '::placeholder';
