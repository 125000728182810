import { border, color, spacer } from '@fabrictech/design-tokens';

import { padding } from '../../utils/css/values';
import { hover, active } from '../../utils/css/selectors';
import { minus, fromPx, times, divide } from '../../utils/css/values/px';

const { borderRadius } = border;
const { setAlpha, darken } = color.utils;

const toPerc = num => `${num}%`;

const baseStyles = {
  backgroundColor: setAlpha(color.background.inverse.secondary, 0.3),
  border: 0,
  boxShadow: 'none',
  borderRadius,
  fontFamily: 'Notera',
  padding: padding(0, spacer(0.5)),
  textAlign: 'center',
  cursor: 'pointer',
  [hover]: {
    backgroundColor: setAlpha(darken(color.background.inverse.secondary), 0.3),
  },
  [active]: {
    backgroundColor: setAlpha(color.background.inverse.primary, 0.3),
  },
};

const getSizeStyles = ({
  lineHeight = '16px',
  height,
  width,
  docHeight,
  docWidth,
  docScale,
  x,
  y,
}) => {
  const percHeight = fromPx(divide(height, docHeight)) * 100;
  const percWidth = fromPx(divide(width, docWidth)) * 100;
  const percX = fromPx(divide(x, docWidth)) * 100;
  const percY = fromPx(divide(y, docHeight)) * 100;
  lineHeight = times(lineHeight, docScale);
  return {
    fontSize: minus(lineHeight, spacer(0.5)),
    lineHeight,
    height: toPerc(percHeight),
    width: toPerc(percWidth),
    left: toPerc(percX),
    bottom: toPerc(percY - percHeight),
    position: x && y ? 'absolute' : undefined,
  };
};

const getStyles = ops => ({
  ...baseStyles,
  ...getSizeStyles(ops),
});

export default getStyles;
