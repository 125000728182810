import _ from 'lodash';
import {
  inputTypes as questionTypes,
  answerStatuses as answerStates,
} from '../constants';
import createQuestionValidator from '../createQuestionValidator';
import getGroupAnswerState from './getGroupAnswerState';

/**
 * PLEASE PLEASE PLEASE NOTE:
 * PLEASE PLEASE PLEASE NOTE:
 * PLEASE PLEASE PLEASE NOTE:
 * PLEASE PLEASE PLEASE NOTE:
 *
 * This is code that, for the time being, is literally duplicated
 * on the frontend and backend. Sigh. We need to move to a place
 * where this will no longer be the case.
 *
 * In the meantime, IF YOU MAKE A CHANGE HERE MAKE SURE THAT YOU
 * ALSO MAKE THE CORRESPONDING CHANGE (frontend or backend, etc.).
 *
 * Ugh. Yuck. Thank you for your time.
 */

const getIsRequired = (constraints = {}) => constraints.required;

/**
 *
 * @param {function} createValidator returns a validator based on the question's configuration
 * @param {Object} question QuestionInstance object
 * @param {?string} answer Answer value for the given question
 * @param {?object[]} childQuestionInstances QuestionInstance objects for the direct descendants of this question
 * @return {string} an answerState
 */
export const createGetAnswerState = createValidator => (
  question,
  answer,
  childQuestionInstances
) => {
  const { constraints, type } = question;
  if (
    type === questionTypes.fabricGroup ||
    type === questionTypes.fabricStruct
  ) {
    return getGroupAnswerState(childQuestionInstances);
  }

  if (_.isUndefined(answer)) {
    return getIsRequired(constraints)
      ? answerStates.missing
      : answerStates.absent;
  }
  const validator = createValidator({ constraints, type });
  const validationResult = validator(answer);
  return _.isEmpty(validationResult)
    ? answerStates.valid
    : answerStates.invalid;
};

const getAnswerState = createGetAnswerState(createQuestionValidator);

export default getAnswerState;
