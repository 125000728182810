import React from 'react';
import { css, Rule } from 'glamor';
import SmoothCollapse from 'react-smooth-collapse';

import {
  spacer,
  input,
  IconType,
  RebrandColorPalette,
} from '@fabrictech/design-tokens';
import { Box, Icon, MarketingText } from '@fabrictech/tapestry';
import tags from '@fabrictech/tapestry/constants/tags';
import { padding } from '@fabrictech/tapestry/utils/css/values';
import { cascadeResponsiveProps } from '@fabrictech/tapestry/utils/joinResponsiveProps';
import mediaQueries from '@fabrictech/tapestry/sharedStyles/vars/mediaQueries';

import { getIconStyles, getComponentStyles } from './getStyles';

type MarketingAccordionItemProps = {
  /** children */
  children?: React.ReactNode;
  /** Title for the item */
  title: string;
  /** Whether or not the entry is expanded */
  expanded: boolean;
  /** Icon for the item */
  icon?: IconType;
  /** Color for item title */
  color?: string;
  /** Bottom Border for item */
  borderColor?: string;
  /** URL for anchor tag */
  href?: string;
  /** Path for Link or GatsbyLink */
  to?: string;
  /** The component should be used under the hood */
  as: React.ReactType;
  /** if the item the first */
  first?: boolean;
  /** if the item the last */
  last?: boolean;
  /** Width */
  width?: string | number;
  /* Activate function */
  activate?: () => void;
  /* Optional title color */
  titleColor?: string;
};

// not maintaining link in rebrand
const MarketingAccordionItemIcon = ({
  expanded,
  iconClassName,
}: {
  expanded: boolean;
  iconClassName: Rule;
}) => {
  return (
    <Box
      verticalAlign="center"
      marginRight={spacer(4)}
      responsiveProps={{
        [mediaQueries.lt.sm]: { marginRight: spacer(2) },
      }}
      className={iconClassName}
    >
      <Icon
        type={expanded ? 'minus' : 'plus'}
        size="sm"
        color={RebrandColorPalette.purple}
      />
    </Box>
  );
};

/** Used with `ContAccordion` to display accordion items. */
export class MarketingAccordionItem extends React.Component<
  MarketingAccordionItemProps
> {
  static defaultProps = {
    expanded: false,
    as: tags.a,
  };

  handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event && event.preventDefault();
    this.props.activate && this.props.activate();
  };

  render() {
    const {
      children,
      expanded,
      title,
      icon,
      as: ItemComponent,
      first,
      width,
      titleColor = '#1E063E',
      borderColor = 'rgb(224, 224, 224)',
    } = this.props;
    const iconClassName = css(getIconStyles({ expanded }));
    const componentClassName = css(getComponentStyles({ expanded }));

    return (
      <Box
        width={width}
        flexDirection="column"
        padding={first ? padding(0, 0, 30, 0) : padding(30, 0)}
        className={css({ borderBottom: `1px solid ${borderColor}` })}
      >
        <ItemComponent
          className={componentClassName}
          onClick={this.handleClick}
          href="#"
        >
          <Box verticalAlign="center">
            <MarketingAccordionItemIcon
              expanded={expanded}
              iconClassName={iconClassName}
            />
            {icon && (
              // TODO: update Icon to handle hover state
              <Icon
                type={icon}
                marginRight={input.layout.paddingRight}
                size="md"
                color={
                  expanded
                    ? RebrandColorPalette.purple
                    : RebrandColorPalette.midnight
                }
              />
            )}

            <Box width="100%">
              <MarketingText
                header
                rank={3}
                className={css({
                  marginBottom: 0,
                  ...cascadeResponsiveProps({
                    [mediaQueries.lt.xxl]: {
                      marginBottom: 0,
                      textDecoration: 'none',
                    },
                  }),
                  color: `${titleColor} !important`,
                })}
              >
                {title}
              </MarketingText>
            </Box>
          </Box>
        </ItemComponent>

        <SmoothCollapse expanded={expanded}>
          <Box
            padding={padding(spacer(2), spacer(2), spacer(2), spacer(7))}
            responsiveProps={{
              [mediaQueries.lt.sm]: { paddingLeft: spacer(5) },
            }}
            block
          >
            {children}
          </Box>
        </SmoothCollapse>
      </Box>
    );
  }
}

export default MarketingAccordionItem;
