import _ from 'lodash';
import {
  border as borderTokens,
  color as colorTokens,
} from '@fabrictech/design-tokens';
import { toPx } from './px';
/*
 * TODO
 * It _probably_ isn't the best that we're assigning default values here as it
 * assumes use of OUR particular design framework and, as such, is very
 * opinionated. That said, for now, it's easy for use to use, so :shrug:.
 */

const borderProper = ({
  color = colorTokens.border.base,
  style = borderTokens.borderStyle,
  width = borderTokens.borderWidth,
}) => [toPx(width), style, color].join(' ');

export const defaultBorderStyle = borderProper({});

const border = ops => {
  if (_.isString(ops)) {
    return borderProper({ color: ops });
  } else if (_.isObject(ops)) {
    return borderProper(ops);
  }

  // eslint-disable-next-line no-console
  return console.warn(
    `Improper use of border() cssFormatter - expected argument of type String or type Object but received "${ops}"`
  );
};
export default border;
