import { color, icons, border, sizes, input } from '@fabrictech/design-tokens';

import {
  checked,
  hover,
  focus,
  adjacentSibling,
  and,
} from '../../../../utils/css/selectors';
import { url } from '../../../../utils/css/values';
import createBackgroundSvgString from '../../../../utils/css/createBackgroundSvgString';
import {
  inputStyles,
  inputTextStyles,
  inputPadding,
} from '../../../../sharedStyles/vars/input';
import { focusShadow } from '../../../../sharedStyles/vars/boxShadow';
import tags from '../../../../constants/tags';

const { borderRadius, borderStyle, borderWidth } = border;

export const getParentStyles = () => ({
  position: 'relative',
  textAlign: 'left',
});

export const getLabelStyles = ({
  borderless,
  first,
  last,
  disabled,
  tooltip,
}) => ({
  cursor: 'pointer',
  display: 'block',
  width: '100%',
  minHeight: input.layout.height,
  ...inputTextStyles,
  textAlign: 'left',
  ...(borderless
    ? { border: 'none' }
    : {
        ...inputStyles.base,
        borderTopLeftRadius: first ? borderRadius : 0,
        borderTopRightRadius: first ? borderRadius : 0,
        borderBottomColor: last ? color.input.base.border : 'transparent',
        borderBottomLeftRadius: last && borderRadius,
        borderBottomRightRadius: last && borderRadius,
      }),
  ...inputPadding,
  paddingLeft: sizes.icon.sm + input.layout.paddingLeft * 2,
  ...(tooltip
    ? { paddingRight: sizes.icon.xs + input.layout.paddingRight * 2 }
    : {}),
  ...(disabled
    ? {
        cursor: 'default',
        color: color.utils.setAlpha(color.input.disabled.text, 0.3),
      }
    : {}),
});

export const getInputStyles = ({ borderless, disabled, isSingleSelect }) => ({
  cursor: disabled ? 'auto' : 'pointer',
  height: sizes.icon.sm,
  width: sizes.icon.sm,
  appearance: 'none',
  borderWidth,
  borderStyle,
  borderRadius: isSingleSelect ? 1000 : borderRadius,
  borderColor: inputStyles.base.borderBottomColor,
  backgroundColor: inputStyles.base.backgroundColor,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  margin: 0,
  transition: 'box-shadow, 0.15s, background-color 0.15s',
  boxShadow: '0 0 0 0 transparent',
  position: 'absolute',
  top: input.layout.paddingTop,
  left: input.layout.paddingLeft,
  opacity: disabled ? 0.3 : undefined,
  ...(borderless
    ? {
        backgroundColor: inputStyles.base.backgroundColor,
      }
    : {
        [adjacentSibling(tags.label)]: {
          backgroundColor: inputStyles.base.backgroundColor,
        },
      }),
  [checked]: {
    backgroundImage: url(
      createBackgroundSvgString({
        svg: isSingleSelect ? icons.circle : icons.square,
        fill: color.input.base.accent,
      })
    ),
    borderColor: color.input.active.border,
    backgroundColor: color.input.active.accent,
    [and(focus, hover)]: {
      backgroundColor: color.input.active.accent,
    },
    backgroundSize: sizes.icon.xs,
    ...(disabled ? { backgroundColor: color.input.active.accent } : {}),
  },
  [hover]: {
    borderColor: inputStyles.hover.borderBottomColor,
    ...(borderless
      ? {
          backgroundColor: inputStyles.hover.backgroundColor,
        }
      : {
          [adjacentSibling(tags.label)]: {
            backgroundColor: inputStyles.hover.backgroundColor,
          },
        }),
  },
  [focus]: {
    outline: '1px solid transparent',
    borderColor: inputStyles.active.borderBottomColor,
    boxShadow: focusShadow,
    ...(borderless
      ? {}
      : {
          [adjacentSibling(tags.label)]: {
            backgroundColor: inputStyles.active.backgroundColor,
          },
        }),
  },
});

export const getTooltipStyles = ({ disabled }) => ({
  position: 'absolute',
  top: input.layout.paddingTop + borderWidth,
  right: input.layout.paddingRight + borderWidth,
  ...(disabled
    ? {
        opacity: 0.3,
        pointerEvents: 'none',
      }
    : {}),
});
