import { spacer } from '@fabrictech/design-tokens';
import createGetStyles from '../../createGetStyles';

const stackingContainerStyles = {
  flexDirection: 'row',
  justifyContent: 'space-between',
  mobileBreakpoint: {
    flexDirection: 'column',
  },
};

const labelStyles = {
  flexDirection: 'column',
};

const footerStyles = {
  margin: 'auto',
  padding: 0,
  mobileBreakpoint: {
    height: spacer(4.5),
  },
};

const styles = {
  stackingContainerStyles,
  labelStyles,
  footerStyles,
};
export default createGetStyles(styles);
