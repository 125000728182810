import React from 'react';
import _ from 'lodash';

import createAddSortedArrayValue from '@fabrictech/questionnaire/utils/createAddSortedArrayValue';

import InputCheckbox from '@fabrictech/tapestry/components/InputCheckbox';

const removeArrayValue = (arr, value) =>
  _.reject(arr, entry => entry === value);

const getValuesFromChildren = children =>
  React.Children.toArray(children).reduce(
    (acc, child) => acc.concat(child.props.value),
    []
  );

class InputCheckboxGroup extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.state = {
      value: this.getInitialValue(),
    };

    /*
     * When adding values, we sort them based on their order which
     * they are initially provided. Because we sort them when they
     * are added, no sorting is necessary upon removal.
     *
     * If in the future these possible values may change, this will
     * need to be recomputed in a componentWillReceiveProps call.
     */
    const possibleValues = getValuesFromChildren(props.children);
    this.addValue = createAddSortedArrayValue(possibleValues);
    this.removeValue = removeArrayValue;
  }

  getInitialValue() {
    /*
     * Because redux-form/immutable expects the values passed to initialValues
     * to be passed as immutable, we may need to cast.
     */
    if (this.props.value === '') {
      return [];
    }
    return this.props.value.toJS ? this.props.value.toJS() : this.props.value;
  }

  getValue() {
    return this.state.value.length > 0 ? this.state.value : [];
  }

  handleChange(event) {
    const { value, checked } = event.target;
    const updater = checked ? this.addValue : this.removeValue;
    this.setState(
      prevState => ({ value: updater(prevState.value, value) }),
      () => {
        this.props.onChange(this.getValue());
      }
    );
  }

  handleBlur() {
    const { onBlur } = this.props;
    onBlur && onBlur(this.getValue());
  }

  handleFocus() {
    const { onFocus } = this.props;
    onFocus && onFocus();
  }

  render() {
    const { children, value, ...rest } = this.props;
    return (
      <InputCheckbox {...rest}>
        {React.Children.map(children, (child, key) =>
          React.cloneElement(child, {
            onChange: this.handleChange,
            onBlur: this.handleBlur,
            onFocus: this.handleFocus,
            checked: _.includes(this.state.value, child.props.value),
            type: 'checkbox',
            key,
          })
        )}
      </InputCheckbox>
    );
  }
}

InputCheckboxGroup.defaultProps = {
  value: [],
};

export default InputCheckboxGroup;
