import {
  TextRank,
  FontStyle,
  RebrandColorPalette,
  Align,
} from '@fabrictech/design-tokens';

import * as mediaQueries from '../../sharedStyles/vars/mediaQueries';
import tags from '../../constants/tags';
import {
  descendant,
  hover,
  focus,
  visited,
  active,
  and,
} from '../../utils/css/selectors';
import { getStyleObjectFromFontStyle } from '../../constants/typography';

export type MarketingTextRank = TextRank | 5 | 6;

export const sharedSpacingStyles = {
  marginBottom: 18,
  [mediaQueries.lt.sm]: {
    marginBottom: 14,
  },
};

const sharedTagStyles = {
  [descendant(tags.a)]: {
    color: RebrandColorPalette.midnight,
    textDecoration: 'none',
    borderBottom: `2px solid ${RebrandColorPalette.purple}`,
    paddingBottom: 1,
    [and(visited)]: {
      color: RebrandColorPalette.midnight,
      backgroundColor: 'transparent',
    },
    [and(focus, hover, active)]: {
      textDecoration: 'none',
      backgroundColor: RebrandColorPalette.purple,
      color: `${RebrandColorPalette.white} !important`,
      transition: 'all 125ms linear',
    },
  },
  // TODO: why do we need this?
  [descendant(tags.u)]: { textDecoration: 'none' },
};

export const baseStyles: FontStyle = {
  fontFamily: 'Inter',
  fontSize: { desktop: 44, mobile: 34 },
  lineHeight: { desktop: 53, mobile: 38 },
  textTransform: 'none',
  color: RebrandColorPalette.midnight,
  fontWeight: 'semibold',
  letterSpacing: 0,
};
export const marketingHeader: { [key in MarketingTextRank]: FontStyle } = {
  1: {
    ...baseStyles,
    fontSize: { desktop: 44, mobile: 34 },
    lineHeight: { desktop: 53, mobile: 38 },
  },
  2: {
    ...baseStyles,
    fontSize: { desktop: 30, mobile: 26 },
    lineHeight: { desktop: 36, mobile: 31 },
  },
  3: {
    ...baseStyles,
    fontSize: { desktop: 24, mobile: 20 },
    lineHeight: { desktop: 29, mobile: 24 },
  },
  4: {
    ...baseStyles,
    fontSize: { desktop: 18, mobile: 16 },
    lineHeight: { desktop: 22, mobile: 19 },
  },
  5: {
    ...baseStyles,
    fontSize: { desktop: 14, mobile: 14 },
    lineHeight: { desktop: 17, mobile: 17 },
  },
  6: {
    ...baseStyles,
    fontSize: { desktop: 13, mobile: 11 },
    lineHeight: { desktop: 16, mobile: 13 },
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 1.5,
  },
};

const paragraphBaseStyles: FontStyle = {
  ...baseStyles,
  fontWeight: 'light',
  fontSize: { desktop: 12, mobile: 9 },
  lineHeight: { desktop: 20, mobile: 14 },
};
export const marketingParagraph: { [key in MarketingTextRank]: FontStyle } = {
  1: {
    ...paragraphBaseStyles,
    fontSize: { desktop: 18, mobile: 16 },
    lineHeight: { desktop: 26, mobile: 24 },
  },
  2: {
    ...paragraphBaseStyles,
    fontSize: { desktop: 16, mobile: 14 },
    lineHeight: { desktop: 24, mobile: 20 },
  },
  3: {
    ...paragraphBaseStyles,
    fontSize: { desktop: 14, mobile: 12 },
    lineHeight: { desktop: 20, mobile: 17 },
  },
  4: {
    ...paragraphBaseStyles,
  },
  // TODO: remove 4-6 when type is updated
  5: { ...paragraphBaseStyles },
  6: { ...paragraphBaseStyles },
};

export const getBaseStyles = ({
  header,
  rank,
  textAlign,
  maxWidth,
  color,
}: {
  header?: boolean;
  rank: MarketingTextRank;
  textAlign: Align;
  maxWidth: string | number;
  color?: string;
}) => {
  const textRank = !header && rank > 4 ? 4 : rank; // TODO: handle better, possibly via type guard
  const fontStyle = header
    ? marketingHeader[textRank]
    : marketingParagraph[textRank];

  const style = getStyleObjectFromFontStyle(fontStyle);
  return {
    textAlign,
    maxWidth,
    ...style,
    ...(color ? { color } : {}),
    ...sharedSpacingStyles,
    ...sharedTagStyles,
  };
};

export default getBaseStyles;
