import React, { Component } from 'react';
import _ from 'lodash';
import SmoothCollapse from 'react-smooth-collapse';

import { spacer, sizes, PartialSize } from '@fabrictech/design-tokens';

import Button from '../Button';
import Text from '../Text';
import Box from '../Box';
import UtilLoadingMask from '../UtilLoadingMask';

import { padItemsToFillGridEvenly } from './helpers';

type ClickHandler = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => void;

type HandleDayNavigation = (dir: Direction) => void;

type HandleSelectAppointment = (
  appt: Partial<Appt & { apptDay: string }>
) => void;

type FormatDate = (yyyyMmDd: string) => string;

type Appt = {
  time: string;
  examinerId?: string;
};

type ApptsForDay = {
  day: string;
  appointments: Array<Appt>;
};

type Direction = 'left' | 'right';

type InputAppointmentPickerSize = PartialSize<'sm' | 'md'>;

type InputAppointmentPickerProps = {
  /** Sorted list of available appointments */
  appointments: Array<ApptsForDay>;
  /** Function to handle clicks on appointment buttons */
  onChange: Function;
  /** Function to format the date displayed on the day scroller */
  formatDate: FormatDate;
  /** Controls how many columns are displayed.
   * If the number of appointments does not evenly fill the grid, borderless disabled buttons are inserted to fill the empty slots
   */
  columns: number;
  /** Controls how many appointment rows display above the "More Appointments" button.
   * If the number of appointments does not evenly fill the grid, borderless disabled buttons are inserted to fill the empty slots
   */
  minimumRows: number;
  /** Button size in the component */
  size: InputAppointmentPickerSize;
  /** Current index within the list of appointments */
  dayIndex: number;
  /** loading state */
  isLoading: boolean;
};

/**
 * allows navigation between different days via arrow buttons.
 */
const DayScroller = ({
  isLoading,
  dayIndex,
  apptDay,
  allAppointments,
  handleDayNavigation,
  formatDate,
  size,
}: {
  isLoading: boolean;
  dayIndex: number;
  apptDay: string;
  allAppointments: Array<ApptsForDay>;
  handleDayNavigation: HandleDayNavigation;
  formatDate: FormatDate;
  size: InputAppointmentPickerSize;
}) => (
  <Box align="stretch" verticalAlign="center" marginBottom={spacer(2)}>
    <Button
      rank="secondary"
      onClick={() => handleDayNavigation('left')}
      size={sizes.getRelativeSize({ size, change: -1 })}
      icon="arrowLeft"
      disabled={isLoading || dayIndex <= 0}
      margin={0}
    />
    <Box width="80%" align="center">
      <UtilLoadingMask isLoading={isLoading} shouldPulse={isLoading}>
        <Text rank={size === 'sm' ? 2 : 1} marginBottom={0} textAlign="center">
          {isLoading ? 'Searching for Appointments ...' : formatDate(apptDay)}
        </Text>
      </UtilLoadingMask>
    </Box>
    <Button
      rank="secondary"
      onClick={() => handleDayNavigation('right')}
      size={sizes.getRelativeSize({ size, change: -1 })}
      icon="arrowRight"
      disabled={isLoading || dayIndex >= allAppointments.length - 1}
      margin={0}
    />
  </Box>
);

const unavailable = '—';

/**
 * Arranges appointments into a grid of buttons displaying appointment times.
 */
const AppointmentButtons = ({
  appointments,
  apptDay,
  columns,
  marginBottom = 0,
  size,
  handleSelectAppointment,
}: {
  appointments: Array<Appt>;
  apptDay: string;
  columns: number;
  marginBottom?: number;
  size: InputAppointmentPickerSize;
  handleSelectAppointment: HandleSelectAppointment;
}) => (
  <Box flexDirection="column" marginBottom={marginBottom}>
    {_.chunk(appointments, columns).map((rowItems, index) => (
      <Box align="stretch" key={index}>
        {rowItems.map(({ time, examinerId }, subIndex) => (
          <Button
            as="button"
            onClick={() =>
              handleSelectAppointment({ time, examinerId, apptDay })
            }
            rank="secondary"
            marginBottom={spacer(2)}
            marginLeft={0}
            marginRight={0}
            padding={spacer(1)}
            // hack to create a column gap but allow buttons to expand width
            width={columns === 1 ? '100%' : `${100 / columns - 2}%`}
            size={size}
            key={subIndex}
            borderless={time === unavailable}
            disabled={time === unavailable}
          >
            {time}
          </Button>
        ))}
      </Box>
    ))}
  </Box>
);

/**
 * If there are no appointments on the currently viewed day there is a future day with availability,
 * this UI allows jumping to that day.
 * Disabled buttons are rendered below to maintain consistency with the rest of the interface.
 */
const JumpToNextAvailable = ({
  minimumRows,
  columns,
  size,
  jumpToIndex,
  nextAvailableIndex,
}: {
  minimumRows: number;
  columns: number;
  size: InputAppointmentPickerSize;
  jumpToIndex: Function;
  nextAvailableIndex: number;
}) => (
  <Box
    flexDirection="column"
    marginBottom={size === 'sm' ? spacer(6) : spacer(8)}
  >
    <Button
      width="100%"
      size={size}
      rank="secondary"
      onClick={() => jumpToIndex(nextAvailableIndex)}
    >
      Next Available
    </Button>
    {_.chunk(
      new Array(columns * (minimumRows - 1)).fill(undefined),
      columns
    ).map((items, index) => (
      <Box align="stretch" key={index}>
        {items.map((rowItems, subIdx) => (
          <Button
            key={subIdx}
            size={size}
            disabled={true}
            borderless={true}
            rank="secondary"
            marginBottom={spacer(2)}
            marginLeft={0}
            marginRight={0}
            padding={spacer(1)}
            // hack to create a column gap but allow buttons to expand width
            width={columns === 1 ? '100%' : `${100 / columns - 2}%`}
          >
            {unavailable}
          </Button>
        ))}
      </Box>
    ))}
  </Box>
);

/**
 * manipulates the list of appointments for a given day if necessary,
 * and renders the grids of buttons with AppointmentButtons.
 *
 * provides a "more appointments" button if there is overflow.
 */
const PaddedAppointmentAvailability = ({
  apptDay,
  appointments,
  expanded = false,
  toggleExpandAppointments,
  minimumRows,
  columns,
  size,
  handleSelectAppointment,
  jumpToIndex,
  findNextAvailable,
}: {
  apptDay: string;
  appointments: Array<Appt>;
  expanded: boolean;
  toggleExpandAppointments: ClickHandler;
  minimumRows: number;
  columns: number;
  size: InputAppointmentPickerSize;
  handleSelectAppointment: HandleSelectAppointment;
  jumpToIndex: Function;
  findNextAvailable: () => number | null;
}) => {
  const minimumGridSize = minimumRows * columns;

  if (!appointments.length) {
    const nextAvailableIndex = findNextAvailable();
    if (nextAvailableIndex !== null) {
      return (
        <JumpToNextAvailable
          nextAvailableIndex={nextAvailableIndex}
          columns={columns}
          minimumRows={minimumRows}
          size={size}
          jumpToIndex={jumpToIndex}
        />
      );
    }
  }

  const allApptsWithPadding = padItemsToFillGridEvenly({
    items: appointments,
    fillerItem: { examinerId: unavailable, time: unavailable },
    minimumGridSize,
    columns,
  });

  const initialAppointments = _.slice(allApptsWithPadding, 0, minimumGridSize);

  const buttonProps = {
    apptDay,
    minimumRows,
    columns,
    size,
    handleSelectAppointment,
  };

  if (allApptsWithPadding.length === minimumGridSize) {
    return (
      <AppointmentButtons
        appointments={initialAppointments}
        // adding a large margin here makes up for the "more appts" button being omitted,
        // and prevents content under the component from jumping around
        marginBottom={size === 'sm' ? spacer(7) : spacer(9)}
        {...buttonProps}
      />
    );
  }
  const additionalAppointments = _.slice(allApptsWithPadding, minimumGridSize);

  return (
    <div>
      <AppointmentButtons appointments={initialAppointments} {...buttonProps} />
      <SmoothCollapse expanded={expanded}>
        <AppointmentButtons
          appointments={additionalAppointments}
          {...buttonProps}
        />
      </SmoothCollapse>
      <Button
        rank="secondary"
        size={size}
        width="100%"
        onClick={toggleExpandAppointments}
      >
        {expanded ? 'fewer appointments' : 'more appointments'}
      </Button>
    </div>
  );
};

const _InputAppointmentPicker = ({
  isLoading,
  dayIndex,
  apptDay,
  allAppointments,
  handleDayNavigation,
  apptsForSelectedDay,
  expanded,
  toggleExpandAppointments,
  formatDate,
  columns,
  minimumRows,
  size,
  handleSelectAppointment,
  jumpToIndex,
  findNextAvailable,
}: {
  isLoading: boolean;
  dayIndex: number;
  apptDay: string;
  allAppointments: Array<ApptsForDay>;
  handleDayNavigation: HandleDayNavigation;
  apptsForSelectedDay: Array<Appt>;
  expanded: boolean;
  toggleExpandAppointments: ClickHandler;
  formatDate: FormatDate;
  columns: number;
  minimumRows: number;
  size: InputAppointmentPickerSize;
  handleSelectAppointment: HandleSelectAppointment;
  jumpToIndex: Function;
  findNextAvailable: () => number | null;
}) => (
  <Box flexDirection="column" padding={spacer(1)}>
    <DayScroller
      isLoading={isLoading}
      formatDate={formatDate}
      dayIndex={dayIndex}
      apptDay={apptDay}
      allAppointments={allAppointments}
      handleDayNavigation={handleDayNavigation}
      size={size}
    />
    <SmoothCollapse expanded={!isLoading}>
      <PaddedAppointmentAvailability
        apptDay={apptDay}
        appointments={apptsForSelectedDay}
        expanded={expanded}
        toggleExpandAppointments={toggleExpandAppointments}
        columns={columns}
        minimumRows={minimumRows}
        size={size}
        handleSelectAppointment={handleSelectAppointment}
        findNextAvailable={findNextAvailable}
        jumpToIndex={jumpToIndex}
      />
    </SmoothCollapse>
  </Box>
);

export class InputAppointmentPicker extends Component<
  InputAppointmentPickerProps
> {
  static defaultProps = {
    dayIndex: 0,
    columns: 3,
    minimumRows: 3,
    size: 'sm',
    onChange: () => {},
    formatDate: (yyyymmdd: string) => yyyymmdd,
    isLoading: false,
  };
  state = {
    dayIndex: this.props.dayIndex,
    apptDay: '',
    examinerId: '',
    apptTime: '',
    expanded: false,
    value: {},
    isLoading: false,
  };

  handleDayNavigation = (dir: Direction) => {
    const { dayIndex } = this.state;
    if (dir === 'left' && dayIndex > 0) {
      this.setState({ dayIndex: dayIndex - 1, expanded: false });
    }
    if (dir === 'right' && dayIndex < this.props.appointments.length - 1) {
      this.setState({ dayIndex: dayIndex + 1, expanded: false });
    }
  };

  toggleExpandAppointments = () => {
    const prevState = this.state.expanded;
    this.setState({ expanded: !prevState });
  };

  handleSelectAppointment = (appt: Partial<Appt & { apptDay: string }>) => {
    this.setState({ value: appt }, () => this.props.onChange(appt));
  };

  findNextAvailable = () => {
    const { dayIndex } = this.state;
    const { appointments } = this.props;

    // if UI is already on the last page, early exit
    if (dayIndex >= appointments.length - 1) {
      return null;
    }

    let nextAvailabilityIndex = dayIndex;
    while (
      !this.props.appointments[nextAvailabilityIndex].appointments.length &&
      nextAvailabilityIndex < appointments.length - 1
    ) {
      nextAvailabilityIndex += 1;
    }

    // if we get to the end of the list and there aren't any appts on that day,
    // we return null to avoid pointing to the end of the list.
    return appointments[nextAvailabilityIndex].appointments.length
      ? nextAvailabilityIndex
      : null;
  };

  jumpToIndex = (targetIdx: number) => {
    this.setState({ dayIndex: targetIdx });
  };

  render() {
    const {
      appointments: allAppointments,
      formatDate,
      columns,
      minimumRows,
      size,
      isLoading,
    } = this.props;
    const { dayIndex, expanded } = this.state;
    const { day: apptDay, appointments: apptsForSelectedDay } = isLoading
      ? { day: '', appointments: [] }
      : allAppointments[dayIndex];

    return (
      <_InputAppointmentPicker
        isLoading={isLoading}
        dayIndex={dayIndex}
        apptDay={apptDay}
        allAppointments={allAppointments}
        handleDayNavigation={this.handleDayNavigation}
        apptsForSelectedDay={apptsForSelectedDay}
        expanded={expanded}
        toggleExpandAppointments={this.toggleExpandAppointments}
        formatDate={formatDate}
        columns={columns}
        minimumRows={minimumRows}
        size={size}
        handleSelectAppointment={this.handleSelectAppointment}
        findNextAvailable={this.findNextAvailable}
        jumpToIndex={this.jumpToIndex}
      />
    );
  }
}

export default InputAppointmentPicker;
