/**
 * **DUPLICATION WARNING:** Keep versions in sync.
 * node/services/underwriting/lib/agency/constants/index.ts
 */

import { AddressRegion } from '../text';

type Agency = {
  name: string;
  agentName: string;
  agentFirstName: string;
  agentLastName: string;
  agentMiddleName: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;
  email: string;
  agencyNumber: string;
  agentNumber: string;
  devQuoteAgentNumber: string;
  licenseNumbers: { [key in AddressRegion]: string };
  agencyNumbers: { [key in AddressRegion]: string };
  agentNumber6Digit: number;
};

// TODO GRW-4360 - update this when information becomes available
const glaAgencyNumber = 'TODO';

// TODO GRW-4360 - update this when information becomes available
const glaAgentNumber6Digit = 800001;

const agentLicenseNumbers = {
  [AddressRegion.AK]: '100132423',
  [AddressRegion.AL]: '761807',
  [AddressRegion.AR]: '17816663',
  [AddressRegion.AZ]: '1149538',
  [AddressRegion.CA]: '0L06546',
  [AddressRegion.CO]: '510070',
  [AddressRegion.CT]: '2526385',
  [AddressRegion.DC]: '3093519',
  [AddressRegion.DE]: '1409603',
  [AddressRegion.FL]: 'W336321',
  [AddressRegion.GA]: '3084737',
  [AddressRegion.HI]: '440995',
  [AddressRegion.IA]: '17816663',
  [AddressRegion.ID]: '576167',
  [AddressRegion.IL]: '17816663',
  [AddressRegion.IN]: '3165051',
  [AddressRegion.KS]: '17816663',
  [AddressRegion.KY]: '918505',
  [AddressRegion.LA]: '696580',
  [AddressRegion.MA]: '2005824',
  [AddressRegion.MD]: '3000009125',
  [AddressRegion.ME]: 'PRN269719',
  [AddressRegion.MI]: '771881',
  [AddressRegion.MN]: '40479915',
  [AddressRegion.MO]: '8378839',
  [AddressRegion.MS]: '10437329',
  [AddressRegion.MT]: '100154187',
  [AddressRegion.NC]: '17816663',
  [AddressRegion.ND]: '17816663',
  [AddressRegion.NE]: '17816663',
  [AddressRegion.NH]: '2344415',
  [AddressRegion.NJ]: '1613761',
  [AddressRegion.NM]: '424519',
  [AddressRegion.NV]: '3164903',
  [AddressRegion.NY]: 'LA-1408467',
  [AddressRegion.OH]: '1109518',
  [AddressRegion.OK]: '100261287',
  [AddressRegion.OR]: '17816663',
  [AddressRegion.PA]: '778195',
  [AddressRegion.RI]: '2335102',
  [AddressRegion.SC]: '819775',
  [AddressRegion.SD]: '40421315',
  [AddressRegion.TN]: '2336984',
  [AddressRegion.TX]: '2106226',
  [AddressRegion.UT]: '576087',
  [AddressRegion.VA]: '995368',
  [AddressRegion.VT]: '3165243',
  [AddressRegion.WA]: '921890',
  [AddressRegion.WI]: '17816663',
  [AddressRegion.WV]: '17816663',
  [AddressRegion.WY]: '314265',
};

const agencyNumbers = {
  [AddressRegion.AK]: '100138036',
  [AddressRegion.AL]: '775904',
  [AddressRegion.AR]: '100166524',
  [AddressRegion.AZ]: '1166890',
  [AddressRegion.CA]: '0L42465',
  [AddressRegion.CO]: '524626',
  [AddressRegion.CT]: '2543353',
  [AddressRegion.DC]: '3000043690',
  [AddressRegion.DE]: '3000042461',
  [AddressRegion.FL]: 'L098093',
  [AddressRegion.GA]: '192479',
  [AddressRegion.HI]: '447371',
  [AddressRegion.IA]: '1002282982',
  [AddressRegion.ID]: '597072',
  [AddressRegion.IL]: '100789345',
  [AddressRegion.IN]: '3205930',
  [AddressRegion.KS]: '371832788',
  [AddressRegion.KY]: '938009',
  [AddressRegion.LA]: '715415',
  [AddressRegion.MA]: '2017049',
  // TODO GRW-4360 - update this when information becomes available
  [AddressRegion.MD]: 'TODO', // Maryland missing from ToU
  [AddressRegion.ME]: 'AGN279128',
  [AddressRegion.MI]: '111281',
  [AddressRegion.MN]: '40503125',
  [AddressRegion.MO]: '8394649',
  [AddressRegion.MS]: '15030658',
  [AddressRegion.MT]: '3000081868',
  [AddressRegion.NC]: '1000506928',
  [AddressRegion.ND]: '3000042571',
  [AddressRegion.NE]: '100260480',
  [AddressRegion.NH]: '2357749',
  [AddressRegion.NJ]: '1628509',
  [AddressRegion.NM]: '100014875',
  [AddressRegion.NV]: '3205934',
  [AddressRegion.NY]: 'LA-1442670',
  [AddressRegion.OH]: '1132193',
  [AddressRegion.OK]: '0100278569',
  [AddressRegion.OR]: '0100300863',
  [AddressRegion.PA]: '796854',
  // TODO GRW-4360 - update this when information becomes available
  [AddressRegion.RI]: 'TODO', // Rhode Island missing from ToU
  [AddressRegion.SC]: '210411',
  [AddressRegion.SD]: '10019313',
  [AddressRegion.TN]: '2352742',
  [AddressRegion.TX]: '2150423',
  [AddressRegion.UT]: '597406',
  [AddressRegion.VA]: '140948',
  [AddressRegion.VT]: '3233500',
  [AddressRegion.WA]: '937533',
  [AddressRegion.WI]: '3000086472',
  [AddressRegion.WV]: '100232984',
  [AddressRegion.WY]: '326855',
};

const glaAgency: Agency = {
  name: 'Gerber Life Agency, LLC',
  agentName: 'Adam Erlebacher',
  agentFirstName: 'Adam',
  agentLastName: 'Erlebacher',
  agentMiddleName: 'Jeffrey',
  streetAddress: '400 Broadway St',
  city: 'Cincinnati',
  state: 'OH',
  zip: '45202',
  phoneNumber: '+19179094131',
  email: 'adam@meetfabric.com',
  agencyNumber: glaAgencyNumber,
  // TODO GRW-4360 - update this when information becomes available
  agentNumber: `${glaAgencyNumber}${glaAgentNumber6Digit}`,
  // From Raquib regarding dev agent numbers.
  // 888001231 to quote VantisTerm (this will return all current Vantis products)
  // 888001235 to quote VelocityTerm (this will return all future Vantis products)
  devQuoteAgentNumber: '888001235',
  licenseNumbers: agentLicenseNumbers,
  agencyNumbers,
  agentNumber6Digit: glaAgentNumber6Digit,
};

/* END OF DUPLICATION, All code below this is unique to this file */

export const getAgency = (): Agency => {
  return glaAgency;
};
