const buildQuoteInteractionProperties = ({
  name,
  product,
  quoteInputs,
  quoteOutputs,
  selectedSliderFaceAmount,
}) => ({
  name,
  product,
  quoteInputs,
  quoteOutputs,
  selectedSliderFaceAmount,
});

export default buildQuoteInteractionProperties;
