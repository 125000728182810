export enum WebviewPostMessages {
  premiumAuSharePolicySuccess = 'premiumAuSharePolicySuccess',
  premiumAuSharePolicySkipped = 'premiumAuSharePolicySkipped',
  premiumAuRTU = 'premiumAuRTU',
  premiumAuHealthExamSuccessfullyScheduled = 'premiumAuHealthExamSuccessfullyScheduled',
  premiumAuHealthExamScheduleOverPhone = 'premiumAuHealthExamScheduleOverPhone',
  premiumAuMTWills = 'premiumAuMTWills',
  intercomHide = 'intercomHide',
  finishedLinkingAccount = 'finishedLinkingAccount',
  closedPlaidModal = 'closedPlaidModal',
  reviewQuiltModal = 'reviewQuiltModal',
  signOutFromWebview = 'signOutFromWebview',
  navigationStateChange = 'navigationStateChange',
}
