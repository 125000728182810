import { css } from 'glamor';
import { color, spacer, border } from '@fabrictech/design-tokens';

import { QuestionStatus } from '@fabrictech/questionnaire/utils/constants';

import { descendant, nthChild } from '../../../../utils/css/selectors';
import { toPx } from '../../../../utils/css/values/px';
import * as mediaQueries from '../../../../sharedStyles/vars/mediaQueries';

// This is a workaround for this issue - https://github.com/threepointone/glamor/issues/332
const cssShim: any = css; // eslint-disable-line @typescript-eslint/no-explicit-any

const { borderWidth } = border;
const height = 32;
const width = 80;

const flagColorsForStatus: { [key in QuestionStatus]: string } = {
  required: color.background.inverse.tertiary,
  optional: color.background.secondary,
  working: color.background.inverse.tertiary,
  invalid: color.base.error,
  valid: color.background.inverse.tertiary,
};

const flagTranslateForStatus: { [key in QuestionStatus]: number } = {
  required: spacer(6),
  optional: spacer(6),
  working: spacer(2.5),
  invalid: spacer(2.5),
  valid: spacer(2),
};

export const getWrapperStyles = () => ({
  left: `calc(100% + ${toPx(borderWidth)})`,
  overflow: 'hidden',
  position: 'absolute',
  zIndex: 1,
  [mediaQueries.lt.md]: {
    left: 'auto',
    right: 0,
  },
});

export const getSvgStyles = ({ status }: { status: QuestionStatus }) => ({
  width,
  height,
  '& svg path ': {
    fill: flagColorsForStatus[status],
    stroke: color.border.base,
  },
});

export const getFlagStyles = ({ status }: { status: QuestionStatus }) => ({
  width,
  height,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: status === 'valid' ? 0 : 1,
  transition: 'transform .25s ease-in-out, opacity .25s ease-in-out',
  transform: `translate3d(-${toPx(flagTranslateForStatus[status]) || 0}, 0, 0)`,
  [mediaQueries.lt.md]: {
    transform: `translate3d(${toPx(flagTranslateForStatus[status]) ||
      '100%'}, 0, 0)`,
  },
});

const animation = cssShim.keyframes({
  '0%': { opacity: 1 },
  '25%': { opacity: 0 },
  '50%': { opacity: 1 },
});

export const getFlagSpinnerStyles = () => ({
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  [descendant('div')]: {
    margin: spacer(0.25),
    width: spacer(0.75),
    height: spacer(0.75),
    background: color.background.primary,
    borderRadius: '50%',
    animation: `${animation} 0.5s infinite ease-in-out`,
    [nthChild(2)]: {
      animationDelay: '0.1s',
    },
    [nthChild(3)]: {
      animationDelay: '0.2s',
    },
  },
});
