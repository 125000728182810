import { inputTypes } from '@fabrictech/questionnaire/utils/constants';

import InputToggle from '@fabrictech/tapestry/components/InputToggle';
import InputRadioText from '@fabrictech/tapestry/components/InputRadioText';
import InputDropdown from '@fabrictech/tapestry/components/InputDropdown';
import InputTag from '@fabrictech/tapestry/components/InputTag';
import InputText from '@fabrictech/tapestry/components/InputText';
import InputTextPassword from '@fabrictech/tapestry/components/InputTextPassword';
import InputDateDropdown from '@fabrictech/tapestry/components/InputDateDropdown';
import InputTextAuto from '@fabrictech/tapestry/components/InputTextAuto';

import InputCheckboxGroup from './InputCheckboxGroup';
import FabricGroupInput from './FabricGroupInput';

// Values whose presence indicates that the singleselection component
// should be displayed as an InputToggle. We convert to lowercase as a
// safety measure.
const inputToggleSingleSelectionValues = [
  'Yes',
  'No',
  'Male',
  'Female',
  'Personal',
  'Professional',
].map(entry => entry.toLowerCase());

/**
 * Should the singleselection component render as an InputToggle based
 * on its options.
 *
 * @param {Immutable.List} options
 * @returns {boolean}
 */
export const getShouldRenderInputToggle = options =>
  options.size === 2 &&
  options.every(
    option =>
      inputToggleSingleSelectionValues.indexOf(option.get('id').toLowerCase()) >
      -1
  );

/**
 * Should the singleselection component render as an InputRadioText based
 * on its options.
 *
 * @param {Immutable.List} options
 * @returns {boolean}
 */
const getShouldRenderRadioGroup = options =>
  options.size < 7 ||
  (options.size < 7 && options.some(option => option.get('label').length > 18));

/**
 * Creates a helper used to get the Questionnaire input component
 * to render when a question is of type singleselection.
 *
 * @param {Object} components
 * @returns {Function}
 */
const getSingleSelectionComponent = options => {
  if (getShouldRenderInputToggle(options)) {
    return InputToggle;
  }

  if (getShouldRenderRadioGroup(options)) {
    return InputRadioText;
  }

  return InputDropdown;
};

/**
 * Creates a helper used to get the Questionnaire component according
 * to a particular inputType.
 *
 * @param {object} inputComponentsOptions Options to pass through to createQuestionnaireInputComponents
 * @returns {Function}
 */
const getInputForInputType = ({ type, options }) => {
  switch (type) {
    case inputTypes.assessmentFactorSearch: {
      return InputTag;
    }

    case inputTypes.streetAddress:
      return InputTextAuto;

    case inputTypes.password: {
      return InputTextPassword;
    }

    case inputTypes.text:
    case inputTypes.number: {
      return InputText;
    }

    case inputTypes.singleSelection: {
      return getSingleSelectionComponent(options);
    }

    case inputTypes.date: {
      return InputDateDropdown;
    }

    case inputTypes.multiSelection: {
      return InputCheckboxGroup;
    }
    case inputTypes.fabricGroup:
      return FabricGroupInput;

    case inputTypes.section:
    case inputTypes.group:
    case inputTypes.fabricGroupItem:
    case inputTypes.fabricStruct:
    case inputTypes.label:
    case inputTypes.assessmentFactorGroup: {
      return null;
    }

    default:
      console.error(
        `Could not find an Input component corresponding to inputType "${type}"`
      );
      return null;
  }
};

export default getInputForInputType;
